import React, { useEffect, useState } from 'react';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import {
  Button,
  Tooltip,
  Switch,
  TextInput,
  Select,
  MantineProvider,
  useMantineTheme,
} from '@mantine/core';
import { IconSearch, IconSearchOff, IconRefresh } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import { ExportToCsv } from 'export-to-csv';
import ContentPaper from '../layouts/rfs/Content';
import { axios } from '../../utils/axios';

//CSV Download
import csvDownload from 'json-to-csv-export';
import { TableExport } from 'tabler-icons-react';
import moment from 'moment';
import { dateFormat } from '../../constants';

const BottleneckReport = ({ columns, unique }) => {
  const [switchChecked, setSwitchChecked] = useState(false);
  const [selected, setSelected] = useState([{}]);
  const [rowSelection, setRowSelection] = useState({});
  const [filteredRows, setFilteredRows] = useState([]);
  const globalTheme = useMantineTheme;

  //Custom search
  const [toBeSearched, setToBeSearched] = useState('');

  //Search Icon
  const [searchOn, setSearchOn] = useState(false);

  //All Select
  const [allSelected, setAllSelected] = useState(false);

  const [data, setData] = useState([]);
  const [successData, setSuccessData] = useState([]);

  const fetchBottleneck = () => {
    return axios({ url: 'reports/bottleneck', timeout: 30000 });
  };

  const replaceEmptyHeaders = (data) => {
    const updatedData = [];

    data.forEach((group) => {
      group.columns.forEach((column) => {
        let updatedHeader = column.header;

        // Check if the accessorKey contains "startDate," "dueDate," or "assignee"
        if (
          column.accessorKey.includes('StartDate') ||
          column.accessorKey.includes('DueDate') ||
          column.accessorKey.includes('Assignee')
        ) {
          // Append the group header at the start of the header
          updatedHeader = group.header + ' ' + column.header;
        }

        // Push the new object into the updatedData array
        updatedData.push({
          header: updatedHeader,
          accessorKey: column.accessorKey,
        });
      });
    });

    return updatedData;
  };

  const updatedColumns = replaceEmptyHeaders(columns);

  //READ hook (get data in api)
  function useGet() {
    return useQuery({
      queryKey: ['fetchBottleneck'],
      queryFn: fetchBottleneck,
      refetchOnWindowFocus: false,
    });
  }

  //call READ hook
  // let successData = [];
  const { data: fetchedData = [], isError, isFetching, isLoading, error, status } = useGet();

  useEffect(() => {
    if (status === 'success' && !isFetching) {
      const data = fetchedData.data.data;
      setData(data);
      setSuccessData(data);
      setFilteredRows(data);
    }
  }, [status, isFetching]);

  const processArray = (array) => {
    return array.map((item) => {
      const processedItem = {};

      for (const [key, value] of Object.entries(item)) {
        if (key !== 'data_id') {
          let processedValue = value;

          // Check if the value is an integer before attempting to format as a date
          if (typeof value === 'number') {
            processedValue = value;
          } else if (moment(value, moment.ISO_8601, true).isValid()) {
            processedValue = moment(value, 'DD-MM-YYYY').format('MM/DD/YYYY');
          }

          // Set null values or keys containing 'Sla' to an empty string
          if (value === null || key.includes('Sla')) {
            processedValue = '';
          }

          if (value === null && key.includes('StartDate')) {
            processedValue = 'Not Started';
          }

          if (value === null && key.includes('DueDate')) {
            processedValue = '/';
          }

          processedItem[key] = processedValue;
        }
      }

      return processedItem;
    });
  };

  //Excel Download------------
  const downloadExcel = (type) => {
    const dataToExport = {
      data: [],
      filename: '',
      delimiter: ',',
      headers: [],
    };

    const headers = [
      'RFS ID',
      'RFS Name',
      'RFS Status',
      'RFS Type',
      'Fast Track',
      `First Shipment Date`,
      `Submission Date`,
      'Gatekeeper',
      'Production Business Unit',
      'Sales Business Unit',
      'Plants',
      'Markets',
      'SKU RFS Volume',
      'RFS Volume',
      'SKU Number',
      'SKU Description',
      'Material Number',
      'Material Description',
      'Material Group',
      'Steps In Progress',
      'SKU Creation Start Date',
      'SKU Creation Due Date',
      'SKU Creation Assignee',
      'Supplier Name Start Date',
      'Supplier Name Due Date',
      'Supplier Name Assignee',
      'SKU Status 40 Start Date',
      'SKU Status 40 Due Date',
      'SKU Status 40 Assignee',
      'Demand Planning Start Date',
      'Demand Planning Due Date',
      'Demand Planning Assignee',
      'Production Version Start Date',
      'Production Version Due Date',
      'Production Version Assignee',
      'Artwork Development Start Date',
      'Artwork Development Due Date',
      'Artwork Development Assignee',
      'EAN Approval Start Date',
      'EAN Approval Due Date',
      'EAN Approval Assignee',
      'Materials Contract Start Date',
      'Materials Contract Due Date',
      'Materials Contract Assignee',
      'Standard Price Start Date',
      'Standard Price Due Date',
      'Standard Price Assignee',
      'Status 99 Start Date',
      'Status 99 Due Date',
      'Status 99 Assignee',
      'Cost Collector Start Date',
      'Cost Collector Due Date',
      'Cost Collector Assignee',
      'Market Extension Start Date',
      'Market Extension Due Date',
      'Market Extension Assignee',
      'Vendor Open Start Date',
      'Vendor Open Due Date',
      'Vendor Open Assignee',
      'VIC Check Start Date',
      'VIC Check Due Date',
      'VIC Check Assignee',
      'VLC Check Start Date',
      'VLC Check Due Date',
      'VLC Check Assignee',
      'Transfer Price Start Date',
      'Transfer Price Due Date',
      'Transfer Price Assignee',
      'Material Code Creation Start Date',
      'Material Code Creation Due Date',
      'Material Code Creation Assignee',
      'Production Plant Extension Start Date',
      'Production Plant Extension Due Date',
      'Production Plant Extension Assignee',
      'Material Status Change Start Date',
      'Material Status Change Due Date',
      'Material Status Change Assignee',
      'SKU Planning Start Date',
      'SKU Planning Due Date',
      'SKU Planning Assignee',
      'Material Pricing Start Date',
      'Material Pricing Due Date',
      'Material Pricing Assignee',
      'Supplier Production Start Date',
      'Supplier Production Due Date',
      'Supplier Production Assignee',
      'Material on Stock at Supplier Start Date',
      'Material on Stock at Supplier Due Date',
      'Material on Stock at Supplier Assignee',
      'Material on Stock at ABI Start Date',
      'Material on Stock at ABI Due Date',
      'Material on Stock at ABI Assignee',
      'BOM Availability Start Date',
      'BOM Availability Due Date',
      'BOM Availability Assignee',
      'Material at Status 99 Start Date',
      'Material at Status 99 Due Date',
      'Material at Status 99 Assignee',
      'Procurement Price Start Date',
      'Procurement Price Due Date',
      'Procurement Price Assignee',
      'Repacking Fee Start Date',
      'Repacking Fee Due Date',
      'Repacking Fee Assignee',
      'Filling and Shipment Start Date',
      'Filling and Shipment Due Date',
      'Filling and Shipment Assignee',
      'Trials Start Date',
      'Trials Due Date',
      'Trials Assignee',
      'Status Check Start Date',
      'Status Check Due Date',
      'Status Check Assignee',
      'Changeover Start Date',
      'Changeover Due Date',
      'Changeover Assignee',
      'Obsoletes Approval MRP Start Date',
      'Obsoletes Approval MRP Due Date',
      'Obsoletes Approval MRP Assignee',
      'SKU Planning Start Date',
      'SKU Planning Due Date',
      'SKU Planning Assignee',
      'Status Change to AA Start Date',
      'Status Change to AA Due Date',
      'Status Change to AA Assignee',
      'Status Change to ZZ Start Date',
      'Status Change to ZZ Due Date',
      'Status Change to ZZ Assignee',
    ];

    const updatedHeaders = headers.map((header) =>
      header.includes('Date') ? `${header} (${dateFormat})` : header,
    );

    dataToExport.data = processArray(data);
    dataToExport.filename = 'Bottleneck_Report_' + moment().format('YYYY-MM-DD HH:mm:ss');
    dataToExport.headers = updatedHeaders;
    csvDownload(dataToExport);
  };

  const getDataToExport = (data) => {
    const csvArray = data.map(dataToExport);
    return csvArray;
  };

  const dataToExport = (data) => {
    return Object.values(data).map((i, index) => i);
  };

  //*********************
  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };

  const csvExporter = new ExportToCsv(csvOptions);

  //Excel Download______________

  //Custom Search
  const filterMultiSearch = (event) => {
    const input = event.target.value.split(' ');

    const myArrayFiltered = successData.filter((el) => {
      return input.some((f) => {
        return el[toBeSearched]?.toLowerCase().includes(f.toLowerCase());
      });
    });

    setData(
      input.length > 0
        ? myArrayFiltered
        : switchChecked && Object.keys(selected).length
        ? selected
        : successData,
    );
    setFilteredRows(input.length > 0 ? myArrayFiltered : successData);
  };

  const resetAll = () => {
    setSearchOn(false);
    setAllSelected(false);
    setRowSelection({});
    setSwitchChecked(false);
    setData(successData);
    setFilteredRows(successData);
  };

  const table = useMantineReactTable({
    columns,
    data: data,
    createDisplayMode: 'row', // ('modal', and 'custom' are also available)
    editDisplayMode: 'row', // ('modal', 'cell', 'table', and 'custom' are also available)
    enableEditing: false,
    enableRowSelection: true,
    selectDisplayMode: 'checkbox',
    enableGlobalFilter: false,
    enablePinning: true,
    enableSelectAll: true,
    enableColumnResizing: true,
    selectAllMode: 'all',
    getRowId: (row) => row[unique],
    initialState: {
      density: 'xs',
      showColumnFilters: true,
    },
    mantineTableProps: {
      highlightOnHover: false,
      withColumnBorders: true,
      withBorder: 'light',
    },
    mantineToolbarAlertBannerProps: isError
      ? {
          color: 'red',
          children: 'Error loading data',
        }
      : undefined,
    mantineTableContainerProps: {
      sx: {
        minHeight: '500px',
      },
    },
    renderTopToolbarCustomActions: ({ table }) => {
      return (
        <>
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <Tooltip label='Show All Selected'>
              <Switch
                disabled={selected.length === 0 && !switchChecked}
                checked={switchChecked}
                onChange={(event) => setSwitchChecked(event.currentTarget.checked)}
              />
            </Tooltip>

            <Tooltip label='Reset All'>
              <IconRefresh onClick={resetAll} />
            </Tooltip>

            <div onClick={() => setSearchOn(searchOn ? false : true)}>
              <Tooltip label='Search All'>{searchOn ? <IconSearchOff /> : <IconSearch />}</Tooltip>
            </div>
          </div>
          {
            //Search Function------------------
          }
          {searchOn && (
            <div className='search-Group'>
              <div className='searchGroup' style={{ display: 'flex' }}>
                <>
                  <Select
                    label='Select The Column To Search'
                    placeholder='Select The Column'
                    onChange={(value) => {
                      setToBeSearched(value);
                    }}
                    data={updatedColumns.map((column) => {
                      return { value: column.accessorKey, label: column.header };
                    })}
                    style={{ position: 'relative', zIndex: '1' }}
                  />
                  <Tooltip
                    arrowOffset={10}
                    arrowSize={4}
                    label='To search for multiple values, please enter space-separated values e.g 10023 12309 11287'
                    withArrow
                    position='top-start'
                    multiline
                    width={220}
                  >
                    <TextInput
                      clearable
                      label='&nbsp;'
                      placeholder='Search'
                      onChange={(event) => filterMultiSearch(event)}
                      style={{ position: 'relative', zIndex: '0' }}
                      disabled={toBeSearched === ''}
                    />
                  </Tooltip>
                </>
              </div>
            </div>
          )}
        </>
      );
    },
    state: {
      isLoading: isLoading,
      showAlertBanner: isError,
      showProgressBars: isFetching,
      rowSelection,
    },
    onRowSelectionChange: setRowSelection,
  });

  //Selection Toggle
  const updateSelected = () => {
    var filtered = filteredRows.filter((item, index) => {
      return Object.keys(rowSelection).indexOf(item[unique]) !== -1;
    });

    setSelected(filtered);
  };

  useEffect(() => {
    if (switchChecked || allSelected) setData(selected);
    else if ((!switchChecked || !allSelected) && !searchOn) setData(successData);
    else if ((!switchChecked || !allSelected) && searchOn) setData(filteredRows);
    else setData(successData);
  }, [switchChecked, selected, data, searchOn]);

  useEffect(() => {
    updateSelected();
  }, [rowSelection]);

  if (isError) {
    return (
      <ContentPaper page={'Decomplexity'}>
        <span>{error.message}</span>
      </ContentPaper>
    );
  }

  return (
    <>
      <div
        className='downLoadBtn'
        style={{
          marginBottom: '1rem',
          marginTop: '2rem',
          display: 'flex',
          justifyContent: 'right',
        }}
      >
        <Button
          leftIcon={<TableExport />}
          onClick={() => {
            downloadExcel();
          }}
          color='dark'
          style={{
            margin: '0',
          }}
        >
          Export Report to CSV
        </Button>
      </div>
      <MantineProvider theme={{ ...globalTheme, primaryColor: 'yellow', primaryShade: 7 }}>
        <MantineReactTable table={table} />
      </MantineProvider>
    </>
  );
};

export default BottleneckReport;
