import React from 'react';
import { Tabs } from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';
import ContentPaper from '../../../components/layouts/rfs/Content';
import ProjectName from '../../../components/projectName/projectName';
import { ObsoletesApproval } from './obsoletesApproval';
import { DemandPlanning } from './demandPlanning';
import { SkuPlanning } from './skuPlanning';
import { StatusChangeAA } from './statusChangeToAA';
import { StatusChangeZZ } from './statusChangeToZZ';
import { useState, useEffect, useContext } from 'react';
import { ProjectOverviewContext } from '../../../contexts/projectOverviewContext';
import { useSearchParams } from 'react-router-dom';
import { routes } from '../../../routes';

const tabStyles = (theme) => ({
  tab: {
    ...theme.fn.focusStyles(),
    backgroundColor: 'white',
    color: theme.colors.gray[9],
    border: `1px solid #e5b611`,
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    cursor: 'pointer',
    fontSize: theme.fontSizes.sm,
    display: 'flex',
    alignItems: 'center',

    '&:disabled': {
      opacity: 0.5,
      cursor: 'not-allowed',
    },

    '&:not(:first-of-type)': {
      borderLeft: 0,
    },

    '&:first-of-type': {
      borderTopLeftRadius: theme.radius.md,
      borderBottomLeftRadius: theme.radius.md,
    },

    '&:last-of-type': {
      borderTopRightRadius: theme.radius.md,
      borderBottomRightRadius: theme.radius.md,
    },

    '&[data-active]': {
      background: 'linear-gradient(90deg,#e3af32 0%,#f4e00f 100%)',
      borderColor: '#f5e003',
      color: 'black',
    },

    '&[data-active]>span': {
      color: 'black',
    },
  },
  tabsList: {
    display: 'flex',
  },
});

export default function DlstImplementation() {
  const params = useParams();
  const { rfsNumber } = params;
  const [searchParams, setSearchParams] = useSearchParams();
  const [loader, setLoader] = useState(false);
  const [slaWeeksIds, setSlaWeeksIds] = useState([]);
  const [trialsReversal, setTrialsReversal] = useState({ display: 'none' });
  const { getSLAWeeksIdsForRFS } = useContext(ProjectOverviewContext);
  const [activeTab, setActiveTab] = useState('oamrp');
  const [tab, setTab] = useState('oamrp');
  const urlParams = new URLSearchParams(window.location.search);
  const subTab = searchParams.get('sub') ? searchParams.get('sub') : 'oamrp';
  const navigate = useNavigate();

  useEffect(() => {
    fetchSLAWeeksIds(params.rfsNumber);
  }, [params.rfsNumber]);

  useEffect(() => {
    setActiveTab(subTab);
  }, [subTab]);

  useEffect(() => {
    const sub = urlParams.get('sub');
    if (sub) {
      setTab(sub);
    }
  }, [urlParams]);

  const fetchSLAWeeksIds = (rfs_number) => {
    rfs_number =
      rfs_number === undefined
        ? window.location.href.substring(window.location.href.lastIndexOf('/') + 1).split('?')[0]
        : rfs_number;
    setLoader(true);
    getSLAWeeksIdsForRFS(rfs_number).then((res) => {
      let sla_weeks_ids = [];
      res.data.data.map(
        (item) =>
          sla_weeks_ids.includes(item.sla_weeks_id) === false &&
          sla_weeks_ids.push(item.sla_weeks_id),
      );
      setSlaWeeksIds(sla_weeks_ids);
      setTrialsReversal({ display: 'block' });

      setLoader(false);
    });
  };

  const checkWithSLAWeeksData = (arr) => arr.reduce((a, c) => a + slaWeeksIds.includes(c), 0) === 1;

  return (
    <ContentPaper page={'ProductDevelopment'}>
      <ProjectName initialItem={0} />
      <br />
      <Tabs
        onTabChange={(value) =>
          navigate(
            `${routes.project(params.rfsNumber).root}?tab=${urlParams.get('tab')}&sub=${value}`,
          )
        }
        styles={tabStyles}
        className='prod-dev-tabs'
        color='yellow'
        value={tab}
        defaultValue={'oamrp'}
        active={activeTab}
      >
        <Tabs.List>
          {checkWithSLAWeeksData([264]) && (
            <Tabs.Tab className='topTabs' value='oamrp'>
              <b>Obsoletes Approval MRP</b>
            </Tabs.Tab>
          )}
          {checkWithSLAWeeksData([265]) && (
            <Tabs.Tab className='topTabs' value='dp'>
              <b>Demand Planning</b>
            </Tabs.Tab>
          )}
          {checkWithSLAWeeksData([266]) && (
            <Tabs.Tab className='topTabs' value='sku'>
              <b>SKU Planning</b>
            </Tabs.Tab>
          )}
          {checkWithSLAWeeksData([267]) && (
            <Tabs.Tab className='topTabs' value='scAA'>
              <b>Status Change to AA</b>
            </Tabs.Tab>
          )}
          {checkWithSLAWeeksData([268]) && (
            <Tabs.Tab className='topTabs' value='scZZ'>
              <b>Status Change to ZZ</b>
            </Tabs.Tab>
          )}
        </Tabs.List>

        {checkWithSLAWeeksData([264]) && (
          <Tabs.Panel value='oamrp'>
            <ObsoletesApproval rfsNumber={rfsNumber} activeTab={activeTab} />
          </Tabs.Panel>
        )}
        {checkWithSLAWeeksData([265]) && (
          <Tabs.Panel value='dp'>
            <DemandPlanning rfsNumber={rfsNumber} activeTab={activeTab} />
          </Tabs.Panel>
        )}

        {checkWithSLAWeeksData([266]) && (
          <Tabs.Panel className='topTabs' value='sku'>
            <SkuPlanning rfsNumber={rfsNumber} activeTab={activeTab} />
          </Tabs.Panel>
        )}

        {checkWithSLAWeeksData([267]) && (
          <Tabs.Panel value='scAA'>
            <StatusChangeAA activeTab={activeTab} />
          </Tabs.Panel>
        )}

        {checkWithSLAWeeksData([268]) && (
          <Tabs.Panel value='scZZ'>
            <StatusChangeZZ activeTab={activeTab} />
          </Tabs.Panel>
        )}
      </Tabs>
    </ContentPaper>
  );
}
