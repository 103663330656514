import React, { useContext, useState, useEffect, useCallback } from 'react';
import { Button } from '@mantine/core';

import { AuthContext } from '../../contexts/AuthContext';
import { roles } from '../../constants';
import { axios } from '../../utils/axios';
import CustomGanttChart from '../../components/gantt';
import GanttModal from '../../components/modal/ganttModal';

export const OskuGantt = ({ rfsNumber, trials, editorOptions }) => {
  const { userRole } = useContext(AuthContext);
  const [editModal, setEditModal] = useState(false);
  const [data, setData] = useState([]);
  const [SLAWeeksData, setSLAWeeksData] = useState();

  useEffect(() => {
    if (!editModal) fetch();
  }, [editModal]);

  const fetch = useCallback(() => {
    axios.get(`/openSku/sku/gantt/${rfsNumber}`).then((res) => {
      setData(res.data.steps);
    });
  }, []);

  useEffect(() => {
    fetchSLAWeeksData();
  }, []);

  const fetchSLAWeeksData = useCallback(() => {
    axios.get(`/openSku/sku/gantt/sla_weeks/${trials ? 1 : 0}`).then((res) => {
      setSLAWeeksData(res.data.data);
    });
  }, []);

  const handleRowClick = (check) => {
    if (check) {
      setEditModal(true);
    }
  };

  const editAccess = () => {
    return [roles.pmo, roles.superAdmin].includes(userRole);
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '16px',
          marginTop: '16px',
        }}
      >
        <h5 className='sectionHeadings'>Project Gantt</h5>
        {editAccess() && (
          <Button
            style={{ maxWidth: '200px', background: 'black', color: 'white' }}
            color='yellow'
            size='sm'
            variant='filled'
            fullWidth
            radius='sm'
            onClick={() => handleRowClick(true)}
          >
            Edit
          </Button>
        )}
      </div>

      <div>
        <CustomGanttChart data={data} options={editorOptions} />
        {editModal && (
          <GanttModal
            ganttData={data}
            editModal={editModal}
            setEditModal={setEditModal}
            rfs_number={rfsNumber}
            steps={SLAWeeksData}
            type={'osku'}
          />
        )}
      </div>
    </div>
  );
};
