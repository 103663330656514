import { useEffect, useState } from 'react';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import {
  ActionIcon,
  Button,
  Flex,
  Text,
  Tooltip,
  Switch,
  TextInput,
  Select,
  Box,
} from '@mantine/core';
import { ModalsProvider, useModals } from '@mantine/modals';
import {
  IconEdit,
  IconTrash,
  IconSearch,
  IconSearchOff,
  IconEditCircle,
  IconEditCircleOff,
  IconRefresh,
} from '@tabler/icons-react';
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

//CSV Download
import csvDownload from 'json-to-csv-export';
import { TableExport } from 'tabler-icons-react';
import moment from 'moment';
import './report.css';
import { dateFormat } from '../../constants';

const ReportCustomComp = ({
  reportType,
  data,
  columns,
  setValidationErrors,
  statusData = [],
  unique,
  enableEditing = false,
}) => {
  //READ hook (get users from api)
  function useGet() {
    return useQuery({
      queryKey: ['reportdatas'],
      queryFn: async () => {
        //send api request here
        await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
        return Promise.resolve(data);
      },
      refetchOnWindowFocus: false,
    });
  }

  //UPDATE hook (put user in api)
  function useUpdate() {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: async (data) => {
        //send api update request here
        await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
        return Promise.resolve();
      },
      //client side optimistic update
      onMutate: (newReportDataInfo) => {
        queryClient.setQueryData(['reportdatas'], (prevReportDatas) =>
          prevReportDatas?.map((prevReportData) =>
            prevReportData[unique] === newReportDataInfo[unique]
              ? newReportDataInfo
              : prevReportData,
          ),
        );
      },
      // onSettled: () => queryClient.invalidateQueries({ queryKey: ['reportdatas'] }), //refetch users after mutation, disabled for demo
    });
  }

  //DELETE hook (delete user in api)
  function useDelete() {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: async (rowId) => {
        //send api update request here
        await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
        return Promise.resolve();
      },
      //client side optimistic update
      onMutate: (rowId) => {
        queryClient.setQueryData(['reportdatas'], (prevReportDatas) =>
          prevReportDatas?.filter((data) => data[unique] !== rowId),
        );
      },
      // onSettled: () => queryClient.invalidateQueries({ queryKey: ['reportdatas'] }), //refetch users after mutation, disabled for demo
    });
  }

  const queryClient = new QueryClient();

  const validateRequired = (value) => !!value?.length;
  const validateEmail = (email) =>
    !!email.length &&
    email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );

  function validate(items) {
    return {
      MaterialDescription: !validateRequired(items.MaterialDescription)
        ? 'MaterialDescription is Required'
        : '',
      LastModified: !validateRequired(items.LastModified) ? 'LastModified is Required' : '',
      Username: !validateRequired(items.Username) ? 'Username is Required' : '',
      ProjectID: !validateRequired(items.ProjectID) ? 'ProjectID is Required' : '',
      Changetype: !validateRequired(items.Changetype) ? 'Changetype is Required' : '',
      SKUStatus: !validateRequired(items.SKUStatus) ? 'SKUStatus is Required' : '',
      ForecastUnit: !validateRequired(items.ForecastUnit) ? 'ForecastUnit is Required' : '',
      plant: !validateRequired(items.plant) ? 'plant is Required' : '',
      ProductVolume: !validateRequired(items.ProductVolume) ? 'ProductVolume is Required' : '',
      StockERP: !validateRequired(items.StockERP) ? 'StockERP is Required' : '',
      //email: !validateEmail(items.email) ? 'Incorrect Email Format' : '',
    };
  }

  const Report = ({ reportData, reportType }) => {
    const [switchChecked, setSwitchChecked] = useState(false);
    const [selected, setSelected] = useState([]);
    const [rowSelection, setRowSelection] = useState({});
    const [filteredRows, setFilteredRows] = useState(reportData);

    const [data, setData] = useState(reportData);

    //Custom search
    const [toBeSearched, setToBeSearched] = useState('');

    //Search Icon
    const [searchOn, setSearchOn] = useState(false);
    const [allEditOn, setAllEditOn] = useState(false);

    //AllEdit
    const [toBeEditedValue, setToBeEditedValue] = useState({});
    const [selectType, setSelectType] = useState('');

    //All Select
    const [allSelected, setAllSelected] = useState(false);

    //const data = switchChecked && Object.keys(selected).length ? selected : reportData;

    //call READ hook
    const {
      data: fetchedUsers = [],
      isError: isLoadingUsersError,
      isFetching: isFetchingUsers,
      isLoading: isLoadingUsers,
    } = useGet();

    //call UPDATE hook
    const { mutateAsync: updateU, isLoading: isUpdatingUser } = useUpdate();

    //call DELETE hook
    const { mutateAsync: deleteUser, isLoading: isDeletingUser } = useDelete();

    //UPDATE action
    const handleSave = async ({ values, exitEditingMode }) => {
      const newValidationErrors = validate(values);
      if (Object.values(newValidationErrors).some((error) => error)) {
        setValidationErrors(newValidationErrors);
        return;
      }
      setValidationErrors({});
      await updateU(values);
      exitEditingMode();
    };

    //DELETE action
    const openDeleteConfirmModal = (row) =>
      useModals.openConfirmModal({
        title: 'Are you sure you want to delete this user?',
        children: (
          <Text>
            Are you sure you want to delete {row.original.firstName} {row.original.lastName}? This
            action cannot be undone.
          </Text>
        ),
        labels: { confirm: 'Delete', cancel: 'Cancel' },
        confirmProps: { color: 'red' },
        onConfirm: () => deleteUser(row.original[unique]),
      });

    //Excel Download------------
    const downloadExcel = (type) => {
      const dataToExport = {
        data: [],
        filename: '',
        delimiter: ',',
        headers: [],
      };

      const headersArray =
        reportType === 'smd'
          ? [
              'RFS ID',
              'RFS Name',
              'Request Type',
              'Request Status',
              'Sales Business Unit',
              'Production Business Unit',
              'Initiator',
              'Gatekeeper',
              'Steps in Progress',
              `Submission Date (${dateFormat})`,
              `Last approval change date (${dateFormat})`,
              'Fast Track',
              `Technical Feasibility Approval Date (${dateFormat})`,
              `Implementation Feasibility Approval Date (${dateFormat})`,
              `Cancellation Date (${dateFormat})`,
            ]
          : columns.map((column) => column.header);

      dataToExport.data = getDataToExport(data);
      dataToExport.filename = 'Download_Report_' + moment().format('YYYY-MM-DD HH:mm:ss');
      dataToExport.headers = headersArray;
      csvDownload(dataToExport);
    };

    function removeNulls(data) {
      return data.map((obj) => {
        const transformedObject = {};

        // Iterate through the keys of the object
        Object.keys(obj).forEach((key) => {
          // Replace null values with empty strings
          transformedObject[key] = obj[key] === null ? '' : obj[key];
        });

        return transformedObject;
      });
    }

    const getDataToExport = (data) => {
      const csvArray = data.map(dataToExport);
      const csvData = removeNulls(csvArray);
      return csvData;
    };

    const dataToExport = (data) => {
      return Object.values(data).map((i, index) => i);
    };
    //Custom Search
    const filterMultiSearch = (event) => {
      const input = event.target.value.split(' ');

      const myArrayFiltered = reportData.filter((el) => {
        return input.some((f) => {
          return el[toBeSearched]?.toLowerCase().includes(f.toLowerCase());
        });
      });

      setData(
        input.length > 0
          ? myArrayFiltered
          : switchChecked && Object.keys(selected).length
          ? selected
          : reportData,
      );
      setFilteredRows(input.length > 0 ? myArrayFiltered : reportData);
    };

    const resetAll = () => {
      setSearchOn(false);
      setAllSelected(false);
      setRowSelection({});
      setAllEditOn(false);
      setSwitchChecked(false);
      setData(reportData);
      setFilteredRows(reportData);
    };

    const table = useMantineReactTable({
      columns,
      data,
      createDisplayMode: 'row', // ('modal', and 'custom' are also available)
      editDisplayMode: 'row', // ('modal', 'cell', 'table', and 'custom' are also available)
      enableEditing: enableEditing,
      getRowId: (row) => row[unique],
      enableRowSelection: true,
      selectDisplayMode: 'checkbox',
      enableGlobalFilter: false,
      enableSelectAll: true,
      selectAllMode: 'all',
      initialState: {
        showColumnFilters: true,
      },
      mantineToolbarAlertBannerProps: isLoadingUsersError
        ? {
            color: 'red',
            children: 'Error loading data',
          }
        : undefined,
      mantineTableContainerProps: {
        sx: {
          minHeight: '500px',
        },
      },
      onCreatingRowCancel: () => setValidationErrors({}),
      onEditingRowCancel: () => setValidationErrors({}),
      onEditingRowSave: handleSave,
      renderRowActions: ({ row, table }) => (
        <Flex gap='md'>
          <Tooltip label='Edit'>
            <ActionIcon onClick={() => table.setEditingRow(row)}>
              <IconEdit />
            </ActionIcon>
          </Tooltip>
          <Tooltip label='Delete'>
            <ActionIcon color='red' onClick={() => openDeleteConfirmModal(row)}>
              <IconTrash />
            </ActionIcon>
          </Tooltip>
        </Flex>
      ),
      renderTopToolbarCustomActions: ({ table }) => {
        const saveAll = () => {
          let ad = [];
          table.getSelectedRowModel().flatRows.map((row) => {
            ad = data.map((item) => {
              if (item[unique] === row.original[unique]) {
                item[toBeEditedValue['selectedColumn']] = toBeEditedValue['value'];
              }
              return item;
            });
            return row;
          });
          setData(ad);
        };

        const setUpEditedValues = (e) => {
          setToBeEditedValue({ ...toBeEditedValue, ['selectedColumn']: e.split('|')[0] });
          setSelectType(e.split('|')[1]);
        };

        return (
          <>
            <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
              <Tooltip label='Show All Selected'>
                <Switch
                  disabled={selected.length === 0 && !switchChecked}
                  checked={switchChecked}
                  onChange={(event) => setSwitchChecked(event.currentTarget.checked)}
                />
              </Tooltip>

              <Tooltip label='Reset All'>
                <IconRefresh onClick={resetAll} />
              </Tooltip>

              <div
                onClick={() => {
                  if (searchOn) setFilteredRows(reportData);
                  setSearchOn(searchOn ? false : true);
                }}
              >
                <Tooltip label='Search All'>
                  {searchOn ? <IconSearchOff /> : <IconSearch />}
                </Tooltip>
              </div>

              {Object.keys(rowSelection).length > 0 && enableEditing && (
                <Tooltip label='Edit All Selected'>
                  <div onClick={() => setAllEditOn(allEditOn ? false : true)}>
                    {allEditOn ? <IconEditCircleOff /> : <IconEditCircle />}
                  </div>
                </Tooltip>
              )}
            </div>

            {searchOn && (
              <div className='search-Group'>
                <div className='searchGroup' style={{ display: 'flex' }}>
                  <>
                    <Select
                      label='Select The Column To Search'
                      placeholder='Select The Column'
                      onChange={(value) => setToBeSearched(value)}
                      data={columns.map((column) => {
                        return { value: column.accessorKey, label: column.header };
                      })}
                      style={{ position: 'relative', zIndex: '1' }}
                    />
                    <Tooltip
                      arrowOffset={10}
                      arrowSize={4}
                      label='To search for multiple values, please enter space-separated values e.g 10023 12309 11287'
                      withArrow
                      position='top-start'
                      multiline
                      width={220}
                    >
                      <TextInput
                        clearable
                        label='&nbsp;'
                        placeholder='Search'
                        onChange={(event) => filterMultiSearch(event)}
                        style={{ position: 'relative', zIndex: '0' }}
                        disabled={toBeSearched === ''}
                      />
                    </Tooltip>
                  </>
                </div>
              </div>
            )}
            {
              //Edit Function-------------------
            }
            {allEditOn && (
              <div className='edit-Group'>
                <div className='searchGroup' style={{ display: 'flex', alignItems: 'flex-end' }}>
                  <Select
                    label='Select The Column To Edit'
                    placeholder='Select The Column'
                    data={columns.map((column) => {
                      return {
                        value: column.accessorKey + '|' + column.editVariant,
                        label: column.header,
                        disabled: column.enableEditing != undefined ? !column.enableEditing : false,
                      };
                    })}
                    onChange={(e) => setUpEditedValues(e)}
                  />
                  {selectType && selectType === 'select' ? (
                    <Select
                      label='Select'
                      placeholder='Select'
                      data={statusData.map((item) => item)}
                      onChange={(value) =>
                        setToBeEditedValue({
                          ...toBeEditedValue,
                          ['value']: value,
                        })
                      }
                    />
                  ) : (
                    <TextInput
                      clearable
                      label='&nbsp;'
                      placeholder='Edit'
                      onKeyUp={(event) =>
                        setToBeEditedValue({
                          ...toBeEditedValue,
                          ['value']: event.target.value,
                        })
                      }
                    />
                  )}
                  <Button onClick={() => saveAll()}>Save</Button>
                </div>
              </div>
            )}
          </>
        );
      },
      state: {
        isLoading: isLoadingUsers,
        isSaving: isUpdatingUser || isDeletingUser,
        showAlertBanner: isLoadingUsersError,
        showProgressBars: isFetchingUsers,
        rowSelection,
      },
      onRowSelectionChange: setRowSelection,
    });

    //Selection Toggle
    const updateSelected = () => {
      var filtered = filteredRows.filter((item, index) => {
        return Object.keys(rowSelection).indexOf(item[unique]) !== -1;
      });

      const currentValues = selected;
      currentValues.forEach((val) => {
        const index = filtered.findIndex((item) => item[unique] === val[unique]);
        if (index === -1) filtered.push(val);
      });

      setSelected(filtered);
    };

    useEffect(() => {
      if (switchChecked || allSelected) setData(selected);
      else if ((!switchChecked || !allSelected) && !searchOn) setData(reportData);
      else if ((!switchChecked || !allSelected) && searchOn) setData(filteredRows);
      else setData(reportData);
    }, [switchChecked, selected, reportData, searchOn]);

    useEffect(() => {
      updateSelected();
    }, [rowSelection]);

    useEffect(() => {
      const handleClick = (event) => {
        setAllSelected(event.target.checked);
      };

      const elem = document.querySelector('input[aria-label="Toggle select all"]');
      elem.addEventListener('click', handleClick);

      return () => elem.removeEventListener('click', handleClick);
    }, []);

    return (
      <>
        <div
          className='downLoadBtn'
          style={{
            marginBottom: '1rem',
            marginTop: '2rem',
            display: 'flex',
            justifyContent: 'right',
          }}
        >
          <Button
            leftIcon={<TableExport />}
            onClick={() => {
              downloadExcel();
            }}
            color='dark'
            style={{
              margin: '0',
            }}
          >
            Export Report to CSV
          </Button>
        </div>
        <MantineReactTable table={table} />
      </>
    );
  };

  return (
    <QueryClientProvider client={queryClient}>
      <ModalsProvider>
        <div className=''>
          <Report reportData={data} reportType={reportType} />
        </div>
      </ModalsProvider>
    </QueryClientProvider>
  );
};
export default ReportCustomComp;
