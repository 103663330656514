import React, { useState, useMemo, useContext } from 'react';
import { useTable } from 'react-table';
import moment from 'moment';
import { Text, Badge, Button, Select, Checkbox } from '@mantine/core';
import { AppLoader } from '../../../../components/common/loader';
import { Styles } from '../../../../components/tables/skutable/production-site/styles';
import { RfsContext } from '../../../../contexts/RfsContext';
import { useRef } from 'react';
import { ProductionLog } from '../productionSiteLogModal';
import { ActionModal } from '../actionModal';

export function PlantExtensionTable({ extensionData, save, refresh, disable }) {
  const [filterLoading, setFilterLoading] = useState(false);
  const bomColumns = extensionData[0]?.type_of_change === 'No changes in bom';
  const [actionModal, setActionModal] = useState({ open: false, id: '' });

  const { storeProductionPlantExtension, fetchLogs } = useContext(RfsContext);

  const [logModal, setLogModal] = useState({ open: false, id: '' });
  const [imp_id, setImp_id] = useState(0);
  const [checked, setChecked] = useState(false);

  const [altValue, setAltValue] = useState('');
  const altValueRef = useRef(null);
  altValueRef.current = altValue;

  const [otherStatus, setotherStatus] = useState({});

  const handleChange = (value, id) => {
    setAltValue({ [id]: value });
  };

  const postData = (sku_id, id) => {
    storeProductionPlantExtension(sku_id, altValueRef.current[id], refresh);
  };

  function getLogs(imp_id) {
    setLogModal({ open: true, id: imp_id });
    setImp_id(imp_id);
  }

  const columns = [
    {
      Header: 'Reference Plant',
      Cell: ({ row }) => {
        return <Text>{row.original.int_rfs_ps_sku_reference.reference_plant}</Text>;
      },
    },
    {
      Header: 'Alternative',
      Cell: ({ row }) => {
        return <Text>{row.original.int_rfs_ps_sku_reference.reference_alt_bom}</Text>;
      },
    },
    {
      Header: 'New Plant',
      Cell: ({ row }) => {
        return <Text>{row.original.int_rfs_ps_sku_reference.selected_plant}</Text>;
      },
    },
    {
      Header: 'Type of Change',
      Cell: ({ row }) => {
        return <Text>{row.original.type_of_change}</Text>;
      },
    },
    !bomColumns && {
      Header: 'Old Material Number',
      Id: 'old_material_number',
      Cell: ({ row }) => {
        return <Text>{row.original?.int_rfs_ps_bom?.bom_component}</Text>;
      },
    },
    !bomColumns && {
      Header: 'Old Material Name',
      accessor: '02',
      Cell: ({ row }) => {
        return <Text>{row.original?.int_rfs_ps_bom?.component_description}</Text>;
      },
    },
    !bomColumns && {
      Header: 'New Material Number',
      accessor: '03',
      Cell: ({ row }) => {
        return (
          <Text>{row.original?.new_material_name ? row.original?.new_material_name : 'N/A'}</Text>
        );
      },
    },
    !bomColumns && {
      Header: 'New Material Name',
      accessor: '04',
      Cell: ({ row }) => {
        return (
          <Text>{row.original?.new_material_desc ? row.original?.new_material_desc : 'N/A'}</Text>
        );
      },
    },
    {
      Header: 'Production',
      Cell: ({ row }) => {
        return <Text>{row.original.int_rfs_ps_sku_reference.production}</Text>;
      },
    },
    {
      Header: 'MP/Dating Reference',
      Cell: ({ row }) => {
        return <Text>{row.original.int_rfs_ps_sku_reference.mother_product_date_formatting}</Text>;
      },
    },
    {
      Header: 'Comments',
      Cell: ({ row }) => {
        return (
          <Text>
            {row.original.type_of_change === 'No changes in bom'
              ? row.original.int_rfs_ps_sku_reference?.comment
                ? row.original.int_rfs_ps_sku_reference?.comment
                : 'N/A'
              : row.original.int_rfs_ps_bom?.comment
              ? row.original.int_rfs_ps_bom?.comment
              : 'N/A'}
          </Text>
        );
      },
    },
    {
      Header: 'New Alternative Number',
      Cell: ({ row }) => {
        const isDisabled = row.original.int_rfs_ps_sku_reference.new_alt_bom;
        const showSubmit = altValueRef?.current[row.original.material_id]?.length > 0;

        return isDisabled ? (
          <Text>{row.original.int_rfs_ps_sku_reference.new_alt_bom}</Text>
        ) : (
          <>
            <Select
              disabled={row.original.status == 'Not Started' || disable ? true : false}
              value={
                row.original.int_rfs_ps_sku_reference.new_alt_bom
                  ? row.original.int_rfs_ps_sku_reference.new_alt_bom
                  : altValueRef[row.original.material_id]
              }
              placeholder='01/02/03/04/05'
              searchable
              clearable
              nothingFound='No options'
              onChange={(event) => handleChange(event, row.original.material_id)}
              data={['01', '02', '03', '04', '05']}
            />
            {showSubmit && (
              <Button
                style={{
                  padding: '10px',
                  marginTop: '10px',
                  background:'black',
                    color:'white'
                }}
                onClick={() => postData(row.original.sku_id, row.original.material_id)}
                position='Right'
                size='sm'
                color='yellow'
                radius='xs'
                fullWidth
              >
                Submit
              </Button>
            )}
          </>
        );
      },
    },
    {
      Header: 'Status',
      Cell: ({ row }) => {
        return (
          <Badge
            color={
              row.original.status === 'In Progress' &&
              !row.original.finished_date &&
              moment(new Date()).format('YYYY-MM-DD') <=
                moment(row.original.sla_date).format('YYYY-MM-DD')
                ? 'yellow'
                : (row.original.status === 'In Progress' &&
                    moment(row.original.finished_date).format('YYYY-MM-DD') >
                      moment(row.original.sla_date).format('YYYY-MM-DD')) ||
                  (row.original.status === 'In Progress' &&
                    moment(new Date()).format('YYYY-MM-DD') >
                      moment(row.original.sla_date).format('YYYY-MM-DD'))
                ? 'red'
                : row.original.status === 'Closed' &&
                  moment(row.original.finished_date).format('YYYY-MM-DD') <=
                    moment(row.original.sla_date).format('YYYY-MM-DD')
                ? 'green'
                : row.original.status === 'Closed' &&
                  moment(row.original.finished_date).format('YYYY-MM-DD') >
                    moment(row.original.sla_date).format('YYYY-MM-DD')
                ? 'red'
                : 'gray'
            }
            size='lg'
          >
            {row.original.status}
          </Badge>
        );
      },
    },
    {
      Header: 'Action',
      Cell: ({ row }) => {
        return row.original.status === 'In Progress' || row.original.status === 'Not Started' ? (
          <Checkbox
            checked={checked}
            disabled={
              row.original.int_rfs_ps_sku_reference.new_alt_bom &&
              row.original.status === 'In Progress' &&
              !disable
                ? false
                : true
            }
            onChange={() => {
              setActionModal({ open: true, id: row.original.id });
              setChecked(true);
              setotherStatus(row.original.id);
            }}
            label='Confirm and Close'
          />
        ) : row.original.manually_closed && row.original.status === 'Closed' ? (
          <Text>Manual Closure</Text>
        ) : (
          <Text>Automatic Closure</Text>
        );
      },
    },
    {
      Header: 'Log',
      Cell: ({ row }) => {
        return (
          <Button onClick={() => getLogs(row.original.id)} style={{background:'black',color:'white'}}>
            View Logs
          </Button>
        );
      },
    },
    {
      Header: 'Step Deadline',
      Cell: ({ row }) => {
        return <Text>{moment(row.original.sla_date).format('YYYY-MM-DD')}</Text>;
      },
    },
    {
      Header: 'Step Finished',
      Cell: ({ row }) => {
        return (
          <Text>
            {row.original.finished_date
              ? moment(row.original.finished_date).format('YYYY-MM-DD')
              : row.original.status}
          </Text>
        );
      },
    },
  ];

  const plant_columns = useMemo(() => columns.filter((c) => c), []);

  const tableInstance = useTable({
    columns: plant_columns,
    data: extensionData,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <Styles>
      <div style={{ overflowX: 'auto' }}>
        <table style={{ width: 'fitContent' }} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} id='header-row'>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
              );
            })}
            {filterLoading && (
              <tr>
                <td colSpan={13}>
                  <div className='loader-container'>
                    <AppLoader size={'md'} />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {actionModal && (
        <ActionModal
          actionModal={actionModal}
          setActionModal={setActionModal}
          save={save}
          status_id={otherStatus}
        />
      )}

      {logModal && (
        <ProductionLog
          logModal={logModal}
          id={imp_id}
          setLogModal={setLogModal}
          type='Production Plant Extension'
        />
      )}
    </Styles>
  );
}
