import React, { useState, useContext, useEffect } from 'react';
import { Box, Accordion, Button } from '@mantine/core';
import ContentPaper from '../../../../components/layouts/rfs/Content';
import { Row } from 'react-bootstrap';
import { RfsContext } from '../../../../contexts/RfsContext';
import { MaterialCodeTableVeam } from './MaterialCodeTableVeam';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AppLoader } from '../../../../components/common/loader';
import { FileX, TableExport } from 'tabler-icons-react';
import { CSVDownload, CSVLink } from 'react-csv';
import ExportButton from '../../../scopeInitiation1/export';
import moment from 'moment';
//import { name } from '@azure/msal-browser/dist/packageMetadata';
import checkClosedOrInProgress from '../../veamHelper';
import csvDownload from 'json-to-csv-export';

export default function MaterialCreationCodeVeam({ checkClosuresMat, readOnly }) {
  const { form } = useContext(RfsContext);
  const { rfs_number } = form.rfsInformation;
  const [materialData, setMaterialData] = useState([]);
  const [materialDataN, setMaterialDataN] = useState([]);
  const [scopeErrors, setScopeErrors] = useState({
    errors: {},
    liquidErrors: {},
    materialErrors: {},
    productionSiteErrors: {},
  });
  const [loading, setLoading] = useState(false);
  const { fetchMaterialCreationVeam, StoreManualClosureVEAM } = useContext(RfsContext);
  const [value, setValue] = useState(0);

  useEffect(() => {
    getMaterialDetails(rfs_number);
  }, [rfs_number]);

  useEffect(() => {
    checkClosuresMat(checkClosedOrInProgress(Object.values(materialDataN)));
  }, [materialDataN]);

  const getMaterialDetails = (rfs_number) => {
    setLoading(true);
    fetchMaterialCreationVeam(rfs_number).then((res) => {
      setMaterialDataN(res.data.data);
      let data = [];

      data['Materials'] = res.data.data.filter((item) => item.int_rfs_material !== null);
      data['Liquids'] = res.data.data.filter((item) => item.int_rfs_liquid !== null);

      setMaterialData(data);
      setLoading(false);
    });
  };

  const save = (payload, id, callback) => {
    setLoading(true);
    StoreManualClosureVEAM(payload, id).then(() => {
      getMaterialDetails(rfs_number);
      setLoading(false);
    });
  };

  const handleMaterialCodeChange = (id, field_name, value, name) => {
    let currentValues = [{ ...materialData }];
    const index = Object.values(currentValues)[0][name].findIndex((i) => i.id === id);
    currentValues[0][name][index]['int_rfs_impl_material_creation'][field_name] = value;
    setMaterialData(currentValues[0]);
  };

  //----CSV download-----------------------------
  const downloadExcel = (type) => {
    const dataToExport = {
      data: [],
      filename: '',
      delimiter: ',',
      headers: [],
    };
    const materialsToExport = {
      data: [],
      filename: '',
      delimiter: ',',
      headers: [],
    };
    const liquidHeaders = [
      'Old Liquid Code',
      'Old Liquid',
      'Name	New Plant',
      'Type Of Change',
      'New Quantity',
      'Unit',
      'Technical Specification',
      'Comments',
      'New Liquid Code',
      'New Liquid Description',
      'Status',
      'Action',
      'Step Deadline',
      'Step Finished',
    ];
    const materialHeaders = [
      'Old Material Code',
      'Old Material Name',
      'New Plant',
      'Technical Pack Change',
      'Technical Quantity Change',
      'Artwork Management',
      'New Tech. Spec.',
      'Comments',
      'New Material Code',
      'New Material Description',
      'Changeover type',
      'Status',
      'Action',
      'Step Deadline',
      'Step Finished',
    ];

    const liquids = materialData.Liquids;
    const materials = materialData.Materials;

    if ((liquids || []).length > 0) {
      dataToExport.data = getDataToExport(liquids, 'liquids');

      dataToExport.filename =
        'Material_Code_Creation_Liquids' + moment().format('YYYY-MM-DD HH:mm:ss');
      dataToExport.headers = liquidHeaders;
      csvDownload(dataToExport);
    }

    if ((materials || []).length > 0) {
      materialsToExport.data = getDataToExport(materials, 'materials');
      materialsToExport.filename =
        'Material_Code_Creation_Materials' + moment().format('YYYY-MM-DD HH:mm:ss');
      materialsToExport.headers = materialHeaders;
      csvDownload(materialsToExport);
    }
  };

  const getDataToExport = (data, type) => {
    const csvArray = type === 'materials' ? data.map(dataToExportM) : data.map(dataToExportL);

    return csvArray;
  };

  const dataToExportM = ({
    int_rfs_material,
    finished_date,
    sla_date,
    int_rfs_impl_material_creation,
    status,
    manually_closed,
  }) => {
    return {
      'Material Code': int_rfs_material?.material_code ? int_rfs_material.material_code : 'N/A',
      'Material Description': int_rfs_material?.material_description
        ? int_rfs_material.material_description
        : 'N/A',
      Plant: int_rfs_material?.plant ? int_rfs_material.plant : 'N/A',
      'Technical Pack Change ': int_rfs_material?.technical_pack_change
        ? int_rfs_material.technical_pack_change
        : 'N/A',
      'Technical Quality Change': int_rfs_material?.technical_quality_change
        ? int_rfs_material.technical_quality_change
        : 'N/A',
      'Artwork Management': int_rfs_material?.artwork_change
        ? int_rfs_material.artwork_change
        : 'N/A',
      'New Tech. Spec.': int_rfs_material?.tech_spec_matrix
        ? int_rfs_material.tech_spec_matrix
        : 'N/A',
      Comments: int_rfs_material?.comment ? int_rfs_material.comment : 'N/A',
      'New Material Number': int_rfs_impl_material_creation?.new_material_code
        ? int_rfs_impl_material_creation.new_material_code
        : 'N/A',
      'New Material Name': int_rfs_impl_material_creation?.new_material_desc
        ? int_rfs_impl_material_creation.new_material_desc
        : 'N/A',
      'Changeover type': int_rfs_material?.changeover_type
        ? int_rfs_material.changeover_type
        : 'N/A',
      Status: status,
      Action:
        status === 'Closed' ? (manually_closed ? 'Manually Closed' : 'Automatic Closure') : 'NA',
      'step Deadline': sla_date,
      'step Finished': status === 'Closed' ? finished_date : status,
    };
  };

  const dataToExportL = ({
    int_rfs_liquid,
    finished_date,
    sla_date,
    int_rfs_impl_material_creation,
    status,
    manually_closed,
  }) => {
    return {
      'Old Liquid Code': int_rfs_liquid?.liquid_code ? int_rfs_liquid.liquid_code : 'N/A',
      'Old Liquid Name': int_rfs_liquid?.liquid_description
        ? int_rfs_liquid.liquid_description
        : 'N/A',
      'New Plant': int_rfs_liquid?.plant ? int_rfs_liquid.plant : 'N/A',
      'Type Of Change': int_rfs_liquid?.bom_changes ? int_rfs_liquid.bom_changes : 'N/A',
      'New Quantity': int_rfs_liquid?.new_quantity ? int_rfs_liquid.new_quantity : 'N/A',
      Unit: int_rfs_liquid?.unit ? int_rfs_liquid.unit : 'N/A',
      'Technical Specification': int_rfs_liquid?.tech_specification
        ? int_rfs_liquid.tech_specification
        : 'N/A',
      Comments: int_rfs_liquid?.comment ? int_rfs_liquid.comment : 'N/A',
      'New Liquid Code': int_rfs_impl_material_creation?.new_material_code
        ? int_rfs_impl_material_creation.new_material_code
        : 'N/A',
      'New Liquid Description': int_rfs_impl_material_creation?.new_material_desc
        ? int_rfs_impl_material_creation.new_material_desc
        : 'N/A',
      Status: status,
      Action:
        status === 'Closed' ? (manually_closed ? 'Manually Closed' : 'Automatic Closure') : 'NA',
      'step Deadline': sla_date,
      'step Finished': status === 'Closed' ? finished_date : status,
    };
  };
  //----CSV download-----------------------------//

  if (loading) {
    return (
      <ContentPaper page={'ProductDevelopment'}>
        <AppLoader size='lg' center />
      </ContentPaper>
    );
  }

  return (
    <Row>
      {
        <div
          style={{
            display: 'flex',
            justifyContent: 'right',
            marginRight: '4rem',
          }}
        >
          <Button
            leftIcon={<TableExport />}
            onClick={() => {
              downloadExcel();
            }}
            color='yellow'
            style={{
              marginBottom: '1rem',
              marginTop: '2rem',
              background: 'black',
            }}
          >
            Export to CSV
          </Button>
        </div>
      }

      {
        <Accordion value={value} onChange={setValue}>
          {Object.keys(materialData) &&
            Object.keys(materialData).map((name, index) => {
              return (
                materialData[name].length > 0 && (
                  <Accordion.Item label={name} key={`key_${index}`} value={`key_${index}`}>
                    <Accordion.Control>{`${name}`}</Accordion.Control>
                    <Accordion.Panel>
                      <MaterialCodeTableVeam
                        name={name}
                        materialData={materialData[name]}
                        onChange={handleMaterialCodeChange}
                        save={save}
                        rfsNumber={rfs_number}
                        readOnly={readOnly}
                      />
                    </Accordion.Panel>
                  </Accordion.Item>
                )
              );
            })}
        </Accordion>
      }
    </Row>
  );
}
