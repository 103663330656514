import { useEffect, useState } from 'react';
import { Modal, Input, Button, Select } from '@mantine/core';
import styled from 'styled-components';
import { additionLiquid } from '../../../constants';

const CustomInput = styled(Input)`
  width: 100%;
  margin-bottom: 16px;
`;

const CustomSelect = styled(Select)`
  width: 100%;
  margin-bottom: 16px;
`;

export const AddLiquid = ({ opened, handleClose, handleAdd, materials, rfs_number }) => {
  const [formValues, setFormValues] = useState({ rfs_number });
  const [plantOptions, setPlantOptions] = useState([]);
  const [bomOptions, setBomOptions] = useState({});

  useEffect(() => {
    const plants = [];
    const boms = [];
    for (const material of materials) {
      if (!plants.includes(material.plant)) plants.push(material.plant);

      if (!boms[material.plant]) boms[material.plant] = [];

      if (!boms[material.plant].includes(material.bom)) boms[material.plant].push(material.bom);
    }
    setPlantOptions(plants);
    setBomOptions(boms);
  }, [materials]);

  const handleSelectChange = (value, name) => {
    const values = { ...formValues };
    values[name] = value;

    if (name === 'plant') values[['bom']] = null;

    setFormValues(values);
  };

  const onlyLetters = (str) => {
    return /^[a-zA-Z]+$/.test(str);
  };

  const onlyNumbers = (str) => {
    return /\d/.test(str);
  };

  const plantCheck = () => {
    return (
      formValues?.plant?.length !== 4 ||
      !onlyLetters(formValues?.plant[0]) ||
      !onlyLetters(formValues?.plant[1]) ||
      !onlyNumbers(formValues?.plant[2]) ||
      !onlyNumbers(formValues?.plant[3])
    );
  };

  const bomCheck = () => formValues?.bom?.length !== 2 || !onlyNumbers(formValues?.bom);

  const isDisabled = () => {
    return plantCheck() || bomCheck();
  };

  return (
    <Modal
      centered
      closeOnClickOutside={false}
      opened={opened}
      onClose={() => {
        setFormValues({});
        handleClose();
      }}
      title='Select BOM to Modify'
    >
      <CustomSelect
        placeholder='Plant (e.g. NL02)'
        name='plant'
        searchable
        clearable
        data={plantOptions}
        value={formValues['plant']}
        onChange={(value) => handleSelectChange(value, 'plant')}
      />
      <CustomSelect
        placeholder='Bom (e.g. 02)'
        name='bom'
        searchable
        clearable
        data={bomOptions[formValues['plant']] || []}
        value={formValues['bom']}
        onChange={(value) => handleSelectChange(value, 'bom')}
        disabled={!formValues['plant'] || formValues['plant'].length === 0}
      />

      <Button
        color='yellow'
        variant='gradient'
        gradient={{ from: '#e3af32', to: '#f4e00f' }}
        radius='sm'
        fullWidth={true}
        disabled={isDisabled()}
        onClick={() =>
          handleAdd({
            ...formValues,
            active: true,
            bom_component: 'New',
            MaterialInfo: 'Addition of liquids',
            bom_changes: additionLiquid.addition,
            custom_added: true,
          })
        }
      >
        Add
      </Button>
    </Modal>
  );
};
