import React, { useContext } from 'react';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from '../../../routes';
import { useSearchParams } from 'react-router-dom';
import { Tabs } from '@mantine/core';
import { MaterialCodeCreation } from './materialCodeCreation';
import { tabStyles } from '../../../utils/helpers';
import ContentPaper from '../../../components/layouts/rfs/Content';
import ProjectName from '../../../components/projectName/projectName';
import { ArtworkDevelopment } from './artworkDevelopment';
import { MaterialsContract } from './materialsContract';
import { CommonColumnsProvider } from '../../../contexts/CommonColumns';
import { ProjectOverviewContext } from '../../../contexts/projectOverviewContext';
import { AppLoader } from '../../../components/common/loader';
import { implementationPermission } from '../../../constants';
import { AuthContext } from '../../../contexts/AuthContext';

const ImplementationSupplierChange = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { rfsNumber } = params;
  const [searchParams, setSearchParams] = useSearchParams();
  const { getSLAWeeksIdsForRFS } = useContext(ProjectOverviewContext);
  const { userRole } = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState('mcc');
  const [tab, setTab] = useState('mcc');
  const [loading, setLoading] = useState(false);
  const [slaWeeksIds, setSlaWeeksIds] = useState([]);

  const urlParams = new URLSearchParams(window.location.search);
  const subTab = searchParams.get('sub') ? searchParams.get('sub') : 'mcc';
  const permissions = implementationPermission['Supplier Change'];

  useEffect(() => {
    setActiveTab(subTab);
  }, [subTab]);

  useEffect(() => {
    const sub = urlParams.get('sub');
    if (sub) {
      setTab(sub);
    }
  }, [urlParams]);

  useEffect(() => {
    setLoading(true);
    getSLAWeeksIdsForRFS(rfsNumber)
      .then((res) => {
        let sla_weeks_ids = [];
        res.data.data.map(
          (item) =>
            sla_weeks_ids.includes(item.sla_weeks_id) === false &&
            sla_weeks_ids.push(item.sla_weeks_id),
        );
        setSlaWeeksIds(sla_weeks_ids);
      })
      .finally(() => setLoading(false));
  }, [rfsNumber]);

  useEffect(() => {
    if (slaWeeksIds.length > 0) {
      const defaultTab = getDefaultTab(slaWeeksIds);
      setTab(defaultTab);
    }
  }, [rfsNumber, slaWeeksIds]);

  const getDefaultTab = (ids) => {
    const defaultValue = ids.includes(333) ? 'mcc' : ids.includes(334) ? 'ad' : 'mc';
    return defaultValue;
  };

  if (loading)
    return (
      <ContentPaper page={'ProductDevelopment'}>
        <AppLoader center size='lg' />
      </ContentPaper>
    );

  return (
    <ContentPaper page={'ProductDevelopment'}>
      <ProjectName initialItem={0} />
      <br />
      <CommonColumnsProvider>
        <Tabs
          variant='unstyled'
          styles={tabStyles}
          className='prod-dev-tabs'
          color='#e5b611'
          onTabChange={(value) =>
            navigate(
              `${routes.project(params.rfsNumber).root}?tab=${urlParams.get('tab')}&sub=${value}`,
            )
          }
          value={tab}
          keepMounted={false}
        >
          <Tabs.List>
            {slaWeeksIds.includes(333) && (
              <Tabs.Tab className='topTabs' value='mcc'>
                <b>Material Code Creation</b>
              </Tabs.Tab>
            )}
            {slaWeeksIds.includes(334) && (
              <Tabs.Tab className='topTabs' value='ad'>
                <b>Artwork Development</b>
              </Tabs.Tab>
            )}
            {slaWeeksIds.includes(337) && (
              <Tabs.Tab className='topTabs' value='mc'>
                <b>Materials Contract</b>
              </Tabs.Tab>
            )}
          </Tabs.List>

          {slaWeeksIds.includes(333) && (
            <Tabs.Panel value='mcc'>
              <MaterialCodeCreation
                activeTab={activeTab}
                permissions={permissions.material_code_creation}
                userRole={userRole}
              />
            </Tabs.Panel>
          )}

          {slaWeeksIds.includes(334) && (
            <Tabs.Panel value='ad'>
              <ArtworkDevelopment
                activeTab={activeTab}
                permissions={permissions.artowrk_development}
                userRole={userRole}
              />
            </Tabs.Panel>
          )}

          {slaWeeksIds.includes(337) && (
            <Tabs.Panel value='mc'>
              <MaterialsContract
                activeTab={activeTab}
                permissions={permissions.material_contracts}
                userRole={userRole}
              />
            </Tabs.Panel>
          )}
        </Tabs>
      </CommonColumnsProvider>
    </ContentPaper>
  );
};

export default ImplementationSupplierChange;
