import React, { useState, useEffect, useContext } from 'react';
import ContentPaper from '../layouts/rfs/Content';
import { Tabs, Text, rem, clsx } from '@mantine/core';
import { axios } from '../../utils/axios';
import { RfsContext } from '../../contexts/RfsContext';
import { Link } from 'react-router-dom';
import { routes } from '../../routes';
import { SkuDetailsTable } from '../tables/SKUDetailsTable/SkuDetailsTable';
import { AppLoader } from '../common/loader';
import { rfsStatus } from '../../constants';
import { filterStartsWith } from '../tables/SKUDetailsTable/Helpers';
import { Tab } from 'react-bootstrap';
import { tabStyles } from '../../utils/helpers';

const tabTypes = {
  all: 'all',
  backup: 'backup',
  my: 'my',
};

const exportMap = {
  ID: 'rfs_number',
  'RFS Name': 'project_name',
  Confidential: 'confidential',
  'Fast Track': 'fast_track',
  'Submission Date': 'submission_date',
  'RFS Status': 'status',
  'Production Business Unit': 'prodbusunit',
  'Sales Business Unit': 'sales_business_unit',
  'RFS Type': 'rfs_type',
  'First Shipment Date': 'first_shipment',
  Gatekeeper: 'GateKeeper',
  'RFS Initiator': 'initiator_name',
  'Group Status': 'groupsstatus',
  'Steps in Progress': 'sip',
  'PMO Comments': 'pmo_comment',
};

function AllRfsComp({ type = 'all' }) {
  const { form, toggle, fullscreen } = useContext(RfsContext);

  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(tabTypes[type]);
  const [nestedTab, setNestedTab] = useState(0);

  const [allRfs, setAllRfs] = useState([]);
  const [allImplementedRfs, setAllImplementedRfs] = useState([]);
  const [myRfs, setMyRfs] = useState([]);
  const [myImplementedRfs, setMyImplementedRfs] = useState([]);
  const [backupRfs, setBackupRfs] = useState([]);
  const [backupImplementedRfs, setBackupImplementedRfs] = useState([]);

  const fetchRfs = (type) => {
    setLoading(true);

    axios
      .get('/rfs', { params: { type: `${type}` } })
      .then(
        (res) => {
          const response = res.data.data;

          if (type === 'all') setAllRfs(response);
          else if (type === 'allImplemented') setAllImplementedRfs(response);
          else if (type === 'my') setMyRfs(response);
          else if (type === 'myImplemented') setMyImplementedRfs(response);
          else if (type === 'backup') setBackupRfs(response);
          else if (type === 'backupImplemented') setBackupImplementedRfs(response);
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchRfs(type);
  }, []);

  const capitalizeSnakeCase = (str) => {
    return str.replaceAll('_', ' ').replace(/(?:^|\s)\S/g, function (a) {
      return a.toUpperCase();
    });
  };

  const handleExport = (rows, data) => {
    const filteredRfs = [];
    data.forEach((item) => {
      const rfs = {};

      Object.keys(exportMap).forEach((key) => {
        rfs[key] =
          exportMap[key] === 'confidential'
            ? item[exportMap[key]] === 'true'
              ? 'True'
              : 'False'
            : exportMap[key] === 'fast_track'
            ? item[exportMap[key]] === 'true'
              ? 'Applied'
              : 'Not Applied'
            : item[exportMap[key]];
        filteredRfs.push(rfs);
      });
    });

    return filteredRfs;
  };

  const ALL_RFS_COLUMNS = [
    {
      Header: 'ID',
      accessor: 'rfs_number',
      sticky: 'left',
      minWidth: 125,
      width: 125,
      maxWidth: 125,
    },
    {
      Header: 'RFS Name',
      accessor: 'project_name',
      sticky: 'left',
      minWidth: 280,
      width: 280,
      maxWidth: 280,
      Cell: ({ row }) => {
        const { rfs_number, status } = row.original;
        return (
          <Link
            to={
              [
                rfsStatus.submitted,
                rfsStatus.rejected,
                rfsStatus.tech_feasible,
                rfsStatus.tech_feasible2,
                rfsStatus.product_development,
                rfsStatus.trial_material_code_completed,
                rfsStatus.supplier_name,
                rfsStatus.artwork_development_not_needed,
                rfsStatus.artwork_deelopment_completed,
                rfsStatus.changeover_applied,
                rfsStatus.ean_approved,
                rfsStatus.contracts_closed,
                rfsStatus.product_development,
                rfsStatus.imp_feasible,
                rfsStatus.implementation,
                rfsStatus.imp_feasible_rejected,
                rfsStatus.implemented,
                rfsStatus.scope_unlock,
                rfsStatus.reversal,
                rfsStatus.scope_restated,
                rfsStatus.reversed,
                rfsStatus.cancelled,
                rfsStatus.scope_unlocked,
                'Rejected',
              ].includes(status)
                ? routes.project(rfs_number).root
                : routes.submission(rfs_number).review
            }
          >
            <Text>{row.original?.project_name}</Text>
          </Link>
        );
      },
    },
    {
      Header: 'Confidential',
      accessor: 'confidential',
      sticky: 'left',
      minWidth: 125,
      width: 125,
      maxWidth: 125,
      Cell: (row) => {
        return row.cell.row.original?.confidential === 'true' ? 'True' : 'False';
      },
    },
    {
      Header: 'Fast Track',
      accessor: 'fast_track',
      sticky: 'left',
      minWidth: 125,
      width: 125,
      maxWidth: 125,
      filter: filterStartsWith,
      Cell: (row) => {
        return row.cell.row.original?.fast_track === 'true' ? 'Applied' : 'Not Applied';
      },
    },

    {
      Header: 'Submission Date',
      accessor: 'submission_date',
      minWidth: 175,
      width: 175,
      maxWidth: 175,
      Cell: (row) => {
        return row.cell.row.original?.submission_date
          ? row.cell.row.original?.submission_date
          : 'Not Submitted yet';
      },
    },

    {
      Header: 'RFS Status',
      accessor: 'status',
      minWidth: 125,
      width: 125,
      maxWidth: 125,
      Cell: (row) => {
        const status = row.cell.row.original?.status;
        return <div className='capatilize'>{status}</div>;
      },
    },
    {
      Header: 'Production Business Unit',
      accessor: 'prodbusunit',
      minWidth: 200,
      width: 200,
      maxWidth: 200,
    },
    {
      Header: 'Sales Business Unit',
      accessor: 'sales_business_unit',
      minWidth: 175,
      width: 175,
      maxWidth: 175,
      Cell: (row) => {
        let data = row.cell.row.original?.sales_business_unit;
        const spaced = data?.replace(/,/g, ', ');
        return spaced ? (spaced === 'null' ? 'Not Selected' : spaced) : 'Not Selected';
      },
    },
    {
      Header: 'RFS Type',
      accessor: 'rfs_type',
    },
    {
      Header: 'First Shipment Date',
      accessor: 'first_shipment',
      minWidth: 175,
      width: 175,
      maxWidth: 175,
    },
    {
      Header: 'Gatekeeper',
      accessor: 'GateKeeper',
      minWidth: 125,
      width: 125,
      maxWidth: 125,
    },
    {
      Header: 'RFS Initiator',
      accessor: 'initiator_name',
      minWidth: 125,
      width: 125,
      maxWidth: 125,
    },
    {
      Header: 'Group Status',
      accessor: 'groupsstatus',
      minWidth: 125,
      width: 125,
      maxWidth: 125,
    },
    /*{
      Header: 'Markets',
      accessor: 'markets',
      minWidth: 150,
      width: 150,
      maxWidth: 150,
      Cell: ({ row }) => {
        let data = row.original.markets?.split(', ');

        return (
          <div>
            {data?.map((d, k) => (
              <p key={k} style={{ marginBottom: '0px' }}>
                {d}
              </p>
            ))}
          </div>
        );
      },
    },
    {
      Header: 'Plants',
      accessor: 'plants',
    },
    {
      Header: 'Brands',
      accessor: 'brands',
    },
    {
      Header: 'Sub-Brands',
      accessor: 'subbrands',
      minWidth: 125,
      width: 125,
      maxWidth: 125,
    },
    {
      Header: 'Containers',
      accessor: 'containers',
      minWidth: 125,
      width: 125,
      maxWidth: 125,
    },
    {
      Header: 'SKU Numbers',
      accessor: 'skunumbers',
      minWidth: 125,
      width: 125,
      maxWidth: 125,
    },
    {
      Header: 'Material Numbers',
      accessor: 'matnumbers',
      minWidth: 175,
      width: 175,
      maxWidth: 175,
    },
    {
      Header: 'SLA',
      accessor: 'sla',
      minWidth: 75,
      width: 75,
      maxWidth: 75,
    },*/
    {
      Header: 'Steps in Progress',
      accessor: 'sip',
      minWidth: 250,
      width: 250,
      maxWidth: 250,
      Cell: ({ row }) => {
        let data = row.original.sip?.split(', ');

        return (
          <div>
            {data?.map((d, k) => (
              <p key={k} style={{ margin: '0rem 1rem' }}>
                {d}
              </p>
            ))}
          </div>
        );
      },
    },
    {
      Header: 'PMO Comments',
      accessor: 'pmo_comment',
      minWidth: 250,
      width: 250,
      maxWidth: 250,
    },
  ];

  const handleTabChange = (activeTab, tabKey) => {
    setActiveTab(activeTab);
    fetchRfs(activeTab);
  };

  const handleNestedTabChange = (nestedTab, tabKey) => {
    setNestedTab(nestedTab);
    fetchRfs(nestedTab);
  };

  return (
    <ContentPaper className='mainpage' page={'all-rfs'}>
      <Tabs
        variant='unstyled'
        styles={tabStyles}
        className='all-rfs-tabs'
        value={activeTab}
        onTabChange={handleTabChange}
        //color='yellow'
        defaultValue='all'
        keepMounted={false}
      >
        <Tabs.List>
          <Tabs.Tab value='all'>All RFS</Tabs.Tab>
          <Tabs.Tab value='backup'>Backup RFS</Tabs.Tab>
          <Tabs.Tab value='my'>My RFS</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value='all'>
          <Tabs
            className='in-progress-tabs'
            active={nestedTab}
            onTabChange={handleNestedTabChange}
            color='dark'
            defaultValue='all'
            keepMounted={false}
          >
            <Tabs.List>
              <Tabs.Tab className='subTab' value='all' style={{ color: '#000' }}>
                In Progress
              </Tabs.Tab>
              <Tabs.Tab className='subTab' value='allImplemented' style={{ color: 'black' }}>
                Fully Implemented
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value='all'>
              <div className='table-container'>
                {!loading ? (
                  <SkuDetailsTable
                    columns={ALL_RFS_COLUMNS}
                    data={allRfs}
                    handleExport={(rows) => handleExport(rows, allRfs)}
                    fullScreenToggle={toggle}
                    fullscreen={fullscreen}
                    type={'rfs'}
                  />
                ) : (
                  <AppLoader center size='lg' />
                )}
              </div>
            </Tabs.Panel>
            <Tabs.Panel value='allImplemented'>
              <div className='table-container'>
                {!loading ? (
                  <SkuDetailsTable
                    columns={ALL_RFS_COLUMNS}
                    data={allImplementedRfs}
                    handleExport={(rows) => handleExport(rows, allImplementedRfs)}
                    fullScreenToggle={toggle}
                    fullscreen={fullscreen}
                    type={'rfs'}
                  />
                ) : (
                  <AppLoader center size='lg' />
                )}
              </div>
            </Tabs.Panel>
          </Tabs>
        </Tabs.Panel>

        <Tabs.Panel value='backup'>
          <Tabs
            className='in-progress-tabs'
            active={nestedTab}
            onTabChange={handleNestedTabChange}
            color='dark'
            defaultValue='backup'
            keepMounted={false}
          >
            <Tabs.List>
              <Tabs.Tab className='subTab' value='backup' style={{ color: '#000' }}>
                In Progress
              </Tabs.Tab>
              <Tabs.Tab className='subTab' value='fullyImplemented' style={{ color: 'black' }}>
                Fully Implemented
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value='backup'>
              <div className='table-container'>
                {!loading ? (
                  <SkuDetailsTable
                    columns={ALL_RFS_COLUMNS}
                    data={backupRfs}
                    handleExport={(rows) => handleExport(rows, backupRfs)}
                    fullScreenToggle={toggle}
                    fullscreen={fullscreen}
                    type={'rfs'}
                  />
                ) : (
                  <AppLoader center size='lg' />
                )}
              </div>
            </Tabs.Panel>
            <Tabs.Panel value='backupImplemented'>
              <div className='table-container'>
                {!loading ? (
                  <SkuDetailsTable
                    columns={ALL_RFS_COLUMNS}
                    data={backupImplementedRfs}
                    handleExport={(rows) => handleExport(rows, backupImplementedRfs)}
                    fullScreenToggle={toggle}
                    fullscreen={fullscreen}
                    type={'rfs'}
                  />
                ) : (
                  <AppLoader center size='lg' />
                )}
              </div>
            </Tabs.Panel>
          </Tabs>
        </Tabs.Panel>

        <Tabs.Panel value='my'>
          <Tabs
            className='in-progress-tabs'
            active={nestedTab}
            onTabChange={handleNestedTabChange}
            color='dark'
            defaultValue='my'
            keepMounted={false}
          >
            <Tabs.List>
              <Tabs.Tab className='subTab' value='my' style={{ color: '#000' }}>
                In Progress
              </Tabs.Tab>
              <Tabs.Tab className='subTab' value='myImplemented' style={{ color: '#000' }}>
                Fully Implemented
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value='my'>
              <div className='table-container'>
                {!loading ? (
                  <SkuDetailsTable
                    columns={ALL_RFS_COLUMNS}
                    data={myRfs}
                    handleExport={(rows) => handleExport(rows, myRfs)}
                    fullScreenToggle={toggle}
                    fullscreen={fullscreen}
                    type={'rfs'}
                  />
                ) : (
                  <AppLoader center size='lg' />
                )}
              </div>
            </Tabs.Panel>
            <Tabs.Panel value='myImplemented'>
              <div className='table-container'>
                {!loading ? (
                  <SkuDetailsTable
                    columns={ALL_RFS_COLUMNS}
                    data={myImplementedRfs}
                    handleExport={(rows) => handleExport(rows, myImplementedRfs)}
                    fullScreenToggle={toggle}
                    fullscreen={fullscreen}
                    type={'rfs'}
                  />
                ) : (
                  <AppLoader center size='lg' />
                )}
              </div>
            </Tabs.Panel>
          </Tabs>
        </Tabs.Panel>
      </Tabs>
    </ContentPaper>
  );
}

export default AllRfsComp;
