import React, { useContext, useState } from 'react';
import { Modal, Button, Grid, Group, Text } from '@mantine/core';
import { Checkbox } from '@mantine/core';

import { RfsContext } from '../../contexts/RfsContext';
import { InfoCircle } from 'tabler-icons-react';
import { AppLoader } from '../common/loader';

export const InvalidModal = ({
  invalidModal,
  setInvalidModal,
  callback = null,
  nextClick,
  text = 'You have not filled all the mandatory fields. If you wish to continue without finishing, please select the checkbox below.',
}) => {
  const { loading, saveAsDraft } = useContext(RfsContext);
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleClose = () => {
    setInvalidModal(false);
  };

  const handleNext = () => {
    nextClick(invalidModal.callback);
  };

  return (
    <>
      <Modal
        className='Invalid-modal'
        opened={invalidModal.open}
        onClose={() => handleClose()}
        title='Warning'
      >
        <Grid>
          <Grid.Col span={12}>
            <Group noWrap className='invalid-modal-group'>
              <InfoCircle color='red' size='5rem' />
              <Text size='sm'>{text}</Text>
            </Group>
          </Grid.Col>
          <Grid.Col span={12}>
            <Checkbox
              label='I have read the above and wish to continue anyway'
              name='confirmed'
              onChange={handleChange}
            />
          </Grid.Col>
          <Grid.Col span={4} offset={8}>
            <Button
              onClick={() => handleNext()}
              variant='gradient'
              gradient={{ from: '#e3af32', to: '#f4e00f' }}
              color='yellow'
              fullWidth
              disabled={!checked || loading || saveAsDraft}
              //style={{background:'linear-gradient(90deg,#e3af32 0%,#f4e00f 100%)',color: 'black' }}
            >
              {!loading && !saveAsDraft ? 'Next' : <AppLoader />}
            </Button>
          </Grid.Col>
        </Grid>
      </Modal>
    </>
  );
};
