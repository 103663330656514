import React, { useEffect, useState } from 'react';
import { Link, NavLink, useLocation, useParams } from 'react-router-dom';
import style from './navItem.module.css';
import { ChevronDown } from 'tabler-icons-react';
import { routes } from '../../routes';

const NavItemHeader = (props) => {
  const location = useLocation();
  const { rfsNumber = null } = useParams();
  const { item } = props;
  const { label, Icon, to: headerToPath, children } = item;

  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const isPathMatching = location.pathname.includes(headerToPath.split('/')[1]);
    const shouldExpand = location.pathname !== routes.submission().add && isPathMatching;
    setExpanded(shouldExpand);
  }, [location.pathname, headerToPath]);

  const onExpandChange = (e) => {
    e.preventDefault();
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const isActive = (data) => (location.pathname.includes(data) ? 'activeNavItem' : '');

  return (
    <>
      <Link
        to={headerToPath}
        className={`${style.navItem} ${isActive(headerToPath)} ${style.navItemHeaderButton}`}
      >
        <Icon className={style.navIcon} />
        <span className={style.navLabel}>{label}</span>

        {children.length > 0 && children.filter((child) => isActive(child.to)).length > 0 && (
          <ChevronDown
            className={`${style.navItemHeaderChevron} ${expanded && style.chevronExpanded}`}
            onClick={onExpandChange}
          />
        )}
      </Link>

      {expanded && (
        <div className={style.navChildrenBlock}>
          {children.map((item, index) => {
            const key = `${item.label}-${index}`;

            const { label, status = null, Icon, children, type = null } = item;

            if (children) {
              return (
                <div key={key}>
                  <NavItemHeader
                    item={{
                      ...item,
                      to: item.to,
                    }}
                  />
                </div>
              );
            }

            return (
              <div
                className={!rfsNumber && type === 'rfs_submission' ? 'cursor-not-allowed' : ''}
                key={key}
              >
                <NavLink
                  to={item.to}
                  className={`${style.navItem} ${isActive(item.to)} ${
                    !rfsNumber && type === 'rfs_submission' ? 'disabled-links' : ''
                  }`}
                  style={{ paddingLeft: '2.5rem' }}
                >
                  <Icon className={style.navIcon} />
                  <span className={style.navLabel}>{label}</span>
                  {status && <span className={style.navLabel}>{status}</span>}
                </NavLink>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default NavItemHeader;
