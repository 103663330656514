import React, { useState, useContext, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button, Select, Text, SimpleGrid, Grid, Accordion, Textarea } from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';

import TechFeasibilityModal from '../../components/modal/techFeasibilityModal/modal';
import LiquidTable from '../../components/tables/liquidsTable/liquidsTable';
import MaterialTable from '../../components/tables/materialTable/materialTable';
import SubmitProjectModal from '../../components/modal/SubmitProjectModal';
import RejectDraftModal from '../../components/modal/RejectDraftModal';

import ProjectName from '../../components/projectName/projectName';
import { AppLoader } from '../../components/common/loader';
import { minWordsValidation } from '../../components/forms/rfsInitiationForm/rfsInitiationForm';
import { AuthContext } from '../../contexts/AuthContext';
import { roles, rfsStatus, NpsIds } from '../../constants';
import './techfeasibility.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { RfsContext } from '../../contexts/RfsContext';
import ContentPaper from '../../components/layouts/rfs/Content';
import { routes } from '../../routes';
import { Pencil } from 'tabler-icons-react';
import { axios } from '../../utils/axios';
import { SkuTable } from '../../components/tables/skutable/skuTable';
import { ProductionSite } from '../../components/tables/skutable/production-site';

import { NpsComponent } from '../../common/nps';
import ModalAccf from '../accf/ModalAccf';
import { FileList } from '../../common/dropzone/fileList';

const accessors = {
  materials: 'selection_number',
  liquids: 'selection_number',
  skus: 'selection_number',
};
const secondaryAccessor = {
  materials: 'selection_name',
  liquids: 'selection_name',
  skus: 'selection_name',
};
const tertiaryAccessor = {
  materials: null,
  liquids: null,
  skus: null,
};

export default function TechFeasibility(props) {
  const navigate = useNavigate();
  const params = useParams();

  const { form, setForm, updateRfsStatus, attachmentLoading, downloadFile, info } =
    useContext(RfsContext);
  const { userRole, userEmail } = useContext(AuthContext);
  const [tfModal, setTfModal] = useState(false);
  const [submitProjectModal, setSubmitProjectModal] = useState(false);
  const [rejectDraftModal, setRejectDraftModalModal] = useState(false);
  const [rejectDraft, setRejectDraft] = useState(false);
  const [validate, setValidate] = useState(false);
  const [fetched, setFetched] = useState({});
  const [vis1, setVis1] = useState(true);
  const [scopeErrors, setScopeErrors] = useState({
    errors: {},
    liquidErrors: {},
    materialErrors: {},
    productionSiteErrors: {},
  });
  const scopeSelectedSkus = form.scopeSelectedSkus;
  const [disable, setDisable] = useState(true);
  const [accordionState, setAccordionState] = useState({});
  const [approveLoading, setApproveLoading] = useState(false);

  const [npsOpened, setNpsOpened] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const {
    initiator_email = null,
    artwork_change = '',
    project_name = '',
    initiator_position = '',
    initiator_team = '',
    backupUsers = [],
    allowedUsers = [],
    first_shipment = '',
    sales_business_unit = [],
    technicalLead = [],
    fast_track = '',
    capex_needed = '',
    capex_amount,
    gatekeeper = [],
    cost_center = '',
    country_legal_spoc = '',
    project_purpose = '',
    project_details = '',
    comments = '',
    trials_needed = '',
    pmo_comments = '',
    are_trials_actually_needed = '',
    rfs_type,
    status,
    submission_date,
    impactedPlants = [],
  } = form.rfsInformation;

  const {
    artworkBriefing,
    fastTrackApproval,
    capexEmailApproval,
    emailAttachment,
    repacking,
    liquidAttachment,
  } = form.attachments;

  const repack = Object.values(scopeSelectedSkus).some((item) => item.production == 'Repacking');
  const { scopeSelectedMaterials = [] } = form;
  const [productionErrors, setProductionErrors] = useState([]);
  const pmo_comment_length = fetched.pmo_comments?.trim().length;
  useEffect(() => {
    if ([roles.admin, roles.superAdmin, roles.pmo].includes(userRole)) setDisable(false);
  }, [userRole]);

  const editScope = () => {
    let access = false;
    if (
      ((initiator_email === userEmail && status === rfsStatus.draft) ||
        [roles.superAdmin, roles.pmo].includes(userRole)) &&
      [rfsStatus.tech_feasible, rfsStatus.tech_feasible2].includes(status)
    ) {
      access = true;
    }

    return access;
  };

  const handleTechChange = (event) => {
    const currentValues = { ...fetched };
    currentValues[event.target?.name] = event.target?.value;

    if (event.target.name === 'pmo_comments' && event.target.value.length > 500)
      currentValues[event.target?.name] = event.target?.value.substring(0, 500);

    setFetched(currentValues);
  };

  const handleTechSelect = (value) => {
    const currentValues = { ...fetched };
    currentValues['are_trials_actually_needed'] = value;
    setFetched(currentValues);
  };

  useEffect(() => {
    if (
      form.rfsInformation &&
      form.rfsInformation.rfs_type &&
      form.rfsInformation.rfs_type === 'Open SKU to market'
    )
      setVis1(false);
  }, [form.rfsInformation.rfs_type]);

  // useEffect(() => {
  //   //Rediret to home after submission
  //   if (submitted && !npsOpened && userRole === roles.pmo) window.location.reload();
  //   if (submitted && userRole !== roles.pmo) window.location.reload();
  // }, [submitted, npsOpened]);

  const afterEditTechFeasibility = () => {
    setNpsOpened(true);
    // setSubmitted(true);
  };

  const getAffectedSKUs = (material) => {
    let skus = form.selectedAffectedSkus.filter((val) => parseInt(val.scope_id) === material.id);

    return skus;
  };

  const getLiquids = (liquid) => {
    let liquids = form.scopeSelectedLiquids.filter((val) => parseInt(val.scope_id) === liquid.id);

    return liquids;
  };

  const getMaterials = (material) => {
    let materials = form.scopeSelectedMaterials.filter(
      (val) => parseInt(val.scope_id) === material.id,
    );

    return materials;
  };

  const getUsers = (type) => {
    if (type === 'allowed') {
      let users = (allowedUsers || []).map((item) => item.name);
      return users?.join(', ');
    } else if (type === 'backup') {
      let users = (backupUsers || []).map((item) => item.name);
      return users?.join(', ');
    } else if (type === 'gatekeeper') {
      let users = (gatekeeper || []).map((item) => item.name);
      return users?.join(', ');
    } else if (type === 'technical') {
      let users = (technicalLead || []).map((item) => item.name);
      return users?.join(', ');
    } else {
      return null;
    }
  };

  const fetchTechFeasibility = (rfsNumber) => {
    axios.get(`/tech-feasibility/fetch/${rfsNumber}`).then(
      (res) => {
        const response = res.data.fetchedData;
        setFetched(response);
      },
      (error) => {},
    );
  };

  const createTechFeasibility = (payload, callback) => {
    setApproveLoading(true);
    axios
      .post(`/tech-feasibility`, payload)
      .then(
        (res) => {
          // info(params.rfsNumber);
          callback();
        },
        (err) => {},
      )
      .finally(() => setApproveLoading(false));
  };

  const updateTechFeasibility = (payload, callback) => {
    setApproveLoading(true);
    axios
      .put(`/tech-feasibility/update/${params.rfsNumber}`, payload)
      .then(
        (res) => {
          // info(params.rfsNumber);
          callback();
        },
        (err) => {},
      )
      .finally(() => setApproveLoading(false));
  };

  const isDisabled = () => {
    return (
      pmo_comment_length === 0 ||
      !fetched.pmo_comments ||
      (!vis1 && impactedPlants.length === 0) ||
      (vis1 && !fetched.are_trials_actually_needed)
    );
  };

  const handleNext = (type) => {
    setValidate(true);

    if (isDisabled() && type === 'Approved') {
      setSubmitProjectModal(true);
      setRejectDraft(true);
    } else if (
      ((typeof fetched.pmo_comments === 'undefined' || fetched.pmo_comments.length === 0) &&
        type === 'Draft') ||
      ((typeof fetched.pmo_comments === 'undefined' || fetched.pmo_comments.length === 0) &&
        type === 'Rejected')
    ) {
      setRejectDraftModalModal(true);
    } else {
      if (type === 'Approved') {
        updateRfsStatus(
          params.rfsNumber,
          fetched.are_trials_actually_needed === 'Yes' ? 'Product Development' : 'Impl Feasible',
        );
      } else if (type === 'Draft') {
        updateRfsStatus(params.rfsNumber, 'draft');
      } else {
        updateRfsStatus(params.rfsNumber, 'rejected');
      }

      if (fetched.id) {
        updateTechFeasibility(
          {
            are_trials_actually_needed: fetched.are_trials_actually_needed,
            pmo_comments: fetched.pmo_comments,
            tf_status: type,
            rfs_number: params.rfsNumber,
            user: userEmail,
            impactedPlants: impactedPlants,
          },
          () => afterEditTechFeasibility(),
        );
      } else {
        createTechFeasibility(
          {
            are_trials_actually_needed: fetched.are_trials_actually_needed,
            pmo_comments: fetched.pmo_comments,
            tf_status: type,
            rfs_number: params.rfsNumber,
            user: userEmail,
            impactedPlants: impactedPlants,
          },
          () => afterEditTechFeasibility(),
        );
      }
    }
  };

  useEffect(() => {
    fetchTechFeasibility(params.rfsNumber);
  }, []);

  const handleAccfSelect = (type, data) => {
    const currentValues = { ...form };
    const fields = {
      markets: 'impactedMarkets',
      plants: 'impactedPlants',
    };
    if (currentValues.rfsInformation[fields[type]]) currentValues.rfsInformation[fields[type]] = [];

    currentValues.rfsInformation[fields[type]] = data;
    setForm(currentValues);
  };

  const Footer = () => (
    <Row className='d-inline-flex justify-content-md-end' md={4}>
      <Col md='auto'>
        <Button
          onClick={() => handleNext('Rejected')}
          className='rejectpr'
          color='red'
          radius='md'
          size='md'
          fullWidth
          style={{
            color: 'white',
            width: '200px',
          }}
        >
          Reject Project
        </Button>
      </Col>
      <Col md='auto'>
        <Button
          onClick={() => handleNext('Draft')}
          className='draft'
          color='yellow'
          radius='md'
          size='md'
          fullWidth
          style={{
            background: 'linear-gradient(90deg,#e3af32 0%,#f4e00f 100%)',
            color: 'black',
            width: '200px',
          }}
        >
          Move to Draft
        </Button>
      </Col>
      <Col md='auto'>
        <Button
          onClick={() => handleNext('Approved')}
          className='approvepr'
          color='green'
          radius='md'
          size='md'
          fullWidth
          style={{
            color: 'white',
            width: '200px',
          }}
          disabled={approveLoading}
        >
          Approve Project
        </Button>
      </Col>
    </Row>
  );

  return (
    <ContentPaper page='tech-feasibility' footer={editScope() ? <Footer /> : null}>
      <ProjectName Pnumber={project_name} initialItem={props.initialItems} />
      <Row>
        <Col
          md={1}
          style={{
            width: '2.0833%',
            flex: '0 0 2.0833%',
            maxWidth: ' 2.0833%',
          }}
        ></Col>
        <Col
          md={{ span: 3 }}
          style={{
            marginTop: '1rem',
          }}
        >
          <h5 className='sectionHeadings'>
            GENERAL DETAILS{' '}
            {editScope() && (
              <span className='editIcon'>
                <Pencil
                  color='#e5b611'
                  onClick={() =>
                    navigate(routes.submission(params.rfsNumber).info, {
                      state: {
                        routeFromRfsOverview: true,
                      },
                    })
                  }
                />
              </span>
            )}
          </h5>
        </Col>
      </Row>
      <Row>
        <Col
          md={1}
          style={{
            width: '4.1666%',
            flex: '0 0 4.1666%',
            maxWidth: ' 4.1666%',
          }}
        ></Col>

        <Col md={{ span: 5, offset: 0 }}>
          <SimpleGrid cols={2}>
            <Text size='sm' weight={600} color={!initiator_position ? 'red' : ''}>
              Initiator Position:
            </Text>
            <Text size='sm' weight={400}>
              {initiator_position}
            </Text>
          </SimpleGrid>
        </Col>

        <Col md={{ span: 5 }}>
          <SimpleGrid cols={2}>
            <Text size='sm' weight={600} color={!initiator_team ? 'red' : ''}>
              Initiator Team:
            </Text>
            <Text size='sm' weight={400}>
              {initiator_team}
            </Text>
          </SimpleGrid>
        </Col>
      </Row>
      <Row>
        <Col
          md={1}
          style={{
            width: '4.1666%',
            flex: '0 0 4.1666%',
            maxWidth: ' 4.1666%',
          }}
        ></Col>

        <Col md={{ span: 5, offset: 0 }}>
          <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
            <Text size='sm' weight={600} color={(backupUsers || []).length === 0 ? 'red' : ''}>
              Selected Backup Users:
            </Text>
            <Text size='sm' weight={400}>
              {getUsers('backup') === '' ? 'No users selected' : getUsers('backup')}
            </Text>
          </SimpleGrid>
        </Col>
        <Col md={{ span: 5, offset: 0 }}>
          <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
            <Text size='sm' weight={600}>
              Request for Fast Track:
            </Text>
            <Text size='sm' weight={400}>
              {fast_track ? 'Yes' : 'No'}
            </Text>
          </SimpleGrid>
        </Col>
      </Row>
      <Row>
        <Col
          md={1}
          style={{
            width: '4.1666%',
            flex: '0 0 4.1666%',
            maxWidth: ' 4.1666%',
          }}
        ></Col>
        {vis1 && (
          <Col md={{ span: 5, offset: 0 }}>
            <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
              <Text size='sm' weight={600} color={(technicalLead || []).length === 0 ? 'red' : ''}>
                Selected Technical Lead:
              </Text>
              <Text size='sm' weight={400}>
                {getUsers('technical') === '' ? 'No users selected' : getUsers('technical')}
              </Text>
            </SimpleGrid>
          </Col>
        )}
        {vis1 && (
          <Col md={{ span: 5, offset: 0 }}>
            <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
              <Text size='sm' weight={600}>
                Selected Allowed Users:
              </Text>
              <Text size='sm' weight={400}>
                {getUsers('allowed') === '' ? 'No users selected' : getUsers('allowed')}
              </Text>
            </SimpleGrid>
          </Col>
        )}
      </Row>
      <Row>
        <Col
          md={1}
          style={{
            width: '4.1666%',
            flex: '0 0 4.1666%',
            maxWidth: ' 4.1666%',
          }}
        ></Col>

        {form.rfsInformation.rfs_type !== 'Production Site' && (
          <Col md={{ span: 5, offset: 0 }}>
            <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
              <Text size='sm' weight={600} color={sales_business_unit.length === 0 ? 'red' : ''}>
                Sales Business Unit:
              </Text>
              <Text size='sm' weight={400}>
                {sales_business_unit?.join(', ')}
              </Text>
            </SimpleGrid>
          </Col>
        )}

        <Col md={{ span: 5, offset: 0 }}>
          <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
            <Text size='sm' weight={600} color={!first_shipment ? 'red' : ''}>
              First Shipment Date:
            </Text>
            <Text size='sm' weight={400}>
              {first_shipment}
            </Text>
          </SimpleGrid>
        </Col>
      </Row>
      <Row>
        <Col
          md={1}
          style={{
            width: '4.1666%',
            flex: '0 0 4.1666%',
            maxWidth: ' 4.1666%',
          }}
        ></Col>

        {!vis1 && (
          <Col md={{ span: 5, offset: 0 }}>
            <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
              <Text
                size='sm'
                weight={600}
                color={!country_legal_spoc || country_legal_spoc.length === 0 ? 'red' : ''}
              >
                Country/Legal SPOC:
              </Text>
              <Text size='sm' weight={400}>
                {country_legal_spoc && country_legal_spoc.length > 0
                  ? JSON.parse(country_legal_spoc).name
                  : 'N/A'}
              </Text>
            </SimpleGrid>
          </Col>
        )}
        <Col md={{ span: 5, offset: 0 }}>
          <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
            <Text size='sm' weight={600} color={!cost_center ? 'red' : ''}>
              Cost Center:
            </Text>
            <Text size='sm' weight={400}>
              {cost_center}
            </Text>
          </SimpleGrid>
        </Col>
        <Row>
          <Col
            md={1}
            style={{
              width: '4.1666%',
              flex: '0 0 4.1666%',
              maxWidth: ' 4.1666%',
            }}
          ></Col>
          <Col md={{ span: 5, offset: 0 }}>
            <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
              <Text size='sm' weight={600} color={gatekeeper?.length === 0 ? 'red' : ''}>
                Selected Gatekeeper:
              </Text>
              <Text size='sm' weight={400} style={{ paddingLeft: '6px' }}>
                {getUsers('gatekeeper') === '' ? 'No users selected' : getUsers('gatekeeper')}
              </Text>
            </SimpleGrid>
          </Col>
        </Row>
      </Row>

      <Row>
        <Col
          md={1}
          style={{
            width: '4.1666%',
            flex: '0 0 4.1666%',
            maxWidth: ' 4.1666%',
          }}
        ></Col>
        {vis1 && (
          <Col md={{ span: 5, offset: 0 }}>
            <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
              <Text size='sm' weight={600} color={!trials_needed ? 'red' : ''}>
                Are Trials needed:
              </Text>
              <Text size='sm' weight={400}>
                {trials_needed}
              </Text>
            </SimpleGrid>
          </Col>
        )}
        {capex_needed ? (
          <Col md={{ span: 5, offset: 0 }}>
            <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
              <Text size='sm' weight={600} color={!capex_amount ? 'red' : ''}>
                Capex Amount:
              </Text>
              <Text size='sm' weight={400}>
                {`${capex_amount ? `$${capex_amount}` : ''}`}
              </Text>
            </SimpleGrid>
          </Col>
        ) : null}
      </Row>

      {(!vis1 || form.rfsInformation.rfs_type === 'Production Site') && (
        <div>
          <Row>
            <Col
              md={1}
              style={{
                width: '4.1666%',
                flex: '0 0 4.1666%',
                maxWidth: ' 4.1666%',
              }}
            ></Col>

            <Row style={{ marginTop: '0.5rem' }}>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666%',
                }}
              ></Col>

              <Col md={{ span: 2 }}>
                <Text
                  size='sm'
                  weight={600}
                  color={!project_purpose || minWordsValidation(project_purpose) ? 'red' : ''}
                >
                  Project Purpose:
                </Text>
              </Col>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666%',
                }}
              ></Col>
              <Col md={{ span: 8 }}>
                <Text size='sm' weight={400}>
                  {project_purpose}
                </Text>
              </Col>
            </Row>

            <Row style={{ marginTop: '0.5rem' }}>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666%',
                }}
              ></Col>

              <Col md={{ span: 2 }}>
                <Text
                  size='sm'
                  weight={600}
                  color={!project_details || minWordsValidation(project_details) ? 'red' : ''}
                >
                  Project Explanation:
                </Text>
              </Col>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666%',
                }}
              ></Col>
              <Col md={{ span: 8 }}>
                <Text size='sm' weight={400}>
                  {project_details}
                </Text>
              </Col>
            </Row>
          </Row>
          <Row style={{ marginTop: '0.5rem' }}>
            <Col
              md={1}
              style={{
                width: '4.1666%',
                flex: '0 0 4.1666%',
                maxWidth: ' 4.1666%',
              }}
            ></Col>

            <Col md={{ span: 2 }}>
              <Text size='sm' weight={600}>
                Any Additional Comments:
              </Text>
            </Col>
            <Col
              md={1}
              style={{
                width: '3.8%',
                flex: '0 0 3.8%',
                maxWidth: ' 3.8%',
              }}
            ></Col>
            <Col md={{ span: 8 }}>
              <Text size='sm' weight={400}>
                {comments}
              </Text>
            </Col>
          </Row>
        </div>
      )}

      <Row style={{ marginTop: '1rem' }}>
        <Col
          md={1}
          style={{
            width: '2.0833%',
            flex: '0 0 2.0833%',
            maxWidth: ' 2.0833%',
          }}
        ></Col>
        <Col
          md={{ span: 3 }}
          style={{
            marginTop: '1rem',
          }}
        >
          <h5 className='sectionHeadings'>
            SCOPE{' '}
            {editScope() && (
              <span className='editIcon'>
                <Pencil
                  color='#e5b611'
                  onClick={() =>
                    navigate(routes.submission(params.rfsNumber).scopeSelect, {
                      state: {
                        routeFromRfsOverview: true,
                      },
                    })
                  }
                />
              </span>
            )}
          </h5>
        </Col>

        <Col
          md={{ span: 2, offset: 6 }}
          style={{
            marginTop: '1rem',
          }}
        >
          <Button
            color='yellow'
            style={{ background: 'black', color: 'white' }}
            onClick={() => setTfModal(true)}
          >
            Other Projects in scope
          </Button>
        </Col>
        <Row>
          <Accordion onChange={(event) => setAccordionState(event)}>
            {Object.keys(form?.selectedScope || {}).map((name) =>
              form?.selectedScope[name].map((item, index) => (
                <Accordion.Item
                  className='scope-label'
                  key={`key_${name}_${index}`}
                  value={`key_${name}_${index}`}
                >
                  <Accordion.Control className={scopeErrors.errors[item.id] ? 'error-label' : ''}>
                    {`${name.substring(0, 3).toUpperCase()} -  ${item[accessors[name]]}- ${
                      item[secondaryAccessor[name]]
                    }${tertiaryAccessor[name] ? `- ${item[tertiaryAccessor[name]]}` : ''}`}
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Grid>
                      {name === 'liquids' && (
                        <Grid.Col span={12}>
                          <LiquidTable
                            index={index}
                            scope={item}
                            scopeLiquids={getLiquids(item)}
                            isSubmissionReview
                            errors={scopeErrors.liquidErrors}
                          />
                        </Grid.Col>
                      )}
                      {name === 'materials' && (
                        <Grid.Col span={12}>
                          <MaterialTable
                            index={index}
                            material={item}
                            errors={scopeErrors.materialErrors}
                            isSubmissionReview={true}
                            materialAccordionIndex={
                              form?.selectedScope.liquids.length > 0
                                ? form?.selectedScope.liquids.length + index
                                : index
                            }
                            accordionState={accordionState}
                          />
                        </Grid.Col>
                      )}
                      {name === 'skus' && (
                        <Grid.Col span={12}>
                          {rfs_type.toLowerCase() === 'production site' && (
                            <ProductionSite
                              name={item[accessors[name]]}
                              scope={item}
                              errors={scopeErrors.productionSiteErrors}
                              isSubmissionReview
                            />
                          )}
                          {rfs_type === 'Open SKU to market' && (
                            <SkuTable
                              name={item[accessors[name]]}
                              scope={item}
                              errors={scopeErrors.productionSiteErrors}
                              isSubmissionReview
                            />
                          )}
                        </Grid.Col>
                      )}
                      {
                        //name !== 'materials' && (
                        <>
                          {/* <Grid.Col span={12}>
                              <PortfolioTable />
                            </Grid.Col>
                            <Grid.Col span={12}>
                              <PalletTypes />
                            </Grid.Col>

                            <Grid.Col span={12}>
                              <EanRequirementsTable />
                            </Grid.Col>
                            <Grid.Col span={6}>
                              <VolumeTable />
                            </Grid.Col>
                            <Grid.Col span={6}>
                              <PlantsTable />
                            </Grid.Col> */}
                        </>
                      }
                      <Grid.Col span={12}>
                        <Textarea
                          disabled={props.isSubmissionReview}
                          label='Additional Comments'
                          className='additional-comments'
                          value={form.scopeComments[item.id] || null}
                        />
                      </Grid.Col>
                    </Grid>
                  </Accordion.Panel>
                </Accordion.Item>
              )),
            )}
          </Accordion>
        </Row>
      </Row>
      <Row style={{ marginTop: '1rem' }}>
        <Col
          md={1}
          style={{
            width: '2.0833%',
            flex: '0 0 2.0833%',
            maxWidth: ' 2.0833%',
          }}
        ></Col>
        <Col
          md={{ span: 3 }}
          style={{
            marginTop: '1rem',
          }}
        >
          <h5 className='sectionHeadings'>
            ATTACHMENTS {}
            {editScope() && (
              <span className='editIcon'>
                <Pencil
                  color='#e5b611'
                  onClick={() =>
                    navigate(routes.submission(params.rfsNumber).attachment, {
                      state: {
                        routeFromRfsOverview: true,
                      },
                    })
                  }
                />
              </span>
            )}
          </h5>
        </Col>
      </Row>
      <Row>
        <Col
          md={1}
          style={{
            width: '4.1666%',
            flex: '0 0 4.1666%',
            maxWidth: ' 4.1666%',
          }}
        ></Col>
        <Col md={{ span: 5, offset: 0 }}>
          <Text size='sm' weight={600}>
            Additional Email Attachment:
          </Text>
          <Text size='sm' weight={400}>
            {attachmentLoading ? (
              <AppLoader />
            ) : (
              <FileList files={emailAttachment} type={'emailAttachment'} readOnly />
            )}
          </Text>
        </Col>

        <Col md={{ span: 5, offset: 0 }}>
          <Text size='sm' weight={600}>
            Fast Track Approval:
          </Text>
          <Text size='sm' weight={400}>
            {attachmentLoading ? (
              <AppLoader />
            ) : (
              <FileList files={fastTrackApproval} type={'fastTrackApproval'} readOnly />
            )}
          </Text>
        </Col>
      </Row>
      <Row>
        <Col
          md={1}
          style={{
            width: '4.1666%',
            flex: '0 0 4.1666%',
            maxWidth: ' 4.1666%',
          }}
        ></Col>
        {vis1 && (
          <Col md={{ span: 5, offset: 0 }}>
            <Text size='sm' weight={600}>
              Capex Email Approval:
            </Text>
            <Text size='sm' weight={400}>
              {attachmentLoading ? (
                <AppLoader />
              ) : (
                <FileList files={capexEmailApproval} type={'capexEmailApproval'} readOnly />
              )}
            </Text>
          </Col>
        )}
        {vis1 && (
          <Col md={{ span: 5, offset: 0 }}>
            <Text size='sm' weight={600}>
              Artwork Briefing:
            </Text>
            <Text size='sm' weight={400}>
              {attachmentLoading ? (
                <AppLoader />
              ) : (
                <FileList files={artworkBriefing} type={'artworkBriefing'} readOnly />
              )}
            </Text>
          </Col>
        )}
      </Row>
      <Row>
        <Col
          md={1}
          style={{
            width: '4.1666%',
            flex: '0 0 4.1666%',
            maxWidth: ' 4.1666%',
          }}
        ></Col>
        {rfs_type.toLowerCase() === 'production site' && (
          <Col md={{ span: 5, offset: 0 }}>
            <Text size='sm' weight={600}>
              Repacking:
            </Text>
            <Text size='sm' weight={400}>
              {attachmentLoading ? (
                <AppLoader />
              ) : (
                <FileList files={repacking} type={'repacking'} readOnly />
              )}
            </Text>
          </Col>
        )}
      </Row>
      <Row>
        <Col
          md={1}
          style={{
            width: '4.1666%',
            flex: '0 0 4.1666%',
            maxWidth: ' 4.1666%',
          }}
        ></Col>
        {rfs_type === 'Article Modification/Value Engineering' && (
          <Col md={{ span: 5, offset: 0 }}>
            <Text size='sm' weight={600}>
              Liquid Attachment:
            </Text>
            <Text size='sm' weight={400}>
              {attachmentLoading ? (
                <AppLoader />
              ) : (
                <FileList files={liquidAttachment} type={'liquidAttachment'} readOnly />
              )}
            </Text>
          </Col>
        )}
      </Row>
      <Row style={{ marginTop: '1rem' }}>
        <Col
          md={1}
          style={{
            width: '2.0833%',
            flex: '0 0 2.0833%',
            maxWidth: ' 2.0833%',
          }}
        ></Col>
        <Col
          md={{ span: 3 }}
          style={{
            marginTop: '1rem',
          }}
        >
          <h5 className='sectionHeadings'>PMO Section</h5>
        </Col>
      </Row>
      <Row style={{ marginBottom: '0.5rem' }}>
        <Col
          md={1}
          style={{
            width: '4.1666%',
            flex: '0 0 4.1666%',
            maxWidth: ' 4.1666%',
          }}
        ></Col>

        <Col md={{ span: 5, offset: 0 }}>
          <SimpleGrid cols={2}>
            <Text size='sm' weight={600}>
              Project Submission Date:
            </Text>
            <Text size='sm' weight={400}>
              {submission_date}
            </Text>
          </SimpleGrid>
        </Col>
      </Row>

      <Row>
        <Col
          md={1}
          style={{
            width: '4.1666%',
            flex: '0 0 4.1666%',
            maxWidth: ' 4.1666%',
          }}
        ></Col>
        {vis1 && (
          <Col md={{ span: 5, offset: 0 }}>
            <SimpleGrid cols={2}>
              <Text size='sm' weight={600}>
                Are Trials actually needed?{' '}
                <span style={{ color: 'red', fontWeight: '400' }}>
                  {rejectDraftModal || rejectDraft === false ? '' : '*'}
                </span>
              </Text>

              {editScope() ? (
                <Select
                  error={
                    rejectDraftModal || rejectDraft === false
                      ? false
                      : validate && !fetched.are_trials_actually_needed
                  }
                  clearable
                  name='are_trials_actually_needed'
                  onChange={(value) => handleTechSelect(value)}
                  disabled={disable}
                  value={fetched.are_trials_actually_needed}
                  placeholder='---'
                  data={['Yes', 'No']}
                />
              ) : (
                <Text size='sm'>{fetched.are_trials_actually_needed}</Text>
              )}
            </SimpleGrid>
          </Col>
        )}
      </Row>

      {rfs_type === 'Open SKU to market' && (
        <Row style={{ marginTop: '0.5rem' }}>
          <Col
            md={1}
            style={{
              width: '4.1666%',
              flex: '0 0 4.1666%',
              maxWidth: ' 4.1666%',
            }}
          ></Col>

          <Col md={{ span: 2 }}>
            <Text size='sm' weight={600}>
              Impacted Plants: <span style={{ color: 'red', fontWeight: '400' }}>*</span>
            </Text>
          </Col>
          <Col
            md={1}
            style={{
              width: '3.8%',
              flex: '0 0 3.8%',
              maxWidth: ' 3.8%',
            }}
          ></Col>

          <Col md={{ span: 8 }} style={{ marginBottom: '1rem', display: 'flex' }}>
            <ModalAccf
              type={'plants'}
              title={
                impactedPlants.length > 0
                  ? !editScope()
                    ? 'View Impacted Plants'
                    : 'Check and Change Impacted Plants'
                  : 'Indicate Impacted Plants'
              }
              selected={
                form.rfsInformation['impactedPlants'] ? form.rfsInformation['impactedPlants'] : []
              }
              handleSelect={handleAccfSelect}
              submitted={false}
              disabled={!editScope()}
            />
          </Col>
        </Row>
      )}

      <Row style={{ marginTop: '0.5rem' }}>
        <Col
          md={1}
          style={{
            width: '4.1666%',
            flex: '0 0 4.1666%',
            maxWidth: ' 4.1666%',
          }}
        ></Col>

        <Col md={{ span: 2 }}>
          <Text size='sm' weight={600}>
            PMO Comments: <span style={{ color: 'red', fontWeight: '400' }}>*</span>
          </Text>
        </Col>
        <Col
          md={1}
          style={{
            width: '3.8%',
            flex: '0 0 3.8%',
            maxWidth: ' 3.8%',
          }}
        ></Col>

        <Col md={{ span: 8 }} style={{ marginBottom: '1rem' }}>
          {editScope() ? (
            <>
              <Textarea
                error={pmo_comment_length === 0 || (validate && !fetched.pmo_comments)}
                placeholder='PMO Comments (Character limit 500)'
                disabled={disable}
                name='pmo_comments'
                value={fetched.pmo_comments}
                autosize
                minRows={4}
                onChange={(event) => handleTechChange(event)}
              />
              {fetched.pmo_comments?.length > 499 && (
                <div className='custom-mantine-Textarea-error'>
                  500 Character limit reached, exceeding character would be truncated on saving.
                </div>
              )}
            </>
          ) : (
            <Text size='sm'>{fetched.pmo_comments}</Text>
          )}
        </Col>
      </Row>

      {tfModal && <TechFeasibilityModal opened={tfModal} setOpened={setTfModal} />}

      {submitProjectModal && (
        <SubmitProjectModal opened={submitProjectModal} setOpened={setSubmitProjectModal} />
      )}
      {rejectDraftModal && (
        <RejectDraftModal opened={rejectDraftModal} setOpened={setRejectDraftModalModal} />
      )}

      {npsOpened && (userRole === roles.pmo || userRole === roles.superAdmin) && (
        <NpsComponent
          rfs_number={params.rfsNumber}
          opened={npsOpened}
          setOpened={setNpsOpened}
          callback={() => {
            window.location.reload();
          }}
          id={NpsIds().techFeasibility}
        />
      )}
    </ContentPaper>
  );
}
