import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { BomModal } from '../components/rfs/liquid-bom/Modal';
import { axios } from '../utils/axios';
import { useMsal } from '@azure/msal-react';
import { useFullscreen } from '@mantine/hooks';
import { AuthContext } from './AuthContext';
import { MaterialSchema } from '../components/tables/materialTable/validation-schema';
import { LiquidsSchema } from '../components/tables/liquidsTable/validations-schema';
import {
  ProductionBomSchema,
  ProductionSiteSchema,
} from '../components/tables/skutable/production-site/validation-schema';
import { rfsStatus, techFeasibilityPermissions } from '../constants';
import { attachmentChunks } from '../utils/helpers';

const EXTENSIONS = {
  pdf: 'data:application/pdf;base64,',
  jpeg: 'data:image/jpeg;base64,',
  jpg: 'data:image/jpg;base64,',
  png: 'data:image/png;base64,',
  xls: 'data:application/vnd.ms-excel;base64,',
  xlsx: 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,',
  msg: 'data:application/octet-stream;base64,',
  eml: 'data:application/octet-stream;base64,',
  csv: 'data:application/octet-stream;base64,',
  docx: 'data:application/msword;base64,',
  doc: 'data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,',
  ppt: 'data:application/vnd.ms-powerpoint;base64,',
  pptx: 'data:application/vnd.openxmlformats-officedocument.presentationml.presentation;base64,',
};

const initialFormState = {
  rfsInformation: {
    project_name: null,
    initiator_name: '',
    rfs_type: '',
    artwork_change: false,
    tech_change: false,
    confirmed: false,
    initiator_position: null,
    initiator_team: null,
    backupUsers: [],
    confidential: false,
    allowedUsers: [],
    technicalLead: [],
    first_shipment: null,
    sales_business_unit: [],
    prod_business_unit: [],
    fast_track: false,
    ft_approval: [],
    rfs_approval: [],
    capex_needed: false,
    capex_amount: null,
    gatekeeper: [],
    cost_center: null,
    project_purpose: null,
    project_details: null,
    comments: null,
    trials_needed: null,
    country_legal_spoc: null,
    status: null,
    submission_date: null,
  },
  scope: {
    skus: [],
    materials: [],
    liquids: [],
  },
  attachments: {
    artworkBriefing: [],
    fastTrackApproval: [],
    capexEmailApproval: [],
    emailAttachment: [],
    repacking: [],
    liquidAttachment: [],
    privacyPolicy: [],
  },
  rfsSubmission: {},
  selectedLiquids: {},
  selectedMaterials: {},
  selectedSkus: {},
  selectedAffectedSkus: [],
  scopeSelectedLiquids: [],
  scopeSelectedMaterials: [],
  scopeSelectedSkus: [],
  selectedScope: {
    materials: [],
    liquids: [],
    skus: [],
  },
  scopeComments: {},
  scopeIds: {},
  techFeasibility: {
    are_trials_actually_needed: null,
    pmo_comments: null,
  },
};

export const RfsContext = createContext();

export const RfsProvider = ({ children }) => {
  const { userName, userEmail, userRole } = useContext(AuthContext);
  const { toggle, fullscreen } = useFullscreen();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(initialFormState);
  const [liquids, setLiquids] = useState([]);
  const [skus, setSkus] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [costCenters, setCostCenters] = useState([]);
  const [costCentersObject, setCostCentersObject] = useState({});
  const [affectedSkus, setAffectedSkus] = useState([]);
  const [allMarkets, setAllMarkets] = useState([]);
  //modals
  const [bomModal, setBomModal] = useState({ state: false, type: 'liquid' });

  //boms
  const [modalType, setModalType] = useState([]);
  const [liquidBom, setLiquidBom] = useState([]);
  const [skuBom, setSkuBom] = useState([]);
  const [skuUom, setSkuUom] = useState([]);

  //production-site
  const [productionPlants, setProductionPlants] = useState([]);
  const [referencePlantsAndBoms, setReferencePlantsAndBoms] = useState({});
  const [repackingOnlineMaterials, setRepackingOnlineMaterials] = useState({});
  const [bomMaterials, setBomMaterials] = useState({});
  const [bomMaterialsLoading, setBomMaterialsLoading] = useState(false);

  //loadings
  const [liquidsLoading, setLiquidsLoading] = useState(false);
  const [skusLoading, setSkusLoading] = useState(false);
  const [materialsLoading, setMaterialsLoading] = useState(false);
  const [attachmentLoading, setAttachmentLoading] = useState(false);
  const [saveAsDraft, setSaveAsDraft] = useState(false);
  const [infoLoading, setInfoLoading] = useState(false);
  const [materialsCount, setMaterialsCount] = useState(null);
  const [liquidsCount, setLiquidsCount] = useState(null);

  //ssoLogin instance
  const { accounts } = useMsal();

  useEffect(() => {
    setForm((val) => {
      return {
        ...val,
        rfsInformation: { ...val.rfsInformation, initiator_name: userName },
      };
    });
  }, [accounts[0]]);

  const reset = () => {
    setForm((val) => {
      return {
        ...val,
        rfsInformation: {
          ...initialFormState.rfsInformation,
          initiator_name: userName,
        },
        scopeSelectedSkus: [],
      };
    });
  };
  const fetchMarkets = useCallback(() => {
    setLoading(true);
    axios
      .get(`openSku/sku/markets`)
      .then(
        (res) => {
          const response = res.data.data;
          setAllMarkets(response);
        },
        (err) => {
          console.log(err);
        },
      )
      .finally(() => setLoading(false));
  }, [setAllMarkets]);
  const dummy = [
    { material: '1', material_text: '1' },
    { material: '2', material_text: '2' },
    { material: '3', material_text: '3' },
  ];
  const fetchSkus = useCallback(() => {
    if (skus.length === 0) {
      setSkusLoading(true);
      axios
        .get('/sku')
        .then(
          (res) => {
            const response = res.data.data.skus.length === 0 ? dummy : res.data.data.skus;
            setSkus(response);
          },
          (error) => {
            console.log(error);
            setSkus(dummy);
          },
        )
        .finally(() => setSkusLoading(false));
    }
  }, [setSkus]);

  const fetchLiquids = useCallback(() => {
    if (liquids.length === 0) {
      setLiquidsLoading(true);
      axios
        .get('/liquids')
        .then(
          (res) => {
            const response = res.data.data.liquids;
            setLiquids(response);
            setLiquidsCount(res.data.data.total);
          },
          (error) => {
            console.log(error);
          },
        )
        .finally(() => setLiquidsLoading(false));
    }
  }, [setLiquids]);

  const fetchMaterials = useCallback(() => {
    if (materials.length === 0 && form.rfsInformation.rfs_type.length > 0) {
      const url =
        form.rfsInformation.rfs_type === 'Supplier Change'
          ? `/supplier-changes/scope-selection/materials`
          : `/materials/distinct`;

      setMaterialsLoading(true);
      axios
        .get(url)
        .then(
          (res) => {
            const response = res.data.data.materials;
            setMaterials(response);
            setMaterialsCount(res.data.data.total);
          },
          (error) => {
            console.log(error);
          },
        )
        .finally(() => setMaterialsLoading(false));
    }
  }, [setMaterials, form.rfsInformation.rfs_type]);

  const fetchCostCenters = useCallback(() => {
    if (costCenters.length === 0) {
      setCostCenters([]);
      axios.get('/cost-center').then(
        (res) => {
          const response = res.data.data.costCenters;
          setCostCenters(response);

          let costCenter = {};
          response.forEach((data) => {
            costCenter[data.value] = data.label;
          });
          setCostCentersObject(costCenter);
        },
        (error) => {
          console.log(error);
        },
      );
    }
  }, [setCostCenters]);

  const groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  const fetchLiquidBom = (id) => {
    setLoading(true);
    axios
      .get(`/liquids/bom/${id}`)
      .then(
        (res) => {
          let response = res.data.data.bom;

          response = response.map((bom) => {
            bom['plant_alternative_pair'] = `${bom.PlantPlant}-${bom.alternative_bom}`;

            return bom;
          });

          response = groupBy(response, 'plant_alternative_pair');
          setModalType(response);
          setLiquidBom(response);
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() => setLoading(false));
  };

  const fetchSkuBom = (id) => {
    setLoading(true);
    axios
      .get(`/sku/bom/${id}`)
      .then(
        (res) => {
          let response = res.data.data.skus;
          response = response.map((skus) => {
            skus['plant_alternative_pair'] = `${skus.PlantPlant}-${skus.alternative_bom}`;

            return skus;
          });

          response = groupBy(response, 'plant_alternative_pair');
          setModalType(response);
          setSkuBom(response);
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() => setLoading(false));
  };
  const fetchSkuUom = (id) => {
    setLoading(true);
    axios
      .get(`/sku/uom/${id}`)
      .then(
        (res) => {
          let response = res.data.data.skus;
          response = groupBy(response, 'Measure');
          setModalType(response);
          setSkuUom(response);
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() => setLoading(false));
  };

  const fetchAffectedSkus = useCallback(
    (material) => {
      setLoading(true);
      axios
        .get('/materials/affected-skus', {
          params: {
            material: material.material_code,
            plant: material.plant,
          },
        })
        .then(
          (res) => {
            let response = res.data.data.affectedSkus;
            setAffectedSkus(response);
          },
          (error) => {
            console.log(error);
          },
        )
        .finally(() => setLoading(false));
    },
    [setAffectedSkus],
  );

  const create = (data, callback) => {
    setLoading(true);
    const { project_name, rfs_type, artwork_change, tech_change } = data;
    axios
      .post('/rfs', {
        project_name,
        rfs_type,
        artwork_change,
        tech_change,
        initiator_name: userName,
        initiator_email: userEmail.toLowerCase(),
      })
      .then(
        (res) => {
          const results = res.data.data[0];
          let currentValues = { ...form };
          currentValues.rfsInformation.id = results.id;
          currentValues.rfsInformation.rfs_number = results.rfs_number;

          setForm(currentValues);
          callback(results.rfs_number);
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() => setLoading(false));
  };

  const update = (callback) => {
    if (callback) setLoading(true);
    else setSaveAsDraft(true);
    const { sales_business_unit, comments } = form.rfsInformation;

    const payload = {
      ...form.rfsInformation,
      sales_business_unit: sales_business_unit.length > 0 ? sales_business_unit.join(',') : '',
      comments: comments?.length > 0 ? comments : '',
    };

    axios
      .put('/rfs', payload, {
        headers: {
          rfsId: form.rfsInformation.id,
        },
      })
      .then(
        (res) => {
          if (callback) callback();
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() => {
        if (callback) setLoading(false);
        else setSaveAsDraft(false);
      });
  };

  const changeLog = (callback) => {
    if (callback) setLoading(true);
    else setSaveAsDraft(true);
    const {
      cost_center,
      project_purpose,
      project_details,
      comments,
      trials_needed,
      artwork_change,
      tech_change,
      country_legal_spoc,
    } = form.rfsInformation;

    const payload = {
      ...form.rfsInformation,
      comments: comments?.length > 0 ? comments : '',
    };

    axios
      .put('/rfs/changeLog', payload)
      .then(
        (res) => {
          if (callback) callback();
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() => {
        if (callback) setLoading(false);
        else setSaveAsDraft(false);
      });
  };

  const updateScopeComment = (data) => {
    return new Promise((resolve) => {
      axios
        .put('/rfs/scope/comment', data, {
          headers: {
            rfsId: form.rfsInformation.id,
          },
        })
        .then(
          (res) => {
            resolve();
          },
          (err) => {
            resolve();
          },
        );
    });
  };
  const filterSkusPayload = (data) => {
    let skus = [];
    data.forEach((val) => {
      if (val.active) {
        val.id = val.id && val.id.toString().includes('temp') ? null : val.id;
        skus.push(val);
      }
    });

    return skus;
  };
  const storeScope = (data, callback) => {
    if (callback) setLoading(true);
    else setSaveAsDraft(true);
    let payload = [];
    Object.keys(data).forEach((key) => {
      Object.keys(data[key]).forEach((element) => {
        let scope = {};
        if (form.scopeIds[element]) scope.id = form.scopeIds[element];
        let elementData = {};

        if (key === 'liquids') {
          const index = liquids.findIndex((val) => val.LiquidCode === element);
          if (index > -1) elementData = liquids[index];
        } else if (key === 'materials') {
          const index = materials.findIndex((val) => val.material_code === element);
          if (index > -1) elementData = materials[index];
        } else if (key === 'skus') {
          const index = skus.findIndex((val) => val.material === element);
          if (index > -1) elementData = skus[index];
        }

        scope = {
          ...scope,
          rfs_number: form.rfsInformation.rfs_number,
          selection_number:
            elementData[
              key === 'liquids' ? 'LiquidCode' : key === 'materials' ? 'material_code' : 'material'
            ],
          selection_name:
            elementData[
              key === 'liquids'
                ? 'LiquidDescription'
                : key === 'materials'
                ? 'material_description'
                : 'material_text'
            ],
          plant: key === 'liquids' ? elementData['plants'] : null,
          selection_type: key,
        };

        // if (key === 'materials') {
        //   const skus = (form.selectedAffectedSkus || []).filter(
        //     (sku) =>
        //       sku.identifier === element ||
        //       (sku.scope_id && sku.scope_id == scope.id)
        //   );

        //   scope.selectedAffectedSkus = [...skus];
        // }

        payload.push(scope);
      });
    });

    const url =
      form.rfsInformation.rfs_type === 'Supplier Change'
        ? `/supplier-changes/scope-selection/create/${form.rfsInformation.rfs_number}`
        : `/rfs/scope/create`;
    axios
      .post(url, payload, {
        headers: {
          rfsId: form.rfsInformation.id,
        },
      })
      .then(
        (res) => {
          callback();
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() => {
        if (callback) setLoading(false);
        else setSaveAsDraft(false);
      });
  };

  const scopeStepTwo = async (callback) => {
    if (callback) setLoading(true);
    else setSaveAsDraft(true);

    await storeLiquids(form.scopeSelectedLiquids);
    await storeMaterials(form.scopeSelectedMaterials);

    //await storeSkus(form.scopeSelectedSkus);
    await updateScopeComment(form.scopeComments);
    info(form.rfsInformation.rfs_number, false);

    if (callback) setLoading(false);
    else setSaveAsDraft(false);
    if (callback) callback();
  };

  const filterLiquidsPayload = (data) => {
    let liquids = [];
    data.forEach((val) => {
      if (val.active) {
        val.id = val.id.toString().includes('temp') ? null : val.id;
        liquids.push(val);
      }
    });

    return liquids;
  };

  const storeLiquids = (data) => {
    const payload = {
      liquids: filterLiquidsPayload(data),
      rfs_number: form.rfsInformation.rfs_number,
    };

    return new Promise((resolve, reject) => {
      axios
        .put('/rfs/scope/liquids', payload, {
          headers: {
            rfsId: form.rfsInformation.id,
          },
        })
        .then(
          (res) => {
            resolve(res);
          },
          (error) => {},
        );
    });
  };

  const filterMaterialPayload = (data) => {
    let materials = [];
    data.forEach((val) => {
      if (val.active) {
        val.id = val.id && val.id.toString().includes('temp') ? null : val.id;
        materials.push(val);
      }
    });

    return materials;
  };

  const storeMaterials = (data) => {
    const payload = {
      materials: filterMaterialPayload(data),
      rfs_number: form.rfsInformation.rfs_number,
    };

    return new Promise((resolve, reject) => {
      axios
        .put('/rfs/scope/materials', payload, {
          headers: {
            rfsId: form.rfsInformation.id,
          },
        })
        .then(
          (res) => {
            resolve(res);
          },
          (error) => {
            console.log(error);
          },
        );
    });
  };
  const storeSkus = (data) => {
    const payload = {
      materials: filterSkusPayload(data),
      rfs_number: form.rfsInformation.rfs_number,
    };

    return new Promise((resolve, reject) => {
      axios
        .put('/rfs/scope/skus', payload, {
          headers: {
            rfsId: form.rfsInformation.id,
          },
        })
        .then(
          (res) => {
            resolve(res);
          },
          (error) => {
            console.log(error);
          },
        );
    });
  };

  const addAttachments = async (callback = null) => {
    try {
      if (callback) {
        setLoading(true);
      } else {
        setSaveAsDraft(true);
      }

      const attachments = form.attachments;

      for (const key of Object.keys(attachments)) {
        const currentAttachments = attachments[key];
        await createFormData(key, currentAttachments);
      }

      info(form.rfsInformation.rfs_number);

      if (callback) {
        setLoading(false);
        callback();
      } else {
        setSaveAsDraft(false);
      }
    } catch (error) {
      console.error('Error while adding attachments:', error);
    }
  };

  const createFormData = async (type, attachments) => {
    const formData = new FormData();
    formData.append('rfs_number', form.rfsInformation.rfs_number);
    formData.append('type', type);

    for (const attachment of attachments) {
      if (attachment.id) {
        formData.append('attachmentId[]', attachment.id);
      }
    }

    const chunks = attachmentChunks(attachments.filter((file) => file.size));

    if (Object.keys(chunks).length > 0) {
      for (const chunk of Object.values(chunks)) {
        for (const file of chunk) {
          formData.append('attachments', file);
        }
        await uploadAttachment(formData);
      }
    } else {
      await uploadAttachment(formData);
    }

    return {};
  };

  const uploadAttachment = async (payload) => {
    try {
      setAttachmentLoading(true);
      const res = await axios.post('/rfs/attachment', payload, {
        headers: {
          rfsId: form.rfsInformation.id,
        },
      });
      return res;
    } catch (error) {
      console.error('Error while uploading attachment:', error);
      return {};
    } finally {
      setAttachmentLoading(false);
    }
  };

  const downloadFile = (
    filename = 'Fast Track for RFS Tool.pdf',
    container = 'stm-download-files',
  ) => {
    axios
      .get(`/rfs/download/${filename}/${container}`, {
        headers: {
          rfsId: form.rfsInformation.id,
        },
      })
      .then(
        (res) => {
          const base64String = res.data.data.downloaded;
          const ext = filename.split('.');
          saveAs(`${EXTENSIONS[ext[ext.length - 1].toLowerCase()]}${base64String}`, filename);
        },
        (error) => {
          console.log(error);
        },
      );
  };

  const downloadLocalFile = (path, fileName) => {
    saveAs(path, fileName);
  };

  const info = (id, loading = true, callback = false) => {
    setInfoLoading(loading);
    axios
      .get(`/rfs/${id}`)
      .then(
        (res) => {
          const response = res.data.data;
          const selectedLiquids = {};
          const selectedMaterials = {};
          const selectedSkus = {};
          let scopeSelectedSkus = {};
          const attachments = {
            artworkBriefing: [],
            fastTrackApproval: [],
            capexEmailApproval: [],
            emailAttachment: [],
            repacking: [],
            liquidAttachment: [],
            privacyPolicy: [],
          };
          const scopeIds = {};
          const comments = {};

          response.scope.forEach((data) => {
            comments[data.id] = data.comment;

            if (data.selection_type === 'liquids') {
              selectedLiquids[`${data.selection_number}`] = true;
              scopeIds[`${data.selection_number}`] = data.id;
            }
            if (data.selection_type === 'materials') {
              selectedMaterials[`${data.selection_number}`] = true;
              scopeIds[`${data.selection_number}`] = data.id;
            }
            if (data.selection_type === 'skus') {
              selectedSkus[`${data.selection_number}`] = true;
              scopeIds[`${data.selection_number}`] = data.id;
            }
          });

          response.attachments.forEach((attachment) => {
            if (attachment.type === 'artworkBriefing') {
              attachments['artworkBriefing'].push(attachment);
            }
            if (attachment.type === 'fastTrackApproval') {
              attachments['fastTrackApproval'].push(attachment);
            }
            if (attachment.type === 'capexEmailApproval') {
              attachments['capexEmailApproval'].push(attachment);
            }
            if (attachment.type === 'emailAttachment') {
              attachments['emailAttachment'].push(attachment);
            }
            if (attachment.type === 'repacking') {
              attachments['repacking'].push(attachment);
            }

            if (attachment.type === 'privacyPolicy') {
              attachments['privacyPolicy'].push(attachment);
            }

            if (attachment.type === 'liquidAttachment')
              attachments['liquidAttachment'].push(attachment);
          });

          response.affectedSkus = response.affectedSkus.map((sku) => {
            const names = {
              skus: 'SKUs',
              sku_description: 'SKUDescription',
              alt_bom: 'AlternativeBOM',
              plant: 'Plant',
              component_qty: 'ComponentQty',
              uom: 'UoM',
              markets: 'Markets',
              ean_numbers: 'EANNumbers',
            };
            Object.keys(sku).forEach((data) => {
              if (names[data]) sku[names[data]] = sku[data];
            });

            return sku;
          });

          response.materials = response.materials.map((material) => {
            material.active = true;
            material.selectedAffectedSkus = response.affectedSkus.filter(
              (val) => parseInt(val.material_id) === parseInt(material.id),
            );
            return material;
          });

          response.liquids = response.liquids.map((liquid) => {
            if (liquid.liquid_type === 'Existing Same Liquid') {
              liquid.liquid_type = 'New Liquid';
            }

            return liquid;
          });

          if (response.overview.rfs_type.toLowerCase() === 'production site') {
            response.skus.forEach((item) => {
              scopeSelectedSkus[item.id] = item;
            });
            fetchPlantsAndAltBoms(scopeSelectedSkus, response.scope);
          } else {
            scopeSelectedSkus = response.skus;
          }

          const impactedMarkets = [];
          (response?.impactedMarkets || []).map((item) => {
            impactedMarkets.push(`${item.market} - ${item.market_region}`);
          });

          const impactedPlants = [];
          (response?.impactedPlants || []).map((item) => {
            impactedPlants.push(`${item.plant} - ${item.plant_text}`);
          });
          const d2pFeedback = [];
          let zitecFeedback = [];

          if (response.overview.rfs_type.toLowerCase() === 'supplier change') {
            const permissions = techFeasibilityPermissions['Supplier Change'];
            response.d2p_feedback.forEach((item) => {
              d2pFeedback.push({
                artwork_actually_needed: item.updated_by
                  ? item.artwork_actually_needed
                    ? 1
                    : 0
                  : '',
                artwork_adaption_actually_needed: item.updated_by
                  ? item.artwork_adaption_actually_needed
                    ? 1
                    : 0
                  : '',
                color_target_setting_done: item.updated_by
                  ? item.color_target_setting_done
                    ? 1
                    : 0
                  : '',
                material_id: item.material_id,
                rfs_number: item.rfs_number,
                scope_id: item.scope_id,
                updated_by: item.updated_by,
                zitec_all_supplier_updated: item.zitec_all_supplier_updated,
                material_code: item.int_rfs_sc_material.material_code,
                material_description: item.int_rfs_sc_material.material_description,
                comment: item.comment,
                id: item.id,
                disabled: !permissions.d2p_approval.includes(userRole)
                  ? false
                  : !item.zitec_all_supplier_updated ||
                    item.updated_by ||
                    ![
                      rfsStatus.tech_feasible,
                      rfsStatus.tech_feasible2,
                      rfsStatus.supplier_pmo1_active,
                      rfsStatus.supplier_pmo2_active,
                      rfsStatus.supplier_zitec_active,
                    ].includes(response.status[0]?.status)
                  ? true
                  : false,
              });
            });

            response.zitec_feedback.forEach((item) => {
              zitecFeedback.push({
                id: item.id,
                material_id: item.material_id,
                rfs_number: item.rfs_number,
                scope_id: item.scope_id,
                supplier_id: item.supplier_id,
                technically_feasible: item.updated_by ? (item.technically_feasible ? 1 : 0) : '',
                trials_needed: item.updated_by ? (item.trials_needed ? 1 : 0) : '',
                updated_by: item.updated_by,
                material_code: item.int_rfs_sc_material.material_code,
                material_description: item.int_rfs_sc_material.material_description,
                plant: item.int_rfs_sc_material.plant,
                to_be_supplier: `${item.int_rfs_sc_supplier.supplier_number} ${item.int_rfs_sc_supplier.supplier_name}`,
                current_suppliers: item.int_rfs_sc_material.current_suppliers,
                comment: item.comment,
                disabled: !permissions.zitec_approval.includes(userRole)
                  ? false
                  : item.updated_by ||
                    ![
                      rfsStatus.tech_feasible,
                      rfsStatus.tech_feasible2,
                      rfsStatus.supplier_pmo1_active,
                      rfsStatus.supplier_pmo2_active,
                      rfsStatus.supplier_zitec_active,
                    ].includes(response.status[0]?.status)
                  ? true
                  : false,
              });
            });
          }

          setForm((o) => {
            return {
              ...o,
              d2pFeedback: d2pFeedback,
              zitecFeedback: zitecFeedback,
              rfsInformation: {
                ...o.rfsInformation,
                ...response.overview,
                id: response.overview.id,
                sales_business_unit: response.overview.sales_business_unit
                  ? response.overview.sales_business_unit.split(',')
                  : [],
                artwork_change: response?.overview?.artwork_change,
                submission_date: moment(response?.overview?.submission_date, 'YYYY-MM-DD').format(
                  'YYYY-MM-DD',
                ),
                tech_change: response?.overview?.tech_change,
                gatekeeper: response?.gatekeeper?.length > 0 ? response.gatekeeper : [],
                backupUsers: response?.backup.length > 0 ? response.backup : [],
                country_legal_spoc: response?.overview?.country_legal_spoc,
                allowedUsers: response?.confidential.length > 0 ? response.confidential : [],
                technicalLead: response?.technicalLead.length > 0 ? response.technicalLead : [],
                status: response.status[0]?.status,
                initiator_email: response.overview.initiator_email,
                impactedMarkets: impactedMarkets,
                impactedPlants: impactedPlants,
                d2p_lead:
                  response.d2p_lead && response.d2p_lead.length > 0 ? response.d2p_lead : [],
                agree_to_pay: response.agree_to_pay
                  ? parseInt(response.agree_to_pay) === 0
                    ? false
                    : true
                  : false,
                color_settings_target_needed: response.color_settings_target_needed || null,
                fast_track:
                  response.overview.fast_track &&
                  ['1', true, 1, 'true'].includes(response.overview.fast_track)
                    ? true
                    : false,
              },
              selectedLiquids: {
                ...selectedLiquids,
              },
              selectedMaterials: {
                ...selectedMaterials,
              },
              selectedSkus: {
                ...selectedSkus,
              },
              attachments: { ...attachments },
              selectedAffectedSkus: response.affectedSkus,
              scopeSelectedLiquids: response.liquids,
              scopeSelectedMaterials: response.materials,
              scopeSelectedSkus: scopeSelectedSkus,
              selectedScope: {
                liquids: response.scope.filter((val) => val.selection_type === 'liquids'),
                materials: response.scope.filter((val) => val.selection_type === 'materials'),
                skus: response.scope.filter((val) => val.selection_type === 'skus'),
              },
              scopeComments: comments,
              scopeIds: { ...scopeIds },
            };
          });

          if (callback) callback();
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() => setInfoLoading(false));
  };

  const updateRfsStatus = (rfs_number, info, callback) => {
    const payload = typeof info === 'string' ? { rfsStatus: info } : info;

    axios.put(`/rfs/moveToDraft/${rfs_number}`, payload).then(
      (res) => {
        if (callback) callback();
      },
      (error) => {
        console.log(error);
      },
    );
  };

  const moveToSubmit = (rfs_number) => {
    axios.put(`/submit/${rfs_number}`).then(
      (res) => {},
      (error) => {
        console.log(error);
      },
    );
  };

  const validateScope = () => {
    const errors = {};
    const materialErrors = {};
    const liquidErrors = {};
    const productionSiteErrors = {};

    const scopeLiquids = form.selectedScope.liquids;
    const scopeMaterials = form.selectedScope.materials;
    const scopeSkus = form.selectedScope.skus;

    scopeLiquids.forEach((element) => {
      let liquids = [];

      const activeLiquids = form.scopeSelectedLiquids.filter(
        (val) => val.active && val.scope_id.toString() === element.id.toString(),
      );

      if (activeLiquids.length === 0) errors[element.id] = true;

      activeLiquids.forEach((val) => {
        if (val.active) {
          const liquid_number = val.liquid_number ? val.liquid_number : val.liquid_type;

          if (val.scope_id.toString() === element.id.toString()) {
            LiquidsSchema.validate(
              {
                ...val,
                liquid_number,
                bom_changes: !val.bom_changes
                  ? []
                  : typeof val.bom_changes === 'object'
                  ? val.bom_changes
                  : val.bom_changes.split(','),
                new_quantity_check: val.new_quantity ? parseInt(val.new_quantity) : null,
              },
              { abortEarly: false },
            )
              .then(() => {
                liquids.push(val);
              })
              .catch((err) => {
                const fieldErrors = err.errors;
                if (fieldErrors) {
                  fieldErrors.forEach((error) => {
                    liquidErrors[val.id] = liquidErrors[val.id]
                      ? {
                          ...liquidErrors[val.id],
                          [error]: true,
                        }
                      : { [error]: true };
                  });
                  errors[element.id] = true;
                }
              });
          }
        }
      });
    });

    scopeMaterials.forEach((element) => {
      let materials = [];

      const activeMaterials = form.scopeSelectedMaterials.filter(
        (val) => val.active && val.scope_id.toString() === element.id.toString(),
      );

      if (activeMaterials.length === 0) errors[element.id] = true;

      form.scopeSelectedMaterials.forEach((val) => {
        if (val.scope_id.toString() === element.id.toString() && val.active) {
          const material_number = val.material_number ? val.material_number : val.material_type;

          MaterialSchema.validate(
            {
              ...val,
              material_number,
              technical_pack_change:
                typeof val.technical_pack_change === 'object'
                  ? val.technical_pack_change
                  : val.technical_pack_change.split(','),
              technical_quality_change:
                typeof val.technical_quality_change === 'object'
                  ? val.technical_quality_change
                  : val.technical_quality_change.split(','),
              selected_material_number: material_number,
              selectedAffectedSkus: val.selectedAffectedSkus ? val.selectedAffectedSkus : [],
            },
            { abortEarly: false },
          )
            .then(() => {
              materials.push(val);
            })
            .catch((err) => {
              const fieldErrors = err.errors;
              if (fieldErrors) {
                fieldErrors.forEach((error) => {
                  materialErrors[val.id] = materialErrors[val.id]
                    ? {
                        ...materialErrors[val.id],
                        [error]: true,
                      }
                    : { [error]: true };
                });
                errors[element.id] = true;
              }
            });
        }
      });
    });
    if (form.rfsInformation.rfs_type.toLowerCase() === 'open sku to market') {
      const scopeSelectedSkus = form.scopeSelectedSkus;
      scopeSkus.forEach((element) => {
        const activeSkus = scopeSelectedSkus.filter(
          (val) => val.scope_id.toString() === element.id.toString(),
        );

        const activeSkusIndex = activeSkus.findIndex((val) => val.active && val.new_market);

        const newMarket = activeSkus.filter((val) => val.new_market);

        if (activeSkusIndex === -1 || activeSkus.length === 0 || newMarket.length === 0)
          errors[element.id] = true;
      });
    }

    if (form.rfsInformation.rfs_type === 'Production Site') {
      const scopeSelectedSkus = Object.values({ ...form.scopeSelectedSkus });
      form.selectedScope.skus.forEach((scope) => {
        const selectedSkus = scopeSelectedSkus.find((i) => i.scope_id === scope.id && i.active);
        if (!selectedSkus) {
          errors[scope.id] = true;
          productionSiteErrors[scope.id] = true;
        }

        scopeSelectedSkus.forEach((item) => {
          if (item.scope_id.toString() === scope.id.toString() && item.active) {
            let boms = [];

            const activeBoms = item.boms.filter((b) => b.active);
            if (activeBoms.length === 0) {
              errors[scope.id] = true;
              productionSiteErrors[scope.id] = true;
              productionSiteErrors[item.id] = {};
              productionSiteErrors[item.id]['bomErrors'] = true;
            }
            if (item.boms) {
              boms = [...item.boms];
              boms = boms.map((item) => {
                if (typeof item.technical_pack_change === 'string') {
                  item.technical_pack_change = item.technical_pack_change.split(',');
                }

                if (typeof item.technical_quality_change === 'string') {
                  item.technical_quality_change = item.technical_quality_change.split(',');
                }
                return item;
              });
            }

            const validate = { ...item, boms };
            ProductionSiteSchema.validate(validate, { abortEarly: false })
              .then(() => {})
              .catch((err) => {
                const fieldErrors = err.errors;
                if (fieldErrors) {
                  fieldErrors.forEach((error) => {
                    productionSiteErrors[item.id] = productionSiteErrors[item.id]
                      ? {
                          ...productionSiteErrors[item.id],
                          [error]: true,
                        }
                      : { [error]: true };
                  });
                  errors[scope.id] = true;
                }
              });

            if (item.bom_action === 'Modify BOM') {
              boms.forEach((bom, index) => {
                ProductionBomSchema.validate(
                  { ...bom, selected_material_number: bom.material_list },
                  { abortEarly: false },
                )
                  .then(() => {})
                  .catch((bomError) => {
                    const bomErrors = bomError.errors;
                    bomErrors.forEach((error) => {
                      productionSiteErrors[item.id] = productionSiteErrors[item.id]
                        ? productionSiteErrors[item.id]
                        : {};
                      productionSiteErrors[item.id]['boms'] = productionSiteErrors[item.id]['boms']
                        ? productionSiteErrors[item.id]['boms']
                        : [];

                      productionSiteErrors[item.id]['boms'][index] = productionSiteErrors[item.id][
                        'boms'
                      ][index]
                        ? {
                            ...productionSiteErrors[item.id]['boms'][index],
                            [error]: true,
                          }
                        : { [error]: true };
                    });

                    errors[scope.id] = true;
                    productionSiteErrors[item.id]['bomErrors'] = true;
                  });
              });
            }
          }
        });
      });
    }

    if (
      (scopeLiquids.length === 0 &&
        scopeMaterials.length === 0 &&
        !['production site', 'open sku to market'].includes(
          form.rfsInformation.rfs_type.toLowerCase(),
        )) ||
      (Object.values({ ...form.scopeSelectedSkus }).length === 0 &&
        form.rfsInformation.rfs_type.toLowerCase() === 'production site') ||
      (form.rfsInformation.rfs_type === 'Open SKU to market' && scopeSkus.length === 0)
    ) {
      errors['all'] = 'Please select liquid or material';
    } else {
      if (errors['all']) delete errors['all'];
    }

    return {
      errors: errors ? errors : {},
      liquidErrors,
      materialErrors,
      productionSiteErrors,
    };
  };

  const deleteRfs = (callback) => {
    axios
      .delete(`/rfs/${form.rfsInformation.rfs_number}`, {
        headers: {
          rfsId: form.rfsInformation.id,
        },
      })
      .then(
        (res) => {
          if (callback) {
            setTimeout(() => {
              callback();
            }, [500]);
          }
        },
        (error) => {},
      );
  };

  const updateSkus = (callback) => {
    const payload = [...form.scopeSelectedSkus];
    if (callback) {
      setLoading(true);
    } else {
      setSaveAsDraft(true);
    }
    axios
      .put(`openSku/sku/update/${form.rfsInformation.rfs_number}`, payload)
      .then(
        (res) => {
          if (callback) {
            callback();
          }
          info(form.rfsInformation.rfs_number);
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() => {
        if (callback) {
          setLoading(false);
        } else {
          setSaveAsDraft(false);
        }
      });
  };

  const fetchProductionPlants = useCallback(() => {
    axios.get('/production-site/plants').then(
      (res) => {
        setProductionPlants(res.data.data.plants.map((plant) => plant.plant));
      },
      (err) => {},
    );
  }, []);

  const fetchReferencePlants = (skuCode) => {
    return axios.get(`/production-site/plants/${skuCode}`);
  };

  const fetchMaterialCreation = (rfs_number, trials) => {
    return axios.get(`/production-site/materials/${rfs_number}`);
  };

  const fetchMaterialCreationVeam = (rfs_number) => {
    return axios.get(`/veam/material_creation/${rfs_number}`);
  };

  const fetchMaterialCreationDetails = (sku_id) => {
    return axios.get(`/production-site/material_creation/${sku_id}`);
  };

  const fetchTrials = (rfs_number) => {
    return axios.get(`production-site/trials/fetch/${rfs_number}`);
  };

  const fetchLogs = (id) => {
    return axios.get(`/production-site/logs/${id}`);
  };

  const fetchAffectedSKUs = (id) => {
    return axios.get(`/veam/getAffectedSKUs/${id}`);
  };

  const fetchSupplierName = (rfs_number, trials) => {
    return axios.get(`production-site/supplier_name/fetch/${rfs_number}`);
  };

  const StoreManualClosure = (payload, id) => {
    return axios.post(`production-site/manual_closure/`, payload);
  };

  const StoreManualClosureVEAM = (payload, id) => {
    return axios.post(`veam/manual_closure/`, payload);
  };

  const submitTrials = (payload, rfs_number) => {
    return axios.post(`production-site/trials/add/${rfs_number}`, payload);
  };

  const submitTrialsVEAM = (payload, rfs_number) => {
    return axios.post(`veam/trials/add/${rfs_number}`, payload);
  };

  const submitTrialsComments = (payload, rfs_number) => {
    return axios.post(`production-site/trials/comments/${rfs_number}`, payload);
  };

  const submitTrialsCommentsVEAM = (payload, rfs_number) => {
    return axios.post(`veam/trials/comments/${rfs_number}`, payload);
  };

  const fetchTrialInfo = (rfs_number) => {
    return axios.get(`production-site/trials/fetchInfo/${rfs_number}`);
  };

  const submitAllTrials = (payload, rfs_number) => {
    return axios.post(`production-site/trials/submit/${rfs_number}`, payload);
  };

  const submitAllTrialsVEAM = (payload, rfs_number) => {
    return axios.post(`veam/trials/submit/${rfs_number}`, payload);
  };

  const fetchImpFeasTable = (id) => {
    return axios.get(`openSku/sku/volumes/${id}`);
  };

  const submitMaterialCreation = (payload, callback) => {
    return axios.post(`/production-site/material_creation/submit/`, payload).then(
      (res) => {
        if (callback) callback(form.rfsInformation.rfs_number);
      },
      (err) => {
        console.log(err);
      },
    );
  };

  const submitMaterialCreationVeam = (payload) => {
    //console.log(payload);
    return axios.post(`/veam/material_creation/submit/`, payload).then(
      (res) => {
        //console.log(res);
      },
      (err) => {
        //console.log(err);
      },
    );
  };

  const storeSupplier = (payload, callback, callback1) => {
    return axios.post(`/production-site/supplier_name/update`, payload).then(
      (res) => {
        if (callback) callback(form.rfsInformation.rfs_number);
        if (callback1) callback1();
      },
      (err) => {
        console.log(err);
      },
    );
  };

  const storeSupplierDetails = (payload, callback) => {
    return axios.post(`/production-site/supplier_name/add`, payload).then(
      (res) => {
        if (callback) callback(form.rfsInformation.rfs_number);
      },
      (err) => {
        console.log(err);
      },
    );
  };

  const storeSupplierVeam = (payload, callback, callback1) => {
    return axios.post(`/rfs/supplier_name/update`, payload).then(
      (res) => {
        if (callback) callback(form.rfsInformation.rfs_number, false);
        if (callback1) callback1();
      },
      (err) => {
        console.log(err);
      },
    );
  };

  const storeSupplierDetailsVeam = (payload, callback) => {
    return axios.post(`/rfs/supplier_name/add`, payload).then(
      (res) => {
        if (callback) callback(form.rfsInformation.rfs_number, false);
      },
      (err) => {
        console.log(err);
      },
    );
  };

  const getEANApproval = (rfs_number) => {
    return axios.get(`/production-site/ean_approval/fetch/${rfs_number}`);
  };

  const fetchProductionPlantExtension = (rfs_number, trials) => {
    return axios.get(`/production-site/plant_production/${rfs_number}`);
  };

  const storeProductionPlantExtension = (sku_id, value, callback) => {
    let payload = {
      input: value,
    };
    return axios.post(`/production-site/alt_update/${sku_id}`, payload).then(
      (res) => {
        if (callback) callback(form.rfsInformation.rfs_number);
      },
      (err) => {
        console.log(err);
      },
    );
  };

  const fetchAltBoms = (skuCode, plant) => {
    return axios.get(`/production-site/alt_boms/${skuCode}/${plant}`);
  };

  const fetchMaterialContract = (rfs_number, trials) => {
    return axios.get(`/production-site/material_contracts/fetch/${rfs_number}`, {
      params: { trials: trials },
    });
  };

  const fetchBoms = (skuCode, plant, altBom) => {
    return axios.get(`/production-site/boms/${skuCode}/${plant}/${altBom}`);
  };

  const fetchRepackingMaterials = (type, plant) => {
    if (type === 'Repacking') {
      return axios.get('/production-site/repacking');
    } else {
      return axios.get(`/production-site/online/${plant}`);
    }
  };

  const storeProductionSiteSkus = (callback) => {
    if (callback) setLoading(true);
    else setSaveAsDraft(true);
    let payload = Object.values(form.scopeSelectedSkus).map((item) =>
      typeof item.id === 'string' && item.id.indexOf('_') > -1 ? { ...item, id: null } : item,
    );

    axios
      .post(`/production-site/sku/reference/${form.rfsInformation.rfs_number}`, payload)
      .then(
        (res) => {
          if (callback) {
            callback();
          } else {
            info(form.rfsInformation.rfs_number);
          }
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() => {
        if (callback) setLoading(false);
        else setSaveAsDraft(false);
      });
  };

  const fetchBomMaterials = (payload) => {
    setBomMaterialsLoading(true);
    axios
      .post('/production-site/bom/materials', payload)
      .then(
        (res) => {
          let materials = res.data.data.materials.map((materialInfo) => {
            const { material, material_text } = materialInfo;
            return {
              label: `${material}-${material_text}`,
              value: `${material}-${material_text}`,
            };
          });
          const currentValues = { ...bomMaterials };
          currentValues[payload[0]] = materials;
          setBomMaterials(currentValues);
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() => setBomMaterialsLoading(false));
  };

  const fetchPlantsAndAltBoms = (data, scope) => {
    const payload = [];
    scope.forEach((s) => {
      const plants = [];
      Object.values(data).forEach((plant) => {
        if (plant.scope_id.toString() === s.id.toString()) {
          plants.push({
            plant: plant.selected_plant,
            referencePlant: plant.reference_plant,
            production: plant.production,
          });
        }
      });

      payload.push({
        selection_number: s.selection_number,
        plants,
      });
    });

    axios.post('/production-site/plants_and_altboms', payload).then(
      (res) => {
        setReferencePlantsAndBoms(res.data.data);
        setRepackingOnlineMaterials(res.data.materials);
      },
      (error) => {
        console.log(error);
      },
    );
  };

  const fetchStatusVeam = (rfs_number) => {
    return axios.get(`/veam/get_veam_status/${rfs_number}`);
  };

  const fetchChangeOversVeam = (rfs_number) => {
    return axios.get(`/veam/get_veam_changeover/${rfs_number}`);
  };

  const fetchChangeOversRemovalVeam = (rfs_number) => {
    return axios.get(`/veam/get_veam_changeover_removal/${rfs_number}`);
  };

  const fetchMaterialChange = (rfs_number) => {
    return axios.get(`production-site/mat_status_change/fetch/${rfs_number}`);
  };

  const fetchSkuPlanning = (rfs_number) => {
    return axios.get(`production-site/sku_planning/fetch/${rfs_number}`);
  };

  const fetchStatus99 = (rfs_number) => {
    return axios.get(`production-site/status_99/fetch/${rfs_number}`);
  };

  const fetchStandardPricing = (rfs_number) => {
    return axios.get(`/production-site/standard_pricing/fetch/${rfs_number}`);
  };

  const fetchPackMats = (rfs_number) => {
    return axios.get(`production-site/pack_mat/fetch/${rfs_number}`);
  };

  const fetchMaterialPricing = (rfs_number) => {
    return axios.get(`production-site/material_pricing/fetch/${rfs_number}`);
  };

  const fetchTrialsVEAM = (rfs_number) => {
    return axios.get(`/veam/get_veam_trials/${rfs_number}`);
  };

  const fetchBlockingsVeam = (rfs_number) => {
    return axios.get(`/veam/get_veam_blockings/${rfs_number}`);
  };

  const fetchStatusReversalVeam = (rfs_number) => {
    return axios.get(`/veam/get_veam_status_reversal/${rfs_number}`);
  };

  const fetchTrialInfoVEAM = (rfs_number) => {
    return axios.get(`/veam/trials/fetchInfo/${rfs_number}`);
  };

  const fetchCurrencyAndPrice = () => {
    return axios.get(`production-site/material_pricing/currency_and_price`);
  };

  const submitMaterialPricing = (rfs_number, price, unit, currency, material_ids) => {
    const payload = {
      pricing_type: '',
      price: price,
      unit: unit,
      currency: currency,
      material_ids: material_ids,
    };

    return axios.post(`production-site/material_pricing/update/${rfs_number}`, payload);
  };

  const fetchShipment = (rfs_number) => {
    return axios.get(`production-site/filling_shipping/fetch/${rfs_number}`);
  };

  const fetchPsBomData = (skus, rfs) => {
    const payload = {
      sku_ids: skus.flat(),
      rfs_number: rfs,
    };
    return axios.post(`production-site/scope_unlock/scope_selection/fetch/`, payload);
  };

  const fetchTabs = (rfs_number) => {
    return axios.get(`production-site/tabs/${rfs_number}`);
  };

  const fetchPackMatsProdDataIMPL = (rfs_number, trials) => {
    return axios.get(`veam/pack_mats/fetch/${rfs_number}&${trials}`);
  };

  const fetchFiilingAndShipment = (rfs_number) => {
    return axios.get(`veam/filling_shipping/fetch/${rfs_number}`);
  };

  const createVEAMTrialLog = (payload) => {
    return axios.post(`veam/create_veam_trial_log/`, payload);
  };

  const fetchDelistingMarkets = (sku_id) => {
    return axios.get(`delisting/markets/${sku_id}`);
  };

  const fetchDelisting = (rfs_number) => {
    return axios.get(`delisting/${rfs_number}`);
  };

  const createDelistingSku = (data) => {
    return axios.post(`delisting/`, data);
  };

  const updateDelistingSku = (data, rfs_number) => {
    return axios.put(`delisting/${rfs_number}`, data);
  };

  const fetchRawdata = () => {
    return axios.get('reports/rawData');
  };

  const fetchStepsTeamsdata = (data) => {
    return axios.get(`reports/stepsTeams`, {
      params: {
        gatekeeper: data.gatekeeper,
        year: data.year,
        fastrack: data.fastrack,
      },
    });
  };

  const fetchGatekeeprs = () => {
    return axios.get('reports/gatekeepers');
  };

  const fetchDecomplexity = () => {
    return axios.get('reports/decomplexity');
  };

  const fetchIndividualStepdata = (data) => {
    return axios.get(`reports/indivisualstepsTeams`, {
      params: {
        gatekeeper: data.gatekeeper,
        year: data.year,
        fastrack: data.fastrack,
        stepname: data.stepname,
        month: data.month,
      },
    });
  };

  const updateDecomplexity = (payload) => {
    return axios.put('reports/decomplexity/update', payload);
  };

  const storeAccfComment = (data) => {
    return axios.post('/accf/comment', data);
  };

  const sendAccf = (data) => {
    return axios.post('/accf/sendAccf', data);
  };

  const getAccfComment = (data) => {
    return axios.get(
      `/accf/get_comment/?rfs_number=${data.rfs_number}&sla_weeks_id=${data.sla_weeks_id}`,
    );
  };

  return (
    <RfsContext.Provider
      value={{
        info,
        loading,
        setLoading,
        fetchLiquids,
        fetchLiquidBom,
        fetchSkuBom,
        fetchSkuUom,
        liquids,
        fetchSkus,
        skus,
        reset,
        materials,
        fetchMaterials,
        liquidBom,
        skuBom,
        setLiquidBom,
        bomModal,
        setBomModal,
        fetchMarkets,
        allMarkets,
        form,
        setForm,
        fetchCostCenters,
        costCenters,
        setCostCenters,
        attachmentLoading,
        setAttachmentLoading,
        liquidsLoading,
        materialsLoading,
        skusLoading,
        fetchAffectedSkus,
        affectedSkus,
        setAffectedSkus,
        update,
        create,
        storeLiquids,
        storeMaterials,
        storeSkus,
        storeScope,
        toggle,
        fullscreen,
        saveAsDraft,
        setSaveAsDraft,
        addAttachments,
        downloadFile,
        infoLoading,
        scopeStepTwo,
        setInfoLoading,
        changeLog,
        updateRfsStatus,
        moveToSubmit,
        validateScope,
        costCentersObject,
        downloadLocalFile,
        deleteRfs,
        updateSkus,
        fetchProductionPlants,
        fetchReferencePlants,
        fetchMaterialCreation,
        fetchMaterialCreationDetails,
        fetchMaterialCreationVeam,
        submitMaterialCreation,
        submitMaterialCreationVeam,
        fetchProductionPlantExtension,
        storeProductionPlantExtension,
        StoreManualClosure,
        fetchBoms,
        fetchLogs,
        productionPlants,
        storeProductionSiteSkus,
        fetchAltBoms,
        referencePlantsAndBoms,
        bomMaterials,
        fetchBomMaterials,
        bomMaterialsLoading,
        materialsCount,
        liquidsCount,
        fetchSupplierName,
        storeSupplier,
        storeSupplierDetails,
        getEANApproval,
        fetchRepackingMaterials,
        fetchMaterialContract,
        fetchStatusVeam,
        fetchChangeOversVeam,
        fetchChangeOversRemovalVeam,
        fetchAffectedSKUs,
        repackingOnlineMaterials,
        fetchTrials,
        submitTrials,
        submitTrialsComments,
        submitAllTrials,
        submitAllTrialsVEAM,
        fetchTrialInfo,
        fetchMaterialChange,
        fetchSkuPlanning,
        fetchImpFeasTable,
        fetchStandardPricing,
        fetchPackMats,
        fetchMaterialPricing,
        submitMaterialPricing,
        fetchCurrencyAndPrice,
        fetchStatus99,
        fetchShipment,
        fetchTrialsVEAM,
        fetchTrialInfoVEAM,
        submitTrialsVEAM,
        submitTrialsCommentsVEAM,
        StoreManualClosureVEAM,
        storeSupplierVeam,
        storeSupplierDetailsVeam,
        fetchBlockingsVeam,
        fetchStatusReversalVeam,
        fetchPsBomData,
        setSkuBom,
        fetchPackMatsProdDataIMPL,
        fetchFiilingAndShipment,
        fetchTabs,
        createVEAMTrialLog,
        storeAccfComment,
        fetchDecomplexity,
        updateDecomplexity,
        fetchRawdata,
        sendAccf,
        getAccfComment,
        fetchStepsTeamsdata,
        fetchGatekeeprs,
        fetchIndividualStepdata,
        uploadAttachment,
      }}
    >
      {children}

      {bomModal && <BomModal bomModal={bomModal} setBomModal={setBomModal} data={modalType} />}
    </RfsContext.Provider>
  );
};
