import React from 'react';
import { Modal, Button, Grid, Group, Text } from '@mantine/core';
import { InfoCircle } from 'tabler-icons-react';
import { useNavigate, useParams } from 'react-router-dom';

import { routes } from '../../routes';

export const SetFirstShipment = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const params = useParams();

  return (
    <>
      <Modal
        closeOnClickOutside={false}
        closeOnEscape={false}
        className='Invalid-modal'
        opened={open}
        onClose={() => setOpen(false)}
        title='Warning'
      >
        <Grid>
          <Grid.Col span={12}>
            <Group noWrap className='invalid-modal-group'>
              <InfoCircle color='red' size='5rem' />
              <Text size='sm'>You need to fill First Shipment date.</Text>
            </Group>
          </Grid.Col>

          <Grid.Col span={6} offset={6}>
            <Button
              onClick={() => {
                navigate(routes.submission(params.rfsNumber).info);
                setOpen(false);
              }}
              variant='filled'
              color='yellow'
              fullWidth
              style={{background:'linear-gradient(90deg,#e3af32 0%,#f4e00f 100%)',color: 'black' }}
            >
              Set First Shipment
            </Button>
          </Grid.Col>
        </Grid>
      </Modal>
    </>
  );
};
