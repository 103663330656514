import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Select, Tooltip } from '@mantine/core';
import {
  Artwork,
  ArtworkAdaptation,
  ArtworkManagement,
  Changeover,
  ChangeoverDate,
  SupplierChange,
  Comment,
} from './helper';
import { Suppliers } from './modal';
import { SupplierContext } from '../../../contexts/SupplierContext';
import moment from 'moment';
import MantineTable from '../../../common/table/mantine';
import { materialchangeOverOptions } from '../../../constants';
import { RfsContext } from '../../../contexts/RfsContext';

const changeoverOptions = [
  {
    value: materialchangeOverOptions.hardChange,
    label: materialchangeOverOptions.hardChange,
  },
  {
    value: materialchangeOverOptions.softChange,
    label: materialchangeOverOptions.softChange,
  },
  {
    value: materialchangeOverOptions.mixChange,
    label: materialchangeOverOptions.mixChange,
  },
  {
    value: materialchangeOverOptions.notSoonerThan,
    label: materialchangeOverOptions.notSoonerThan,
  },
  {
    value: materialchangeOverOptions.notLaterThan,
    label: materialchangeOverOptions.notLaterThan,
  },
];

export const SupplierTable = ({
  isSubmissionReview = false,
  confirm,
  errors = {},
  vendors = [],
}) => {
  const { supplierForm, setSupplierForm, setActivePlants, activePlants, plantsLoading } =
    useContext(SupplierContext);
  const { form } = useContext(RfsContext);
  const [status, setStatus] = useState({ open: false, id: null });
  const [suppliers, setSuppliers] = useState([]);
  const formRef = useRef(null);
  formRef.current = supplierForm;

  useEffect(() => {
    if (vendors.length > 0) {
      setSelectedSuppliers();
    }
  }, [vendors]);

  const setSelectedSuppliers = () => {
    const currentValues = [...suppliers];

    vendors.forEach((item) => {
      currentValues.push({
        ...item,
        selected: false,
      });
    });

    setSuppliers(currentValues);
  };

  const resetTableData = () => {
    const currentValues = [...formRef.current];
    currentValues.forEach((val, key) => {
      currentValues[key]['artwork_required'] = null;
      currentValues[key]['artwork_change'] = null;
      currentValues[key]['artwork_adaption'] = null;
      currentValues[key]['changeover_date'] = null;
      currentValues[key]['supplier_change_type'] = null;
      currentValues[key]['changeover_type'] = null;
      currentValues[key]['leading_material'] = null;
      currentValues[key]['to_be_suppliers'] = '';
      currentValues[key]['comment'] = '';
    });
    setSupplierForm(currentValues);
  };

  const handleDefaultSet = (event, type, selectedRows, allSelected) => {
    const filteredIds = selectedRows.rowsById;
    let value =
      type === 'active'
        ? event.target.checked
        : type === 'changeover_date'
        ? moment(event).isValid()
          ? moment(event).format('YYYY-MM-DD')
          : null
        : event;

    const currentValues = [...formRef.current];
    currentValues.forEach((val, key) => {
      if (!filteredIds[val.scope_id]) return currentValues[key];

      currentValues[key][type] = value;

      if (currentValues[key]['artwork_required'] === 'No') {
        currentValues[key]['artwork_change'] = null;
        currentValues[key]['artwork_adaption'] = null;
      }

      if ((type === 'artwork_adaption' && value == 'No') || !value)
        currentValues[key]['artwork_change'] = null;

      if (
        currentValues[key]['changeover_type'] === materialchangeOverOptions.softChange ||
        currentValues[key]['changeover_type'] === materialchangeOverOptions.mixChange
      ) {
        currentValues[key]['changeover_date'] = null;
      }
    });
    setSupplierForm(currentValues);
  };

  const handleChange = (event, type, row, selectedRows) => {
    let value =
      type === 'active'
        ? event.target.checked
        : event && event.target
        ? (event?.target?.value || '').trim().length === 0
          ? event.target.value.trim()
          : event.target.value
        : type === 'changeover_date'
        ? moment(event).isValid()
          ? moment(event).format('YYYY-MM-DD')
          : null
        : event;

    const currentValues = [...formRef.current];

    const index = currentValues.findIndex((item) => item.scope_id === row.original.scope_id);

    if (type === 'changeover_type' && value === materialchangeOverOptions.mixChange) {
      handleDefaultSet(event, type, selectedRows);
    } else if (
      type === 'changeover_type' &&
      value !== materialchangeOverOptions.mixChange &&
      currentValues[index]['changeover_type'] === materialchangeOverOptions.mixChange
    ) {
      handleDefaultSet(null, type, selectedRows);
      handleDefaultSet(null, 'mix_changeover', selectedRows);
      currentValues[index][type] = value;
    }

    currentValues[index][type] = value;

    if ((type === 'artwork_adaption' && value === 'No') || (type === 'artwork_adaption' && !value))
      currentValues[index]['artwork_change'] = null;

    if (
      (type === 'artwork_required' && value === 'No') ||
      (type === 'artwork_required' && !value)
    ) {
      currentValues[index]['artwork_change'] = null;
      currentValues[index]['artwork_adaption'] = null;
    }

    setSupplierForm(currentValues);
  };

  const handleSupplierSelect = (data, scope_id) => {
    const currentValues = [...formRef.current];

    const index = currentValues.findIndex((val) => val.scope_id === scope_id);

    currentValues[index]['to_be_supplier'] = data;
    setStatus({ open: false, id: null });
    setSupplierForm(currentValues);
  };

  const mixChangeOverEligibility = () => {
    return Object.keys(activePlants).length > 1;
  };

  const getMixChangeOverOptions = () => {
    let materials = [];
    supplierForm.forEach((item) => {
      materials.push(item.material_code);
    });

    materials = [...new Set(materials)];

    return materials;
  };

  const handleMixOverChange = (event, row, type, selectedRows, allSelected) => {
    handleDefaultSet(event, type, selectedRows, allSelected);
  };

  const handleRowSelection = (data) => {
    let currentValues = { ...activePlants };
    currentValues = { ...data };

    setActivePlants(currentValues);
  };

  const getMinChangeOverDate = () => {
    const { first_shipment } = { ...form.rfsInformation };

    return moment(first_shipment, 'YYYY-MM-DD').isValid()
      ? moment(first_shipment, 'YYYY-MM-DD').toDate()
      : moment().add('days', 1).toDate();
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'material_code',
        header: 'Material Code',
        Header: ({ table }) => (
          <Tooltip label='Material Code'>
            <div title=''>Material Code</div>
          </Tooltip>
        ),
        enableEditing: false,
      },
      {
        accessorKey: 'material_description',
        header: 'Material Description',
        Header: ({ table }) => (
          <Tooltip label='Material Description'>
            <div title=''>Material Description</div>
          </Tooltip>
        ),
        enableEditing: false,
      },
      {
        header: 'Plant',
        accessorKey: 'plant',
        Header: ({ table }) => (
          <Tooltip label='Plant'>
            <div title=''>Plant</div>
          </Tooltip>
        ),
        enableEditing: false,
      },
      {
        Header: ({ table }) => (
          <div style={{ minWidth: '350px' }} title=''>
            <Tooltip label='Printed Materials always require artwork'>
              <span className='pointer'> Is Artwork Required?</span>
            </Tooltip>
          </div>
        ),
        enableEditing: true,
        editVariant: 'select',
        mantineEditSelectProps: {
          data: ['Yes', 'No'],
        },
        header: 'Is Artwork Required?',
        accessorKey: 'artwork_required',
        Cell: ({ row }) => {
          return (
            <Artwork
              onChange={(event) => handleChange(event, 'artwork_required', row)}
              value={row.original.artwork_required}
              disabled={isSubmissionReview}
              error={errors[`${row.original.scope_id}_${row.original.plant}`]?.artwork_required}
            />
          );
        },
      },
      {
        header: 'Is Artwork Adaptation Required?',
        enableEditing: true,
        editVariant: 'select',
        mantineEditSelectProps: {
          data: ['Yes', 'No'],
        },
        Header: ({ table }) => (
          <div style={{ minWidth: '350px' }} title=''>
            <Tooltip label='Existing artwork needs to be adapted. Ex: Add a new legal change'>
              <span className='pointer'>Is Artwork Adaptation Required?</span>
            </Tooltip>
          </div>
        ),
        accessorKey: 'artwork_adaption',
        Cell: ({ row }) => (
          <div className='custom-multi-select'>
            <ArtworkAdaptation
              name='artwork_adaption'
              value={row.original.artwork_adaption}
              onChange={(event) => handleChange(event, 'artwork_adaption', row)}
              disabled={isSubmissionReview || row.original.artwork_required === 'No'}
              error={errors[`${row.original.scope_id}_${row.original.plant}`]?.artwork_adaption}
            />
          </div>
        ),
      },
      {
        header: 'Artwork Management',
        enableEditing: true,
        editVariant: 'select',
        mantineEditSelectProps: {
          data: [
            'Text change only',
            'Design or technical specs change, with new colors',
            'Design or technical specs change, with existing colors',
            'Others',
          ],
        },
        Header: ({ table }) => {
          return (
            <div title=''>
              <Tooltip label='Artwork Management'>
                <span className='pointer'>Artwork Management</span>
              </Tooltip>
            </div>
          );
        },
        accessorKey: 'artwork_change',
        Cell: ({ row }) => (
          <div className='custom-multi-select'>
            <ArtworkManagement
              name='artwork_change'
              value={row.original.artwork_change}
              onChange={(event) => handleChange(event, 'artwork_change', row)}
              disabled={
                isSubmissionReview ||
                row.original.artwork_required === 'No' ||
                row.original.artwork_adaption === 'No'
              }
              error={errors[`${row.original.scope_id}_${row.original.plant}`]?.artwork_change}
            />
          </div>
        ),
      },
      {
        header: 'Type of Supplier Change',
        enableEditing: true,
        editVariant: 'select',
        mantineEditSelectProps: {
          data: ['Supplier Switch', 'Dual Sourcing'],
        },
        Header: ({ table }) => (
          <div style={{ minWidth: '300px' }} title=''>
            <Tooltip label='Type of Supplier Change'>
              <span className='pointer'>Type of Supplier Change</span>
            </Tooltip>
          </div>
        ),
        accessorKey: 'supplier_change_type',
        Cell: ({ row }) => (
          <SupplierChange
            name='supplier_change_type'
            value={row.original.supplier_change_type}
            onChange={(event) => handleChange(event, 'supplier_change_type', row)}
            disabled={isSubmissionReview}
          />
        ),
      },
      {
        header: 'Type of Changeover',
        enableEditing: true,
        editVariant: 'select',
        mantineEditSelectProps: {
          data: changeoverOptions.filter((val) => {
            if (!mixChangeOverEligibility() && val.label === materialchangeOverOptions.mixChange) {
              return false;
            }
            return val;
          }),
        },
        Header: ({ table }) => (
          <div style={{ marginBottom: '2px' }} title=''>
            <Tooltip label='Type of Changeover'>
              <span className='pointer'>Type of Changeover</span>
            </Tooltip>
          </div>
        ),
        accessorKey: 'changeover_type',
        Cell: ({ row, table }) => (
          <Changeover
            name='changeover_type'
            value={row.original.changeover_type}
            onChange={(event) =>
              handleChange(event, 'changeover_type', row, table.getFilteredSelectedRowModel())
            }
            disabled={isSubmissionReview}
            error={errors[`${row.original.scope_id}_${row.original.plant}`]?.changeover_type}
            data={changeoverOptions.filter((val) => {
              if (
                !mixChangeOverEligibility() &&
                val.label === materialchangeOverOptions.mixChange
              ) {
                return false;
              }
              return val;
            })}
          />
        ),
      },

      {
        header: 'Leading material',
        Header: () => (
          <div title=''>
            <Tooltip label='Leading material'>
              <span className='pointer'> Leading material</span>
            </Tooltip>
          </div>
        ),
        accessorKey: 'leading_material',
        Cell: ({ row, table }) =>
          row.original.changeover_type === materialchangeOverOptions.mixChange &&
          mixChangeOverEligibility() ? (
            <Select
              size='sm'
              radius='md'
              value={row.original.mix_changeover}
              onChange={(event) =>
                handleMixOverChange(
                  event,
                  row,
                  'mix_changeover',
                  table.getFilteredSelectedRowModel(),
                  table.getIsAllRowsSelected(),
                )
              }
              placeholder='Select'
              searchable
              disabled={isSubmissionReview}
              data={getMixChangeOverOptions() || []}
            />
          ) : (
            ''
          ),
      },
      {
        header: 'Changeover Date',
        enableEditing: true,
        editVariant: 'datepicker',
        mantineEditSelectProps: {
          data: { minDate: getMinChangeOverDate(), initialMonth: getMinChangeOverDate() },
        },
        Header: ({ table }) => (
          <div title=''>
            <Tooltip label='Changeover Date'>
              <span className='pointer'>Changeover Date</span>
            </Tooltip>
          </div>
        ),
        accessorKey: 'changeover_date',
        Cell: ({ row }) => (
          <ChangeoverDate
            name='changeover_date'
            value={row.original.changeover_date}
            onChange={(event) => handleChange(event, 'changeover_date', row)}
            disabled={
              isSubmissionReview ||
              (row.original.changeover_type &&
                row.original.changeover_type === materialchangeOverOptions.softChange) ||
              row.original.changeover_type === materialchangeOverOptions.mixChange
            }
            error={errors[`${row.original.scope_id}_${row.original.plant}`]?.changeover_date}
            minDate={getMinChangeOverDate()}
            initialMonth={getMinChangeOverDate()}
          />
        ),
      },
      {
        header: 'Current Suppliers',
        Header: ({ table }) => (
          <Tooltip label='Current Suppliers'>
            <div title=''>Current Suppliers</div>
          </Tooltip>
        ),
        accessorKey: 'current_suppliers',
      },
      {
        header: 'To be Suppliers',
        Header: ({ table }) => (
          <Tooltip label='To be Suppliers'>
            <div title=''>To be Suppliers</div>
          </Tooltip>
        ),
        id: 'to_be_supplier',
        Cell: ({ row }) => (
          <Button
            onClick={() => setStatus({ open: true, id: row.original.scope_id })}
            variant='filled'
            color='yellow'
            style={{
              background: 'black',
              color: errors[`${row.original.scope_id}_${row.original.plant}`]?.to_be_supplier
                ? 'red'
                : 'white',
            }}
            fullWidth
            size='sm'
            radius='sm'
            className='add-supplier-button'
          >
            {!isSubmissionReview ? 'ADD TO BE SUPPLIERS' : 'VIEW ADDED SUPPLIERS'}
          </Button>
        ),
      },
      {
        header: 'Comment',
        enableEditing: true,
        editVariant: 'text',
        Header: ({ table }) => (
          <div title=''>
            <Tooltip label='Comment'>
              <span className='pointer'>Comment</span>
            </Tooltip>
          </div>
        ),
        accessorKey: 'comment',
        Cell: ({ row }) => {
          return (
            <Comment
              disabled={isSubmissionReview}
              name='comment'
              value={row.original.comment}
              onChange={(event) => handleChange(event, 'comment', row)}
              error={
                errors[`${row.original.scope_id}_${row.original.plant}`]?.comment
                  ? errors[`${row.original.scope_id}_${row.original.plant}`]?.comment
                  : false
              }
            />
          );
        },
      },
    ],
    [errors, activePlants],
  );

  return (
    <>
      <MantineTable
        columns={columns}
        initialData={supplierForm}
        unique={'scope_id'}
        handleRowSelection={handleRowSelection}
        activeRows={activePlants}
        enableRowSelection={!isSubmissionReview}
        loading={plantsLoading}
        resetTableData={resetTableData}
        editAll={!isSubmissionReview}
        applyToAll={handleDefaultSet}
        showSelectedToggle={!isSubmissionReview}
        showResetAll={!isSubmissionReview}
        hideSelectColumn={isSubmissionReview}
        alwaysShowEditAll={!isSubmissionReview}
      />
      {status.open && (
        <Suppliers
          data={suppliers}
          status={status}
          setStatus={setStatus}
          onChange={handleSupplierSelect}
          isSubmissionReview={isSubmissionReview}
        />
      )}
    </>
  );
};
