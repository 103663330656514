import React, { useContext } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { tabStyles } from '../../../utils/helpers';
import { Button, Tabs } from '@mantine/core';
import { PMO } from './pmo';
import { Zitec } from './zitec';
import { D2P } from './d2p';
import { PmoFinal } from './pmoFinal';
import ContentPaper from '../../../components/layouts/rfs/Content';
import ProjectName from '../../../components/projectName/projectName';
import { Col, Row } from 'react-bootstrap';
import { RfsContext } from '../../../contexts/RfsContext';
import { AuthContext } from '../../../contexts/AuthContext';
import { axios } from '../../../utils/axios';
import { rfsStatus, techFeasibilityPermissions } from '../../../constants';
import moment from 'moment';
import { AppLoader } from '../../../components/common/loader';
import { SupplierContext } from '../../../contexts/SupplierContext';

const SupplierTechFeasibility = (props) => {
  const { rfsNumber } = useParams();
  const [activeTab, setActiveTab] = useState('pmo');
  const [tab, setTab] = useState('pmo');

  const { form, updateRfsStatus, info } = useContext(RfsContext);
  const { userRole, userEmail } = useContext(AuthContext);
  const { plantsLoading } = useContext(SupplierContext);
  const { status, fast_track } = form.rfsInformation;
  const [firstShipmentPmo, setFirstShipmentPmo] = useState(
    moment(new Date()).add(11, 'week').toDate(),
  );
  const [fastTrackPmo, setFastTrackPmo] = useState(fast_track ? 'Yes' : 'No');

  const [techFeasibility, setTechFeasibility] = useState({});
  const [approveLoading, setApproveLoading] = useState(false);
  const [validate, setValidate] = useState(false);
  const [submitProjectModal, setSubmitProjectModal] = useState(false);
  const [rejectDraftModal, setRejectDraftModalModal] = useState(false);
  const [rejectDraft, setRejectDraft] = useState(false);
  const [npsOpened, setNpsOpened] = useState(false);
  const [tfModal, setTfModal] = useState(false);
  const pmo_comment_length = techFeasibility.pmo_comments?.trim().length;
  const permissions = techFeasibilityPermissions['Supplier Change'];

  const { fastTrackApproval } = form.attachments;

  const isDisabled = () => {
    return (
      pmo_comment_length === 0 ||
      !techFeasibility.pmo_comments ||
      (Boolean(fast_track) && fastTrackApproval.length === 0)
    );
  };

  const afterEditTechFeasibility = () => {
    info(rfsNumber, false);
    setTimeout(() => {
      setNpsOpened(true);
      setApproveLoading(false);
    }, [1000]);
  };

  const handleTechChange = (event) => {
    const currentValues = { ...techFeasibility };
    currentValues[event.target?.name] = event.target?.value;

    if (event.target.name === 'pmo_comments' && event.target.value.length > 500)
      currentValues[event.target?.name] = event.target?.value.substring(0, 500);

    setTechFeasibility(currentValues);
  };

  const createImplementationSteps = (payload, newStatus, callback) => {
    axios.post(`/tech-feasibility/submit/${rfsNumber}`, payload).then(
      (res) => {
        updateRfsStatus(rfsNumber, newStatus);
        setTimeout(() => {
          callback();
        }, [1000]);
      },
      (err) => {},
    );
  };

  const updateStatus = (payload, callback, newStatus = rfsStatus.supplier_d2p_active) => {
    setApproveLoading(true);
    axios.post(`/tech-feasibility/submit/${rfsNumber}`, payload).then(
      (res) => {
        if (newStatus === rfsStatus.implementation) {
          //create implementation steps if tech feasibility is completed
          createImplementationSteps(payload, newStatus, callback);
        } else {
          updateRfsStatus(rfsNumber, newStatus);
          setTimeout(() => {
            callback();
          }, [1000]);
        }
      },
      (err) => {
        setApproveLoading(false);
      },
    );
  };

  const handleNext = (type) => {
    if (activeTab === 'pmo-final' && [rfsStatus.supplier_pmo2_active].includes(status)) {
      if (type === 'Approved') {
        updateStatus(
          {
            pmo_comments: techFeasibility.pmo_comments,
            tf_status: type,
            rfs_number: rfsNumber,
            user: userEmail,
            fast_track: fastTrackPmo === 'Yes' ? 1 : 0,
            first_shipment: moment(firstShipmentPmo).format('YYYY-MM-DD'),
          },
          () => {
            afterEditTechFeasibility();
          },
          rfsStatus.implementation,
        );
      } else if (type === 'Draft') {
        updateRfsStatus(
          rfsNumber,
          { rfsStatus: 'draft', pmo_comments: techFeasibility.pmo_comments },
          () => {
            window.location.reload();
          },
        );
      } else {
        updateRfsStatus(
          rfsNumber,
          { rfsStatus: 'rejected', pmo_comments: techFeasibility.pmo_comments },
          () => {
            window.location.reload();
          },
        );
      }
    } else {
      setValidate(true);

      if (isDisabled() && type === 'Approved') {
        setSubmitProjectModal(true);
        setRejectDraft(true);
      } else if (
        ((typeof techFeasibility.pmo_comments === 'undefined' ||
          techFeasibility.pmo_comments.length === 0) &&
          type === 'Draft') ||
        ((typeof techFeasibility.pmo_comments === 'undefined' ||
          techFeasibility.pmo_comments.length === 0) &&
          type === 'Rejected')
      ) {
        setRejectDraftModalModal(true);
      } else {
        const payload = {
          pmo_comments: techFeasibility.pmo_comments,
          tf_status: type,
          rfs_number: rfsNumber,
          user: userEmail,
        };

        if (type === 'Approved') {
          updateStatus(payload, () => {
            afterEditTechFeasibility();
          });
        } else if (type === 'Draft') {
          updateRfsStatus(
            rfsNumber,
            { rfsStatus: 'draft', pmo_comments: techFeasibility.pmo_comments },
            () => {
              window.location.reload();
            },
          );
        } else {
          updateRfsStatus(
            rfsNumber,
            { rfsStatus: 'rejected', pmo_comments: techFeasibility.pmo_comments },
            () => {
              window.location.reload();
            },
          );
        }
      }
    }
  };

  const Footer = () => (
    <Row className='d-inline-flex justify-content-md-end' md={4}>
      <Col md='auto'>
        <Button
          onClick={() => handleNext('Rejected')}
          className='rejectpr'
          color='red'
          radius='md'
          size='md'
          fullWidth
          style={{
            color: 'white',
            width: '200px',
          }}
          disabled={plantsLoading}
        >
          Reject Project
        </Button>
      </Col>
      <Col md='auto'>
        <Button
          onClick={() => handleNext('Draft')}
          className='draft'
          color='yellow'
          radius='md'
          size='md'
          fullWidth
          style={{
            color: 'black',
            width: '200px',
          }}
          disabled={plantsLoading}
        >
          Move to Draft
        </Button>
      </Col>
      <Col md='auto'>
        <Button
          onClick={() => handleNext('Approved')}
          className='approvepr'
          color='green'
          radius='md'
          size='md'
          fullWidth
          style={{
            color: 'white',
            width: '200px',
          }}
          disabled={approveLoading || plantsLoading}
        >
          {approveLoading ? <AppLoader /> : 'Approve Project'}
        </Button>
      </Col>
    </Row>
  );

  const isReadOnly = () => {
    return ![
      rfsStatus.supplier_pmo1_active,
      rfsStatus.supplier_zitec_active,
      rfsStatus.supplier_pmo2_active,
      rfsStatus.tech_feasible,
    ].includes(status);
  };

  return (
    <ContentPaper
      page={'ProductDevelopment'}
      footer={
        !permissions.pmo_approval.includes(userRole) ? null : (activeTab === 'pmo' &&
            [rfsStatus.supplier_pmo1_active].includes(status)) ||
          (activeTab === 'pmo-final' && [rfsStatus.supplier_pmo2_active].includes(status)) ? (
          <Footer />
        ) : null
      }
    >
      <ProjectName initialItem={0} />
      <br />
      <Tabs
        variant='unstyled'
        styles={tabStyles}
        className='prod-dev-tabs'
        color='#e5b611'
        onTabChange={(value) => {
          setTab(value);
          setActiveTab(value);
        }}
        value={tab}
        keepMounted={false}
      >
        <Tabs.List>
          <Tabs.Tab
            className='topTabs'
            value='pmo'
            disabled={
              ![
                rfsStatus.supplier_pmo1_active,
                rfsStatus.supplier_zitec_active,
                rfsStatus.supplier_pmo2_active,
              ].includes(status) && !isReadOnly()
            }
          >
            <b>PMO Approval</b>
          </Tabs.Tab>
          <Tabs.Tab
            className='topTabs'
            value='zitec'
            disabled={
              ![rfsStatus.supplier_zitec_active, rfsStatus.supplier_pmo2_active].includes(status) &&
              !isReadOnly()
            }
          >
            <b>ZITEC Approval</b>
          </Tabs.Tab>
          <Tabs.Tab
            className='topTabs'
            value='d2p'
            disabled={
              ![rfsStatus.supplier_d2p_active, rfsStatus.supplier_pmo2_active].includes(status) &&
              !isReadOnly()
            }
          >
            <b>D2P Approval</b>
          </Tabs.Tab>
          <Tabs.Tab
            className='topTabs'
            value='pmo-final'
            disabled={![rfsStatus.supplier_pmo2_active].includes(status) && !isReadOnly()}
          >
            <b>PMO Final Approval</b>
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value='pmo'>
          <PMO
            activeTab={activeTab}
            tfModal={tfModal}
            setTfModal={setTfModal}
            npsOpened={npsOpened}
            setNpsOpened={setNpsOpened}
            techFeasibility={techFeasibility}
            setTechFeasibility={setTechFeasibility}
            validate={validate}
            submitProjectModal={submitProjectModal}
            setSubmitProjectModal={setSubmitProjectModal}
            rejectDraftModal={rejectDraftModal}
            setRejectDraftModalModal={setRejectDraftModalModal}
            pmo_comment_length={pmo_comment_length}
            handleTechChange={handleTechChange}
            readOnly={!permissions.pmo_approval.includes(userRole)}
            {...props}
          />
        </Tabs.Panel>

        <Tabs.Panel value='zitec'>
          <Zitec activeTab={activeTab} {...props} />
        </Tabs.Panel>

        <Tabs.Panel value='d2p'>
          <D2P activeTab={activeTab} {...props} />
        </Tabs.Panel>

        <Tabs.Panel value='pmo-final'>
          <PmoFinal
            activeTab={activeTab}
            tfModal={tfModal}
            setTfModal={setTfModal}
            npsOpened={npsOpened}
            setNpsOpened={setNpsOpened}
            techFeasibility={techFeasibility}
            setTechFeasibility={setTechFeasibility}
            validate={validate}
            submitProjectModal={submitProjectModal}
            setSubmitProjectModal={setSubmitProjectModal}
            rejectDraftModal={rejectDraftModal}
            setRejectDraftModalModal={setRejectDraftModalModal}
            pmo_comment_length={pmo_comment_length}
            handleTechChange={handleTechChange}
            firstShipmentPmo={firstShipmentPmo}
            setFirstShipmentPmo={setFirstShipmentPmo}
            fastTrackPmo={fastTrackPmo}
            setFastTrackPmo={setFastTrackPmo}
            readOnly={!permissions.pmo_final_approval.includes(userRole)}
            {...props}
          />
        </Tabs.Panel>
      </Tabs>
    </ContentPaper>
  );
};

export default SupplierTechFeasibility;
