import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Checkbox,
  Select,
  Textarea,
  TextInput,
  MultiSelect,
  Button,
  CloseButton,
} from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { ProjectOverviewContext } from '../../../contexts/projectOverviewContext';
import { showAlert } from '../../../utils/alerts';
import { AuthContext } from '../../../contexts/AuthContext';
import { AppLoader } from '../../common/loader';
import {
  artworkUpdate,
  changeoverCancellation,
  existingMaterialTrialOptions,
  newMaterialTrialOptions,
  scopeRestatment,
  technicalSpecificationNumberChange,
} from '../../../constants/product-development';
import { RfsContext } from '../../../contexts/RfsContext';
import {
  materialArtworkChangeOptions,
  materialTechnicalPackChangeOptions,
  materialTechnicalQualityChangeOptions,
} from '../../../constants';
import DOMPurify from 'dompurify';

export const options = [];

export const ProductDevelopmentTableMaterial = ({ trials, editable }) => {
  const dropzoneRef = useRef();
  const {
    materials,
    setMaterials,
    createProductDevelopmentMaterial,
    vendors,
    steps,
    stepEigibleRoles,
    materialStepStatus,
    getMaterialContracts,
  } = useContext(ProjectOverviewContext);
  const { downloadLocalFile, downloadFile } = useContext(RfsContext);
  const { fetchUsers, users, userRole, userEmail } = useContext(AuthContext);
  const [saveLoading, setSaveLoading] = useState(false);
  const [stepButton, setStepButton] = useState(null);
  const [activeMaterialIndex, setActiveMaterialIndex] = useState(-1);
  const [error, setError] = useState({ state: false, message: null });

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (materials.length > 0) {
      getMaterialContracts(
        materials.map((material) => {
          return {
            material_number: material.material_number,
            packMatsMaterialType: material.packMatsMaterialType,
            plant: material.plant,
            sup_num: material.sup_num,
          };
        }),
      );
    }
  }, []);

  const handleChange = (event, index, step_id) => {
    setStepButton(step_id);
    setActiveMaterialIndex(index);

    const currentValues = [...materials];
    if (event.target.type === 'checkbox') {
      currentValues[index][event.target.name] = event.target.checked;
    } else {
      currentValues[index][event.target.name] = event.target.value;
    }

    setMaterials(currentValues);
  };

  const handleSelect = (type, value, index, step_id) => {
    setStepButton(step_id);
    setActiveMaterialIndex(index);

    const currentValues = [...materials];
    currentValues[index][type] = value;

    setMaterials(currentValues);
  };

  const sanitizePayload = (data) => {
    const payload = { ...data };
    if (payload.material_type === 'New Material' && !payload.material_number) {
      payload.material_number = payload.new_material_number;
      delete payload.new_material_number;
    }

    return payload;
  };

  const handleClick = (data) => {
    setSaveLoading(true);
    const payload = sanitizePayload({ ...data });

    createProductDevelopmentMaterial(payload, () => setSaveLoading(false), trials);
  };

  const getSuppliers = (item) => {
    const vendors = item.sup_num.split('/');
    const vendorsDescription = item.sup_name.split('/');
    const finalList = [];

    vendors.map((vendor, key) => {
      finalList.push(`${vendor}-${vendorsDescription[key]}`);
    });

    return finalList.join('<br />');
  };

  const handleFileDelete = (fileIndex, materialIndex) => {
    const currentValues = [...materials];
    currentValues[materialIndex].attachments.splice(fileIndex, 1);

    setMaterials(currentValues);
  };

  const FileList = ({ files = [], rfs_number, materialIndex, readOnly = false }) => {
    rfs_number = rfs_number.replace(/[^a-zA-Z0-9]/g, '');

    return files.length > 0 ? (
      <ul style={{ fontSize: '0.8rem', paddingLeft: '0px', marginTop: '12px' }}>
        {files.map((file, index) => (
          <li
            key={index}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {file.id ? (
              <span
                style={{
                  color: '#e5b611',
                  cursor: 'pointer',
                }}
                onClick={() => downloadFile(file.name, rfs_number.toLowerCase())}
              >
                {file.name}{' '}
              </span>
            ) : (
              <span
                style={{
                  color: '#e5b611',
                  cursor: 'pointer',
                }}
                onClick={() => downloadLocalFile(URL.createObjectURL(file), file.name)}
              >
                {file.name}
              </span>
            )}
            {!readOnly && (
              <CloseButton
                style={{
                  marginTop: '2px',
                }}
                aria-label='Close modal'
                color='red'
                onClick={() => handleFileDelete(index, materialIndex)}
              />
            )}
          </li>
        ))}
      </ul>
    ) : (
      <p style={{ fontSize: '0.8rem' }}> No Files Attached </p>
    );
  };

  const rows = [
    {
      step_id: 'trial_material_code',
      status_key: 'Material Code',
      name: <b>{trials ? 'Trial Material Code' : 'Material Code'}</b>,
      render: (item, index, active, step_id) => {
        return (
          <>
            {item.material_number ? (
              item.material_number
            ) : (
              <>
                <TextInput
                  name='new_material_number'
                  value={item.new_material_number ? item.new_material_number : ''}
                  disabled={!active || !editable}
                  onChange={(event) => handleChange(event, index, step_id)}
                />
                {stepButton === step_id && activeMaterialIndex === index && (
                  <SaveButton clickHandler={() => handleClick(item)} active={active} />
                )}
              </>
            )}
          </>
        );
      },
    },
    {
      step_id: 'status',
      status_key: 'status',
      name: <b>Status</b>,
      render: (item, index, active, step_id, status) => {
        if (getStepStatus('trial_material_code', item)) return 'Open';

        return status === 'inprogress'
          ? 'In progress'
          : item.packMatsPlantStatus
          ? item.packMatsPlantStatus
          : item.plantDataPlantStatus
          ? item.plantDataPlantStatus
          : 'In progress';
      },
    },
    {
      step_id: 'material_description',
      status_key: 'Material Description',
      name: <b>Material Description</b>,
      render: (item, index, active, step_id, status) => {
        if (getStepStatus('trial_material_code', item)) return 'Open';

        return status === 'inprogress'
          ? 'In progress'
          : item.PackMatDesc
          ? item.PackMatDesc
          : item.PlantDataDesc;
      },
    },
    {
      step_id: 'supplier_number_and_name',
      status_key: 'Supplier Name',
      name: <b>Supplier Number & Name</b>,
      render: (item, index, active, step_id) => {
        return item.sup_num ? (
          <div style={{ padding: '10px 0px 10px 10px' }}>
            <div
              style={{
                height: '75px',
                overflowY: 'auto',
              }}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(getSuppliers(item)),
              }}
            />
          </div>
        ) : (
          <>
            <MultiSelect
              searchable
              name='supplier_name_number'
              onChange={(value) => {
                if (value.length > 5)
                  return showAlert({ message: 'Only 5 vendors can be selected' }, 'error');
                handleSelect('supplier_name_number', value, index, step_id);
              }}
              value={item.supplier_name_number || []}
              dropdownPosition='bottom'
              data={vendors}
              disabled={!active || !editable}
            />
            {stepButton === step_id && activeMaterialIndex === index && (
              <SaveButton clickHandler={() => handleClick(item)} active={active} />
            )}
          </>
        );
      },
    },
    {
      step_id: 'artwork_developement',
      name: <b>Artwork Development</b>,
      status_key: 'Artwork Development',
      render: (item, index, active, step_id, stepStatus) => {
        const status = item.packMatsPlantStatus
          ? item.packMatsPlantStatus
          : item.plantDataPlantStatus;
        if (!status) return 'Open';

        if (stepStatus === 'closed') return 'Closed';
        if (stepStatus === 'notapplicable') return 'Not Applicable';

        if (
          item.material_type === 'New Material' &&
          (status === '42' || (item.conf_mat_num && item.packMatsPlantStatus === '96'))
        )
          return (
            <>
              <div className='artworkCheck'>
                <Checkbox
                  disabled={!active || !editable}
                  style={{ padding: '10px' }}
                  name='po_created'
                  label='PO  created for this number'
                  checked={item.po_created}
                  onChange={(event) => handleChange(event, index, step_id)}
                />{' '}
                <Select
                  disabled={!active || !item.po_created || !editable}
                  style={{ padding: '10px' }}
                  searchable
                  name='po_num_provider'
                  label='PO number Provider'
                  clearable={!(!active || !item.po_created)}
                  dropdownPosition='bottom'
                  data={(users || []).map((val) => {
                    return {
                      value: val.email,
                      label: val.name,
                    };
                  })}
                  value={item.po_num_provider}
                  onChange={(value) => handleSelect('po_num_provider', value, index, step_id)}
                />{' '}
                <TextInput
                  disabled={
                    !active || !item.po_created || item.po_num_provider !== userEmail || !editable
                  }
                  name='po_num'
                  value={item.po_num ? item.po_num : ''}
                  onChange={(event) => handleChange(event, index, step_id)}
                  style={{ padding: '10px' }}
                  placeholder='PO Number'
                />
                <Checkbox
                  disabled={!active || !editable}
                  style={{ padding: '10px' }}
                  label='Artwork Related to Agency'
                  name='artwork_agency'
                  checked={item.artwork_agency}
                  onChange={(event) => handleChange(event, index, step_id)}
                />
                <Checkbox
                  disabled={!active || !editable}
                  style={{ padding: '10px' }}
                  label='Colour Target Setting Finalised'
                  name='colour_target_final'
                  checked={item.colour_target_final}
                  onChange={(event) => handleChange(event, index, step_id)}
                />
                <Checkbox
                  disabled={!active || !editable}
                  style={{ padding: '10px' }}
                  label='Artwork Approved/ Artwork Sent to Supplier'
                  name='artwork_approved'
                  checked={item.artwork_approved}
                  onChange={(event) => handleChange(event, index, step_id)}
                />
                <Checkbox
                  disabled={!active || !editable}
                  style={{ padding: '10px' }}
                  label='PO Made for supplier '
                  checked={item.po_supplier}
                  name='po_supplier'
                  onChange={(event) => handleChange(event, index, step_id)}
                />{' '}
                <Select
                  disabled={!active || !item.po_supplier || !editable}
                  style={{ padding: '10px' }}
                  searchable
                  label='PO number Provider'
                  clearable={!(!active || !item.po_supplier)}
                  name='po_sup_num_provider'
                  dropdownPosition='bottom'
                  data={(users || []).map((val) => {
                    return {
                      value: val.email,
                      label: val.name,
                    };
                  })}
                  value={item.po_sup_num_provider}
                  onChange={(value) => handleSelect('po_sup_num_provider', value, index, step_id)}
                />{' '}
                <TextInput
                  disabled={!active || !item.po_supplier || !editable}
                  name='po_sup_num'
                  value={item.po_sup_num ? item.po_sup_num : ''}
                  onChange={(event) => handleChange(event, index, step_id)}
                  style={{ padding: '10px' }}
                  placeholder='Supplier PO Number'
                />
                <Checkbox
                  disabled={!active || !editable}
                  style={{ padding: '10px' }}
                  label='Good For Print provided'
                  name='good_for_print'
                  checked={item.good_for_print}
                  onChange={(event) => handleChange(event, index, step_id)}
                />
                {item.attachments.length > 0 && (
                  <div style={{ padding: '0px 10px', textAlign: 'center' }}>
                    <FileList
                      files={item.attachments}
                      rfs_number={item.rfs_number}
                      materialIndex={index}
                      readOnly={
                        item.attachments.filter((attachment) => attachment.rfs_number).length > 0
                      }
                    />
                  </div>
                )}
                {item.good_for_print && (
                  <div style={{ padding: '10px', textAlign: 'center' }}>
                    {item.attachments.filter((attachment) => attachment.rfs_number).length ===
                      0 && (
                      <Button
                        color='yellow'
                        style={{ background: 'black', color: 'white' }}
                        onClick={() => dropzoneRef.current()}
                      >
                        Upload Attachment
                      </Button>
                    )}
                    <Dropzone
                      multiple={false}
                      className='dropzone-comp'
                      openRef={dropzoneRef}
                      onDrop={(files) => handleFileSelect(files, index)}
                      onReject={(files) =>
                        setError({ state: true, message: files[0].errors[0].message })
                      }
                      maxSize={5 * 1024 ** 2}
                    >
                      {(status) => dropzoneChildren(status)}
                    </Dropzone>
                    {error.state && (
                      <div className='red-custom-mantine-Textarea-error'>{error.message}</div>
                    )}
                  </div>
                )}
              </div>

              {stepButton === step_id && activeMaterialIndex === index && (
                <SaveButton
                  disabled={
                    item.attachments.length === 0 ||
                    !item.good_for_print ||
                    !item.artwork_approved ||
                    !item.colour_target_final ||
                    !item.artwork_agency ||
                    !item.po_num ||
                    !item.po_num_provider ||
                    !item.po_created
                  }
                  clickHandler={() => handleClick(item)}
                  active={active}
                />
              )}
            </>
          );
      },
    },
    {
      step_id: 'changeover',
      status_key: 'Changeover',
      name: <b>Changeover Status</b>,
      render: (item, index, active, step_id, status) =>
        status === 'closed' ? (
          <Checkbox
            label='Automatically closed'
            style={{ padding: '10px' }}
            checked={true}
            disabled={true}
          />
        ) : status === 'inprogress' ? (
          'In progress'
        ) : (
          'Open'
        ),
    },

    {
      step_id: 'ean_approval',
      status_key: 'EAN Approval',
      name: <b>EAN Approval</b>,
      render: (item, index, active, step_id, stepStatus) => {
        const status = item.packMatsPlantStatus
          ? item.packMatsPlantStatus
          : item.plantDataPlantStatus;
        if (!status) return 'Open';

        if (stepStatus === 'closed') return 'Closed';
        if (stepStatus === 'notapplicable') return 'Not Applicable';

        if (item.packMatsMaterialType === 'Z014') {
          return (
            <div className='EANCheck'>
              <Checkbox
                disabled={true}
                style={{ padding: '10px' }}
                label='Materials on BOMs'
                name='mat_on_boms'
                checked={item.BOMPresence === 'yes'}
              />
              <Select
                disabled={!active || !editable}
                style={{ padding: '10px' }}
                label=' Confirm Material Number and EAN on ERP and PDF'
                placeholder='Pick one'
                data={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' },
                ]}
                name='conf_mat_num'
                value={item.conf_mat_num}
                onChange={(value) => handleSelect('conf_mat_num', value, index, step_id)}
              />
              <Select
                disabled={item.conf_mat_num === 'Yes' || !active || !editable}
                style={{ padding: '10px' }}
                label=' EAN not approved'
                placeholder='Pick one'
                data={[
                  { value: 'EAN', label: 'EAN not matching' },
                  { value: 'Material', label: 'Material not matching' },
                ]}
                name='ean_not_provided'
                value={item.ean_not_provided}
                onChange={(value) => handleSelect('ean_not_provided', value, index, step_id)}
              />
              <Checkbox
                disabled={true}
                style={{ padding: '10px' }}
                label='Change status from 42 to 96'
                name='status_change'
                checked={item.packMatsPlantStatus === '96'}
              />

              {stepButton === step_id && activeMaterialIndex === index && (
                <SaveButton clickHandler={() => handleClick(item)} active={active} />
              )}
            </div>
          );
        } else {
          return stepStatus === 'inprogress' ? 'In Progress' : 'Open';
        }
      },
    },
    {
      step_id: 'material_contract',
      status_key: 'Material Contract',
      name: <b>Material Contract</b>,
      render: (item, index, active, step_id, status) =>
        status === 'inprogress' ? 'In progress' : status === 'closed' ? 'Closed' : 'Open',
    },
    {
      step_id: 'pack_mat_change',
      name: <b>Pack Mat Change</b>,
      status_key: 'Pack Mat Change',
      render: (item, index, active) => (
        <>
          <div className='artworkCheck'>
            <Checkbox
              style={{ padding: '10px' }}
              label='Supplier Production'
              checked={item.StockAtSupplierProduction > 0}
              disabled={true}
            />
            <Checkbox
              style={{ padding: '10px' }}
              checked={item.StockAtSupplier > 0}
              label='Material on stock at supplier'
              disabled={true}
            />
            <Checkbox
              style={{ padding: '10px' }}
              label='Material on stock at ABI'
              disabled={true}
              checked={item.ABICount > 0}
            />
          </div>
        </>
      ),
    },
    {
      step_id: 'filing_brewing_repacking',
      status_key: 'Filing/Brewing/Repacking',
      name: <b>Filing/Brewing/Repacking</b>,
      render: (item) => (item.fillingStatus && item.fillingStatus > 0 ? 'Closed' : 'Open'),
    },
    {
      step_id: 'trial',
      status_key: 'Trials',
      name: <b>Trials (Success or Fail)</b>,
      render: (item, index, active, step_id, status) => {
        if (status === 'open') return 'Open';
        return (
          <>
            <Select
              searchable
              clearable={active}
              dropdownPosition='bottom'
              data={[
                { value: 'success', label: 'Success' },
                { value: 'fail', label: 'Fail' },
              ]}
              name='trial_status'
              value={item.trial_status}
              onChange={(value) => handleSelect('trial_status', value, index, step_id)}
              disabled={!active || !editable}
            />

            {stepButton === step_id && activeMaterialIndex === index && (
              <SaveButton clickHandler={() => handleClick(item)} active={active} />
            )}
          </>
        );
      },
    },
    {
      step_id: 'trials_failed',
      status_key: 'Trials Failed',
      name: <b>Trials Failed</b>,
      render: (item, index, active, step_id) => (
        <>
          <Select
            searchable
            clearable={active}
            dropdownPosition='bottom'
            name='trial_fail'
            data={
              item.material_type === 'Existing Material'
                ? existingMaterialTrialOptions
                : newMaterialTrialOptions
            }
            value={item.trial_fail}
            onChange={(value) => handleSelect('trial_fail', value, index, step_id)}
            disabled={!active || !editable}
          />

          {item.trial_fail === scopeRestatment && (
            <div style={{ paddingTop: '10px' }}>
              <MultiSelect
                disabled={!active || !editable}
                style={{ paddingBottom: '10px' }}
                radius='md'
                onChange={(value) => handleSelect('technical_pack_change', value, index, step_id)}
                value={
                  typeof item.technical_pack_change === 'object'
                    ? item.technical_pack_change
                    : item.technical_pack_change.split(',')
                }
                placeholder={'Technical Pack Change'}
                data={[
                  {
                    value: materialTechnicalPackChangeOptions.thickness,
                    label: materialTechnicalPackChangeOptions.thickness,
                  },
                  {
                    value: materialTechnicalPackChangeOptions.drawing,
                    label: materialTechnicalPackChangeOptions.drawing,
                  },
                ]}
                searchable
                nothingFound='Nothing found'
              />

              <MultiSelect
                disabled={!active || !editable}
                radius='md'
                style={{ paddingBottom: '10px' }}
                onChange={(value) =>
                  handleSelect('technical_quality_change', value, index, step_id)
                }
                value={
                  typeof item.technical_quality_change === 'object'
                    ? item.technical_quality_change
                    : item.technical_quality_change.split(',')
                }
                placeholder={'Technical Quality Change'}
                data={[
                  {
                    value: materialTechnicalQualityChangeOptions.substrate,
                    label: materialTechnicalQualityChangeOptions.substrate,
                  },
                  {
                    value: materialTechnicalQualityChangeOptions.coating,
                    label: materialTechnicalQualityChangeOptions.coating,
                  },
                  {
                    value: materialTechnicalQualityChangeOptions.prodTech,
                    label: materialTechnicalQualityChangeOptions.prodTech,
                  },
                  {
                    value: materialTechnicalQualityChangeOptions.compQuality,
                    label: materialTechnicalQualityChangeOptions.compQuality,
                  },
                ]}
                searchable
                nothingFound='Nothing found'
              />

              <Select
                disabled={!active || !editable}
                style={{ paddingBottom: '10px' }}
                radius='md'
                onChange={(value) => handleSelect('artwork_change', value, index, step_id)}
                value={item.artwork_change}
                placeholder='Artwork Management'
                searchable
                data={[
                  {
                    value: materialArtworkChangeOptions.text,
                    label: materialArtworkChangeOptions.text,
                  },
                  {
                    value: materialArtworkChangeOptions.newColours,
                    label: materialArtworkChangeOptions.newColours,
                  },
                  {
                    value: materialArtworkChangeOptions.existingColours,
                    label: materialArtworkChangeOptions.existingColours,
                  },
                ]}
              />
            </div>
          )}
          {stepButton === step_id && activeMaterialIndex === index && (
            <SaveButton clickHandler={() => handleClick(item)} active={active} />
          )}
        </>
      ),
    },
    {
      step_id: 'reason_of_failure',
      status_key: 'Reason of Failure',
      name: <b>Reason of Failure</b>,
      render: (item, index, active, step_id) => (
        <>
          <Textarea
            style={{ paddingTop: '10px' }}
            placeholder='Please write reason here'
            name='fail_reason'
            onChange={(event) => handleChange(event, index, step_id)}
            value={item.fail_reason}
            disabled={!active || !editable}
            required
          />

          {stepButton === step_id && activeMaterialIndex === index && (
            <SaveButton clickHandler={() => handleClick(item)} active={active} />
          )}
        </>
      ),
    },
    {
      step_id: 'tech_spec_number_changed',
      name: <b>Tech Spec Number Changed</b>,
      render: (item, index, active, step_id) =>
        item.trial_fail === technicalSpecificationNumberChange ? (
          <>
            <Textarea
              disabled={!active || !editable}
              style={{ paddingTop: '10px' }}
              placeholder='Please type here'
              name='tech_spec_matrix'
              onChange={(event) => handleChange(event, index, step_id)}
              value={item.tech_spec_matrix}
              required
            />

            {stepButton === step_id && activeMaterialIndex === index && (
              <SaveButton clickHandler={() => handleClick(item)} active={active} />
            )}
          </>
        ) : (
          'Not Applicable'
        ),
    },

    {
      step_id: 'removal_of_changeover',
      status_key: 'Removal of Changeover',
      name: <b>Removal of Changeover</b>,
      render: (item, index, active, step_id, status) =>
        [changeoverCancellation, scopeRestatment].includes(item.trial_fail) ? (
          <>
            <Checkbox
              checked={
                (item.changeoverAppliedStatusRemoval > 0 &&
                  item.material_type === 'Remove Material') ||
                item.changeoverAppliedStatus === 0
              }
              name='changeover_removal'
              disabled={true}
            />
          </>
        ) : (
          'Not Applicable'
        ),
    },
    {
      step_id: 'material_status_change',
      status_key: 'Material Status Change',
      name: <b>Material Status Change</b>,
      render: (item, index, active) =>
        [artworkUpdate, scopeRestatment, technicalSpecificationNumberChange].includes(
          item.trial_fail,
        ) ? (
          <>
            {' '}
            <Checkbox
              disabled={true}
              name='material_status_change'
              checked={item.packMatsPlantStatus === '42'}
            />
          </>
        ) : (
          'Not Applicable'
        ),
    },
  ];

  const SaveButton = ({ clickHandler, active, disabled = false }) =>
    active ? (
      <Button
        onClick={clickHandler}
        position='Right'
        size='sm'
        color='dark'
        radius='xs'
        disabled={saveLoading || disabled}
        fullWidth
      >
        {saveLoading ? <AppLoader /> : 'Submit'}
      </Button>
    ) : null;

  const getStepStatus = (step_id, item) => {
    if (materialStepStatus[`${item.material_code}_${item.plant}`]) {
      return ['inprogress'].includes(
        materialStepStatus[`${item.material_code}_${item.plant}`][step_id],
      );
    }

    return false;
  };

  const getStepStatusName = (step_id, item) => {
    return materialStepStatus[`${item.material_code}_${item.plant}`][step_id];
  };

  const handleFileSelect = (files, index) => {
    const currentValues = [...materials];
    files.forEach((file) => currentValues[index].attachments.push(file));

    setMaterials(currentValues);
  };

  const dropzoneChildren = (status) => <div></div>;

  return (
    <>
      {rows.map(({ name, render, step_id }, index) =>
        (trials && !['filing_brewing_repacking', 'pack_mat_change'].includes(step_id)) ||
        (!trials &&
          ![
            'material_status_change',
            'tech_spec_number_changed',
            'trials_failed',
            'reason_of_failure',
            'trial',
          ].includes(step_id)) ? (
          <tr key={`material_${index}`}>
            <td className='prod-dev-table-header'>{name}</td>

            {(materials || []).map((item, i) => {
              const status = getStepStatusName(step_id, item);
              return (
                <td className='prod-dev-table-data' key={i}>
                  {([
                    'reason_of_failure',
                    'trials_failed',
                    'tech_spec_number_changed',
                    'removal_of_changeover',
                    'material_status_change',
                  ].includes(step_id) &&
                    item.trial_status !== 'Fail') ||
                  status === 'notapplicable'
                    ? 'Not Applicable'
                    : render(
                        item,
                        i,
                        getStepStatus(step_id, item) &&
                          stepEigibleRoles[step_id] &&
                          stepEigibleRoles[step_id].includes(userRole),
                        step_id,
                        status,
                      )}
                </td>
              );
            })}
          </tr>
        ) : null,
      )}
    </>
  );
};
