import { useState, useContext } from 'react';
import { Button, Text, Navbar, Modal, Grid, Group } from '@mantine/core';
import {
  Home,
  InfoCircle,
  Download,
  Table,
  Forms,
  Plus,
  ReportAnalytics,
  ClipboardText,
  Logout,
  File,
} from 'tabler-icons-react';
import { Link, useParams } from 'react-router-dom';
import style from './sidebar.module.css';
import NavItem from './NavItem.js';
import { routes } from '../../routes';
import { AuthContext } from '../../contexts/AuthContext';
import navItem from './navItem.module.css';
import { RfsContext } from '../../contexts/RfsContext';

const SideMenu = ({ leftNav, rightNav, setLeftNav, open }) => {
  const { userRole } = useContext(AuthContext);
  const params = useParams();
  const [modal, setModal] = useState(false);

  let sideMenu = [
    {
      label: (
        <Link style={{ color: 'unset' }} to={routes.rfs.root}>
          All Projects
        </Link>
      ),
      Icon: Home,
      visibility: true,
      to: `${routes.rfs.root}`,
    },
    {
      label: 'Add New Project',
      Icon: Plus,
      to: `${routes.submission().add}`,
      visibility: true,
      children: [
        {
          label: 'Information',
          Icon: InfoCircle,
          to: `${routes.submission(params.rfsNumber).info}`,
          type: 'rfs_submission',
        },
        {
          label: 'Scope',
          Icon: Table,
          to: `${routes.submission(params.rfsNumber).scopeSelect}`,
          type: 'rfs_submission',
        },
        {
          label: 'Attachments',
          Icon: Download,
          to: `${routes.submission(params.rfsNumber).attachment}`,
          type: 'rfs_submission',
        },
        {
          label: 'Validate',
          Icon: Forms,
          to: `${routes.submission(params.rfsNumber).review}`,
          type: 'rfs_submission',
        },
      ],
    },
    {
      label: '	ACCF Settings',
      Icon: ClipboardText,
      to: `${routes.accf.root}`,
      visibility: true,
    },
    {
      label: 'Reports',
      Icon: ReportAnalytics,
      to: `${routes.reports.root}`,
      visibility: true,
    },
  ];

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = '/';
  };

  const { downloadFile } = useContext(RfsContext);

  return (
    <Navbar
      className={style.sidebar}
      hiddenBreakpoint='md'
      hidden={!open}
      style={{ bottom: '0.8px' }}
    >
      {rightNav && (
        <div className={`nav-close-button`}>
          <span onClick={() => setLeftNav(!leftNav)} className='vertical-text'>
            Close
          </span>
        </div>
      )}
      {sideMenu.map((item, index) => {
        return (item.eligibleRoles && !item.eligibleRoles.includes(userRole)) ||
          !item.visibility ? null : (
          <NavItem key={`${item.label}-${index}`} item={item} />
        );
      })}
      <div>
        <button
          onClick={() => downloadFile('Privacy Policy.pdf')}
          style={{
            padding: '1rem 1.25rem',
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            background: '#2d2f34',
            color: 'white',
            position: 'absolute',
            bottom: '75px',
            width: '100%',
            border: '#2d2f34',
          }}
          className='privacy-policy-button'
        >
          {/* Use an appropriate icon or label for your privacy policy */}
          <File className={navItem.navIcon} />
          <span>Privacy Policy</span>
        </button>
      </div>
      <div>
        <Modal
          opened={modal}
          onClose={() => setModal(false)}
          title={
            <Text weight={500} align='center'>
              Are you sure you want to logout?
            </Text>
          }
        >
          <Grid>
            <Grid.Col span={12}>
              <Group noWrap className='invalid-modal-group'>
                <InfoCircle color='red' size='5rem' />
                <Text size='sm'>
                  You will be logged out of the website and all unsaved progress will be lost.
                </Text>
              </Group>
            </Grid.Col>

            <Grid.Col span={4} offset={8}>
              <Button
                onClick={() => {
                  handleLogout();
                }}
                variant='filled'
                color='yellow'
                fullWidth
                style={{
                  background: 'linear-gradient(90deg,#e3af32 0%,#f4e00f 100%)',
                  color: 'black',
                }}
              >
                Logout
              </Button>
            </Grid.Col>
          </Grid>
        </Modal>
      </div>

      <hr
        style={{
          position: 'absolute',
          bottom: '60px',
          color: 'white',
          width: '100%',
        }}
      />

      <div
        style={{
          padding: '1rem 1.25rem',
          textDecoration: 'none',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          color: '#FFFFFF',
          position: 'absolute',
          bottom: '10px',
          width: '100%',
        }}
        className='logout-button'
        onClick={() => setModal(true)}
      >
        <Logout className={navItem.navIcon} />
        <span className={navItem.navLabel}>Logout</span>
      </div>
    </Navbar>
  );
};

export default SideMenu;
