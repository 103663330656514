import React from 'react';
import { useState } from 'react';
import { Modal, Button, Container, Grid, Checkbox, List } from '@mantine/core';
import { DownloadIcon } from '@modulz/radix-icons';

import './FastTrackModal.css';

function FastTrackModal({ disabled, value, handleChange, download }) {
  const [opened, setOpened] = useState(false);

  return (
    <div>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title='Request for Fast Track'
        className='fast-track-modal'
      >
        <Container>
          <Grid>
            <Grid.Col span={10} offset={1}>
              <List className='modal-content' type='ordered' spacing='xs' size='md'>
                <List.Item>
                  Request your own VP/BU president for the fast track approval (with business
                  justification) and once received share back the email with the Gatekeeper
                </List.Item>
                <List.Item>
                  Portfolio Team will request Fast track approval from VP supply
                </List.Item>
                <List.Item>
                  Only once VP supply confirms, the project is considered fast track.
                </List.Item>
                <List.Item>Approved Fast track does not guarantee meeting requested FSD</List.Item>
                <List.Item>For additional details, please download the attached PDF</List.Item>
              </List>
            </Grid.Col>
            <Grid.Col span={3} offset={0}>
              <Button
                color='yellow'
                leftIcon={<DownloadIcon />}
                fullWidth
                style={{ marginTop: '1rem' ,background:'linear-gradient(90deg,#e3af32 0%,#f4e00f 100%)', color: 'black' }}
                onClick={() => download('Fast Track Document.pdf')}
              >
                Download
              </Button>
            </Grid.Col>
            <Grid.Col span={3} offset={6}>
              <Button
                color='yellow'
                onClick={() => setOpened(false)}
                fullWidth
                style={{ marginTop: '1rem' ,background:'linear-gradient(90deg,#e3af32 0%,#f4e00f 100%)', color: 'black' }}
              >
                Close
              </Button>
            </Grid.Col>
          </Grid>
        </Container>
      </Modal>

      <Checkbox
        className='checkbox'
        label='Request For Fast Track'
        color='orange'
        size='sm'
        checked={value}
        disabled={disabled}
        name={'fast_track'}
        onClick={(e) => {
          handleChange(e);
          if (e.target.checked) setOpened(true);
        }}
      />
    </div>
  );
}

export default FastTrackModal;
