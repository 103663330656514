import React, { useState, useContext, useEffect } from 'react';
import { Box, Accordion, Grid, Button } from '@mantine/core';
import ContentPaper from '../../../../../components/layouts/rfs/Content';
import { Row } from 'react-bootstrap';
import { RfsContext } from '../../../../../contexts/RfsContext';

import 'bootstrap/dist/css/bootstrap.min.css';
import { AppLoader } from '../../../../../components/common/loader';
import ChangeOverRemovalTableVEAM from './changeOverRemovalTable';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { TableExport } from 'tabler-icons-react';
import csvDownload from 'json-to-csv-export';
import { axios } from '../../../../../utils/axios';
import checkClosedOrInProgress from '../../../veamHelper';

const ChangeOverRemovalVEAM = ({ readOnly, checkClosuresChangeOverRem }) => {
  const { form, info, fetchChangeOversRemovalVeam, StoreManualClosureVEAM } =
    useContext(RfsContext);
  const { rfs_number } = form.rfsInformation;
  const [statusData, setstatusData] = useState([]);
  const [statusDataN, setstatusDataN] = useState([]);
  const [loading, setLoading] = useState(false);

  const [state, handlers] = useState({ initialItem: 0 });
  const [active, setActive] = useState(0);

  const [accState, setAccState] = useState([]);

  useEffect(() => {
    handlers({ initialItem: 0 });
  }, [active]);

  useEffect(() => {
    checkClosuresChangeOverRem(checkClosedOrInProgress(statusDataN));
  }, [statusDataN]);

  const save = (payload, id, callback) => {
    setLoading(true);
    axios.post(`/veam/manual_closure_cancellation_and_scope/`, payload).then(() => {
      getStatusDetails(rfs_number);
      setLoading(false);
      setAccState(callback);
    });
  };

  useEffect(() => {
    getStatusDetails(rfs_number);
  }, [rfs_number]);

  const getStatusDetails = (rfs_number) => {
    setLoading(true);
    fetchChangeOversRemovalVeam(rfs_number).then((res) => {
      const changeOverFilter = res.data.data.filter(
        (item) => item.sla_weeks_id === 253 || item.sla_weeks_id === 255,
      );
      setstatusDataN(changeOverFilter);
      const result = [];

      result['Materials'] = changeOverFilter
        .filter((item) => item.selection_type === 'materials')
        .sort((a, b) => a.id - b.id);
      result['Liquids'] = changeOverFilter
        .filter((item) => item.selection_type === 'liquids')
        .sort((a, b) => a.id - b.id);

      setstatusData(result);
      setLoading(false);
    });
  };

  if (loading) {
    return (
      <ContentPaper page={'ProductDevelopment'}>
        <AppLoader size='lg' center />
      </ContentPaper>
    );
  }

  const downloadExcel = (type) => {
    const dataToExport = {
      data: [],
      filename: '',
      delimiter: ',',
      headers: [],
    };
    const materialsToExport = {
      data: [],
      filename: '',
      delimiter: ',',
      headers: [],
    };
    const liquidHeaders = [
      'Liquid Code',
      'Liquid Description',
      'Plant',
      'Liquid BOM Material Change',
      'New Liquid Number',
      'New Liquid Name ',
      'Current Quantity',
      'New Quantity',
      'Status',
      'Action',
      'Step Deadline',
      'Step Finished',
    ];
    const materialHeaders = [
      'Material Code',
      'Material Description',
      'Plant',
      'Type of Change',
      'New Material Number',
      'New Material Name',
      'Status',
      'Action',
      'Step Deadline',
      'Step Finished',
    ];

    const liquids = statusData.Liquids;
    const materials = statusData.Materials;

    if ((liquids || []).length > 0) {
      dataToExport.data = getDataToExport(liquids, 'liquids');

      dataToExport.filename = ' ChangeOverRemoval_liquids' + moment().format('YYYY-MM-DD HH:mm:ss');
      dataToExport.headers = liquidHeaders;
      csvDownload(dataToExport);
    }

    if ((materials || []).length > 0) {
      materialsToExport.data = getDataToExport(materials, 'materials');
      materialsToExport.filename =
        'ChangeOverRemoval_materials' + moment().format('YYYY-MM-DD HH:mm:ss');
      materialsToExport.headers = materialHeaders;
      csvDownload(materialsToExport);
    }
  };

  const getDataToExport = (data, type) => {
    const csvArray = type === 'materials' ? data.map(dataToExportM) : data.map(dataToExportL);

    return csvArray;
  };

  const dataToExportM = ({
    manually_closed,
    sla_date,
    status,
    finished_date,
    material_type,
    int_rfs_material_number,
    material_code_or,
    pack_mat_material_text,
    plant,
    material_description_or,
    new_material_code,
    new_material_description_intrfsml,
    material_changeover_type,
    material_changeover_date,
    material_comment,
  }) => {
    let Code = 'N/A',
      new_num = 'N/A',
      new_name = 'N/A',
      type_of_change = 'N/A',
      changeovertype = 'N/A',
      changeoverdate = 'N/A',
      comment = 'N/A',
      Desc;
    if (material_type === 'Existing Material' || material_type === 'Remove Material') {
      Code = material_code_or ? material_code_or : 'N/A';
      new_num = int_rfs_material_number ? int_rfs_material_number : 'N/A';

      Desc = material_description_or;
      new_name = pack_mat_material_text ? pack_mat_material_text : 'N/A';
    } else if (material_type === 'New Material') {
      Code = material_code_or ? material_code_or : 'N/A';
      new_num = new_material_code ? new_material_code : 'N/A';

      Desc = material_description_or;
      new_name = new_material_description_intrfsml ? new_material_description_intrfsml : 'N/A';
    }

    type_of_change = material_type ? material_type : 'N/A';
    changeovertype = material_changeover_type ? material_changeover_type : 'N/a';
    changeoverdate = material_changeover_date ? material_changeover_date : 'N/A';
    comment = material_comment ? material_comment : 'N/A';

    return {
      'Material Code': Code ? Code : 'N/A',
      'Material Description': Desc ? Desc : 'N/A',
      Plant: plant ? plant : 'N/A',
      'Type of Change ': type_of_change ? type_of_change : 'N/A',
      'New Material Number': new_num ? new_num : 'N/A',
      'New Material Name': new_name ? new_name : 'N/A',
      Status: status,
      Action:
        status === 'Closed' ? (manually_closed ? 'Manually Closed' : 'Automatic Closure') : 'NA',
      'step Deadline': sla_date,
      'step Finished': status === 'Closed' ? finished_date : status,
    };
  };

  const dataToExportL = ({
    manually_closed,
    sla_date,
    status,
    finished_date,
    liquid_code,
    liquid_number,
    liquid_description,
    material_text,
    liquid_changeover_type,
    bom_changes,
    liquid_changeover_date,
    liquid_comment,
    plant,
    quantity,
    new_quantity,
  }) => {
    let Code = 'N/A',
      new_num = 'N/A',
      new_name = 'N/A',
      type_of_change = 'N/A',
      changeovertype = 'N/A',
      changeoverdate = 'N/A',
      comment = 'N/A',
      Desc;

    Code = liquid_code ? liquid_code : 'N/A';
    new_num = liquid_number ? liquid_number : 'N/A';

    Desc = liquid_description ? liquid_description : 'N/A';
    new_name = material_text ? material_text : 'N/A';

    changeovertype = liquid_changeover_type ? liquid_changeover_type : 'N/A';
    type_of_change = bom_changes ? bom_changes : 'N/a';
    changeoverdate = liquid_changeover_date ? liquid_changeover_date : 'N/A';
    comment = liquid_comment ? liquid_comment : 'N/A';
    return {
      'Material Code': Code ? Code : 'N/A',
      'Material Description': Desc ? Desc : 'N/A',
      Plant: plant ? plant : 'N/A',
      'Type of Change ': type_of_change ? type_of_change : 'N/A',
      'New Material Number': new_num ? new_num : 'N/A',
      'New Material Name': new_name ? new_name : 'N/A',
      'Current Quantity': quantity ? quantity : 'N/A',
      'New Quantity': new_quantity ? new_quantity : 'N/A',
      Status: status,
      Action:
        status === 'Closed' ? (manually_closed ? 'Manually Closed' : 'Automatic Closure') : 'NA',
      'step Deadline': sla_date,
      'step Finished': status === 'Closed' ? finished_date : status,
    };
  };

  return (
    <Row>
      <div
        style={{
          display: 'flex',
          justifyContent: 'right',
          marginRight: '4rem',
          marginBottom: '1rem',
        }}
      >
        <Button
          leftIcon={<TableExport />}
          onClick={() => {
            downloadExcel();
          }}
          color='yellow'
          style={{
            marginTop: '1rem',
            background: 'black',
          }}
        >
          Export to CSV
        </Button>
      </div>
      <Accordion id='outside' state={state} onChange={handlers}>
        {Object.keys(statusData) &&
          Object.keys(statusData).map((name, index) => {
            return (
              Object.values(statusData)[index].length > 0 && (
                <Accordion.Item className='scope-label' key={`key_${index}`} value={`key_${index}`}>
                  <Accordion.Control>{`${name}`}</Accordion.Control>
                  <Accordion.Panel>
                    <Grid>
                      <Grid.Col span={12}>
                        <ChangeOverRemovalTableVEAM
                          statusDataList={Object.values(statusData)[index]}
                          type={name}
                          save={save}
                          accState={accState}
                          readOnly={readOnly}
                        />
                      </Grid.Col>
                    </Grid>
                  </Accordion.Panel>
                </Accordion.Item>
              )
            );
          })}
      </Accordion>
    </Row>
  );
};

export default ChangeOverRemovalVEAM;
