import React from 'react';
import { Modal, Grid, Group, Text, Button } from '@mantine/core';

import { InfoCircle } from 'tabler-icons-react';

export const DuplicatePlantModal = ({ duplicatePlantModal, setDuplicatePlantModal }) => {
  const handleClose = () => {
    setDuplicatePlantModal({
      open: false,
      callback: null,
    });
  };

  return (
    <>
      <Modal
        className='duplicatePlant-modal'
        opened={duplicatePlantModal.open}
        onClose={() => handleClose()}
        title='Warning'
      >
        <Grid>
          <Grid.Col span={12}>
            <Group noWrap className='duplicatePlant-modal-group'>
              <InfoCircle color='red' size='5rem' />
              <Text size='sm'>
                You have already selected this plant for this SKU. Are you sure you want to select
                this plant again for a different reference plant.
              </Text>
            </Group>
          </Grid.Col>

          <Grid.Col span={4} offset={8}>
            <Button
              onClick={() => duplicatePlantModal.callback()}
              variant='filled'
              color='yellow'
              fullWidth
              style={{background:'linear-gradient(90deg,#e3af32 0%,#f4e00f 100%)',color: 'black' }}
            >
              Confirm
            </Button>
          </Grid.Col>
        </Grid>
      </Modal>
    </>
  );
};
