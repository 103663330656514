import React, { useContext, useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import './ScopeCompDLST.css';
import { Tooltip } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useDisclosure } from '@mantine/hooks';
import ReactTable from '../dlstTable/dlstTable';

import { InvalidModal } from '../../../components/modal/invalidModal';

import { BrowserRouter as Router, Link, useNavigate, useParams } from 'react-router-dom';
import { TextInput, Checkbox, Text, Modal } from '@mantine/core';
import { CalendarIcon, Pencil1Icon } from '@modulz/radix-icons';
import { Select } from '@mantine/core';
import { Accordion, Button } from '@mantine/core';
import ProjectName from '../../../components/projectName/projectName';
import { RfsContext } from '../../../contexts/RfsContext';
import ContentPaper from '../../../components/layouts/rfs/Content';
import { Grid } from '@mantine/core';
import { AppLoader } from '../../../components/common/loader';
import { routes } from '../../../routes';
import { rfsStatus } from '../../../constants';
import MarketModal from './marketModal';
import { DelistingContext } from '../../../contexts/DelistingContext';
import CCModal from '../../../components/modal/CCModal';

import { axios } from '../../../utils/axios';
import { CostCenter } from '../../../common/inputs/CostCenter';

export default function ScopeCompDLST(props) {
  const params = useParams();
  const { rfsNumber } = params;
  const navigate = useNavigate();
  const {
    delistingForm,
    setDelistingForm,
    scopeErrors,
    setScopeErrors,
    fetchDelisting,
    fetchSelectedMarkets,
    validateDelisting,
    selectedMarkets,
    marketLoading,
  } = useContext(DelistingContext);

  const { form, loading, infoLoading } = useContext(RfsContext);

  const [saveAsDraft, setSaveAsDraft] = useState(false);
  const [accordionState, setAccordionState] = useState({});
  const [opened, setOpened] = useState({ open: false, id: null, item: null });
  const [isChecked, setIsChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ccModal, setCcModal] = useState({
    open: false,
    callback: null,
    query: null,
    id: null,
  });

  const [invalidModal, setInvalidModal] = useState({
    open: false,
    callback: false,
  });
  const { status = '', rfs_type } = form.rfsInformation;

  useEffect(() => {
    fetchDelisting(rfsNumber);
  }, [rfsNumber, fetchDelisting]);

  useEffect(() => {
    fetchSelectedMarkets(rfsNumber, delistingForm);
  }, [rfsNumber, delistingForm]);

  useEffect(() => {
    for (const key in delistingForm) {
      if (delistingForm.hasOwnProperty(key) && selectedMarkets.hasOwnProperty(key)) {
        const impactedMarketsArray = selectedMarkets[key];
        delistingForm[key].impacted_markets = impactedMarketsArray;
      }
    }
  }, [selectedMarkets]);

  const storeDelisting = async (data, callback) => {
    setSaveAsDraft(true);
    const promises = Object.values(data).map((item) => {
      return axios.put(`delisting/${rfsNumber}`, item);
    });

    await Promise.all(promises);
    setSaveAsDraft(false);

    if (callback) callback();
  };

  const handleNext = (callback = null, type = null) => {
    const errors = validateDelisting(delistingForm);

    setScopeErrors({
      errors: errors.errors,
      delistingErrors: errors.delistingErrors,
    });

    setTimeout(() => {
      if (Object.keys(errors.errors).length > 0 || Object.keys(errors.delistingErrors).length > 0) {
        setInvalidModal({
          open: true,
          callback: callback ? callback : () => setInvalidModal(false),
        });
      } else {
        storeDelisting(delistingForm);

        callback();
      }
    }, 500);
  };

  const closeModal = (scope_id, sku_id) => {
    const currentValues = { ...delistingForm };
    currentValues[scope_id].confirm_obsoletes = false;
    setOpened({ open: false, id: null, item: null });

    setDelistingForm(currentValues);
  };

  const handleConfirm = (scope_id, sku_id) => {
    const currentValues = { ...delistingForm };
    currentValues[scope_id].confirm_obsoletes = true;
    setOpened({ open: false, id: null, item: null });

    setDelistingForm(currentValues);
  };

  const handleDelistingFormChange = (value, name, scope_id) => {
    const currentValues = { ...delistingForm };
    currentValues[scope_id][name] = value;

    if (name === 'delisting_date') currentValues[scope_id].confirm_obsoletes = false;

    setDelistingForm(currentValues);
  };

  const handleCostCenterCreate = (query, id) => {
    handleDelistingFormChange(query, 'cost_centre', id);
  };

  const setSelectedMarkets = (markets, value, scope_id) => {
    const currentValues = { ...delistingForm };
    currentValues[scope_id].impacted_markets = markets;
    currentValues[scope_id].allMarkets = value;
    currentValues[scope_id].confirm_obsoletes = false;
    setDelistingForm(currentValues);
  };

  const Footer = () =>
    props.disableButtons ? null : (
      <Grid>
        <Grid.Col span={2} offset={6}>
          {' '}
          <Link to={routes.submission(params.rfsNumber).scopeSelect}>
            <Button
              variant='filled'
              color='yellow'
              fullWidth
              style={{ background: 'white', color: 'black', border: ' 0.25px solid gray' }}
              size='md'
              radius='sm'
            >
              Back
            </Button>
          </Link>
        </Grid.Col>
        <Grid.Col span={2}>
          <Button
            disabled={saveAsDraft}
            onClick={() => storeDelisting(delistingForm)}
            variant='filled'
            color='yellow'
            fullWidth
            style={{ background: 'black', color: 'white' }}
            size='md'
            radius='sm'
          >
            {!saveAsDraft ? 'Save' : <AppLoader />}
          </Button>
        </Grid.Col>
        <Grid.Col span={2}>
          <Button
            onClick={() =>
              handleNext(
                () =>
                  status === rfsStatus.draft
                    ? navigate(routes.submission(params.rfsNumber).attachment)
                    : navigate(-2),
                rfs_type,
              )
            }
            disabled={loading}
            variant='gradient'
            gradient={{ from: '#e3af32', to: '#f4e00f' }}
            color='yellow'
            size='md'
            fullWidth
          >
            {!loading ? 'Next' : <AppLoader />}
          </Button>
        </Grid.Col>
      </Grid>
    );

  if (infoLoading)
    return (
      <ContentPaper page={'sku-2'}>
        <AppLoader size='md' center />
      </ContentPaper>
    );

  return (
    <ContentPaper page={'sku-2'} footer={<Footer />}>
      {props.withProjectName ? (
        <Row style={{ padding: '1rem' }}>
          <ProjectName Pnumber='AM001' initialItem={props.initialItem} />
        </Row>
      ) : null}

      <Accordion
        style={props.withEdit ? { maxWidth: '100vw' } : { maxWidth: '100vw', minHeight: '100vh' }}
        onChange={(event) => setAccordionState(event)}
      >
        {Object.keys(form?.selectedScope || {}).map((name) =>
          form?.selectedScope[name].map((item, index) => {
            const accordionKey = `accordion_${index}`;

            return (
              <Accordion.Item key={accordionKey} value={accordionKey}>
                <Accordion.Control
                  className={scopeErrors.errors[item.id] ? 'error-label' : ''}
                >{`${name.substring(0, 3).toUpperCase()} - ${item['selection_number']} - ${
                  item['selection_name']
                }`}</Accordion.Control>
                <Accordion.Panel>
                  <Row>
                    <Col
                      md={1}
                      style={{
                        width: '4.1666%',
                        flex: '0 0 4.1666%',
                        maxWidth: ' 4.1666%',
                      }}
                    ></Col>
                    <Col md={{ span: 5, offset: 0 }}>
                      <CostCenter
                        onCreate={(query) => {
                          setCcModal({
                            open: true,
                            callback: handleCostCenterCreate,
                            query: (query || '').trim(),
                            id: item.id,
                          });
                        }}
                        value={delistingForm[item.id]?.cost_centre}
                        onChange={(value) =>
                          handleDelistingFormChange(value, 'cost_centre', item.id)
                        }
                        error={scopeErrors.delistingErrors[item.id]?.cost_centre}
                        name='cost_centre'
                      />

                      {delistingForm[item.id]?.cost_centre?.length > 49 && (
                        <div className='custom-mantine-Textarea-error'>
                          50 Character limit reached, exceeding character would be truncated on
                          saving.
                        </div>
                      )}
                    </Col>

                    <Col md={{ span: 5, offset: 1 }} style={{ marginTop: '8px' }}>
                      <DatePickerInput
                        placeholder='Select Delisting date.'
                        icon={<CalendarIcon color='#e5b611' />}
                        label={
                          <div>
                            De-Listing Date{' '}
                            <Text component='span' style={{ color: 'red' }}>
                              *
                            </Text>
                          </div>
                        }
                        minDate={moment(new Date()).toDate()}
                        maxDate={moment().add(2, 'years').toDate()}
                        name='delisting_date'
                        value={
                          moment(delistingForm[item.id]?.delisting_date).isValid()
                            ? moment(delistingForm[item.id]?.delisting_date).toDate()
                            : null
                        }
                        onChange={(date) => {
                          handleDelistingFormChange(
                            moment(date).format('YYYY-MM-DD'),
                            'delisting_date',
                            item.id,
                          );
                        }}
                        error={scopeErrors.delistingErrors[item.id]?.delisting_date}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col
                      md={1}
                      style={{
                        width: '4.1666%',
                        flex: '0 0 4.1666%',
                        maxWidth: ' 4.1666%',
                      }}
                    ></Col>
                    <Col md={{ span: 2, offset: 0 }} style={{ marginTop: '40px' }}>
                      <div>
                        {' '}
                        <p
                          className='fixedLeft'
                          style={{ marginBottom: '0px', paddingBottom: '0px' }}
                        >
                          Markets <span style={{ color: 'red' }}>*</span>
                        </p>{' '}
                      </div>
                    </Col>

                    {marketLoading ? (
                      <Col
                        className='FixedRight'
                        md={{ span: 2, offset: 1 }}
                        style={{ marginTop: '1rem' }}
                      >
                        {' '}
                        <div
                          style={{
                            marginTop: '25px',
                            marginLeft: '50px',
                          }}
                        >
                          <AppLoader size='xs' />{' '}
                        </div>
                      </Col>
                    ) : (
                      <Col
                        className='FixedRight'
                        md={{ span: 2, offset: 1 }}
                        style={{ marginTop: '1rem' }}
                      >
                        <div
                          style={{
                            marginTop: '25px',
                            marginLeft: '50px',
                          }}
                        >
                          <MarketModal
                            rfsNumber={params.rfsNumber}
                            required
                            title={
                              !delistingForm[item.id]?.impacted_markets.length > 0
                                ? 'Select Markets'
                                : 'Check and change markets'
                            }
                            type={'backupUsers'}
                            scope_id={item.id}
                            sku_id={item['selection_number']}
                            selectedMarkets={delistingForm[item.id]?.impacted_markets}
                            setSelectedMarkets={(markets, value) =>
                              setSelectedMarkets(markets, value, item.id)
                            }
                          />
                        </div>
                        {scopeErrors.delistingErrors[item.id]?.impacted_markets && (
                          <div className='FixedRight error'>Select atleast one market</div>
                        )}
                      </Col>
                    )}

                    <Col md={{ span: 5, offset: 1 }} style={{ marginTop: '40px' }}>
                      <Checkbox
                        label={
                          <div>
                            De-Listing Approved by sales team{' '}
                            <Text component='span' style={{ color: 'red' }}>
                              *
                            </Text>
                          </div>
                        }
                        className='checkbox'
                        color='orange'
                        name='approved_by_sales'
                        checked={delistingForm[item.id]?.approved_by_sales}
                        onChange={(event) =>
                          handleDelistingFormChange(
                            event.target.checked,
                            'approved_by_sales',
                            item.id,
                          )
                        }
                      />
                      {scopeErrors.delistingErrors[item.id]?.approved_by_sales && (
                        <div className='FixedRight error'>This is a required field</div>
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col
                      md={1}
                      style={{
                        width: '4.1666%',
                        flex: '0 0 4.1666%',
                        maxWidth: ' 4.1666%',
                      }}
                    ></Col>
                    <Col md={{ span: 5, offset: 0 }} style={{ marginTop: '1rem' }}>
                      {' '}
                      <Tooltip
                        label='Please select a delisting date and atleast one market to enable this checkbox'
                        placement='start'
                        withArrow
                        wrapLines
                        width={220}
                        className='data'
                      >
                        <Checkbox
                          label={
                            <span
                              style={
                                delistingForm[item.id]?.impacted_markets.length === 0 ||
                                !delistingForm[item.id]?.delisting_date
                                  ? { color: '#BFBFBF' }
                                  : {
                                      textDecoration: 'underline',
                                      color: '#e5b611',
                                    }
                              }
                              className='modalText'
                            >
                              Confirm Obsoletes
                            </span>
                          }
                          className='mantine-bd8giz mantine-Text-root modalText'
                          color='orange'
                          name='confirm_obsoletes'
                          checked={delistingForm[item.id]?.confirm_obsoletes}
                          onChange={(event) => {
                            handleDelistingFormChange(
                              event.target.checked,
                              'confirm_obsoletes',
                              item.id,
                            );
                            setOpened({
                              open: event.target.checked ? true : false,
                              id: item['selection_number'],
                              item: item,
                            });
                          }}
                          disabled={
                            delistingForm[item.id]?.impacted_markets.length === 0 ||
                            !delistingForm[item.id]?.delisting_date ||
                            delistingForm[item.id]?.delisting_date === 'Invalid date'
                          } // Disable the checkbox if no market is selected in the modal or no delisting date is provided
                        />
                      </Tooltip>
                      {scopeErrors.delistingErrors[item.id]?.confirm_obsoletes && (
                        <div className='FixedRight error'>This is a required field</div>
                      )}
                    </Col>
                  </Row>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '10px',
                    }}
                  ></div>
                </Accordion.Panel>
              </Accordion.Item>
            );
          }),
        )}
      </Accordion>

      {opened.open && (
        <Modal
          opened={opened.open}
          onClose={() => closeModal(opened.item.id, opened.item['selection_number'])}
          size='75%'
          title={
            <Text weight={700} size='xl'>
              Confirm Obsoletes
            </Text>
          }
        >
          <ReactTable
            handleMainConfirm={() => handleConfirm(opened.item.id, opened.item['selection_number'])}
            delistingDate={moment(delistingForm[opened.item.id]?.delisting_date).format(
              'YYYY-MM-DD',
            )}
            selectedMarkets={delistingForm[opened.item.id]?.impacted_markets}
            sku_id={opened.item['selection_number']}
            scope_id={opened.item.id}
            item_id={delistingForm[opened.item.id]?.confirm_obsoletes}
            rfsNumber={rfsNumber}
          />
        </Modal>
      )}

      {invalidModal && (
        <InvalidModal
          callback={() =>
            status === rfsStatus.draft
              ? navigate(routes.submission(params.rfsNumber).attachment)
              : navigate(-2)
          }
          invalidModal={invalidModal}
          setInvalidModal={setInvalidModal}
          nextClick={(callback) => storeDelisting(delistingForm, callback)}
        />
      )}

      {ccModal.open && (
        <CCModal
          data={ccModal}
          setCcModal={setCcModal}
          value={delistingForm[ccModal.id]?.cost_centre}
          disabled={props.disabled}
          onClose={() => {
            handleDelistingFormChange(null, 'cost_centre', ccModal.id);
            setCcModal({ open: false, callback: null, query: null, id: null });
          }}
        />
      )}
    </ContentPaper>
  );
}
