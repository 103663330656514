import React from 'react';
import styled from 'styled-components';
import { Paper, MediaQuery } from '@mantine/core';

const Footer = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0px;
  right: 0;
  text-align: right;
  padding: 0.5rem 1.5rem 0.5rem;
  background: #efefef;
  box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.075);
  z-index: 10;
`;

const ContentPaper = ({ children, page = '', footer, submissionReview }) => {
  if (submissionReview) {
    return <div>{children}</div>;
  }

  return (
    <MediaQuery query='(max-width: 576px) ' styles={{ width: '100vw !important' }}>
      <Paper
        shadow='sm'
        radius='md'
        style={{
          backgroundColor: '#EEE',
          height: '100%',
          overflow: 'hidden',
          width: 'calc(100vw - 0)',
        }}
        className={`scroll page-${page.toLowerCase()} custom-paper`}
      >
        <div
          style={
            footer
              ? {
                  padding: '0.5rem 0.5rem 5em 1.5rem',
                  height: '100%',
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  width: '100%',
                }
              : {
                  padding: '0.5rem 1.5rem 0 1.5rem',
                  height: 'calc(100% - 50px)',
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  width: '100%',
                }
          }
        >
          {children}
        </div>

        {footer ? <Footer>{footer}</Footer> : null}
      </Paper>
    </MediaQuery>
  );
};

export default ContentPaper;
