import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import {
  Modal,
  Button,
  Group,
  UnstyledButton,
  Text,
  Input,
  Container,
  Avatar,
  Tabs,
  Grid,
} from '@mantine/core';
import './modal.css';
import { MagnifyingGlassIcon } from '@modulz/radix-icons';
import { CircleCheck } from 'tabler-icons-react';
import { AuthContext } from '../../contexts/AuthContext';
import { RfsContext } from '../../contexts/RfsContext';

function ModalUsers({
  title,
  type,
  selected = [],
  handleUserSelect,
  variant = 'multiSelect',
  error = false,
}) {
  const { users, userEmail, gateKeepers, techLeads, d2pUsers } = useContext(AuthContext);
  const [opened, setOpened] = useState(false);
  const [foundUsers, setFoundUsers] = useState([]);
  const [searchStr, setSearchStr] = useState(null);

  const [selectedUsers_1, setSelectedUsers_1] = useState([]);

  const [selectedFirst, setSelectedFirst] = useState([]);

  useEffect(() => {
    if (opened) setSearchStr(null);
  }, [opened]);

  useEffect(() => {
    if (foundUsers.length === 0 && type === 'gatekeeper') setFoundUsers(gateKeepers);
    else if (foundUsers.length === 0 && type === 'technicalLead') setFoundUsers(techLeads);
    else if (foundUsers.length === 0 && type == 'd2p_lead') setFoundUsers(d2pUsers);
    else if (foundUsers.length === 0) setFoundUsers(users);
  }, [users]);

  const getInitials = (name) => {
    if (name.includes(' ')) {
      const fullName = name.split(' ');
      const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
      return initials;
    } else {
      const initials = name.charAt(0);
      return initials;
    }
  };

  const toggleUser = (user, event) => {
    let selectedUsers = selected ? [...selected] : [];

    const index = (selectedUsers || []).findIndex((val) => val.email === user.email);

    if (variant !== 'multiSelect') {
      selectedUsers = index < 0 ? [user] : [];
    } else {
      if (index > -1) {
        selectedUsers.splice(index, 1);
      } else {
        selectedUsers.push(user);
      }
    }

    handleUserSelect(type, selectedUsers);
    setSelectedUsers_1(selectedUsers);
  };

  const confirmed = () => {
    setOpened(false);
    setSelectedFirst(selectedUsers_1);
    handleUserSelect(type, selectedUsers_1);
  };

  const ModalClosed = () => {
    handleUserSelect(type, selectedFirst);
    setOpened(false);
  };

  const ModalOpened = () => {
    setOpened(true);
  };

  return (
    <div>
      <Modal opened={opened} onClose={() => ModalClosed()} title={title} className='users-modal'>
        <Container>
          <Grid>
            <Grid.Col span={12}>
              <Input
                className='search'
                radius='md'
                onChange={(event) => setSearchStr(event.target.value)}
                value={searchStr}
                icon={<MagnifyingGlassIcon />}
                style={{ margin: '0 4rem' }}
              />
            </Grid.Col>

            <Grid.Col span={12}>
              <Tabs style={{ marginTop: 10 }} defaultValue='All'>
                <Tabs.List>
                  <Tabs.Tab value='All' color='dark'>
                    All
                  </Tabs.Tab>
                  <Tabs.Tab value='Selected' color='dark'>
                    Selected
                  </Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value='All' color='yellow'>
                  <Users
                    getInitials={getInitials}
                    users={foundUsers}
                    toggleUser={toggleUser}
                    type='all'
                    modalType={type}
                    selectedUsers={selected}
                    variant={variant}
                    searchStr={searchStr}
                    userEmail={userEmail}
                  />
                </Tabs.Panel>
                <Tabs.Panel value='Selected' color='dark'>
                  <Users
                    getInitials={getInitials}
                    users={foundUsers}
                    toggleUser={toggleUser}
                    type='selected'
                    modalType={type}
                    selectedUsers={selected}
                    variant={variant}
                    searchStr={searchStr}
                    userEmail={userEmail}
                  />
                </Tabs.Panel>
              </Tabs>
            </Grid.Col>

            <Grid.Col span={4} offset={8} className={'p-0'}>
              <Button
                fullWidth
                variant='filled'
                onClick={() => confirmed()}
                color='yellow'
                style={{
                  background: 'linear-gradient(90deg,#e3af32 0%,#f4e00f 100%)',
                  color: 'black',
                }}
                className='modal-button'
              >
                Confirm
              </Button>
            </Grid.Col>
          </Grid>
        </Container>
      </Modal>

      <Group position='center'>
        <UnstyledButton onClick={() => ModalOpened()}>
          <Text
            style={{
              textDecoration: 'underline',
            }}
            color={error ? 'red !important' : '#E5B611'}
            className='modalText'
          >
            {title}
          </Text>
        </UnstyledButton>
      </Group>
    </div>
  );
}

const Users = ({
  users,
  gateKeepers,
  techLeads,
  getInitials,
  toggleUser,
  type,
  selectedUsers,
  variant,
  searchStr,
  userEmail,
  modalType,
}) => (
  <Grid.Col span={12}>
    <Container style={{ height: '18rem', overflowY: 'auto' }}>
      {users && users.length > 0 ? (
        users
          .filter(
            (user) =>
              (!searchStr ||
                user.email.toLowerCase().includes(searchStr.toLowerCase()) ||
                user.name.toLowerCase().includes(searchStr.toLowerCase())) &&
              (user.email.toLowerCase() !== userEmail.toLowerCase() || modalType === 'gatekeeper'),
          )
          .map((item) =>
            (type === 'selected' &&
              selectedUsers.findIndex((user) => user.email === item.email) > -1) ||
            type === 'all' ? (
              <UnstyledButton
                style={{ display: 'inherit', width: '100%' }}
                onClick={(event) => toggleUser(item, event)}
                color=''
              >
                <Group
                  style={{
                    marginTop: '1rem',
                    justifyContent: 'space-between',
                  }}
                >
                  <Group>
                    <Avatar
                      color='dark'
                      /*style={{
                      backgroundColor: '#cdcdcd',
                      color:'black'}}*/
                      radius='xl'
                    >
                      {getInitials(item.name)}
                    </Avatar>
                    <div>
                      <Text>{item.name}</Text>
                      <Text size='sm' color='dimmed' weight={400}>
                        {item.email}
                      </Text>
                    </div>
                  </Group>
                  {(selectedUsers || []).findIndex((user) => user.email === item.email) > -1 && (
                    <CircleCheck color='green' size='1.5rem' />
                  )}
                </Group>
              </UnstyledButton>
            ) : (
              ''
            ),
          )
      ) : (
        <div style={{ marginTop: '1rem' }}>No results Found</div>
      )}
    </Container>
  </Grid.Col>
);

export default ModalUsers;
