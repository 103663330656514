import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import MantineTable from '../../../../common/table/mantine';
import { Select, TextInput, Button } from '@mantine/core';
import { axios } from '../../../../utils/axios';
import { useParams } from 'react-router-dom';
import { RfsContext } from '../../../../contexts/RfsContext';
import { NpsComponent } from '../../../../common/nps';
import { NpsIds, techFeasibilityPermissions } from '../../../../constants';
import { ImpactedSkus } from './modal';
import { AuthContext } from '../../../../contexts/AuthContext';

export const Zitec = () => {
  const { rfsNumber } = useParams();
  const { form: rfsInfo, info: fetchRfsInfo } = useContext(RfsContext);
  const { userRole } = useContext(AuthContext);
  const [initialData, setInitialData] = useState(rfsInfo.zitecFeedback);
  const [form, setForm] = useState({});
  const [activePlants, setActivePlants] = useState({});
  const [loading, setLoading] = useState(false);
  const [npsOpened, setNpsOpened] = useState(false);
  const [impactedSkus, showImpactedSkus] = useState({ open: false, material: null, plant: null });

  const permissions = techFeasibilityPermissions['Supplier Change'].zitec_approval;
  const readOnly = !permissions.includes(userRole);

  const formRef = useRef(null);
  formRef.current = form;

  useEffect(() => {
    if (rfsInfo.zitecFeedback.length > 0) {
      const currentValues = { ...form };
      rfsInfo.zitecFeedback.forEach((item) => {
        currentValues[item.id] = {
          ...item,
        };
      });
      setInitialData(rfsInfo.zitecFeedback);
      setActivePlants({});
      setForm(currentValues);
    }
  }, [rfsInfo, loading]);

  const resetTableData = () => {
    const currentValues = { ...formRef.current };
    Object.keys(currentValues).forEach((val, key) => {
      if (!currentValues[val].updated_by) {
        currentValues[val]['technically_feasible'] = null;
        currentValues[val]['trials_needed'] = null;
        currentValues[val]['comment'] = '';
      }
    });
    setForm(currentValues);
  };

  const onChange = (value, type, row) => {
    if (row.getIsSelected()) {
      const curentValues = { ...formRef.current };
      if (!curentValues[row.original.id]) curentValues[row.original.id] = {};

      curentValues[row.original.id] = {
        ...curentValues[row.original.id],
        [type]: value,
      };

      if (type === 'technically_feasible' && value === 0) {
        curentValues[row.original.id] = {
          ...curentValues[row.original.id],
          trials_needed: '',
        };
      }
      setForm(curentValues);
    }
  };

  const handleSubmit = (info) => {
    const payload = form[info.id];
    payload['material_id'] = info.material_id;
    payload['supplier_id'] = info.supplier_id;

    setLoading(true);
    axios
      .put(`/supplier-changes/zitec/submit/${rfsNumber}`, payload)
      .then(
        (res) => {
          fetchRfsInfo(rfsNumber);
          setNpsOpened(true);
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() => setLoading(false));
  };

  const isDisabled = (id) => {
    const currentInfo = formRef.current[id];
    if (!currentInfo.technically_feasible && currentInfo.technically_feasible !== 0) {
      return true;
    } else if (
      currentInfo.technically_feasible === 1 &&
      ![0, 1].includes(currentInfo.trials_needed)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'material_code',
        header: 'Material Code',
      },
      {
        accessorKey: 'material_description',
        header: 'Material Description',
      },
      {
        header: 'Plant',
        accessorKey: 'plant',
      },
      {
        header: 'SKUs using the material',
        id: 'impacted_skus',
        Cell: ({ row }) => (
          <Button
            variant='filled'
            color='yellow'
            fullWidth
            style={{ background: 'black', color: 'white' }}
            size='sm'
            radius='sm'
            className='add-supplier-button'
            onClick={() =>
              showImpactedSkus({
                open: true,
                material: row.original.material_code,
                plant: row.original.plant,
              })
            }
          >
            Impacted SKUs
          </Button>
        ),
      },
      {
        header: 'Current Supplier',
        accessorKey: 'current_suppliers',
      },
      {
        header: 'To be supplier',
        accessorKey: 'to_be_supplier',
      },
      {
        header: 'Is the project technically feasible?',
        accessorKey: 'technically_feasible',
        enableEditing: true,
        editVariant: 'select',
        mantineEditSelectProps: {
          data: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
        },
        Cell: ({ row }) => {
          const selected = row.getIsSelected();
          return (
            <Select
              size='sm'
              radius='md'
              onChange={(value) => onChange(value, 'technically_feasible', row)}
              clearable
              data={[
                { label: 'Yes', value: 1 },
                { label: 'No', value: 0 },
              ]}
              disabled={!selected || row.original.updated_by || readOnly}
              value={formRef.current[row.original.id]?.technically_feasible}
              // error={error}
              withinPortal
            />
          );
        },
      },
      {
        header: 'Is trial needed?',
        accessorKey: 'trials_needed',
        enableEditing: true,
        editVariant: 'select',
        mantineEditSelectProps: {
          data: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
        },
        Cell: ({ row }) => {
          const selected = row.getIsSelected();
          return (
            <div className='custom-multi-select'>
              <Select
                size='sm'
                radius='md'
                onChange={(value) => onChange(value, 'trials_needed', row)}
                clearable
                data={[
                  { label: 'Yes', value: 1 },
                  { label: 'No', value: 0 },
                ]}
                disabled={
                  !selected ||
                  row.original.updated_by ||
                  !formRef.current[row.original.id]?.technically_feasible ||
                  formRef.current[row.original.id]?.technically_feasible === 0 ||
                  readOnly
                }
                value={formRef.current[row.original.id]?.trials_needed}
                // error={error}
                withinPortal
              />
            </div>
          );
        },
      },

      {
        header: 'Comment',
        accessorKey: 'comment',
        enableEditing: true,
        editVariant: 'text',
        Cell: ({ row }) => {
          const selected = row.getIsSelected();
          return (
            <TextInput
              radius='md'
              name='comment'
              onChange={(event) => onChange(event.target.value, 'comment', row)}
              style={{
                input: { width: '50px', height: '1rem' },
                width: '20rem',
              }}
              disabled={!selected || row.original.updated_by || readOnly}
              value={formRef.current[row.original.id]?.comment || ''}
              // error={error}
            />
          );
        },
      },
      {
        header: 'Action',
        id: 'action',
        Cell: ({ row }) => {
          return (
            <Button
              onClick={() => handleSubmit(row.original)}
              variant='filled'
              color='yellow'
              fullWidth
              style={{ background: 'black', color: 'white' }}
              size='sm'
              radius='sm'
              disabled={
                !row.getIsSelected() ||
                row.original.updated_by ||
                isDisabled(row.original.id) ||
                readOnly
              }
              className='add-supplier-button'
            >
              Submit
            </Button>
          );
        },
      },
    ],
    [form, activePlants, rfsInfo],
  );

  const handleRowSelection = (data) => {
    let currentValues = { ...activePlants };
    currentValues = { ...data };

    setActivePlants(currentValues);
  };

  const handleDefaultSet = (value, name) => {
    const curentValues = { ...formRef.current };
    value = name === 'comment' ? value : parseInt(value);

    Object.keys(activePlants).forEach((material) => {
      if (activePlants[material]) curentValues[material][name] = value;
    });

    setForm(curentValues);
  };

  return (
    <div>
      <MantineTable
        columns={columns}
        initialData={initialData}
        unique={'id'}
        handleRowSelection={handleRowSelection}
        activeRows={activePlants}
        enableRowSelection={true}
        loading={loading}
        resetTableData={resetTableData}
        editAll={true}
        applyToAll={handleDefaultSet}
        showSelectedToggle={true}
        showResetAll={true}
        hideSelectColumn={false}
        enablePinning={true}
        initialState={{
          showColumnFilters: true,
          density: 'xs',
          columnPinning: {
            left: ['mrt-row-select'],
            right: [''],
          },
        }}
      />

      {npsOpened && (
        <NpsComponent
          rfs_number={rfsNumber}
          opened={npsOpened}
          setOpened={setNpsOpened}
          callback={() => {
            setNpsOpened(false);
          }}
          id={NpsIds().technically_feasible}
        />
      )}

      {impactedSkus.open && <ImpactedSkus state={impactedSkus} setState={showImpactedSkus} />}
    </div>
  );
};
