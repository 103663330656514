import React, { useState, useEffect, useRef, useContext } from 'react';
import ObsoletesMrp from './skuPlanningTable/skuPlanning';
import { Text, Box, Button, Accordion } from '@mantine/core';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { TableExport } from 'tabler-icons-react';
import { axios } from '../../../../utils/axios';
import { tableToArray } from '../helpers';
import { RfsContext } from '../../../../contexts/RfsContext';
import SkuPlanningTable from './skuPlanningTable/skuPlanning';
import { dlstImplPermissions } from '../../../../constants';
import { AuthContext } from '../../../../contexts/AuthContext';
import { sCodeStyles } from '../../../../utils/helpers';

export const SkuPlanning = ({ rfsNumber, activeTab }) => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { userRole } = useContext(AuthContext);
  const { form } = useContext(RfsContext);
  const { rfs_type } = form.rfsInformation;
  const [manualClosureLoading, setManualClosureLoading] = useState(false);

  const fetchSkuPlanning = (rfsNumber) => {
    return axios.get(`delisting/sku_planning/fetch/${rfsNumber}`);
  };

  const storeManualClosure = (payload) => {
    return axios.post(`delisting/manual_closure/sku_planning`, payload);
  };

  const permissions = dlstImplPermissions[rfs_type];

  useEffect(() => {
    if (activeTab === 'sku') {
      setLoading(true);
      axios
      .get(`delisting/sku_planning/fetch/${rfsNumber}`)
      .then(
        (res) => {
          const data = res.data.data.map((s) => {
            s.status = s.status === 'Not Stared' ? 'Not Started' : s.status;
            return s;
          });
          setTableData(data);
        },
        (err) => {
          console.log(err);
        },
      )
      .finally(() => setLoading(false));
    }
  }, [rfsNumber, activeTab]);

  const refresh = (rfs_number) => {
    fetchSkuPlanning(rfs_number).then((res) => {
      setTableData(res.data.data);
    });
  };

  const save = (payload, callback) => {
    setManualClosureLoading(true);
    storeManualClosure(payload)
      .then(() => {
        refresh(rfsNumber);
      })
      .finally(() => {
        setManualClosureLoading(false);
        if (callback) callback();
      });
  };

  const getCsvData = () => {
    const data = [];

    [...tableData].forEach((info) => {
      data.push({
        'SKU number': info.int_rfs_scope_selection?.selection_number,
        Description: info.int_rfs_scope_selection?.selection_name,
        Plants: info.int_rfs_dlst_scope_plant ? info.int_rfs_dlst_scope_plant.plant : 'No Plants',
        Status: info.status,
        Action:
          info.status === 'Closed'
            ? info.manually_closed
              ? 'Manually Closed'
              : 'Automatic Closure'
            : 'NA',
        'Step Deadline': moment(info.sla_date).format('DD-MM-YYYY'),
        'Step Finished': info.finished_date
          ? moment(info.finished_date).format('DD-MM-YYYY')
          : 'Not Finished',
      });
    });

    return data;
  };

  return (
    <div>
      <Box sx={sCodeStyles}>SKU CODES</Box>
      <div style={{ marginTop: '1rem' }} className='exportToCsv-container'>
        <CSVLink
          data={getCsvData()}
          className='export-to-csv'
          filename={'Sku Planning_' + moment().format('YYYY-MM-DD HH:mm:ss')}
        >
          <Button
            leftIcon={<TableExport />}
            color='yellow'
            style={{
              marginBottom: '1rem',
              background: 'black',
            }}
          >
            Export to CSV
          </Button>
        </CSVLink>
      </div>
      <Accordion style={{ maxWidth: '100vw' }}>
        {Object.keys(form?.selectedScope || {}).map((name) =>
          form?.selectedScope[name].map((item, index) => {
            const accordionKey = `accordion_${index}`;
            const accordion = tableData.filter(
              (s) => s.int_rfs_scope_selection.selection_number === item['selection_number'],
            );
            if (accordion.length > 0)
              return (
                <Accordion.Item key={accordionKey} value={accordionKey}>
                  <Accordion.Control>
                    {`${name.substring(0, 3).toUpperCase()} - ${item['selection_number']} - ${
                      item['selection_name']
                    }`}
                  </Accordion.Control>
                  <Accordion.Panel>
                    <SkuPlanningTable
                      data={tableData.filter((s) => s.int_rfs_scope_selection.id === item.id)}
                      loading={loading}
                      manualClosureLoading={manualClosureLoading}
                      save={save}
                      readOnly={!permissions['sku_planning'].includes(userRole)}
                    />
                  </Accordion.Panel>
                </Accordion.Item>
              );
          }),
        )}
      </Accordion>
    </div>
  );
};
