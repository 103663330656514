import React from 'react';
import { useState } from 'react';
import { Modal, Button, Container, Grid, Checkbox, Group, Text } from '@mantine/core';
import { InfoCircle } from 'tabler-icons-react';

import './ToggleModal.css';

function TechSpecModal({ opened, setOpened, confirm }) {
  const [disabled, setDisabled] = useState(true);

  const handleChange = (event) => {
    event.target.checked ? setDisabled(false) : setDisabled(true);
  };

  const handleConfirm = () => {
    confirm({ target: { name: 'tech_change', value: true } });
    setOpened(false);
  };

  return (
    <Modal
      opened={opened}
      onClose={() => setOpened(false)}
      title='Disclaimer'
      className='toggle-modal'
    >
      <Container>
        <Grid>
          <Grid.Col span={12}>
            <Group noWrap className='disclaimer-modal-group'>
              <InfoCircle color='black' size='5rem' />
              <Text size='sm'>
                The Initiator confirms that any technical changes have been aproved via ZMTC / ZTC
                or ZITEC MANCOM - Responsibility on Obsoletes or Business impacts resulting by lack
                of compliance on this point wll be responsibility of Initiator
              </Text>
            </Group>
          </Grid.Col>
          <Grid.Col span={12}>
            <Checkbox
              onChange={handleChange}
              className='modal-checkbox'
              label='I accept the terms and conditions above.'
            ></Checkbox>
          </Grid.Col>
          <Grid.Col span={3} offset={9}>
            <Button
              disabled={disabled}
              variant='gradient'
              gradient={{ from: '#e3af32', to: '#f4e00f' }}
              onClick={handleConfirm}
              fullWidth
            >
              OK
            </Button>
          </Grid.Col>
        </Grid>
      </Container>
    </Modal>
  );
}

export default TechSpecModal;
