import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import MantineTable from '../../../../common/table/mantine';
import { Select, TextInput, Button } from '@mantine/core';
import { axios } from '../../../../utils/axios';
import { useParams } from 'react-router-dom';
import { RfsContext } from '../../../../contexts/RfsContext';
import { NpsIds, rfsStatus, techFeasibilityPermissions } from '../../../../constants';
import { NpsComponent } from '../../../../common/nps';
import { AuthContext } from '../../../../contexts/AuthContext';

export const D2P = () => {
  const { rfsNumber } = useParams();
  const { userRole } = useContext(AuthContext);
  const { form: rfsInfo, info: fetchRfsInfo, updateRfsStatus } = useContext(RfsContext);
  const [initialData, setInitialData] = useState(rfsInfo.d2pFeedback);
  const [form, setForm] = useState({});
  const [activePlants, setActivePlants] = useState({});
  const [loading, setLoading] = useState(false);
  const [npsOpened, setNpsOpened] = useState(false);

  const permissions = techFeasibilityPermissions['Supplier Change'].zitec_approval;
  const readOnly = !permissions.includes(userRole);

  const formRef = useRef(null);
  formRef.current = form;

  useEffect(() => {
    if (rfsInfo.d2pFeedback.length > 0) {
      const currentValues = { ...form };
      rfsInfo.d2pFeedback.forEach((item) => {
        currentValues[item.id] = {
          ...item,
        };
      });

      let statusUpdateCall = rfsInfo.d2pFeedback.findIndex((item) => !item.updated_by);

      if (
        statusUpdateCall === -1 &&
        rfsInfo.rfsInformation.status === rfsStatus.supplier_zitec_active
      ) {
        updateRfsStatus(rfsNumber, 'Final Approval Pending from PMO');
        fetchRfsInfo(rfsNumber);
      }

      setInitialData(rfsInfo.d2pFeedback);
      setActivePlants({});
      setForm(currentValues);
    }
  }, [rfsInfo, loading]);

  const resetTableData = () => {
    const currentValues = { ...formRef.current };
    Object.keys(currentValues).forEach((val, key) => {
      if (!currentValues[val].updated_by) {
        currentValues[val]['artwork_actually_needed'] = null;
        currentValues[val]['artwork_adaption_actually_needed'] = null;
        currentValues[val]['color_target_setting_done'] = null;
        currentValues[val]['comment'] = '';
      }
    });
    setForm(currentValues);
  };

  const onChange = (value, type, row) => {
    if (row.getIsSelected()) {
      const curentValues = { ...formRef.current };
      if (!curentValues[row.original.id]) curentValues[row.original.id] = {};

      curentValues[row.original.id] = {
        ...curentValues[row.original.id],
        [type]: value,
      };

      if (type === 'artwork_actually_needed' && value === 0) {
        curentValues[row.original.id] = {
          ...curentValues[row.original.id],
          artwork_adaption_actually_needed: '',
        };
      }
      setForm(curentValues);
    }
  };

  const handleSubmit = (info, callback) => {
    const payload = form[info.id];
    payload['material_id'] = info.material_id;

    setLoading(true);
    axios
      .put(`/supplier-changes/d2p/submit/${rfsNumber}`, payload)
      .then(
        (res) => {
          setNpsOpened(true);
          fetchRfsInfo(rfsNumber);
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() => setLoading(false));
  };

  const handleRowSelection = (data) => {
    let currentValues = { ...activePlants };
    currentValues = { ...data };

    setActivePlants(currentValues);
  };

  const handleDefaultSet = (value, name) => {
    const curentValues = { ...formRef.current };
    value = name === 'comment' ? value : parseInt(value);

    Object.keys(activePlants).forEach((material) => {
      if (activePlants[material]) curentValues[material][name] = value;
    });

    setForm(curentValues);
  };

  const isDisabled = (id) => {
    const currentInfo = formRef.current[id];
    if (!currentInfo.artwork_actually_needed && currentInfo.artwork_actually_needed !== 0) {
      return true;
    } else if (
      (currentInfo.artwork_actually_needed === 1 &&
        ![0, 1].includes(currentInfo.artwork_adaption_actually_needed)) ||
      ![0, 1].includes(currentInfo.color_target_setting_done)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'material_code',
        header: 'Material Code',
      },
      {
        accessorKey: 'material_description',
        header: 'Material Description',
      },

      {
        header: 'Is Artwork Actually needed?',
        id: 'artwork_actually_needed',
        enableEditing: true,
        editVariant: 'select',
        mantineEditSelectProps: {
          data: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
        },
        Cell: ({ row }) => {
          const selected = row.getIsSelected();
          return (
            <Select
              size='sm'
              radius='md'
              onChange={(value) => onChange(value, 'artwork_actually_needed', row)}
              clearable
              data={[
                { label: 'Yes', value: 1 },
                { label: 'No', value: 0 },
              ]}
              disabled={
                !selected ||
                !row.original.zitec_all_supplier_updated ||
                row.original.updated_by ||
                readOnly
              }
              value={formRef.current[row.original.id]?.artwork_actually_needed}
              // error={error}
              withinPortal
            />
          );
        },
      },
      {
        header: 'Is Artwork Adaptation Actually needed?',
        id: 'artwork_adaption_actually_needed',
        enableEditing: true,
        editVariant: 'select',
        mantineEditSelectProps: {
          data: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
        },
        Cell: ({ row }) => (
          <div className='custom-multi-select'>
            <Select
              size='sm'
              radius='md'
              onChange={(value) => onChange(value, 'artwork_adaption_actually_needed', row)}
              clearable
              data={[
                { label: 'Yes', value: 1 },
                { label: 'No', value: 0 },
              ]}
              disabled={
                !row.getIsSelected() ||
                !row.original.zitec_all_supplier_updated ||
                row.original.updated_by ||
                !formRef.current[row.original.id]?.artwork_actually_needed ||
                readOnly
              }
              value={formRef.current[row.original.id]?.artwork_adaption_actually_needed}
              // error={error}
              withinPortal
            />
          </div>
        ),
      },

      {
        header: 'Is Colour Target Setting Done?',
        id: 'color_target_setting_done',
        enableEditing: true,
        editVariant: 'select',
        mantineEditSelectProps: {
          data: [
            { label: 'Yes', value: '1' },
            { label: 'No', value: '0' },
          ],
        },
        Cell: ({ row }) => (
          <div className='custom-multi-select'>
            <Select
              size='sm'
              radius='md'
              onChange={(value) => onChange(value, 'color_target_setting_done', row)}
              clearable
              data={[
                { label: 'Yes', value: 1 },
                { label: 'No', value: 0 },
              ]}
              disabled={
                !row.getIsSelected() ||
                !row.original.zitec_all_supplier_updated ||
                row.original.updated_by ||
                readOnly
              }
              value={formRef.current[row.original.id]?.color_target_setting_done}
              // error={error}
              withinPortal
            />
          </div>
        ),
      },
      {
        header: 'Comment',
        id: 'comment',
        enableEditing: true,
        editVariant: 'text',
        Cell: ({ row }) => {
          return (
            <TextInput
              radius='md'
              name='comment'
              onChange={(event) => onChange(event.target.value, 'comment', row)}
              style={{
                input: { width: '50px', height: '1rem' },
                width: '20rem',
              }}
              disabled={
                !row.getIsSelected() ||
                !row.original.zitec_all_supplier_updated ||
                row.original.updated_by ||
                readOnly
              }
              value={formRef.current[row.original.id]?.comment}
              // error={error}
            />
          );
        },
      },
      {
        header: 'Action',
        id: 'action',
        Cell: ({ row }) => {
          return (
            <Button
              onClick={() => handleSubmit(row.original)}
              variant='filled'
              color='yellow'
              style={{ background: 'black', color: 'white' }}
              size='sm'
              radius='sm'
              fullWidth
              disabled={
                !row.getIsSelected() ||
                row.original.updated_by ||
                isDisabled(row.original.id) ||
                readOnly
              }
              className='add-supplier-button'
            >
              Submit
            </Button>
          );
        },
      },
    ],
    [form, activePlants, loading, rfsInfo],
  );

  return (
    <div>
      <MantineTable
        columns={columns}
        initialData={initialData}
        unique={'id'}
        handleRowSelection={handleRowSelection}
        activeRows={activePlants}
        enableRowSelection={true}
        loading={loading}
        resetTableData={resetTableData}
        editAll={true}
        applyToAll={handleDefaultSet}
        showSelectedToggle={true}
        showResetAll={true}
        hideSelectColumn={false}
        enablePinning={true}
        initialState={{
          showColumnFilters: true,
          density: 'xs',
          columnPinning: {
            left: ['mrt-row-select'],
            right: [''],
          },
        }}
      />

      {npsOpened && (
        <NpsComponent
          rfs_number={rfsNumber}
          opened={npsOpened}
          setOpened={setNpsOpened}
          callback={() => {
            setNpsOpened(false);
          }}
          id={NpsIds().technically_feasible}
        />
      )}
    </div>
  );
};
