import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Tabs, Accordion, Grid, Button } from '@mantine/core';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { TableExport } from 'tabler-icons-react';
import { CSVLink } from 'react-csv';
import { roles } from '../../../constants';
import { AuthContext } from '../../../contexts/AuthContext';

import ContentPaper from '../../../components/layouts/rfs/Content';
import { NewMaterialCodeTable } from './table';
import { axios } from '../../../utils/axios';
import { AppLoader } from '../../../components/common/loader';
import { Logs } from './logs';
import { ManualClosureModal } from './modal';
import { RfsContext } from '../../../contexts/RfsContext';
import ProductionLog from '../productDevelopment/productionSiteLogModal';
import { BomTable } from './bom';
import { ProcurementTable } from './procurement';
import { StatusTable } from './reversal';
import { tabStyles } from '../../../utils/helpers';

const accessors = {
  materials: 'selection_number',
  liquids: 'selection_number',
  skus: 'selection_number',
};
const secondaryAccessor = {
  materials: 'selection_name',
  liquids: 'selection_name',
  skus: 'selection_name',
};
const tertiaryAccessor = {
  materials: null,
  liquids: null,
  skus: null,
};

const TabsMap = {
  bnmc: 'new_material_code',
  rob: 'removal_of_bom',
  bpt: 'procurement_type',
  sr: 'status_reversal',
};

export default function Reversal({
  new_material_code,
  removal_of_bom,
  procurement_type,
  status_reversal,
}) {
  const { userRole } = useContext(AuthContext);
  const params = useParams();
  const { rfsNumber } = params;
  const { form } = useContext(RfsContext);
  const [logModal, setLogModal] = useState({ open: false, id: '' });
  const [activeTab, setActiveTab] = useState(
    new_material_code
      ? 'bnmc'
      : removal_of_bom
      ? 'rob'
      : procurement_type
      ? 'bpt'
      : status_reversal
      ? 'sr'
      : '',
  );
  const [state, handlers] = useState({});
  const [tabStatus, setTabStatus] = useState({
    new_material_code: [],
    procurement_type: [],
    removal_of_bom: [],
    status_reversal: [],
  });
  const [disable, setDisable] = useState(true);

  useEffect(() => {
    if ([roles.admin, roles.superAdmin, roles.pmo, roles.masterDataTeam].includes(userRole))
      setDisable(false);
  }, [userRole]);

  const [manualClosureModal, setManualClosureModal] = useState({
    open: false,
    rfs_sku_id: null,
    step: null,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getInfo(false, true);
  }, []);

  useEffect(() => {
    handlers({});
  }, [activeTab]);

  const getInfo = (closeModal = false, load = false) => {
    setLoading(load);
    axios
      .get(`/production-site/reversal/info/${rfsNumber}/${TabsMap[activeTab]}`)
      .then(
        (res) => {
          setTabStatus(res.data.status);

          if (closeModal)
            setManualClosureModal({
              open: false,
              rfs_sku_id: null,
              step: null,
            });
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() => setLoading(false));
  };

  const ItemList = ({ type }) => (
    <div className='table-container' style={{ overflowY: 'auto' }}>
      {' '}
      {loading ? (
        <div
          style={{
            minHeight: '40vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <AppLoader size='lg' center />
        </div>
      ) : (
        <Accordion
          value={state}
          onChange={handlers}
          iconPosition='right'
          classNames={{
            label: 'accordion-label',
            contentInner: 'accordion-innerContent',
            control: 'accordion-control',
          }}
        >
          {Object.keys(form?.selectedScope || {}).map((name) =>
            form?.selectedScope[name].map((item, index) => {
              const data = tabStatus[type].filter((m) => m.scope_id === item.id);

              return (
                data.length > 0 && (
                  <Accordion.Item
                    className='scope-label'
                    key={`key_${name}_${index}`}
                    value={`key_${name}_${index}`}
                  >
                    <Accordion.Control>
                      {`${name.substring(0, 3).toUpperCase()} -  ${item[accessors[name]]}- ${
                        item[secondaryAccessor[name]]
                      }${tertiaryAccessor[name] ? `- ${item[tertiaryAccessor[name]]}` : ''}`}
                    </Accordion.Control>
                    <Accordion.Panel>
                      {
                        <Grid>
                          <Grid.Col span={12}>
                            <Tables
                              data={data}
                              setLogModal={setLogModal}
                              setManualClosureModal={setManualClosureModal}
                              type={type}
                              disable={disable}
                            />
                          </Grid.Col>
                        </Grid>
                      }
                    </Accordion.Panel>
                  </Accordion.Item>
                )
              );
            }),
          )}
        </Accordion>
      )}
    </div>
  );

  const sort_index_acctoSKUs = [...tabStatus[TabsMap[activeTab]]].sort(
    (a, b) => a.scope_id - b.scope_id,
  );

  const selectedScopeFn = (data) => {
    let selectedScopeList = {};
    for (let i = 0; i < data.length; i++) {
      selectedScopeList[data[i].id] = {
        selection_number: data[i].selection_number,
        selection_name: data[i].selection_name,
      };
    }
    return selectedScopeList;
  };

  var selectedScopeList = selectedScopeFn(form.selectedScope.skus);

  const DataBlockingMaterial = ({
    int_rfs_ps_sku_reference,
    int_rfs_impl_material_creation,
    status,
    manually_closed,
    sla_date,
    scope_id,
    finished_date,
  }) => {
    return {
      'SKU no.': selectedScopeList[scope_id].selection_number,
      'SKU DESC': selectedScopeList[scope_id].selection_name,
      'New Material Code': int_rfs_impl_material_creation?.new_material_code,
      'New Material Description': int_rfs_impl_material_creation?.new_material_desc,
      Plants: int_rfs_ps_sku_reference?.selected_plant,
      Status: status,
      Action: manually_closed && status === 'Closed' ? 'Manually Closed' : 'NA',
      'Step deadline': sla_date,
      'Step finished': status === 'Closed' ? finished_date : status,
    };
  };
  const CSV_Blocking_NewMaterial = sort_index_acctoSKUs.map(DataBlockingMaterial);

  const DataRemovalBom = ({
    int_rfs_ps_sku_reference,
    status,
    manually_closed,
    sla_date,
    scope_id,
    finished_date,
  }) => {
    return {
      'SKU no.': selectedScopeList[scope_id]?.selection_number,
      'SKU DESC': selectedScopeList[scope_id]?.selection_name,
      Plants: int_rfs_ps_sku_reference?.selected_plant,
      'BOM Alternative': int_rfs_ps_sku_reference?.new_alt_bom,
      Status: status,
      Action: manually_closed && status === 'Closed' ? 'Manually Closed' : 'NA',
      'Step deadline': sla_date,
      'Step finished': status === 'Closed' ? finished_date : status,
    };
  };
  const CSV_Removal_Bom = sort_index_acctoSKUs.map(DataRemovalBom);

  const Data_Procurement = ({
    int_rfs_ps_sku_reference,
    status,
    manually_closed,
    sla_date,
    scope_id,
    finished_date,
  }) => {
    return {
      'SKU no.': selectedScopeList[scope_id]?.selection_number,
      'SKU DESC': selectedScopeList[scope_id]?.selection_name,
      Plants: int_rfs_ps_sku_reference?.selected_plant,
      Status: status,
      Action: manually_closed && status === 'Closed' ? 'Manually Closed' : 'NA',
      'Step deadline': sla_date,
      'Step finished': status === 'Closed' ? finished_date : status,
    };
  };
  const CSV_Procurement = sort_index_acctoSKUs.map(Data_Procurement);

  const Data_StatusReversal = ({
    int_rfs_ps_sku_reference,
    int_rfs_impl_material_creation,
    status,
    manually_closed,
    sla_date,
    scope_id,
    finished_date,
  }) => {
    return {
      'SKU no.': selectedScopeList[scope_id].selection_number,
      'SKU DESC': selectedScopeList[scope_id].selection_name,
      'New Material Code': int_rfs_impl_material_creation?.new_material_code,
      'New Material Description': int_rfs_impl_material_creation?.new_material_desc,
      Plants: int_rfs_ps_sku_reference?.selected_plant,
      Status: status,
      Action: manually_closed && status === 'Closed' ? 'Manually Closed' : 'NA',
      'Step deadline': sla_date,
      'Step finished': status === 'Closed' ? finished_date : status,
    };
  };
  const CSV_StatusReversal = sort_index_acctoSKUs.map(Data_StatusReversal);
  var CSV_StatusReversal_special = [];
  if (activeTab === 'sr') {
    const sort_index_acctoSKUs_reversal_special = [...tabStatus[TabsMap['sr']]].sort(
      (a, b) => a.scope_id - b.scope_id,
    );
    CSV_StatusReversal_special = sort_index_acctoSKUs_reversal_special.map(Data_StatusReversal);
  }
  const Tables = useCallback((props) => {
    const components = {
      new_material_code: <NewMaterialCodeTable {...props} />,
      removal_of_bom: <BomTable {...props} />,
      procurement_type: <ProcurementTable {...props} />,
      status_reversal: <StatusTable {...props} />,
    };
    return components[props.type];
  });

  return (
    <ContentPaper page={'ProductDevelopment'}>
      {Object.values(tabStatus).find((a) => a.length > 0) && !loading ? (
        <Tabs
          value={activeTab}
          onTabChange={setActiveTab}
          variant='unstyled'
          styles={tabStyles}
          className='prod-dev-tabs'
          color='yellow'
          keepMounted={false}
        >
          <Tabs.List>
            {new_material_code && (
              <Tabs.Tab className='topTabs' value='bnmc'>
                <b>Blocking of New Material Code</b>
              </Tabs.Tab>
            )}
            {removal_of_bom && (
              <Tabs.Tab className='topTabs' value='rob'>
                <b>Removal of BOM</b>
              </Tabs.Tab>
            )}
            {procurement_type && (
              <Tabs.Tab className='topTabs' value='bpt'>
                <b>Blocking Procurement Type</b>
              </Tabs.Tab>
            )}
            {status_reversal && (
              <Tabs.Tab className='topTabs' value='sr'>
                <b>Status Reversal</b>
              </Tabs.Tab>
            )}
          </Tabs.List>

          {tabStatus.new_material_code.length > 0 && (
            <Tabs.Panel className='topTabs' value='bnmc'>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'right',
                  marginRight: '4rem',
                }}
              >
                <CSVLink
                  data={CSV_Blocking_NewMaterial}
                  filename={'Blocking of material code_' + moment().format('YYYY-MM-DD HH:mm:ss')}
                >
                  <Button
                    leftIcon={<TableExport />}
                    color='yellow'
                    style={{
                      marginBottom: '1rem',
                      marginTop: '2rem',
                      background: 'black',
                    }}
                  >
                    Export to CSV
                  </Button>
                </CSVLink>
              </div>
              <ItemList type={'new_material_code'} />
              <ProductionLog
                logModal={logModal}
                setLogModal={setLogModal}
                type={'Blocking of New Material Code'}
              />
            </Tabs.Panel>
          )}

          {tabStatus.removal_of_bom.length > 0 && (
            <Tabs.Panel className='topTabs' value='rob'>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'right',
                  marginRight: '4rem',
                }}
              >
                <CSVLink
                  data={CSV_Removal_Bom}
                  filename={'Removal of Bom_' + moment().format('YYYY-MM-DD HH:mm:ss')}
                >
                  <Button
                    leftIcon={<TableExport />}
                    color='yellow'
                    style={{
                      marginBottom: '1rem',
                      marginTop: '2rem',
                      background: 'black',
                    }}
                  >
                    Export to CSV
                  </Button>
                </CSVLink>
              </div>
              <ItemList type={'removal_of_bom'} />
              <ProductionLog
                logModal={logModal}
                setLogModal={setLogModal}
                type={'Removal of BOM'}
              />
            </Tabs.Panel>
          )}

          {tabStatus.procurement_type.length > 0 && (
            <Tabs.Panel className='topTabs' value='bpt'>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'right',
                  marginRight: '4rem',
                }}
              >
                <CSVLink
                  data={CSV_Procurement}
                  filename={
                    'Blocking of Procurement type_' + moment().format('YYYY-MM-DD HH:mm:ss')
                  }
                >
                  <Button
                    leftIcon={<TableExport />}
                    color='yellow'
                    style={{
                      marginBottom: '1rem',
                      marginTop: '2rem',
                      background: 'black',
                    }}
                  >
                    Export to CSV
                  </Button>
                </CSVLink>
              </div>
              <ItemList type={'procurement_type'} />
              <ProductionLog
                logModal={logModal}
                setLogModal={setLogModal}
                type={'Blocking Procurement Type'}
              />
            </Tabs.Panel>
          )}

          {tabStatus.status_reversal.length > 0 && (
            <Tabs.Panel className='topTabs' value='sr'>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'right',
                  marginRight: '4rem',
                }}
              >
                <CSVLink
                  data={
                    CSV_StatusReversal_special.length
                      ? CSV_StatusReversal_special
                      : CSV_StatusReversal
                  }
                  filename={'Status Reversal_' + moment().format('YYYY-MM-DD HH:mm:ss')}
                >
                  <Button
                    leftIcon={<TableExport />}
                    color='yellow'
                    style={{
                      marginBottom: '1rem',
                      marginTop: '2rem',
                      background: 'black',
                    }}
                  >
                    Export to CSV
                  </Button>
                </CSVLink>
              </div>
              <ItemList type={'status_reversal'} />

              <ProductionLog
                logModal={logModal}
                setLogModal={setLogModal}
                type={'Status Reversal'}
              />
            </Tabs.Panel>
          )}
        </Tabs>
      ) : loading ? (
        <AppLoader center size='lg' />
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '100px',
          }}
        >
          No records found
        </div>
      )}

      {manualClosureModal.open && (
        <ManualClosureModal
          manualClosureModal={manualClosureModal}
          setManualClosureModal={setManualClosureModal}
          getInfo={getInfo}
        />
      )}
    </ContentPaper>
  );
}
