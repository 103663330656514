import { Button, Container, Grid, Modal } from '@mantine/core';
import React from 'react';
import { CustomDropzone } from '../dropzone';
import { FileList } from '../dropzone/fileList';
import { AppLoader } from '../../components/common/loader';
import styled from '@emotion/styled';

const SubmitButton = styled(Button)`
  margin-top: 0.8rem;
`;

export const AttachmentsModal = ({
  modal,
  setModal,
  onSubmit,
  onChange,
  onRemove,
  readOnly = false,
  attachmentLoading,
}) => {
  return (
    <Modal
      closeOnClickOutside={false}
      opened={modal.open}
      onClose={() => setModal({ ...modal, open: false })}
      title='Attachment'
      className='toggle-modal1'
    >
      <Container>
        {!readOnly && <CustomDropzone onChange={onChange} />}
        <FileList files={modal.attachments} onRemove={onRemove} readOnly={readOnly} />

        <Grid>
          <Grid.Col span={3} offset={9}>
            <SubmitButton
              color='yellow'
              variant='gradient'
              gradient={{ from: '#e3af32', to: '#f4e00f' }}
              disabled={readOnly}
              onClick={() => onSubmit()}
              fullWidth
            >
              {attachmentLoading ? <AppLoader /> : 'Submit'}
            </SubmitButton>
          </Grid.Col>
        </Grid>
      </Container>
    </Modal>
  );
};
