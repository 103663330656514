import React, { useState, useContext, useEffect } from 'react';
import { Box, Accordion, Grid, Button } from '@mantine/core';

import ContentPaper from '../../../../../components/layouts/rfs/Content';
import { Row } from 'react-bootstrap';
import { RfsContext } from '../../../../../contexts/RfsContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AppLoader } from '../../../../../components/common/loader';
import moment from 'moment';
import { TableExport } from 'tabler-icons-react';
import { axios } from '../../../../../utils/axios';
import checkClosedOrInProgress from '../../../veamHelper';
import BlockingTable from './blockingTable';
import csvDownload from 'json-to-csv-export';

const BlockingVEAM = ({ readOnly, checkClosuresBlocking }) => {
  const { form, fetchBlockingsVeam } = useContext(RfsContext);
  const { rfs_number } = form.rfsInformation;
  const [blockingData, setblockingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [state, handlers] = useState(0);
  const [accState, setAccState] = useState([]);

  const save = (payload, id, callback) => {
    setLoading(true);
    axios.post(`/veam/manual_closure_cancellation_and_scope/`, payload).then(() => {
      setAccState(callback);
      getStatusDetails(rfs_number);
      setLoading(false);
    });
  };

  useEffect(() => {
    getStatusDetails(rfs_number);
  }, [rfs_number]);

  useEffect(() => {
    checkClosuresBlocking(checkClosedOrInProgress(blockingData));
  }, [blockingData]);

  const getStatusDetails = (rfs_number) => {
    setLoading(true);
    fetchBlockingsVeam(rfs_number).then((res) => {
      const response = res.data.data;
      const result = [];

      result['Materials'] = response.filter((item) => item.int_rfs_material);

      result['Liquids'] = response.filter((item) => item.int_rfs_liquid);

      setblockingData(result);
      setLoading(false);
    });
  };

  const downloadExcel = (type) => {
    const dataToExport = {
      data: [],
      filename: '',
      delimiter: ',',
      headers: [],
    };
    const materialsToExport = {
      data: [],
      filename: '',
      delimiter: ',',
      headers: [],
    };
    const liquidHeaders = [
      'New Material Code',
      'New Material Description',
      'Plant',
      'Status',
      'Action',
      'Step Deadline',
      'Step Finished',
    ];
    const materialHeaders = [
      'New Material Code',
      'New Material Description',
      'Plant',
      'Status',
      'Action',
      'Step Deadline',
      'Step Finished',
    ];

    const liquids = blockingData.Liquids;
    const materials = blockingData.Materials;

    if ((liquids || []).length > 0) {
      dataToExport.data = getDataToExport(liquids, 'liquids');

      dataToExport.filename =
        ' Blocking_of_material_code_liquids' + moment().format('YYYY-MM-DD HH:mm:ss');
      dataToExport.headers = liquidHeaders;
      csvDownload(dataToExport);
    }

    if ((materials || []).length > 0) {
      materialsToExport.data = getDataToExport(materials, 'materials');
      materialsToExport.filename =
        'Blocking_of_material_code_materials' + moment().format('YYYY-MM-DD HH:mm:ss');
      materialsToExport.headers = materialHeaders;
      csvDownload(materialsToExport);
    }
  };

  const getDataToExport = (data, type) => {
    const csvArray = type === 'materials' ? data.map(dataToExportM) : data.map(dataToExportL);

    return csvArray;
  };

  const dataToExportM = ({
    int_rfs_impl_material_creation,
    int_rfs_material,
    status,
    manually_closed,
    sla_date,
    finished_date,
  }) => {
    return {
      'New Material Code': int_rfs_impl_material_creation?.new_material_code,
      'New Material Description': int_rfs_impl_material_creation?.new_material_desc,
      Plant: int_rfs_material?.plant,
      Status: status,
      Action:
        status === 'Closed' ? (manually_closed ? 'Manually Closed' : 'Automatic Closure') : 'NA',
      'step Deadline': sla_date,
      'step Finished': status === 'Closed' ? finished_date : status,
    };
  };

  const dataToExportL = ({
    int_rfs_impl_material_creation,
    int_rfs_liquid,
    status,
    manually_closed,
    sla_date,
    finished_date,
  }) => {
    return {
      'New Material Code': int_rfs_impl_material_creation?.new_material_code,
      'New Material Description': int_rfs_impl_material_creation?.new_material_desc,
      Plant: int_rfs_liquid?.plant,
      Status: status,
      Action:
        status === 'Closed' ? (manually_closed ? 'Manually Closed' : 'Automatic Closure') : 'NA',
      'step Deadline': sla_date,
      'step Finished': status === 'Closed' ? finished_date : status,
    };
  };

  if (loading) {
    return (
      <ContentPaper page={'ProductDevelopment'}>
        <AppLoader size='lg' center />
      </ContentPaper>
    );
  }

  return (
    <Row>
      <div
        style={{
          display: 'flex',
          justifyContent: 'right',
        }}
      >
        <Button
          leftIcon={<TableExport />}
          onClick={() => {
            downloadExcel();
          }}
          color='yellow'
          style={{
            marginTop: '1rem',
            background: 'black',
          }}
        >
          Export to CSV
        </Button>
      </div>

      <Accordion id='outside' state={state} onChange={handlers}>
        {Object.keys(blockingData) &&
          Object.keys(blockingData).map((name, index) => {
            return (
              Object.values(blockingData)[index].length > 0 && (
                <Accordion.Item className='scope-label' label={`${name}`} key={`key_${index}`}>
                  <Grid>
                    <Grid.Col span={12}>
                      <BlockingTable
                        statusDataList={Object.values(blockingData)[index]}
                        type={name}
                        save={save}
                        accState={accState}
                        readOnly={readOnly}
                      />
                    </Grid.Col>
                  </Grid>
                </Accordion.Item>
              )
            );
          })}
      </Accordion>
    </Row>
  );
};

export default BlockingVEAM;
