import React, { createContext } from 'react';
import { axios } from '../utils/axios';

export const AdminContext = createContext();

export const AdminProvider = ({ children }) => {
  const fetchNewUsers = () => {
    return axios.get(`/register/fetch`, { params: { type: 'new' } });
  };

  const fetchExistingUsers = (type = 'existing') => {
    return axios.get(`/register/fetch`, { params: { type: 'existing' } });
  };

  const updateTable = (payload) => {
    return axios.put(`/register/update`, payload);
  };

  const deleteEntry = (payload) => {
    return axios.delete(`/register/delete`, { data: payload });
  };

  return (
    <AdminContext.Provider
      value={{
        fetchNewUsers,
        fetchExistingUsers,
        updateTable,
        deleteEntry,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};
