import React from 'react';
import ReactDOM from 'react-dom';
import { Notifications } from '@mantine/notifications';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RfsProvider } from './contexts/RfsContext';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './utils/authConfig';
import { AuthProvider } from './contexts/AuthContext';
import { CommonProvider } from './contexts/common';
import { ToastContainer, toast } from 'react-toastify';
import { MantineProvider } from '@mantine/core';

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.StrictMode>
    <MantineProvider>
      <MsalProvider instance={msalInstance}>
        <AuthProvider>
          <CommonProvider>
            <RfsProvider>
              <Notifications />
              <App style={{ documentbodystylezoom: '80%' }} />
            </RfsProvider>
          </CommonProvider>
        </AuthProvider>
      </MsalProvider>
    </MantineProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
