import React, { useContext, useState, useEffect, useCallback } from 'react';
import { Button } from '@mantine/core';

import { AuthContext } from '../../contexts/AuthContext';
import { roles } from '../../constants';
import { axios } from '../../utils/axios';
import CustomGanttChart from '../../components/gantt';
import GanttModal from '../../components/modal/ganttModal';
import { ProjectOverviewContext } from '../../contexts/projectOverviewContext';

export const DelistingGantt = ({ rfsNumber, trials, editorOptions }) => {
  const { userRole } = useContext(AuthContext);
  const [editModal, setEditModal] = useState(false);
  const [data, setData] = useState();
  const [SLAWeeksData, setSLAWeeksData] = useState();
  const { getSLAWeeksIdsForRFS } = useContext(ProjectOverviewContext);
  const [slaWeeksIds, setSlaWeeksIds] = useState([]);

  const fetchSLAWeeksIds = (rfs_number) => {
    rfs_number =
      rfs_number === undefined
        ? window.location.href.substring(window.location.href.lastIndexOf('/') + 1).split('?')[0]
        : rfs_number;
    getSLAWeeksIdsForRFS(rfs_number).then((res) => {
      let sla_weeks_ids = [];
      res.data.data.map(
        (item) =>
          sla_weeks_ids.includes(item.sla_weeks_id) === false &&
          sla_weeks_ids.push(item.sla_weeks_id),
      );
      setSlaWeeksIds(sla_weeks_ids);
    });
  };

  const fetch = useCallback(() => {
    axios.get(`/delisting/gantt/${rfsNumber}`).then((res) => {
      setData(res.data.steps);
    });
  }, []);

  const fetchSLAWeeksData = useCallback(() => {
    axios.get(`/delisting/gantt/sla_weeks/${trials ? 1 : 0}`).then((res) => {
      setSLAWeeksData(res.data.data);
    });
  }, []);

  useEffect(() => {
    if (!editModal) fetch();
  }, [editModal]);

  useEffect(() => {
    fetchSLAWeeksData();
    fetchSLAWeeksIds(rfsNumber);
  }, []);

  const checkWithSLAWeeksData = (arr) => arr.reduce((a, c) => a + slaWeeksIds.includes(c), 0) === 1;

  const handleRowClick = (check) => {
    if (check) {
      setEditModal(true);
    }
  };

  const editAccess = () => {
    return [roles.pmo, roles.superAdmin].includes(userRole);
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '16px',
          marginTop: '16px',
        }}
      >
        <h5 className='sectionHeadings'>Project Gantt</h5>
        {editAccess() && (
          <Button
            style={{ maxWidth: '200px', background: 'black', color: 'white' }}
            color='yellow'
            size='sm'
            variant='filled'
            fullWidth
            radius='sm'
            onClick={() => handleRowClick(true)}
          >
            Edit
          </Button>
        )}
      </div>

      <div>
        <CustomGanttChart data={data} options={editorOptions} />
        {editModal && (
          <GanttModal
            ganttData={data}
            editModal={editModal}
            setEditModal={setEditModal}
            rfs_number={rfsNumber}
            type={'delisting'}
            steps={SLAWeeksData}
          />
        )}
      </div>
    </div>
  );
};
