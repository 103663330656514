import { Button, Select, NumberInput } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { PercentTable } from './percentReportTable';
import { axios } from '../../utils/axios';

const initialFilters = {
  fast_track: 'all',
  gatekeeper: '',
  year: null,
};

const PercentReport = ({ downloadExcel }) => {
  const [gatekeeperOptions, setGatekeeperOptions] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [target, setTarget] = useState(90);
  const [filteredRows, setFilteredRows] = useState([]);
  const [footerData, setFooterData] = useState({});

  useEffect(() => {
    function setGatekeepers() {
      axios
        .get('/reports/gatekeepers')
        .then(
          (res) => {
            let gatekeepers = res.data.data;

            const data = {};
            gatekeepers.forEach((column) => {
              if (!data[column.email])
                data[column.email] = { value: column.email, label: column.name };
            });

            const options = Object.values(data);
            options.unshift({ label: 'Nothing Selected', value: '' });
            setGatekeeperOptions(options);
          },
          (err) => {},
        )
        .finally(() => {});
    }

    if (gatekeeperOptions.length === 0) setGatekeepers();
  }, []);

  return (
    <div>
      <Header
        filters={filters}
        setFilters={setFilters}
        downloadExcel={downloadExcel}
        filteredRows={filteredRows}
        gatekeepers={gatekeeperOptions}
        target={target}
        footerData={footerData}
      />
      <Target handleChange={setTarget} value={target} />

      {filters.year && (
        <PercentTable
          filters={filters}
          target={target}
          filteredRows={filteredRows}
          setFilteredRows={setFilteredRows}
          footerData={footerData}
          setFooterData={setFooterData}
        />
      )}
    </div>
  );
};

const Target = ({ handleChange, value }) => {
  const [tempTarget, setTempTarget] = useState(value);

  return (
    <div
      className='downLoadBtn'
      style={{
        marginBottom: '1rem',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <NumberInput
        hideControls
        name='Target'
        label='Target'
        value={tempTarget}
        onChange={setTempTarget}
        min={1}
        max={100}
        w={200}
        size='md'
        style={{ marginRight: '1rem' }}
      />

      <Button
        onClick={() => {
          handleChange(tempTarget);
        }}
        disabled={!tempTarget || tempTarget < 0}
        className='custom-mantine-button'
        color='yellow'
        style={{
          marginTop: '1.8rem',
          height: '2.5rem',
          background: 'linear-gradient(90deg, rgb(227, 175, 50) 0%, rgb(244, 224, 15) 100%)',
          color: 'black',
        }}
      >
        Apply
      </Button>
    </div>
  );
};

const Header = ({
  filters,
  setFilters,
  downloadExcel,
  gatekeepers,
  filteredRows,
  target,
  footerData,
}) => {
  const [tempFilters, setTempFilters] = useState(filters);
  const [yearOptions, setYearOptions] = useState([]);
  const [defaultYear, setDefaultYear] = useState(null);

  useEffect(() => {
    const fetchYear = () => {
      axios.get('/reports/submissionDate').then((res) => {
        let response = res.data.data;
        const options = [];
        response.forEach((val) =>
          options.push({ label: val.year.toString(), value: val.year.toString() }),
        );
        setYearOptions(options);
        setFilters((o) => {
          return {
            ...o,
            year: options[options.length - 1].value,
          };
        });
        setTempFilters((o) => {
          return {
            ...o,
            year: options[options.length - 1].value,
          };
        });

        setDefaultYear(options[options.length - 1].value);
      });
    };

    fetchYear();
  }, []);

  const onChange = (name, value) => {
    if (!name) setTempFilters(filters);
    setTempFilters((o) => {
      return {
        ...o,
        [name]: value,
      };
    });
  };

  const makeExcelData = () => {
    const data = [];

    const filterData = (row, percent = true) => {
      return {
        Teams: row.Teams,
        Target: percent ? `${target}%` : null,
        Total: row.total && percent ? `${row.total}%` : null,
        January: row.January && percent ? `${row.January}%` : row.January,
        February: row.February && percent ? `${row.February}%` : row.February,
        March: row.March && percent ? `${row.March}%` : row.March,
        April: row.April && percent ? `${row.April}%` : row.April,
        May: row.May && percent ? `${row.May}%` : row.May,
        June: row.June && percent ? `${row.June}%` : row.June,
        July: row.July && percent ? `${row.July}%` : row.July,
        August: row.August && percent ? `${row.August}%` : row.August,
        September: row.September && percent ? `${row.September}%` : row.September,
        October: row.October && percent ? `${row.October}%` : row.October,
        November: row.November && percent ? `${row.November}%` : row.November,
        December: row.December && percent ? `${row.December}%` : row.December,
      };
    };

    filteredRows.forEach((row) => {
      const filteredRow = filterData(row);
      data.push(filteredRow);

      row.subRows.forEach((subrow) => {
        const filteredSubrow = filterData(subrow);
        data.push(filteredSubrow);

        subrow.subRows.forEach((rfs) => {
          const filteredRfs = filterData(rfs, false);
          data.push(filteredRfs);
        });
      });
    });
    data.push({
      Teams: 'Total Performance',
      Target: `${target}%`,
      Total: footerData.total ? `${footerData.total}%` : null,
      January: footerData.January ? `${footerData.January}%` : footerData.January,
      February: footerData.February ? `${footerData.February}%` : footerData.February,
      March: footerData.March ? `${footerData.March}%` : footerData.March,
      April: footerData.April ? `${footerData.April}%` : footerData.April,
      May: footerData.May ? `${footerData.May}%` : footerData.May,
      June: footerData.June ? `${footerData.June}%` : footerData.June,
      July: footerData.July ? `${footerData.July}%` : footerData.July,
      August: footerData.August ? `${footerData.August}%` : footerData.August,
      September: footerData.September ? `${footerData.September}%` : footerData.September,
      October: footerData.October ? `${footerData.October}%` : footerData.October,
      November: footerData.November ? `${footerData.November}%` : footerData.November,
      December: footerData.December ? `${footerData.December}%` : footerData.December,
    });

    downloadExcel(data);
  };

  return (
    <div
      className='downLoadBtn'
      style={{
        marginBottom: '1rem',
        marginTop: '1rem',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Button
        onClick={() => {
          makeExcelData();
        }}
        color='yellow'
        size='md'
        style={{
          marginTop: '1.8rem',
          height: '2.5rem',
          background: 'linear-gradient(90deg, rgb(227, 175, 50) 0%, rgb(244, 224, 15) 100%)',
          color: 'black',
        }}
      >
        Download CSV
      </Button>

      <Select
        size='md'
        color='yellow'
        w={200}
        label='Select Gatekeeper'
        placeholder='Select Gatekeeper'
        onChange={(value) => {
          onChange('gatekeeper', value);
        }}
        data={gatekeepers}
        value={tempFilters?.gatekeeper}
        sx={{
          '.mantine-Select-dropdown': {
            backgroundColor: '#ffffff !important',
            zIndex: '1',
          },
        }}
        searchable
        clearable
        style={{ height: '3.6rem !important', marginLeft: '1rem' }}
      />

      <Select
        size='md'
        color='yellow'
        w={160}
        label='Select Year'
        placeholder='Select Year'
        onChange={(value) => {
          onChange('year', value);
        }}
        data={yearOptions}
        value={tempFilters?.year || ''}
        sx={{
          '.mantine-Select-dropdown': {
            backgroundColor: '#ffffff !important',
            zIndex: '1',
          },
        }}
        searchable
        style={{ height: '3.6rem', marginLeft: '1rem' }}
      />

      <Select
        size='md'
        color='yellow'
        w={160}
        label='Fastrack'
        placeholder='Fastrack'
        onChange={(value) => {
          onChange('fast_track', value);
        }}
        data={[
          { label: 'All', value: 'all' },
          { value: 'true', label: 'Fast Track' },
          { value: 'false', label: 'Not Fast Track' },
        ]}
        value={tempFilters?.fast_track}
        sx={{
          '.mantine-Select-dropdown': {
            backgroundColor: '#ffffff !important',
            zIndex: '1',
          },
        }}
        searchable
        style={{
          height: '3.6rem',
          color: 'rgb(33 37 41 / 66%)',
          marginLeft: '1rem',
          marginRight: '1rem',
        }}
      />

      <Button
        onClick={() => {
          setFilters(tempFilters);
        }}
        color='yellow'
        style={{
          marginTop: '1.8rem',
          height: '2.5rem',
          background: 'linear-gradient(90deg, rgb(227, 175, 50) 0%, rgb(244, 224, 15) 100%)',
          color: 'black',
        }}
      >
        Apply
      </Button>
      <Button
        onClick={() => {
          setFilters({ ...initialFilters, year: defaultYear });
          setTempFilters({ ...initialFilters, year: defaultYear });
        }}
        color='default'
        size='md'
        style={{
          marginTop: '1.8rem',
          height: '2.5rem',
          marginLeft: '1rem',
          background: 'rgb(251 250 248)',
          color: 'black',
        }}
      >
        Reset
      </Button>
    </div>
  );
};

export default PercentReport;
