import React, { useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import style from './navItem.module.css';
import NavItemHeader from './NavItemHeader';

const NavItem = ({ item }) => {
  const location = useLocation();
  const { label, Icon, to, children } = item;

  if (children) {
    return <NavItemHeader item={item} />;
  }

  const isActive = (data) => {
    if (location.pathname.includes(data)) return 'activeNavItem';
  };

  return (
    <NavLink
      to={to}
      className={`${style.navItem} ${isActive(to)}`}
      // activeClassName={style.activeNavItem}
    >
      <Icon className={style.navIcon} />
      <span className={style.navLabel}>{label}</span>
    </NavLink>
  );
};

export default NavItem;
