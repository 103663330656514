import React, { useState, useMemo, useContext } from 'react';
import { useTable } from 'react-table';
import moment from 'moment';
import { Text, Button, Select, Textarea } from '@mantine/core';
import { AppLoader } from '../../../../components/common/loader';
import { Styles } from '../../../../components/tables/skutable/production-site/styles';
import { ProductionLog } from '../productionSiteLogModal';
import { VeamAffectedSKUs } from '../veamaffectedSKUsModal';
import { RfsContext } from '../../../../contexts/RfsContext';
import { useRef } from 'react';
import { AuthContext } from '../../../../contexts/AuthContext';

export default function TrialsTableVEAM({ trialsData, onChange, save, name, readOnly }) {
  const [filterLoading, setFilterLoading] = useState(false);
  const data = trialsData;

  const [logModal, setLogModal] = useState({ open: false, id: '' });
  const [imp_id, setImp_id] = useState(0);
  const { submitTrialsVEAM, submitTrialsCommentsVEAM, form, createVEAMTrialLog } =
    useContext(RfsContext);
  const { userEmail } = useContext(AuthContext);
  const { rfs_number } = form.rfsInformation;
  const a = 1;

  const [SKUModal, setSKUModal] = useState({ open: false, id: '' });
  const [MaterialID, setMaterialID] = useState(0);

  const reason = {};

  const [forms, setForm] = useState({
    ids: 0,
    reason: '',
    comment: '',
  });
  const formRef = useRef(null);
  formRef.current = forms;

  const [results, setResults] = useState({});
  const resultRef = useRef(null);
  resultRef.current = results;

  const handleChangeResult = (value, id) => {
    const selectTarget = document.querySelector(
      '#i' + Object.keys(resultRef?.current)[0] + ' .mantine-Select-rightSection button',
    );
    if (selectTarget) selectTarget.click();
    setResults({ [id]: value });
  };

  const handleChange = (name, value, id) => {
    const currentValues = { ...forms };
    currentValues[name] = value;
    currentValues['ids'] = id;
    setForm({ [id]: currentValues });

    if (name === 'reason' && value) {
      reason[id] = value;
    }
  };

  function getLogs(imp_id) {
    setLogModal({ open: true, id: imp_id });
    setImp_id(imp_id);
  }

  function postTrialsComments(id, ids, name, value) {
    let payload = {
      status_id: id,
      result: resultRef.current[id] ? resultRef.current[id] : '',
      reason: reason[id] ? reason[id] : '',
      comment: formRef.current[id]?.comment,
    };
    submitTrialsCommentsVEAM(payload, rfs_number).then(() => save());
    onChange(id, name, value);
  }

  function postTrials(type, id, scope_id, name, value, callback) {
    let payload = {
      type: type,
      status_id: id,
      scope_id: scope_id,
      result: resultRef.current[id] ? resultRef.current[id] : '',
      reason: reason[id] ? reason[id] : '',
    };
    submitTrialsVEAM(payload, rfs_number).then(async () => {
      save();
      createVEAMTrialLog({
        id: id,
        email: userEmail,
        comment: formRef.current[id]?.comment,
      });
      if (callback) await callback();
    });
    onChange(id, name, value);
    onChange(id, 'trials_result', resultRef.current[id]);
  }

  const ImpactedSKUs = (id) => {
    return (
      <Button dfdf={id} onClick={() => getImpactedSKUs(id)} style={{ background:'black',color: 'white'}}>
        View SKUs
      </Button>
    );
  };

  const getImpactedSKUs = (id) => {
    setSKUModal({ open: true, id: id });
    setMaterialID(id);
  };

  let columns = useMemo(
    () => [
      {
        Header: `${name.charAt(0).toUpperCase() + name.slice(1)} Code`,
        Cell: ({ row }) => {
          return (
            <Text>
              {name === 'liquids'
                ? row.original.Trials_Liquids
                : row.original.int_rfs_material.material_code +
                  ' ' +
                  row.original.int_rfs_material.material_description}
            </Text>
          );
        },
      },
      {
        Header: 'Result',
        Cell: ({ row }) => {
          const isDisabled = row.original.int_rfs_impl_trial?.trials_result;
          const showSubmit =
            resultRef.current[row.original.id] && resultRef.current[row.original.id] === 'Success';

          return isDisabled ? (
            <Text>{row.original.int_rfs_impl_trial?.trials_result}</Text>
          ) : (
            <div id={'i' + row.original.id}>
              <Select
                placeholder='Success/Fail'
                data={[
                  { value: 'Success', label: 'Success' },
                  { value: 'Fail', label: 'Fail' },
                ]}
                onChange={(event) => handleChangeResult(event, row.original.id)}
                disabled={row.original.status === 'Not Started' || readOnly}
                clearable
              />
              {showSubmit && (
                <Button
                  style={{
                    padding: '10px',
                    marginTop: '10px',
                    background:'black',
                    color:'white'
                  }}
                  onClick={() => {
                    postTrials(
                      row.original.int_rfs_scope_selection.selection_type,
                      row.original.id,
                      row.original.int_rfs_scope_selection.id,
                      'trials_result',
                      resultRef.current[row.original.id],
                    );
                  }}
                  position='Right'
                  size='sm'
                  color='yellow'
                  radius='xs'
                  fullWidth
                  disabled={readOnly}
                >
                  Submit
                </Button>
              )}
            </div>
          );
        },
      },
      {
        Header: 'Reason of Failure',
        Cell: ({ row }) => {
          const isDisabled = row.original.int_rfs_impl_trial?.trials_failure_reason;
          const showSubmit =
            formRef.current[row.original.id]?.reason &&
            formRef.current[row.original.id]?.reason?.length > 0;
          return isDisabled ? (
            <Text>{row.original.int_rfs_impl_trial?.trials_failure_reason}</Text>
          ) : (
            <>
              <Select
                placeholder='Cancellation/Scope Restatement'
                data={[
                  { value: 'Cancellation', label: 'Cancellation' },
                  {
                    value: 'Scope Restatement',
                    label: 'Scope Restatement',
                  },
                ]}
                clearable
                disabled={resultRef.current[row.original.id] !== 'Fail' || readOnly}
                onChange={(event) => handleChange('reason', event, row.original.id)}
              />
              {/* showSubmit && (
                <Button
                  style={{
                    padding: '10px',
                    marginTop: '10px',
                  }}
                  position='Right'
                  onClick={() =>
                    postTrials(
                      row.original.int_rfs_scope_selection.selection_type,
                      row.original.id,
                      row.original.int_rfs_scope_selection.id,
                      'trials_failure_reason',
                      formRef.current[row.original.id]?.reason,
                    )
                  }
                  size='sm'
                  color='yellow'
                  radius='xs'
                  fullWidth
                  disabled={readOnly}
                >
                  Submit
                </Button>
              ) */}
            </>
          );
        },
      },
      {
        Header: 'Comments',
        Cell: ({ row }) => {
          const isDisabled = row.original.int_rfs_impl_trial?.trials_comment;
          const showSubmit =
            formRef.current[row.original.id]?.comment &&
            formRef.current[row.original.id]?.comment?.length > 0;

          return isDisabled ? (
            <Text>{row.original.int_rfs_impl_trial?.trials_comment}</Text>
          ) : (
            <>
              <Textarea
                disabled={
                  //row.original.int_rfs_impl_trial?.trials_result !== 'Fail' ||
                  resultRef.current[row.original.id] !== 'Fail' || readOnly
                }
                onChange={(event) =>
                  handleChange(
                    'comment',
                    (event.target.value || '').trim().length === 0
                      ? event.target.value.trim()
                      : event.target.value,
                    row.original.id,
                  )
                }
                value={formRef.current[row.original.id]?.comment}
                placeholder='Your comment'
              />
              {showSubmit && (
                <Button
                  style={{
                    padding: '10px',
                    marginTop: '10px',
                    background:'black',
                    color:'white'
                  }}
                  position='Right'
                  size='sm'
                  onClick={() => {
                    postTrials(
                      row.original.int_rfs_scope_selection.selection_type,
                      row.original.id,
                      row.original.int_rfs_scope_selection.id,
                      'trials_failure_reason',
                      formRef.current[row.original.id]?.reason,
                      () =>
                        postTrialsComments(
                          row.original.id,
                          row.original.ids,
                          'trials_comment',
                          formRef.current[row.original.id]?.comment,
                        ),
                    );
                  }}
                  color='yellow'
                  radius='xs'
                  fullWidth
                  disabled={readOnly}
                >
                  Submit
                </Button>
              )}
            </>
          );
        },
      },
      {
        Header: 'Logs',
        Cell: ({ row }) => {
          return (
            <Button onClick={() => getLogs(row.original.id)}style={{ background: 'black', color:'white' }}>
              View Logs
            </Button>
          );
        },
      },
      {
        Header: 'Step Deadline',
        Cell: ({ row }) => {
          return <Text>{moment(row.original.sla_date).format('YYYY-MM-DD')}</Text>;
        },
      },
      {
        Header: 'Step Finished',
        Cell: ({ row }) => {
          return (
            <Text>
              {row.original.finished_date
                ? moment(row.original.finished_date).format('YYYY-MM-DD')
                : row.original.status}
            </Text>
          );
        },
      },
    ],
    [a],
  );

  if (name === 'materials') {
    columns.splice(1, 0, {
      Header: 'Plant',
      Cell: ({ row }) => {
        return <Text>{row.original.int_rfs_material?.plant}</Text>;
      },
    });

    columns.splice(2, 0, {
      Header: 'Impacted SKUs',
      Cell: ({ row }) => {
        return ImpactedSKUs(row.original.material_id);
      },
    });
  }

  const tableInstance = useTable({
    columns,
    data,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;
  let arr = [],
    arr2 = [];
  let newRows = rows.filter((item) => {
    if (arr.includes(item.original.id) === false) {
      arr.push(item.original.id);
      return item;
    }
  });

  newRows = newRows.filter((item) => {
    if (arr2.includes(item.original.int_rfs_impl_trial.id) === false) {
      arr2.push(item.original.int_rfs_impl_trial.id);
      return item;
    }
  });

  return (
    <Styles>
      <div style={{ overflowX: 'scroll' }} className='limitTDWidth'>
        <table style={{ width: 'fitContent' }} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} id='header-row'>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    let stile = cell.column.Header === 'Result' ? { minWidth: '10rem' } : {};
                    return (
                      <td style={stile} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {filterLoading && (
              <tr>
                <td colSpan={13}>
                  <div className='loader-container'>
                    <AppLoader size={'md'} />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {logModal && (
        <ProductionLog logModal={logModal} id={imp_id} setLogModal={setLogModal} type='Trials' />
      )}

      {logModal && (
        <VeamAffectedSKUs SKUModal={SKUModal} setSKUModal={setSKUModal} MaterialID={MaterialID} />
      )}
    </Styles>
  );
}
