import React, { useEffect } from 'react';
import { useTable, usePagination, useFilters, useSortBy } from 'react-table';
import { ChevronDown, ChevronUp, ArrowsSort } from 'tabler-icons-react';
import { TextInput, Switch, Button } from '@mantine/core';
import styled from 'styled-components';
import { Styles } from './Styles';
import { TablePagination } from '../../../components/tables/SKUDetailsTable/Pagination';
import { PaginationContainer } from '../../../components/tables/SKUDetailsTable/Styles';

const ButtonContainer = styled.div`
  position: relative;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Table = ({
  columns,
  data,
  isSubmissionReview,
  showOnlySelected,
  setOnlyShowSelected,
  rightButton = false,
  buttonLabel = null,
  onButtonClick = null,
  selections = true,
  displaySizeSelect = false,
}) => {
  const DefaultColumnFilter = ({ column: { filterValue, setFilter } }) => {
    return isSubmissionReview ? null : (
      <TextInput
        disabled={isSubmissionReview}
        value={filterValue || ''}
        placeholder={'Search'}
        className='liquidComment'
        size='sm'
        name='comment'
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
        id='fname'
      />
    );
  };

  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    [],
  );

  const handleToggle = (status) => {
    setOnlyShowSelected(status);
  };

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    [],
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;

  useEffect(() => {
    setPageSize(5);
  }, [setPageSize]);

  return (
    <>
      {(selections || rightButton) && (
        <ButtonContainer>
          {selections && (
            <Switch
              className='my-selections'
              label='My Selections'
              size={'md'}
              color='dark'
              checked={showOnlySelected}
              onChange={(event) => handleToggle(event.currentTarget.checked)}
            />
          )}
          {rightButton && (
            <Button
              color='yellow'
              variant='gradient'
              gradient={{ from: '#e3af32', to: '#f4e00f' }}
              radius='sm'
              onClick={() => onButtonClick()}
            >
              {buttonLabel}
            </Button>
          )}
        </ButtonContainer>
      )}
      <div style={{ marginBottom: '12px' }}></div>
      <div style={{ overflowX: 'auto', marginBottom: '10px' }}>
        <Styles>
          <table style={{ minWidth: '70vw', maxWidth: '70vw' }} {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    return (
                      <td
                        style={{
                          minWidth: column.minWidth,
                          width: column.width,
                        }}
                      >
                        {column?.columns?.length > 0 ? (
                          column.render('Header')
                        ) : (
                          <>
                            <span
                              {...column.getHeaderProps(column.getSortByToggleProps())}
                              style={{
                                boxShadow: 'none',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              {column.render('Header')}
                              {!column.disableSortBy && (
                                <>
                                  {column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <ChevronDown className='sort-icon' />
                                    ) : (
                                      <ChevronUp className='sort-icon' />
                                    )
                                  ) : column.id === 'checkbox' ? null : (
                                    <ArrowsSort className='sort-icon' />
                                  )}
                                </>
                              )}
                            </span>
                            {/* Keeping this to support table handled with canFilter. Need to remove in future */}
                            {!column.hasOwnProperty('filter') && (
                              <div>{column.canFilter ? column.render('Filter') : null}</div>
                            )}

                            {column.hasOwnProperty('filter') && (
                              <div>{column.filter ? column.render('Filter') : null}</div>
                            )}
                          </>
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Styles>
      </div>
      <PaginationContainer>
        <TablePagination
          total={data.length}
          page={page}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageOptions={pageOptions}
          pageCount={pageCount}
          gotoPage={gotoPage}
          nextPage={nextPage}
          previousPage={previousPage}
          setPageSize={setPageSize}
          pageIndex={pageIndex}
          pageSize={pageSize}
          displaySize={displaySizeSelect}
        />
      </PaginationContainer>
    </>
  );
};
