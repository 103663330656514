import React from 'react';
import RfsLayout from '../../components/layouts/RfsLayout';
import AllRfsComp from '../../components/allrfs/allrfs';

export default function AllRfsPage(props) {
  return (
    <RfsLayout>
      <AllRfsComp {...props} />
    </RfsLayout>
  );
}
