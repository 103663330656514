import React, { useEffect, useState, useContext } from 'react';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import {
  Button,
  Tooltip,
  TextInput,
  Select,
  MantineProvider,
  useMantineTheme,
} from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { RfsContext } from '../../contexts/RfsContext';
import { IconSearch, IconSearchOff, IconRefresh, ChevronsDown } from '@tabler/icons-react';
import csvDownload from 'json-to-csv-export';
import moment from 'moment';

const IndivisualStepsTeamsReport = ({
  columns,
  setValidationErrors,
  statusData,
  unique,
  enableEditing,
}) => {
  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({ data_id: false });
  const [filteredRows, setFilteredRows] = useState([]);
  const globalTheme = useMantineTheme;

  //Custom search

  const [gatekeepers, setGatekeepers] = useState([]);
  const [yeardropdown, setYearfilter] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchOn, setSearchOn] = useState(false);

  const [datas, setData] = useState([]);
  const [successData, setSuccessData] = useState([]);
  const { fetchIndividualStepdata } = useContext(RfsContext);
  const [enabled, setEnabled] = useState(false);
  const [expandAll, setExpandAll] = useState(true);
  const [toBeSearched, setToBeSearched] = useState('');
  const [showDescription, setDescription] = useState(true);
  const [rawData, setRawData] = useState();
  let fullscreen = false;

  //READ hook (get data in api)
  function useGet() {
    let gatekeper =
      statusData.selectedStepGatekeeper == 'Nothing Selected'
        ? ''
        : statusData.selectedStepGatekeeper;
    let fastrack =
      statusData.selectedFastrack == 'all' ? 2 : statusData.selectedFastrack == 'true' ? 1 : 0;

    const payload = {
      gatekeeper: gatekeper,
      year: statusData.selectedYear,
      fastrack: fastrack,
      stepname: statusData.selectedStepname,
      month: statusData.selectedStepmonth,
    };

    return useQuery({
      queryKey: ['fetchIndividualStepdata', payload],
      queryFn: () => fetchIndividualStepdata(payload),
      refetchOnWindowFocus: false,
      enabled: enabled,
    });
  }

  //call READ hook
  const { data: fetchedData = [], isError, isFetching, isLoading, status, refetch } = useGet();

  function formatDataWithHeading(indivisualdata) {
    return groupBy(indivisualdata, 'rfs_id');
  }

  function getRfsNumber(rfsNumber) {
    rfsNumber = rfsNumber.split('_');
    return rfsNumber[1];
  }

  function groupBy(arr, key) {
    let i = 0,
      groupdata = [];
    let condition = false;
    arr.reduce((result, currentrow) => {
      let heading = {};
      if (currentrow.status === 'On Time' && currentrow.reference_sku_number == null) {
        // heading.title = `SKU ${currentrow.sku_number} ${statusData.selectedStepname} (RefSKU# n/a)`;
        heading.title = `RFS ${getRfsNumber(currentrow.rfs_id)} - ${currentrow.rfs_title}`;
        heading.color = 'gray';
        currentrow.color = 'gray';
        condition = true;
      } else if (currentrow.status === 'Late' && currentrow.reference_sku_number == null) {
        heading.title = `RFS ${getRfsNumber(currentrow.rfs_id)} - ${currentrow.rfs_title}`;
        heading.color = 'red';
        condition = true;
        currentrow.color = 'pink';
      } else {
        condition = false;
      }

      if (condition === true) {
        let k = currentrow[key];
        let entry = result[k];
        if (entry) {
          entry.push(currentrow);
        } else {
          result[k] = [currentrow];
          heading['subRows'] = result[k];
          groupdata[i] = heading;
          groupdata[i]['data_id'] = i + 1;
          i++;
        }
      } else {
        groupdata[i] = currentrow;
        groupdata[i]['data_id'] = i + 1;
        i++;
      }

      return result;
    }, {});
    return groupdata;
  }

  useEffect(() => {
    if (status == 'success' && !isFetching) {
      let data = fetchedData.data.data ? formatDataWithHeading(fetchedData.data.data) : [];
      setRawData(fetchedData.data.data);
      setData(data);
      setSuccessData(data);
      setFilteredRows(data);
      setEnabled(false);
    }
  }, [status, isFetching]);

  useEffect(() => {
    setEnabled(true);
    setColumnVisibility({ data_id: false });
  }, []);

  const resetAll = () => {
    setSearchOn(false);
    //setAllSelected(false);
    setRowSelection({});
    // setAllEditOn(false);
    // setSwitchChecked(false);
    setData(successData);
    setFilteredRows(successData);
  };

  const applyFullScreen = () => {
    fullscreen = !fullscreen;
  };

  const showAndHideDescription = () => {
    setDescription(!showDescription);
  };

  //Custom Search
  const filterMultiSearch = (event) => {
    const input = event.target.value.split(' ');
    const myArrayFiltered = (rawData || []).filter((el) => {
      return input.some((f) => {
        return el[toBeSearched]?.toString().toLowerCase().includes(f.toLowerCase());
      });
    });
    let formattedData = formatDataWithHeading(myArrayFiltered);
    setData(input.length > 0 ? formattedData : successData);
    setFilteredRows(input.length > 0 ? formattedData : successData);
    if (input[0] == '') {
      setData(successData);
    }
  };

  //Excel Download------------
  const downloadExcel = (type) => {
    const dataToExport = {
      data: [],
      filename: '',
      delimiter: ',',
      headers: [],
    };

    const exportdata = removeFirstElement(datas);
    const notUndefined = (anyValue) => typeof anyValue !== 'undefined';
    const headers = columns
      .map((v, i) => {
        if (v !== undefined && v.header != 'data_id' && v.header != 'title') {
          return v.header;
        }
      })
      .filter(notUndefined);
    dataToExport.data = getDataToExport(exportdata);
    dataToExport.filename = 'Download_Report_' + moment().format('YYYY-MM-DD HH:mm:ss');
    dataToExport.headers = headers;
    csvDownload(dataToExport);
  };

  const removeFirstElement = (data) => {
    let newarray = [];
    data = data.map((item, index) => {
      Object.keys(item).forEach(function (field) {
        if (item[field] == null || item[field] == 'N/A' || item[field] == 'null') {
          item[field] = '--';
        }
      });
      if (item['data_id']) delete item['data_id'];
      if (item['color']) delete item['color'];
      if (item['subRows']) {
        let subrow = item['subRows'].map((items, indexs) => {
          Object.keys(items).forEach(function (field) {
            if (items[field] == null || items[field] == 'N/A' || items[field] == 'null') {
              items[field] = '--';
            }
          });
          if (items['color']) delete items['color'];
          return items;
        });
        newarray.push(...subrow);
        item = {};
      }

      return item;
    });
    Array.prototype.push.apply(data, newarray);
    return data.filter((obj) => !(obj && Object.keys(obj).length === 0));
  };

  const getDataToExport = (data) => {
    const csvArray = data.map(dataToExport);
    return csvArray;
  };

  const dataToExport = (data) => {
    return Object.values(data).map((i, index) => i);
  };

  const table = useMantineReactTable({
    columns,
    data: datas,
    enableEditing: enableEditing,
    enableExpanding: true,
    enableExpandAll: true,
    paginateExpandedRows: true,
    getRowId: (row) => row[unique],
    enableGlobalFilter: false,
    enableSelectAll: true,
    enableFullScreenToggle: fullscreen,
    enableStickyHeader: true,
    filterFromLeafRows: true,
    enablePagination: false,
    enableBottomToolbar: false,
    initialState: {
      expanded: expandAll,
      showColumnFilters: true,
      density: 'xs',
    },
    mantineToolbarAlertBannerProps: isError
      ? {
          color: 'red',
          children: 'Error loading data',
        }
      : undefined,
    mantineTableContainerProps: {
      sx: {
        minHeight: '400px',
      },
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    onEditingRowCancel: () => setValidationErrors({}),
    renderTopToolbarCustomActions: ({ table }) => {
      return (
        <>
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <Tooltip label='Reset All'>
              <IconRefresh onClick={resetAll} />
            </Tooltip>

            <div
              onClick={() => {
                if (searchOn) setFilteredRows(successData);
                setSearchOn(searchOn ? false : true);
              }}
            >
              <Tooltip label='Search All'>{searchOn ? <IconSearchOff /> : <IconSearch />}</Tooltip>
            </div>
          </div>

          {searchOn && (
            <div className='search-Group'>
              <div className='searchGroup' style={{ display: 'flex' }}>
                <>
                  <Select
                    label='Select The Column To Search'
                    placeholder='Select The Column'
                    onChange={(value) => {
                      setToBeSearched(value);
                    }}
                    data={columns
                      .filter((c) => c.accessorKey !== unique && c.accessorKey !== 'title')
                      .map((column) => {
                        return { value: column.accessorKey, label: column.header };
                      })}
                    style={{ position: 'relative', zIndex: '1' }}
                  />
                  <TextInput
                    clearable
                    label='&nbsp;'
                    placeholder='Search'
                    onChange={(event) => filterMultiSearch(event)}
                    style={{ position: 'relative', zIndex: '0' }}
                    disabled={toBeSearched === ''}
                  />
                </>
              </div>
            </div>
          )}
        </>
      );
    },
    state: {
      isLoading: isLoading,
      showAlertBanner: isError,
      showProgressBars: isFetching,
      rowSelection,
      columnVisibility,
    },
    onRowSelectionChange: setRowSelection,
    onColumnVisibilityChange: setColumnVisibility,
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: '', //change header text
      },
      'mrt-row-expand': {
        header: '', //change header text
      },
      'mrt-row-data_id': {
        header: '',
        visibleInShowHideMenu: false,
        enableHiding: true,
      },
      'mrt-row-numbers': {
        enableHiding: true, //now row numbers are hidable too
      },
    },
    getSubRows: (originalRow) => originalRow.subRows,
  });

  return (
    <>
      <div
        className='downLoadBtn'
        style={{
          marginBottom: '1rem',
          marginTop: '1rem',
          display: 'flex',
          justifyContent: 'right',
        }}
      >
        <Button
          onClick={() => {
            downloadExcel();
          }}
          color='yellow'
          size='md'
          className='indivisualstepsbutton'
        >
          Download CSV
        </Button>
      </div>
      <MantineProvider theme={{ ...globalTheme, primaryColor: 'yellow', primaryShade: 8 }}>
        <MantineReactTable table={table} enablePinning />
      </MantineProvider>
    </>
  );
};

export default IndivisualStepsTeamsReport;
