import React, { useContext, useState } from 'react';
import { Modal, Button, Grid, Group, Text } from '@mantine/core';
import { Checkbox } from '@mantine/core';
import { routes } from '../../routes';
import { useNavigate, useParams } from 'react-router-dom';

import { RfsContext } from '../../contexts/RfsContext';
import { InfoCircle } from 'tabler-icons-react';
import { AppLoader } from '../common/loader';

export const AttachmentModal = ({ invalidModal, setInvalidModal }) => {
  const params = useParams();
  const navigate = useNavigate();
  const { loading, saveAsDraft, addAttachments } = useContext(RfsContext);
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleClose = () => {
    setInvalidModal(false);
  };

  const handleNext = (callback) => {
    addAttachments(callback);
    navigate(routes.submission(params.rfsNumber).review);
  };

  return (
    <>
      <Modal
        className='Invalid-modal'
        opened={invalidModal}
        onClose={() => handleClose()}
        title='Warning'
      >
        <Grid>
          <Grid.Col span={12}>
            <Group noWrap className='invalid-modal-group'>
              <InfoCircle color='red' size='5rem' />
              <Text size='sm'>
                You have not uploaded all the mandatory files. If you wish to continue without
                uploading, please select the checkbox below.
              </Text>
            </Group>
          </Grid.Col>
          <Grid.Col span={12}>
            <Checkbox
              label='I have read the above and wish to continue anyway'
              name='confirmed'
              onChange={handleChange}
            />
          </Grid.Col>
          <Grid.Col span={4} offset={8}>
            <Button
              onClick={() => handleNext()}
              variant='gradient'
              gradient={{ from: '#e3af32', to: '#f4e00f' }}
              color='yellow'
              fullWidth
              disabled={!checked || loading || saveAsDraft}
              style={{ marginRight: '1rem' }}
            >
              {!loading && !saveAsDraft ? 'Next' : <AppLoader />}
            </Button>
          </Grid.Col>
        </Grid>
      </Modal>
    </>
  );
};
