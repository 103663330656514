import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Button, Text, SimpleGrid, Grid, Accordion, Textarea } from '@mantine/core';
import { SkuTable } from '../../tables/skutable/skuTable';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import LiquidTable from '../../tables/liquidsTable/liquidsTable';
import MaterialTable from '../../tables/materialTable/materialTable';
import ConfirmModal from '../../../components/modal/ConfirmModal';
import SubmitProjectModal from '../../modal/SubmitProjectModal';
import { AppLoader } from '../../common/loader/index';

import ProjectName from '../../projectName/projectName';

import { minWordsValidation } from './rfsInitiationForm';
import 'bootstrap/dist/css/bootstrap.min.css';
import './rfsInitiationForm.css';
import { routes } from '../../../routes/index';
import { RfsContext } from '../../../contexts/RfsContext';
import ContentPaper from '../../layouts/rfs/Content';
import { Pencil } from 'tabler-icons-react';
import { AuthContext } from '../../../contexts/AuthContext';
import { NpsIds, NpsTitle, liquidTypes, rfsStatus, roles } from '../../../constants';
import withConfirm from '../../common/confirm-dialog';
import TechFeasibilityModal from '../../modal/techFeasibilityModal/modal';
import { ProductionSite } from '../../tables/skutable/production-site';
import { axios } from '../../../utils/axios';

import { NpsComponent } from '../../../common/nps';

const accessors = {
  materials: 'selection_number',
  liquids: 'selection_number',
  skus: 'selection_number',
};
const secondaryAccessor = {
  materials: 'selection_name',
  liquids: 'selection_name',
  skus: 'selection_name',
};
const tertiaryAccessor = {
  materials: null,
  liquids: null,
  skus: null,
};

function Rfsinitiation2(props) {
  const location = useLocation();
  const { confirm, setConfirmLoading } = props;
  const [confirmModal, setConfirmModal] = useState(false);
  const [submitProjectModal, setSubmitProjectModal] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const [tfModal, setTfModal] = useState(false);
  const [scopeErrors, setScopeErrors] = useState({
    errors: {},
    liquidErrors: {},
    materialErrors: {},
    productionSiteErrors: {},
  });
  const [artworkRequired, setArtworkRequired] = useState(false);
  const [liquidRequired, setLiquidRequired] = useState(false);
  const [vis1, setVis1] = useState(true);
  const [accordionState, setAccordionState] = useState({});

  const [npsOpened, setNpsOpened] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const {
    form,
    downloadFile,
    attachmentLoading,
    MoveToSubmit,
    infoLoading,
    validateScope,
    deleteRfs,
  } = useContext(RfsContext);
  const { userRole, userEmail } = useContext(AuthContext);

  const {
    initiator_email = null,
    project_name = '',
    initiator_position = '',
    initiator_team = '',
    backupUsers = [],
    allowedUsers = [],
    first_shipment = '',
    sales_business_unit = [],
    technicalLead = [],
    fast_track = '',
    capex_needed = '',
    capex_amount,
    gatekeeper = [],
    cost_center = '',
    confidential,
    country_spoc = [],
    project_purpose = '',
    project_details = '',
    comments = '',
    trials_needed = '',
    country_legal_spoc = '',
    status,
    rfs_type,
    impactedMarkets = [],
    impactedPlants = [],
  } = form.rfsInformation;
  const {
    artworkBriefing,
    fastTrackApproval,
    capexEmailApproval,
    emailAttachment,
    repacking,
    liquidAttachment,
  } = form.attachments;
  const scopeSelectedSkus = form.scopeSelectedSkus;
  const psScope =
    form.rfsInformation.rfs_type === 'Production Site' && form.selectedScope.skus.length === 0;
  const ostmScope =
    form.rfsInformation.rfs_type === 'Open SKU to market' && form.selectedScope.skus.length === 0;
  const veamScope =
    form.rfsInformation.rfs_type === 'Article Modification/Value Engineering' &&
    form.selectedScope.liquids.length === 0 &&
    form.selectedScope.materials.length === 0;

  const repack = Object.values(scopeSelectedSkus).some((item) => item.production == 'Repacking');

  const { scopeSelectedMaterials = [], scopeSelectedLiquids = [] } = form;
  const [productionErrors, setProductionErrors] = useState([]);

  const artworkRequiredRef = useRef(null);
  const liquidRequiredRef = useRef(null);
  artworkRequiredRef.current = artworkRequired;
  liquidRequiredRef.current = liquidRequired;

  useEffect(() => {
    if (
      !!form.rfsInformation &&
      !!form.rfsInformation.rfs_type &&
      form.rfsInformation.rfs_type === 'Open SKU to market'
    )
      setVis1(false);
  }, [form.rfsInformation.rfs_type]);

  useEffect(() => {
    if (scopeSelectedMaterials.length > 0) {
      const index = scopeSelectedMaterials.findIndex((material) => material.artwork_change);
      setArtworkRequired(index > -1);
    }
  }, [scopeSelectedMaterials]);

  useEffect(() => {
    if (scopeSelectedLiquids.length > 0) {
      const index = scopeSelectedLiquids.findIndex(
        (liquid) =>
          [liquidTypes.new, liquidTypes.add].includes(liquid.liquid_type) && !liquid.liquid_number,
      );
      setLiquidRequired(index > -1);
    }
  }, [scopeSelectedLiquids]);
  useEffect(() => {
    if (
      form.rfsInformation &&
      form.rfsInformation.rfs_type &&
      form.rfsInformation.rfs_type === 'Open SKU to market'
    )
      setVis1(false);
  }, [form.rfsInformation.rfs_type]);

  useEffect(() => {
    //Rediret to home after submission
    if (submitted && !npsOpened && userRole === roles.initiator) navigate('/');
    if (submitted && userRole !== roles.initiator) navigate('/');
  }, [submitted, npsOpened]);

  const getAffectedSKUs = (material) => {
    let skus = form.selectedAffectedSkus.filter((val) => parseInt(val.scope_id) === material.id);

    return skus;
  };

  const getLiquids = (liquid) => {
    let liquids = form.scopeSelectedLiquids.filter((val) => parseInt(val.scope_id) === liquid.id);

    return liquids;
  };

  const getMaterials = (material) => {
    let materials = form.scopeSelectedMaterials.filter(
      (val) => parseInt(val.scope_id) === material.id,
    );

    return materials;
  };

  const getUsers = (type) => {
    if (type === 'allowed') {
      let users = (allowedUsers || []).map((item) => item.name);
      return users?.join(', ');
    } else if (type === 'backup') {
      let users = (backupUsers || []).map((item) => item.name);
      return users?.join(', ');
    } else if (type === 'gatekeeper') {
      let users = (gatekeeper || []).map((item) => item.name);
      return users?.join(', ');
    } else if (type === 'technical') {
      let users = (technicalLead || []).map((item) => item.name);
      return users?.join(', ');
    } else {
      return null;
    }
  };

  const getImpactedMarkets = () => {
    let markets = (impactedMarkets || []).map((item) => item);
    return markets?.join(', ');
  };

  const getImpactedPlants = () => {
    let plants = (impactedPlants || []).map((item) => item);
    return plants?.join(', ');
  };

  const FileList = ({ type, files = [] }) => {
    let { rfsNumber } = props;
    rfsNumber = rfsNumber.replace(/[^a-zA-Z0-9]/g, '');

    return (files || []).length > 0 ? (
      <ul style={{ fontSize: '0.8rem' }}>
        {files.map((file, index) => (
          <li
            style={{
              color: '#e5b611',
              cursor: 'pointer',
            }}
            key={index}
            onClick={() => downloadFile(file.name, rfsNumber.toLowerCase())}
          >
            {file.name}
          </li>
        ))}
      </ul>
    ) : (
      <p
        style={
          (type === 'artworkBriefing' &&
            Boolean(artworkRequired) &&
            artworkBriefing.length === 0) ||
          (type === 'liquidAttachment' &&
            Boolean(liquidRequired) &&
            liquidAttachment.length === 0) ||
          (type === 'fastTrackApproval' && Boolean(fast_track) && fastTrackApproval.length === 0) ||
          (type === 'capexEmailApproval' &&
            Boolean(capex_needed) &&
            capexEmailApproval.length === 0) ||
          (type === 'repacking' && Boolean(repack) && repacking.length === 0)
            ? { fontSize: '0.8rem', color: 'red' }
            : { fontSize: '0.8rem' }
        }
      >
        {' '}
        No Files Attached{' '}
      </p>
    );
  };

  const isDisabled = (type) => {
    const OSKU = type === 'Open SKU to market';
    const VEAM = type === 'Article Modification/Value Engineering';
    const PRODSITE = type === 'Production Site';

    return (
      !initiator_position ||
      !initiator_team ||
      backupUsers.length === 0 ||
      !first_shipment ||
      (!PRODSITE && !sales_business_unit) ||
      (!PRODSITE && (sales_business_unit || []).length === 0) ||
      (!OSKU && !trials_needed) ||
      minWordsValidation(project_purpose) ||
      minWordsValidation(project_details) ||
      (Boolean(fast_track) && fastTrackApproval.length === 0) ||
      (OSKU && !country_legal_spoc) ||
      ((VEAM || PRODSITE) && impactedMarkets.length === 0) ||
      gatekeeper?.length === 0 ||
      ((VEAM || PRODSITE || OSKU) && !cost_center) ||
      ((VEAM || PRODSITE) && (!technicalLead || technicalLead.length === 0)) ||
      (PRODSITE && confidential && (!allowedUsers || allowedUsers.length === 0)) ||
      (PRODSITE && capex_needed && (!capex_amount || capex_amount.length === 0)) ||
      ((VEAM || PRODSITE) &&
        ((Boolean(artworkRequiredRef.current) && artworkBriefing.length === 0) ||
          (Boolean(liquidRequiredRef.current) && liquidAttachment.length === 0) ||
          (Boolean(capex_needed) && capexEmailApproval.length === 0) ||
          (Boolean(repack) && repacking.length === 0)))
    );
  };

  const setSubmissionDateAndStatus = () => {
    setConfirmLoading(true);

    axios
      .put(
        `/rfs/submit/${params.rfsNumber}`,
        {},
        {
          headers: {
            rfsId: form.rfsInformation.id,
          },
        },
      )
      .then(
        (res) => {
          setConfirmLoading(false);
          setConfirmModal(true);
        },
        (error) => {
          setConfirmLoading(false);
        },
      );
  };

  const handleSubmit = useCallback(() => {
    handleSubmit1();
  }, [confirm]);

  const handleSubmit1 = (type = form.rfsInformation.rfs_type) => {
    const VEAM = type === 'Article Modification/Value Engineering';
    const errors = validateScope();
    setScopeErrors(errors);

    setTimeout(() => {
      if (
        Object.keys(errors.errors).length > 0 ||
        Object.keys(errors.liquidErrors).length > 0 ||
        Object.keys(errors.materialErrors).length > 0 ||
        Object.keys(errors.productionSiteErrors).length > 0
      ) {
        return setSubmitProjectModal(true);
      } else if (isDisabled(type)) {
        return setSubmitProjectModal(true);
      } else {
        return confirm(
          () => {
            setSubmissionDateAndStatus();
          },
          {
            title: 'Confirm!',
            description: VEAM
              ? 'Are you sure you want to submit this project? Once submitted you will not be able to access it again!'
              : "Please confirm all legal /country specific mentions, required for the new markets on packaging of the SKU's, are correct and packaging does not need any artwork change.\n Once project is submitted you will not be able to access it again!",
            confirmationText: 'Submit',
          },
        );
      }
    }, 500);
  };

  const editScope = () => {
    let access = false;
    if (!props.disableButtons) {
      if (
        (initiator_email === userEmail && status === rfsStatus.draft) ||
        userRole === roles.superAdmin
      ) {
        access = true;
      }
    }

    return access;
  };

  const handleDelete = useCallback(() => {
    confirm(() => deleteRfs(() => navigate(routes.rfs.my)), {
      title: 'Confirm!',
      description:
        'Are you sure you want to delete this project? Once deleted you will not be able to access it again!',
      confirmationText: 'Delete',
    });
  }, [confirm]);

  const canDelete = () => {
    return userEmail?.toLowerCase() === initiator_email?.toLowerCase();
  };

  const Footer = () => (
    <Row className='justify-content-md-end' md={4}>
      <Col md='auto'>
        {canDelete() && !infoLoading && (
          <Button
            className='delete'
            color='red'
            radius='md'
            size='md'
            fullWidth
            style={{ marginRight: '1rem', color: 'white', width: '200px' }}
            onClick={() => handleDelete()}
          >
            Delete Project
          </Button>
        )}
      </Col>
      <Col md='auto'>
        <Button
          className='submit'
          color='yellow'
          variant='gradient'
          gradient={{ from: '#e3af32', to: '#f4e00f' }}
          radius='md'
          size='md'
          fullWidth
          style={{
            marginRight: '1rem',
            width: '200px',
          }}
          onClick={handleSubmit}
        >
          Submit Project
        </Button>
      </Col>
    </Row>
  );

  const OpenSkuDetails = () => (
    <>
      <Row>
        <Col
          md={1}
          style={{
            width: '4.1666%',
            flex: '0 0 4.1666%',
            maxWidth: ' 4.1666%',
          }}
        ></Col>
        {/* <Col className="Empty" md={1} style={{width:'50%'}}></Col> */}

        <Col md={{ span: 5, offset: 0 }}>
          <SimpleGrid cols={2}>
            <Text size='sm' weight={600} color={!initiator_position ? 'red' : ''}>
              Initiator Position:
            </Text>
            <Text size='sm' weight={400}>
              {initiator_position}
            </Text>
          </SimpleGrid>
        </Col>

        <Col md={{ span: 5 }}>
          <SimpleGrid cols={2}>
            <Text size='sm' weight={600} color={!initiator_team ? 'red' : ''}>
              Initiator Team:
            </Text>
            <Text size='sm' weight={400}>
              {initiator_team}
            </Text>
          </SimpleGrid>
        </Col>
      </Row>

      <Row>
        <Col
          md={1}
          style={{
            width: '4.1666%',
            flex: '0 0 4.1666%',
            maxWidth: ' 4.1666%',
          }}
        ></Col>

        <Col md={{ span: 5, offset: 0 }}>
          <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
            <Text size='sm' weight={600} color={(backupUsers || []).length === 0 ? 'red' : ''}>
              Selected Backup Users:
            </Text>
            <Text size='sm' weight={400}>
              {getUsers('backup') === '' ? 'No users selected' : getUsers('backup')}
            </Text>
          </SimpleGrid>
        </Col>

        <Col md={{ span: 5, offset: 0 }}>
          <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
            <Text size='sm' weight={600} color={gatekeeper?.length === 0 ? 'red' : ''}>
              Selected Gatekeeper:
            </Text>
            <Text size='sm' weight={400}>
              {getUsers('gatekeeper') === '' ? 'No users selected' : getUsers('gatekeeper')}
            </Text>
          </SimpleGrid>
        </Col>
      </Row>

      <Row>
        <Col
          md={1}
          style={{
            width: '4.1666%',
            flex: '0 0 4.1666%',
            maxWidth: ' 4.1666%',
          }}
        ></Col>

        <Col md={{ span: 5, offset: 0 }}>
          <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
            <Text size='sm' weight={600}>
              Request for Fast Track:
            </Text>
            <Text size='sm' weight={400}>
              {fast_track ? 'Yes' : 'No'}
            </Text>
          </SimpleGrid>
        </Col>

        <Col md={{ span: 5, offset: 0 }}>
          <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
            <Text size='sm' weight={600} color={!first_shipment ? 'red' : ''}>
              First Shipment Date:
            </Text>
            <Text size='sm' weight={400}>
              {first_shipment}
            </Text>
          </SimpleGrid>
        </Col>
      </Row>

      <Row>
        <Col
          md={1}
          style={{
            width: '4.1666%',
            flex: '0 0 4.1666%',
            maxWidth: ' 4.1666%',
          }}
        ></Col>
        <Col md={{ span: 5, offset: 0 }}>
          <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
            <Text size='sm' weight={600} color={sales_business_unit.length === 0 ? 'red' : ''}>
              Sales Business Unit:
            </Text>
            <Text size='sm' weight={400}>
              {sales_business_unit?.join(', ')}
            </Text>
          </SimpleGrid>
        </Col>

        {!vis1 && (
          <Col md={{ span: 5, offset: 0 }}>
            <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
              <Text size='sm' weight={600} color={!country_legal_spoc ? 'red' : ''}>
                Country/ Legal SPOC:
              </Text>
              <Text size='sm' weight={400}>
                {country_legal_spoc === null
                  ? 'No User Selected'
                  : JSON.parse(country_legal_spoc).name}
              </Text>
            </SimpleGrid>
          </Col>
        )}
      </Row>

      <Row>
        <Col
          md={1}
          style={{
            width: '4.1666%',
            flex: '0 0 4.1666%',
            maxWidth: ' 4.1666%',
          }}
        ></Col>

        <Col md={{ span: 5, offset: 0 }}>
          <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
            {!cost_center ? (
              <Link to={routes.submission(params.rfsNumber).info}>
                <Text size='sm' weight={600} color={!cost_center ? 'red' : ''}>
                  Cost Center:
                </Text>
              </Link>
            ) : (
              <Text size='sm' weight={600} color={!cost_center ? 'red' : ''}>
                Cost Center:
              </Text>
            )}
            <Text size='sm' weight={400}>
              {cost_center}
            </Text>
          </SimpleGrid>
        </Col>
      </Row>

      {/* <Row>
        <Col
          md={1}
          style={{
            width: '4.1666%',
            flex: '0 0 4.1666%',
            maxWidth: ' 4.1666%',
          }}
        ></Col>

        <Col md={{ span: 5, offset: 0 }}>
          <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
            <Text size='sm' weight={600} color={impactedPlants.length === 0 ? 'red' : ''}>
              Impacted Plants
            </Text>
            <Text size='sm' weight={400}>
              {getImpactedPlants() === '' ? 'No plants selected' : getImpactedPlants()}
            </Text>
          </SimpleGrid>
        </Col>
      </Row> */}

      <Row>
        <Col
          md={1}
          style={{
            width: '4.1666%',
            flex: '0 0 4.1666%',
            maxWidth: ' 4.1666%',
          }}
        ></Col>

        <Row style={{ marginTop: '0.5rem' }}>
          <Col
            md={1}
            style={{
              width: '4.1666%',
              flex: '0 0 4.1666%',
              maxWidth: ' 4.1666%',
            }}
          ></Col>

          <Col md={{ span: 2 }}>
            {!project_purpose || minWordsValidation(project_purpose) ? (
              <Link to={routes.submission(params.rfsNumber).info}>
                <Text
                  size='sm'
                  weight={600}
                  color={!project_purpose || minWordsValidation(project_purpose) ? 'red' : ''}
                >
                  Project Purpose:
                </Text>
              </Link>
            ) : (
              <Text
                size='sm'
                weight={600}
                color={!project_purpose || minWordsValidation(project_purpose) ? 'red' : ''}
              >
                Project Purpose:
              </Text>
            )}
          </Col>
          <Col
            md={1}
            style={{
              width: '4.1666%',
              flex: '0 0 4.1666%',
              maxWidth: ' 4.1666%',
            }}
          ></Col>
          <Col md={{ span: 8 }}>
            <Text size='sm' weight={400}>
              {project_purpose}
            </Text>
          </Col>
        </Row>

        <Row style={{ marginTop: '0.5rem' }}>
          <Col
            md={1}
            style={{
              width: '4.1666%',
              flex: '0 0 4.1666%',
              maxWidth: ' 4.1666%',
            }}
          ></Col>

          <Col md={{ span: 2 }}>
            {!project_details || minWordsValidation(project_details) ? (
              <Link to={routes.submission(params.rfsNumber).info}>
                <Text
                  size='sm'
                  weight={600}
                  color={!project_details || minWordsValidation(project_details) ? 'red' : ''}
                >
                  Project Explanation:
                </Text>
              </Link>
            ) : (
              <Text
                size='sm'
                weight={600}
                color={!project_details || minWordsValidation(project_details) ? 'red' : ''}
              >
                Project Explanation:
              </Text>
            )}
          </Col>
          <Col
            md={1}
            style={{
              width: '4.1666%',
              flex: '0 0 4.1666%',
              maxWidth: ' 4.1666%',
            }}
          ></Col>
          <Col md={{ span: 8 }}>
            <Text size='sm' weight={400}>
              {project_details}
            </Text>
          </Col>
        </Row>
      </Row>

      <Row style={{ marginTop: '0.5rem' }}>
        <Col
          md={1}
          style={{
            width: '4.1666%',
            flex: '0 0 4.1666%',
            maxWidth: ' 4.1666%',
          }}
        ></Col>

        <Col md={{ span: 2 }}>
          <Text size='sm' weight={600}>
            Any Additional Comments:
          </Text>
        </Col>
        <Col
          md={1}
          style={{
            width: '3.8%',
            flex: '0 0 3.8%',
            maxWidth: ' 3.8%',
          }}
        ></Col>
        <Col md={{ span: 8 }}>
          <Text size='sm' weight={400}>
            {comments}
          </Text>
        </Col>
      </Row>
    </>
  );

  if (
    form.rfsInformation.status &&
    ![rfsStatus.draft, rfsStatus.tech_feasible, rfsStatus.tech_feasible2].includes(
      form.rfsInformation.status,
    ) &&
    location.pathname.includes('/submission/review')
  ) {
    navigate(routes.submission(params.rfsNumber).overView);
  }

  if (
    form.rfsInformation.status &&
    form.rfsInformation.status === rfsStatus.draft &&
    location.pathname.includes('/project/overview')
  ) {
    navigate(routes.submission(params.rfsNumber).review);
  }

  return (
    <ContentPaper page='submission-review' footer={editScope() ? <Footer /> : null}>
      {infoLoading ? (
        <AppLoader size='lg' center />
      ) : (
        <div>
          <ProjectName Pnumber={project_name} initialItem={props.initialItems} />

          <Row>
            <Col
              md={1}
              style={{
                width: '2.0833%',
                flex: '0 0 2.0833%',
                maxWidth: ' 2.0833%',
              }}
            ></Col>
            <Col
              md={{ span: 3 }}
              style={{
                marginTop: '1rem',
              }}
            >
              <h5 className='sectionHeadings'>
                GENERAL DETAILS{' '}
                {editScope() && (
                  <span className='editIcon'>
                    <Pencil
                      color='#e5b611'
                      onClick={() =>
                        navigate(routes.submission(params.rfsNumber).info, {
                          state: {
                            routeFromRfsOverview: true,
                          },
                        })
                      }
                    />
                  </span>
                )}
              </h5>
            </Col>
          </Row>

          {!vis1 ? (
            <OpenSkuDetails />
          ) : (
            <>
              <Row>
                <Col
                  md={1}
                  style={{
                    width: '4.1666%',
                    flex: '0 0 4.1666%',
                    maxWidth: ' 4.1666%',
                  }}
                ></Col>
                {/* <Col className="Empty" md={1} style={{width:'50%'}}></Col> */}

                <Col md={{ span: 5, offset: 0 }}>
                  <SimpleGrid cols={2}>
                    {!initiator_position ? (
                      <Link to={routes.submission(params.rfsNumber).info}>
                        <Text size='sm' weight={600} color={!initiator_position ? 'red' : ''}>
                          Initiator Position:
                        </Text>
                      </Link>
                    ) : (
                      <Text size='sm' weight={600} color={!initiator_position ? 'red' : ''}>
                        Initiator Position:
                      </Text>
                    )}
                    <Text size='sm' weight={400}>
                      {initiator_position}
                    </Text>
                  </SimpleGrid>
                </Col>

                <Col md={{ span: 5 }}>
                  <SimpleGrid cols={2}>
                    {!initiator_team ? (
                      <Link to={routes.submission(params.rfsNumber).info}>
                        <Text size='sm' weight={600} color={!initiator_team ? 'red' : ''}>
                          Initiator Team:
                        </Text>
                      </Link>
                    ) : (
                      <Text size='sm' weight={600} color={!initiator_team ? 'red' : ''}>
                        Initiator Team:
                      </Text>
                    )}
                    <Text size='sm' weight={400}>
                      {initiator_team}
                    </Text>
                  </SimpleGrid>
                </Col>
              </Row>
              <Row>
                <Col
                  md={1}
                  style={{
                    width: '4.1666%',
                    flex: '0 0 4.1666%',
                    maxWidth: ' 4.1666%',
                  }}
                ></Col>

                <Col md={{ span: 5, offset: 0 }}>
                  <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
                    {(backupUsers || []).length === 0 ? (
                      <Link to={routes.submission(params.rfsNumber).info}>
                        <Text
                          size='sm'
                          weight={600}
                          color={(backupUsers || []).length === 0 ? 'red' : ''}
                        >
                          Selected Backup Users:
                        </Text>
                      </Link>
                    ) : (
                      <Text
                        size='sm'
                        weight={600}
                        color={(backupUsers || []).length === 0 ? 'red' : ''}
                      >
                        Selected Backup Users:
                      </Text>
                    )}
                    <Text size='sm' weight={400}>
                      {getUsers('backup') === '' ? 'No users selected' : getUsers('backup')}
                    </Text>
                  </SimpleGrid>
                </Col>
                <Col md={{ span: 5, offset: 0 }}>
                  <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
                    <Text size='sm' weight={600}>
                      Request for Fast Track:
                    </Text>
                    <Text size='sm' weight={400}>
                      {fast_track ? 'Yes' : 'No'}
                    </Text>
                  </SimpleGrid>
                </Col>
              </Row>

              <Row>
                <Col
                  md={1}
                  style={{
                    width: '4.1666%',
                    flex: '0 0 4.1666%',
                    maxWidth: ' 4.1666%',
                  }}
                ></Col>
                {vis1 && (
                  <Col md={{ span: 5, offset: 0 }}>
                    <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
                      {(technicalLead || []).length === 0 ? (
                        <Link to={routes.submission(params.rfsNumber).info}>
                          <Text
                            size='sm'
                            weight={600}
                            color={(technicalLead || []).length === 0 ? 'red' : ''}
                          >
                            Selected Technical Lead:
                          </Text>
                        </Link>
                      ) : (
                        <Text
                          size='sm'
                          weight={600}
                          color={(technicalLead || []).length === 0 ? 'red' : ''}
                        >
                          Selected Technical Lead:
                        </Text>
                      )}
                      <Text size='sm' weight={400}>
                        {getUsers('technical') === '' ? 'No users selected' : getUsers('technical')}
                      </Text>
                    </SimpleGrid>
                  </Col>
                )}
                {vis1 && (
                  <Col md={{ span: 5, offset: 0 }}>
                    <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
                      <Text size='sm' weight={600}>
                        Selected Allowed Users:
                      </Text>
                      <Text size='sm' weight={400}>
                        {getUsers('allowed') === '' ? 'No users selected' : getUsers('allowed')}
                      </Text>
                    </SimpleGrid>
                  </Col>
                )}
              </Row>

              <Row>
                <Col
                  md={1}
                  style={{
                    width: '4.1666%',
                    flex: '0 0 4.1666%',
                    maxWidth: ' 4.1666%',
                  }}
                ></Col>

                {rfs_type.toLowerCase() !== 'production site' && (
                  <Col md={{ span: 5, offset: 0 }}>
                    <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
                      {sales_business_unit.length === 0 ? (
                        <Link to={routes.submission(params.rfsNumber).info}>
                          <Text
                            size='sm'
                            weight={600}
                            color={sales_business_unit.length === 0 ? 'red' : ''}
                          >
                            Sales Business Unit:
                          </Text>
                        </Link>
                      ) : (
                        <Text
                          size='sm'
                          weight={600}
                          color={sales_business_unit.length === 0 ? 'red' : ''}
                        >
                          Sales Business Unit:
                        </Text>
                      )}
                      <Text size='sm' weight={400}>
                        {sales_business_unit?.join(', ')}
                      </Text>
                    </SimpleGrid>
                  </Col>
                )}

                <Col md={{ span: 5, offset: 0 }}>
                  <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
                    {!first_shipment ? (
                      <Link to={routes.submission(params.rfsNumber).info}>
                        <Text size='sm' weight={600} color={!first_shipment ? 'red' : ''}>
                          First Shipment Date:
                        </Text>
                      </Link>
                    ) : (
                      <Text size='sm' weight={600} color={!first_shipment ? 'red' : ''}>
                        First Shipment Date:
                      </Text>
                    )}
                    <Text size='sm' weight={400}>
                      {first_shipment}
                    </Text>
                  </SimpleGrid>
                </Col>
              </Row>

              <Row>
                <Col
                  md={1}
                  style={{
                    width: '4.1666%',
                    flex: '0 0 4.1666%',
                    maxWidth: ' 4.1666%',
                  }}
                ></Col>
                {vis1 && (
                  <Col md={{ span: 5, offset: 0 }}>
                    <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
                      {!cost_center ? (
                        <Link to={routes.submission(params.rfsNumber).info}>
                          <Text size='sm' weight={600} color={!cost_center ? 'red' : ''}>
                            Cost Center:
                          </Text>
                        </Link>
                      ) : (
                        <Text size='sm' weight={600} color={!cost_center ? 'red' : ''}>
                          Cost Center:
                        </Text>
                      )}
                      <Text size='sm' weight={400}>
                        {cost_center}
                      </Text>
                    </SimpleGrid>
                  </Col>
                )}

                <Col md={{ span: 5, offset: 0 }}>
                  <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
                    {gatekeeper?.length === 0 ? (
                      <Link to={routes.submission(params.rfsNumber).info}>
                        <Text size='sm' weight={600} color={gatekeeper?.length === 0 ? 'red' : ''}>
                          Selected Gatekeeper:
                        </Text>
                      </Link>
                    ) : (
                      <Text size='sm' weight={600} color={gatekeeper?.length === 0 ? 'red' : ''}>
                        Selected Gatekeeper:
                      </Text>
                    )}
                    <Text size='sm' weight={400}>
                      {getUsers('gatekeeper') === '' ? 'No users selected' : getUsers('gatekeeper')}
                    </Text>
                  </SimpleGrid>
                </Col>
              </Row>

              <Row>
                <Col
                  md={1}
                  style={{
                    width: '4.1666%',
                    flex: '0 0 4.1666%',
                    maxWidth: ' 4.1666%',
                  }}
                ></Col>
                {vis1 && (
                  <Col md={{ span: 5, offset: 0 }}>
                    <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
                      {!trials_needed ? (
                        <Link to={routes.submission(params.rfsNumber).info}>
                          <Text size='sm' weight={600} color={!trials_needed ? 'red' : ''}>
                            Are Trials needed:
                          </Text>
                        </Link>
                      ) : (
                        <Text size='sm' weight={600} color={!trials_needed ? 'red' : ''}>
                          Are Trials needed:
                        </Text>
                      )}
                      <Text size='sm' weight={400}>
                        {trials_needed}
                      </Text>
                    </SimpleGrid>
                  </Col>
                )}

                {capex_needed ? (
                  <Col md={{ span: 5, offset: 0 }}>
                    <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
                      {!capex_amount ? (
                        <Link to={routes.submission(params.rfsNumber).info}>
                          <Text size='sm' weight={600} color={!capex_amount ? 'red' : ''}>
                            Capex Amount:
                          </Text>
                        </Link>
                      ) : (
                        <Text size='sm' weight={600} color={!capex_amount ? 'red' : ''}>
                          Capex Amount:
                        </Text>
                      )}
                      <Text size='sm' weight={400}>
                        {`${capex_amount ? `$${capex_amount}` : ''}`}
                      </Text>
                    </SimpleGrid>
                  </Col>
                ) : null}
              </Row>
              <Row>
                <Col
                  md={1}
                  style={{
                    width: '4.1666%',
                    flex: '0 0 4.1666%',
                    maxWidth: ' 4.1666%',
                  }}
                ></Col>

                <Col md={{ span: 5, offset: 0 }}>
                  <SimpleGrid cols={2} style={{ paddingTop: '0.5rem' }}>
                    <Text size='sm' weight={600} color={impactedMarkets?.length === 0 ? 'red' : ''}>
                      Impacted Markets
                    </Text>
                    <Text size='sm' weight={400}>
                      {['Production Site', 'Article Modification/Value Engineering'].includes(
                        rfs_type,
                      ) && getImpactedMarkets() === ''
                        ? 'No markets selected'
                        : getImpactedMarkets()}
                    </Text>
                  </SimpleGrid>
                </Col>
              </Row>
              <Row>
                <Col
                  md={1}
                  style={{
                    width: '4.1666%',
                    flex: '0 0 4.1666%',
                    maxWidth: ' 4.1666%',
                  }}
                ></Col>

                <Row style={{ marginTop: '0.5rem' }}>
                  <Col
                    md={1}
                    style={{
                      width: '4.1666%',
                      flex: '0 0 4.1666%',
                      maxWidth: ' 4.1666%',
                    }}
                  ></Col>

                  <Col md={{ span: 2 }}>
                    {!project_purpose || minWordsValidation(project_purpose) ? (
                      <Link to={routes.submission(params.rfsNumber).info}>
                        <Text
                          size='sm'
                          weight={600}
                          color={
                            !project_purpose || minWordsValidation(project_purpose) ? 'red' : ''
                          }
                        >
                          Project Purpose:
                        </Text>
                      </Link>
                    ) : (
                      <Text
                        size='sm'
                        weight={600}
                        color={!project_purpose || minWordsValidation(project_purpose) ? 'red' : ''}
                      >
                        Project Purpose:
                      </Text>
                    )}
                  </Col>
                  <Col
                    md={1}
                    style={{
                      width: '4.1666%',
                      flex: '0 0 4.1666%',
                      maxWidth: ' 4.1666%',
                    }}
                  ></Col>
                  <Col md={{ span: 8 }}>
                    <Text size='sm' weight={400}>
                      {project_purpose}
                    </Text>
                  </Col>
                </Row>

                <Row style={{ marginTop: '0.5rem' }}>
                  <Col
                    md={1}
                    style={{
                      width: '4.1666%',
                      flex: '0 0 4.1666%',
                      maxWidth: ' 4.1666%',
                    }}
                  ></Col>

                  <Col md={{ span: 2 }}>
                    {!project_details || minWordsValidation(project_details) ? (
                      <Link to={routes.submission(params.rfsNumber).info}>
                        <Text
                          size='sm'
                          weight={600}
                          color={
                            !project_details || minWordsValidation(project_details) ? 'red' : ''
                          }
                        >
                          Project Explanation:
                        </Text>
                      </Link>
                    ) : (
                      <Text
                        size='sm'
                        weight={600}
                        color={!project_details || minWordsValidation(project_details) ? 'red' : ''}
                      >
                        Project Explanation:
                      </Text>
                    )}
                  </Col>
                  <Col
                    md={1}
                    style={{
                      width: '4.1666%',
                      flex: '0 0 4.1666%',
                      maxWidth: ' 4.1666%',
                    }}
                  ></Col>
                  <Col md={{ span: 8 }}>
                    <Text size='sm' weight={400}>
                      {project_details}
                    </Text>
                  </Col>
                </Row>
              </Row>

              <Row style={{ marginTop: '0.5rem' }}>
                <Col
                  md={1}
                  style={{
                    width: '4.1666%',
                    flex: '0 0 4.1666%',
                    maxWidth: ' 4.1666%',
                  }}
                ></Col>

                <Col md={{ span: 2 }}>
                  <Text size='sm' weight={600}>
                    Any Additional Comments:
                  </Text>
                </Col>
                <Col
                  md={1}
                  style={{
                    width: '3.8%',
                    flex: '0 0 3.8%',
                    maxWidth: ' 3.8%',
                  }}
                ></Col>
                <Col md={{ span: 8 }}>
                  <Text size='sm' weight={400}>
                    {comments}
                  </Text>
                </Col>
              </Row>
            </>
          )}
          <Row style={{ marginTop: '1rem' }}>
            <Col
              md={1}
              style={{
                width: '2.0833%',
                flex: '0 0 2.0833%',
                maxWidth: ' 2.0833%',
              }}
            ></Col>
            <Col
              md={{ span: 3 }}
              style={{
                marginTop: '1rem',
              }}
            >
              <h5 className='sectionHeadings'>
                SCOPE{' '}
                {editScope() && (
                  <span className='editIcon'>
                    <Pencil
                      color='#e5b611'
                      onClick={() =>
                        navigate(routes.submission(params.rfsNumber).scopeSelect, {
                          state: {
                            routeFromRfsOverview: true,
                          },
                        })
                      }
                    />
                  </span>
                )}
              </h5>
              {(psScope || veamScope || ostmScope) && (
                <span style={{ color: 'red', fontWeight: '400', fontSize: '0.8rem' }}>
                  Required Field
                </span>
              )}
            </Col>

            <Col
              md={{ span: 2, offset: 6 }}
              style={{
                marginTop: '1rem',
              }}
            >
              <Button
                color='yellow'
                style={{ background: 'black', color: 'white' }}
                onClick={() => setTfModal(true)}
              >
                Other Projects in scope
              </Button>
            </Col>

            <Row>
              <Accordion onChange={(event) => setAccordionState(event)}>
                {Object.keys(form?.selectedScope || {}).map((name) =>
                  form?.selectedScope[name].map((item, index) => (
                    <Accordion.Item key={`key_${name}_${index}`} value={`key_${name}_${index}`}>
                      <Accordion.Control
                        className={scopeErrors.errors[item.id] ? 'error-label' : ''}
                      >
                        {`${name.substring(0, 3).toUpperCase()} -  ${item[accessors[name]]}- ${
                          item[secondaryAccessor[name]]
                        }${tertiaryAccessor[name] ? `- ${item[tertiaryAccessor[name]]}` : ''}`}
                      </Accordion.Control>
                      <Accordion.Panel>
                        <Grid>
                          {name === 'liquids' && (
                            <Grid.Col span={12}>
                              <LiquidTable
                                index={index}
                                scope={item}
                                scopeLiquids={getLiquids(item)}
                                isSubmissionReview
                                errors={scopeErrors.liquidErrors}
                              />
                            </Grid.Col>
                          )}
                          {name === 'materials' && (
                            <Grid.Col span={12}>
                              <MaterialTable
                                index={index}
                                material={item}
                                errors={scopeErrors.materialErrors}
                                isSubmissionReview={true}
                                materialAccordionIndex={
                                  form?.selectedScope.liquids.length > 0
                                    ? form?.selectedScope.liquids.length + index
                                    : index
                                }
                                accordionState={accordionState}
                              />
                            </Grid.Col>
                          )}
                          {name === 'skus' && (
                            <Grid.Col span={12}>
                              {rfs_type.toLowerCase() === 'production site' && (
                                <ProductionSite
                                  name={item[accessors[name]]}
                                  scope={item}
                                  errors={scopeErrors.productionSiteErrors}
                                  isSubmissionReview
                                />
                              )}
                              {rfs_type === 'Open SKU to market' && (
                                <SkuTable
                                  name={item[accessors[name]]}
                                  scope={item}
                                  isSubmissionReview
                                />
                              )}
                            </Grid.Col>
                          )}
                          {
                            //name !== 'materials' && (
                            <>
                              {/* <Grid.Col span={12}>
                                  <PortfolioTable />
                                </Grid.Col>
                                <Grid.Col span={12}>
                                  <PalletTypes />
                                </Grid.Col>

                                <Grid.Col span={12}>
                                  <EanRequirementsTable />
                                </Grid.Col>
                                <Grid.Col span={6}>
                                  <VolumeTable />
                                </Grid.Col>
                                <Grid.Col span={6}>
                                  <PlantsTable />
                                </Grid.Col> */}
                            </>
                          }
                          <Grid.Col span={12}>
                            <Textarea
                              disabled={props.isSubmissionReview}
                              label='Additional Comments'
                              className='additional-comments'
                              value={form.scopeComments[item.id] || null}
                            />
                          </Grid.Col>
                        </Grid>
                      </Accordion.Panel>
                    </Accordion.Item>
                  )),
                )}
              </Accordion>
            </Row>
          </Row>

          <Row style={{ marginTop: '1rem' }}>
            <Col
              md={1}
              style={{
                width: '2.0833%',
                flex: '0 0 2.0833%',
                maxWidth: ' 2.0833%',
              }}
            ></Col>
            <Col
              md={{ span: 3 }}
              style={{
                marginTop: '1rem',
              }}
            >
              <h5 className='sectionHeadings'>
                ATTACHMENTS {}
                {editScope() && (
                  <span className='editIcon'>
                    <Pencil
                      color='#e5b611'
                      onClick={() =>
                        navigate(routes.submission(params.rfsNumber).attachment, {
                          state: {
                            routeFromRfsOverview: true,
                          },
                        })
                      }
                    />
                  </span>
                )}
              </h5>
            </Col>
          </Row>

          <Row>
            <Col
              md={1}
              style={{
                width: '4.1666%',
                flex: '0 0 4.1666%',
                maxWidth: ' 4.1666%',
              }}
            ></Col>

            <Col md={{ span: 5, offset: 0 }}>
              {emailAttachment.length === 0 ? (
                <Link to={routes.submission(params.rfsNumber).attachment}>
                  <Text size='sm' weight={600} color={'dark'}>
                    Additional Email Attachment:
                  </Text>
                </Link>
              ) : (
                <Text size='sm' weight={600}>
                  Additional Email Attachment:
                </Text>
              )}
              <Text size='sm' weight={400}>
                {attachmentLoading ? (
                  <AppLoader />
                ) : (
                  <FileList files={emailAttachment} type={'emailAttachment'} />
                )}
              </Text>
            </Col>
            <Col md={{ span: 5, offset: 0 }}>
              {fastTrackApproval.length === 0 ? (
                <Link to={routes.submission(params.rfsNumber).attachment}>
                  <Text size='sm' weight={600} color={'dark'}>
                    Fast Track Approval:
                  </Text>
                </Link>
              ) : (
                <Text size='sm' weight={600}>
                  Fast Track Approval:
                </Text>
              )}
              <Text size='sm' weight={400}>
                {attachmentLoading ? (
                  <AppLoader />
                ) : (
                  <FileList files={fastTrackApproval} type={'fastTrackApproval'} />
                )}
              </Text>
            </Col>
          </Row>

          <Row>
            <Col
              md={1}
              style={{
                width: '4.1666%',
                flex: '0 0 4.1666%',
                maxWidth: ' 4.1666%',
              }}
            ></Col>

            {vis1 && (
              <Col md={{ span: 5, offset: 0 }}>
                {artworkBriefing.length === 0 ? (
                  <Link to={routes.submission(params.rfsNumber).attachment}>
                    <Text size='sm' weight={600} color={'dark'}>
                      Artwork Briefing:
                    </Text>
                  </Link>
                ) : (
                  <Text size='sm' weight={600}>
                    Artwork Briefing:
                  </Text>
                )}
                <Text size='sm' weight={400}>
                  {attachmentLoading ? (
                    <AppLoader />
                  ) : (
                    <FileList files={artworkBriefing} type={'artworkBriefing'} />
                  )}
                </Text>
              </Col>
            )}
            {vis1 && (
              <Col md={{ span: 5, offset: 0 }}>
                {capexEmailApproval.length === 0 ? (
                  <Link to={routes.submission(params.rfsNumber).attachment}>
                    <Text size='sm' weight={600} color={'dark'}>
                      Capex Email Approval:
                    </Text>
                  </Link>
                ) : (
                  <Text size='sm' weight={600}>
                    Capex Email Approval:
                  </Text>
                )}
                <Text size='sm' weight={400}>
                  {attachmentLoading ? (
                    <AppLoader />
                  ) : (
                    <FileList files={capexEmailApproval} type={'capexEmailApproval'} />
                  )}
                </Text>
              </Col>
            )}
          </Row>
          <Row>
            <Col
              md={1}
              style={{
                width: '4.1666%',
                flex: '0 0 4.1666%',
                maxWidth: ' 4.1666%',
              }}
            ></Col>
            {rfs_type.toLowerCase() === 'production site' && (
              <Col md={{ span: 5, offset: 0 }}>
                {repacking.length === 0 ? (
                  <Link to={routes.submission(params.rfsNumber).attachment}>
                    <Text size='sm' weight={600} color={'dark'}>
                      Repacking:
                    </Text>
                  </Link>
                ) : (
                  <Text size='sm' weight={600}>
                    Repacking:
                  </Text>
                )}
                <Text size='sm' weight={400}>
                  {attachmentLoading ? (
                    <AppLoader />
                  ) : (
                    <FileList files={repacking} type={'repacking'} />
                  )}
                </Text>
              </Col>
            )}
          </Row>

          <Row>
            <Col
              md={1}
              style={{
                width: '4.1666%',
                flex: '0 0 4.1666%',
                maxWidth: ' 4.1666%',
              }}
            ></Col>
            {rfs_type === 'Article Modification/Value Engineering' && (
              <Col md={{ span: 5, offset: 0 }}>
                {liquidAttachment.length === 0 ? (
                  <Link to={routes.submission(params.rfsNumber).attachment}>
                    <Text size='sm' weight={600} color={'dark'}>
                      Liquid Attachment:
                    </Text>
                  </Link>
                ) : (
                  <Text size='sm' weight={600}>
                    Liquid Attachment:
                  </Text>
                )}
                <Text size='sm' weight={400}>
                  {attachmentLoading ? (
                    <AppLoader />
                  ) : (
                    <FileList files={liquidAttachment} type={'liquidAttachment'} />
                  )}
                </Text>
              </Col>
            )}
          </Row>

          {tfModal && <TechFeasibilityModal opened={tfModal} setOpened={setTfModal} />}

          {submitProjectModal && (
            <SubmitProjectModal opened={submitProjectModal} setOpened={setSubmitProjectModal} />
          )}

          {confirmModal && (
            <ConfirmModal
              opened={confirmModal}
              setOpened={setConfirmModal}
              pname={project_name}
              npsOpened={npsOpened}
              setNpsOpened={setNpsOpened}
              setSubmitted={setSubmitted}
            />
          )}

          {npsOpened && (
            <NpsComponent
              rfs_number={params.rfsNumber}
              opened={npsOpened}
              setOpened={setNpsOpened}
              callback={() => {
                navigate('/');
              }}
              id={NpsIds().submission}
            />
          )}
        </div>
      )}
    </ContentPaper>
  );
}

export default withConfirm(Rfsinitiation2);
