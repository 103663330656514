import React from 'react';
import { Button, Checkbox, Text, Badge } from '@mantine/core';
import moment from 'moment';

import { calculateColor } from '../../../../pages/DLST/implementation/helpers';

export const implementationColumns = (
  setLogsModal = () => {},
  setActionModal = () => {},
  config,
  readOnly = false,
) => {
  return [
    {
      accessorKey: 'status',
      header: 'Status',
      Cell: ({ row }) => {
        const { status, sla_date } = row.original;
        const color = calculateColor(status, sla_date);
        return (
          <Badge style={{ padding: '0.8rem' }} size='md' color={color}>
            {status}
          </Badge>
        );
      },
    },
    !(config.hidden || []).includes('action')
      ? {
          header: 'Action',
          id: 'action',
          disableSortBy: true,
          Cell: ({ row }) => {
            const invalidIndex = (config.required || []).findIndex((key) => {
              if (key === 'new_material_code') {
                return (
                  !row.original.int_rfs_impl_material_creation ||
                  !row.original.int_rfs_impl_material_creation[key]
                );
              }
              return !row.original[key];
            });

            return row.original.status === 'In Progress' ||
              row.original.status === 'Not Started' ? (
              <Checkbox
                onChange={() => {
                  setActionModal({ open: true, id: row.original.id, callback: config.fetchInfo });
                }}
                defaultChecked={false}
                label='Confirm and Close'
                disabled={row.original.status === 'Not Started' || readOnly || invalidIndex > -1}
              />
            ) : row.original.manually_closed && row.original.status === 'Closed' ? (
              <Text>Manual Closure</Text>
            ) : (
              <Text>Automatic Closure</Text>
            );
          },
        }
      : null,
    {
      id: 'logs',
      header: 'Logs',
      Cell: ({ row }) => {
        return (
          <Button
            disabled={['Not Started'].includes(row.original.status)}
            style={{ background: 'black', color: 'white' }}
            onClick={() => setLogsModal({ open: true, id: row.original.id, title: config.title })}
          >
            View Logs
          </Button>
        );
      },
    },

    {
      header: 'Step Deadline',
      accessorKey: 'sla_date',
      Cell: ({ row }) => {
        return <Text>{moment(row.original.sla_date).format('DD-MM-YYYY')}</Text>;
      },
    },
    {
      header: 'Step Finished',
      accessorKey: 'finished_date',
      Cell: ({ row }) => {
        return (
          <Text>
            {row.original.finished_date
              ? moment(row.original.finished_date).format('DD-MM-YYYY')
              : 'Not Finished'}
          </Text>
        );
      },
    },
  ].filter((column) => column);
};
