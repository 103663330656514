import React, { useMemo, useState } from 'react';
import { Button } from '@mantine/core';
import MantineTable from '../../../common/table/mantine';
import { ImpactedSkus } from '../techFeasibility/zitec/modal';

export function Table({ values, setValues, materials }) {
  const [activePlants, setActivePlants] = useState({});
  const [impactedSkus, showImpactedSkus] = useState({ open: false, material: null, plant: null });

  const columns = useMemo(
    () => [
      {
        accessorKey: 'material_code',
        header: 'Material Code',
      },
      {
        accessorKey: 'material_description',
        header: 'Material Description',
      },
      {
        header: 'Plant',
        accessorKey: 'plant',
      },
      {
        header: 'SKUs using the material',
        id: 'impacted_skus',
        Cell: ({ row }) => (
          <Button
            variant='filled'
            color='yellow'
            fullWidth
            style={{ background: 'black', color: 'white' }}
            size='sm'
            radius='sm'
            className='add-supplier-button'
            onClick={() =>
              showImpactedSkus({
                open: true,
                material: row.original.material_code,
                plant: row.original.plant,
              })
            }
          >
            Impacted SKUs
          </Button>
        ),
      },
    ],
    [values],
  );

  const handleRowSelection = (data) => {
    let currentValues = { ...activePlants };
    currentValues = { ...data };

    setActivePlants(currentValues);
    setValues({ scope_ids: Object.keys(currentValues) }, null, 'sc');
  };

  return (
    <>
      <MantineTable
        columns={columns}
        initialData={materials}
        unique={'scope_id'}
        handleRowSelection={handleRowSelection}
        activeRows={activePlants}
        enableRowSelection={true}
        loading={false}
        showSelectedToggle={true}
        hideSelectColumn={false}
        showResetAll={false}
      />
      {impactedSkus.open && <ImpactedSkus state={impactedSkus} setState={showImpactedSkus} />}
    </>
  );
}
