import React from 'react';
import { Button, Grid } from '@mantine/core';
import csvDownload from 'json-to-csv-export';
import moment from 'moment';

export const DownloadExcel = ({ data, filename = 'Data' }) => {
  const downloadExcel = (rows = []) => {
    const dataToExport = {
      data: [],
      filename: '',
      delimiter: ',',
      headers: [],
    };

    const headers = Object.keys(rows[0] || []);

    dataToExport.data = getDataToExport(rows);
    dataToExport.filename = `Download_${filename}_` + moment().format('YYYY-MM-DD HH:mm:ss');
    dataToExport.headers = headers;
    csvDownload(dataToExport);
  };

  const getDataToExport = (data) => {
    const csvArray = data.map((element) => Object.values(element).map((i, index) => i));
    return csvArray;
  };

  return (
    <Grid justify='flex-end'>
      <Grid.Col
        span={3}
        style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '16px' }}
      >
        {' '}
        <Button
          onClick={() => {
            downloadExcel(data);
          }}
          color='yellow'
          size='md'
          style={{
            height: '2.5rem',
            background: 'linear-gradient(90deg, rgb(227, 175, 50) 0%, rgb(244, 224, 15) 100%)',
            color: 'black',
          }}
        >
          Download CSV
        </Button>
      </Grid.Col>
    </Grid>
  );
};
