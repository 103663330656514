import { Route, Routes, Navigate } from 'react-router-dom';
import styled from 'styled-components';

import AddRfsPage from '../pages/addRFS/AddRfs';
import AttachmentPage from '../pages/attachmentpage/attachmentPage';
import AllRfsPage from '../pages/allrfs/allRfsPage';

import ImpFeasibilityPage from '../pages/impFeasibility/impFeasibilityPage';

import ProjectOverviewPage from '../pages/projectOverview/projectOverviewPage';

import RfsInitiationPage from '../pages/rfsInitiation/rfsInitiationPage';
import SubmissionReview from '../pages/submissionReview/submissionReview';
import ScopeInitiation2 from '../pages/scopeInitiationPage/scopeInitiation2';
import SelectSku from '../pages/scopeInitiation1/SelectSku';
import TechFeasibilityPage from '../pages/techfeasibility/techFeasibilityPage';
import { LiquidsProvider } from '../contexts/liquids';

import { routes } from '../routes';
import ProductDevelopmentPage from '../pages/productDevelopment/productDevelopmentPage';
import AdminConsolePage from '../pages/register/adminConsole/adminConsolePage';
import DecomplexityPage from '../pages/decomplexity/decomplexityPage';
import ReportHomePage from '../pages/reports/reportHomePage';
import RawDataReports from '../pages/reports/rawDataReports';
import BottleneckReport from '../pages/reports/bottleneckReport';
import FtrReport from '../pages/reports/ftrReport';
import SmdReport from '../pages/reports/smdWorkload';
import ConcordanceTableReport from '../pages/reports/concordanceTableReport';
import DecomplexityReport from '../pages/reports/decomplexityReport';
import PortfolioReport from '../pages/reports/portfolioReport';
import FeedbackForm from '../components/forms/feedbackForm/feedbackForm';
import { ProjectOverviewProvider } from '../contexts/projectOverviewContext';

import NoMatch from '../pages/noMatch/noMatch';
import UnauthorisedPage from '../pages/unauthorised/unauth';
import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { roles } from '../constants';
import { AppLoader } from '../components/common/loader';
import AccfPage from '../pages/accf/accf';
import TicketPage from '../pages/RaiseTicket/TicketPage';
import TeamsStepsDayReport from '../pages/reports/teamsStepsReportDays';

const LoadingContainer = styled.div`
  height: 100vh;
  width: 100vw;
`;

const LoggedInRoutes = () => {
  const { userRole } = useContext(AuthContext);

  if (!userRole || userRole.length === 0) {
    return (
      <LoadingContainer>
        <AppLoader size='lg' center />
      </LoadingContainer>
    );
  }

  return (
    <Routes>
      <Route path={routes.home.root} element={<Navigate replace to={routes.rfs.root} />} />
      <Route path={routes.submission().add} element={<AddRfsPage />} />
      <Route path={routes.submission().edit} element={<AddRfsPage />} />
      <Route path={routes.submission().info} element={<RfsInitiationPage />} />
      <Route
        path={routes.submission().scopeSelect}
        element={
          <LiquidsProvider>
            <SelectSku />
          </LiquidsProvider>
        }
      />
      <Route path={routes.submission().scopeDefine} element={<ScopeInitiation2 />} />
      <Route path={routes.submission().attachment} element={<AttachmentPage />} />
      <Route path={routes.submission().review} element={<SubmissionReview />} />
      <Route path={routes.rfs.root} element={<AllRfsPage />} />
      <Route path={routes.rfs.my} element={<AllRfsPage type={'my'} />} />
      <Route path={routes.product().development} element={<ProductDevelopmentPage />} />
      <Route path={routes.tech.root} element={<TechFeasibilityPage />} />
      <Route path={routes.scope.root} element={<scopeUnlockPage />} />
      <Route path={routes.implementation.root} element={<ImpFeasibilityPage />} />
      <Route
        path={routes.admin.root}
        element={userRole === roles.superAdmin ? <AdminConsolePage /> : <UnauthorisedPage />}
      />
      <Route path={routes.decomplexity.root} element={<DecomplexityPage />} />
      <Route
        path={routes.project().root}
        element={
          <ProjectOverviewProvider>
            <ProjectOverviewPage />
          </ProjectOverviewProvider>
        }
      />
      <Route path={routes.reports.root} element={<ReportHomePage />} />
      <Route path={routes.reports.rawData} element={<RawDataReports />} />
      <Route path={routes.reports.ftr} element={<FtrReport />} />
      <Route path={routes.reports.smd} element={<SmdReport />} />
      <Route path={routes.reports.bottleneck} element={<BottleneckReport />} />
      <Route path={routes.reports.concordance} element={<ConcordanceTableReport />} />
      <Route path={routes.reports.ftr} element={<FtrReport />} />
      <Route path={routes.reports.smd} element={<SmdReport />} />
      <Route path={routes.reports.decomplexity} element={<DecomplexityReport />} />
      <Route path={routes.reports.portfolio} element={<PortfolioReport />} />
      <Route path='/feedbackftr' element={<FeedbackForm />} />
      <Route path={routes.accf.root} element={<AccfPage />} />
      <Route path={routes.reports.stepsTeams} element={<TeamsStepsDayReport />} />

      <Route path='/unauth' element={<UnauthorisedPage />} />
      <Route path='/RaiseTicket' element={<TicketPage />} />

      <Route path='/' element={<Navigate replace to={routes.rfs.root} />} />
      <Route path='*' element={<NoMatch />} />
    </Routes>
  );
};

export default LoggedInRoutes;
