import React from 'react';
import { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from '@mantine/form';
import { Modal, Button, Container, Grid, Checkbox, Group, Text, Textarea } from '@mantine/core';
import { InfoCircle } from 'tabler-icons-react';
import { axios } from '../../../utils/axios';
import '../../../components/OSKU/Implementation/ToggleModal1.css';
import { AppLoader } from '../../../components/common/loader';
import { AuthContext } from '../../../contexts/AuthContext';

export const ManualClosureModal = ({ manualClosureModal, setManualClosureModal, getInfo }) => {
  const params = useParams();
  const [disabled, setDisabled] = useState(true);
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(false);
  const { userEmail } = useContext(AuthContext);

  const form = useForm({
    initialValues: {
      comments: comment,
    },
  });

  const handleChange = (event) => {
    event.target.checked ? setDisabled(false) : setDisabled(true);
  };

  const handleConfirm = async () => {
    setLoading(true);
    const payload = {
      comment: comment,
      status_id: manualClosureModal.id,
    };
    axios
      .post(`production-site/manual_closure/scope_restatement/`, payload)
      .then(
        (res) => {
          getInfo(true);
          // window.location.reload(true);
        },
        (err) => {
          console.log(err);
        },
      )
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      opened={manualClosureModal.open}
      onClose={() => setManualClosureModal({ open: false, id: null, step: null })}
      title='Disclaimer'
      className='toggle-modal1'
    >
      <Container>
        <Grid>
          <form onSubmit={form.onSubmit(() => handleConfirm())}>
            <Grid.Col span={12}>
              <Group noWrap className='disclaimer-modal-group'>
                <InfoCircle color='black' size='5rem' />
                <Text size='sm'>
                  You have selected the manual closure option. This action cannot be undone once
                  confirmed. Please check the checkbox and click on OK if you wish to proceed.
                </Text>
              </Group>
            </Grid.Col>
            <Grid.Col span={12}>
              <Textarea
                label='Enter Comments'
                value={comment}
                onChange={(event) => {
                  setComment(event.target.value);
                }}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <Checkbox
                onChange={handleChange}
                className='modal-checkbox'
                label='I accept the terms and conditions above.'
              ></Checkbox>
            </Grid.Col>
            <Grid.Col span={3} offset={9}>
              <Button
                disabled={disabled || loading}
                color='yellow'
                variant='gradient'
                gradient={{ from: '#e3af32', to: '#f4e00f' }}
                //style={{background:'linear-gradient(90deg,#e3af32 0%,#f4e00f 100%)',color: 'black' }}
                onClick={() => handleConfirm()}
                fullWidth
              >
                {loading ? <AppLoader /> : 'OK'}
              </Button>
            </Grid.Col>
          </form>
        </Grid>
      </Container>
    </Modal>
  );
};
