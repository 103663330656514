import React, { useState, useMemo, useContext, useEffect } from 'react';
import { useTable } from 'react-table';
import moment from 'moment';
import { Text, Badge, Button, TextInput, MultiSelect } from '@mantine/core';
import { AppLoader } from '../../../../components/common/loader';
import { Styles } from '../../../../components/tables/skutable/production-site/styles';
import { useRef } from 'react';
import { RfsContext } from '../../../../contexts/RfsContext';
import ProductionLog from '../productionSiteLogModal';
import DOMPurify from 'dompurify';

export function SupplierNameTable({ supplierData, onChange, refresh, rfs, vendors, disable }) {
  const [filterLoading, setFilterLoading] = useState(false);
  const [imp_id, setImp_id] = useState(0);
  const data = supplierData;
  const [logModal, setLogModal] = useState({ open: false, id: '' });
  const { storeSupplier, storeSupplierDetails } = useContext(RfsContext);
  let hasInvalidCharacters = null;

  const getVendorList = () => {
    let vendorList = [...vendors];
    if (vendorList.length > 0) {
      vendorList.unshift({
        label: 'No New Supplier',
        value: 'No New Supplier',
      });
    }
    vendorList.unshift({ label: '+ New Supplier', value: 'New Supplier' });

    return vendorList;
  };

  const removeNoNewSupplier = () => {
    let vendorList = [...vendors];
    if (vendorList) {
      vendorList.unshift({ label: '+ New Supplier', value: 'New Supplier' });
    }

    return vendorList;
  };

  const [form, setForm] = useState({
    material_id: 0,
    new_supplier_number: '',
    new_supplier_name: '',
  });
  const formRef = useRef(null);
  formRef.current = form;

  const [supplier, setSupplier] = useState({});
  const supplierRef = useRef(null);
  supplierRef.current = supplier;

  const [supplierNumber, setSupplierNumber] = useState('');
  const numberRef = useRef(null);
  numberRef.current = supplierNumber;

  const handleChanged = (value, id) => {
    setSupplier({ [id]: value });
  };

  const handleChange = (name, value, id, material_id) => {
    const currentValues = { ...form };
    currentValues[name] = value;
    currentValues['material_id'] = material_id;
    setForm({ [id]: currentValues });
  };

  const handleNumberChange = (event, id) => {
    hasInvalidCharacters = event.target.value.match(/[^0-9/]/);
    if (hasInvalidCharacters) {
      setSupplierNumber('');
    } else {
      setSupplierNumber({ [id]: event.target.value });
    }
  };
  function postSupplier(material_ids, id) {
    const value = supplierRef.current[id].join(';');
    let payload = {
      add_supplier: value,
      material_ids: material_ids,
      rfs_number: rfs,
    };
    storeSupplier(payload, refresh);
  }

  function postSupplierDetails(material_ids, id) {
    let payload = {
      new_supplier_number: numberRef.current[id],
      new_supplier_name: formRef.current[id]?.new_supplier_name,
      material_ids: material_ids,
      rfs_number: rfs,
    };
    storeSupplierDetails(payload, refresh);
  }

  function getLogs(imp_id) {
    setLogModal({ open: true, id: imp_id });
    setImp_id(imp_id);
  }

  const getSuppliers = (data) => {
    let suppliers = typeof data === 'object' ? data : data.split(';');
    suppliers = suppliers.join('<br />');
    return suppliers;
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Pack Mat Number',
        Cell: ({ row }) => {
          return <Text>{row.original.mat_code}</Text>;
        },
      },
      {
        Header: 'Pack Mat Description',
        Cell: ({ row }) => {
          return <Text>{row.original.mat_desc}</Text>;
        },
      },
      {
        Header: 'Existing Suppliers',
        Cell: ({ row }) => {
          let data = row.original.supplier?.split(',');

          return (
            <div>
              {data?.map((d, k) => (
                <p key={k} style={{ marginBottom: '0px' }}>
                  {d ? d : 'No Supplier Found'}
                </p>
              ))}
            </div>
          );
        },
      },
      {
        Header: 'Add Supplier',
        Cell: ({ row }) => {
          const isDisabled = row.original.int_rfs_impl_supplier.add_supplier;
          const showSubmit =
            supplierRef.current[row.original.id] && supplierRef.current[row.original.id].length > 0;

          return isDisabled ? (
            <div style={{ padding: '10px 0px 10px 10px' }}>
              <div
                style={{
                  height: '75px',
                  overflowY: 'auto',
                }}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    getSuppliers(row.original.int_rfs_impl_supplier.add_supplier),
                  ),
                }}
              />
            </div>
          ) : (
            // <Text>{row.original.int_rfs_impl_supplier.add_supplier}</Text>
            <>
              <MultiSelect
                disabled={row.original.status == 'Not Started' || disable}
                searchable
                maxSelectedValues={5}
                style={{ width: '250px' }}
                value={
                  supplierRef.current[row.original.id] ? supplierRef.current[row.original.id] : []
                }
                onChange={(event) => handleChanged(event, row.original.id)}
                placeholder='Pick one'
                data={
                  supplierRef.current[row.original.id] &&
                  supplierRef.current[row.original.id].includes('No New Supplier')
                    ? [
                        {
                          label: 'No New Supplier',
                          value: 'No New Supplier',
                        },
                      ]
                    : supplierRef.current[row.original.id] &&
                      !supplierRef.current[row.original.id].includes('No New Supplier') &&
                      supplierRef.current[row.original.id].length > 0
                    ? removeNoNewSupplier()
                    : getVendorList()
                }
              />
              {showSubmit && (
                <Button
                  style={{
                    padding: '10px',
                    marginTop: '10px',
                    background: 'black',
                    color: 'white',
                  }}
                  position='Right'
                  size='sm'
                  onClick={() => postSupplier(row.original.other_materials, row.original.id)}
                  color='yellow'
                  radius='xs'
                  fullWidth
                >
                  Submit
                </Button>
              )}
            </>
          );
        },
      },
      {
        Header: 'New Supplier Number',
        Cell: ({ row }) => {
          const isDisabled = row.original.int_rfs_impl_supplier.new_supplier_number;
          const value = numberRef.current[row.original.id] || null;
          return isDisabled ? (
            <Text>{row.original.int_rfs_impl_supplier.new_supplier_number}</Text>
          ) : (
            <>
              <TextInput
                placeholder='New Supplier Number'
                radius='md'
                error={
                  (value || '').toString().length > 55
                    ? 'Invalid Number (Max Length: 55)'
                    : hasInvalidCharacters?.length > 0
                }
                hideControls
                onChange={(event) => handleNumberChange(event, row.original.id)}
                disabled={
                  !row.original.int_rfs_impl_supplier.add_supplier?.includes('New Supplier') ||
                  row.original.int_rfs_impl_supplier.add_supplier == 'No New Supplier' ||
                  row.original.status == 'Not Started' ||
                  (supplierRef.current[row.original.id] &&
                    !supplierRef.current[row.original.id].includes('New Supplier')) ||
                  disable
                }
              />
            </>
          );
        },
      },
      {
        Header: 'New Supplier Name',
        Cell: ({ row }) => {
          const isDisabled = row.original.int_rfs_impl_supplier.new_supplier_name;
          const supplierNumber = numberRef.current[row.original.id];
          const supplierName = formRef.current[row.original.id]?.new_supplier_name;
          //check for multiple supplier number and name
          const number = (supplierNumber || '').split('/');
          const name = (supplierName || '').split('/');

          const showSubmit =
            formRef.current[row.original.id]?.new_supplier_name &&
            formRef.current[row.original.id]?.new_supplier_name.length > 0 &&
            number.length === name.length;
          const value = numberRef.current[row.original.id] || null;
          return isDisabled ? (
            <Text>{row.original.int_rfs_impl_supplier.new_supplier_name}</Text>
          ) : (
            <>
              <TextInput
                placeholder='New Supplier Name'
                radius='md'
                onChange={(event) =>
                  handleChange(
                    'new_supplier_name',
                    event.target.value,
                    row.original.id,
                    row.original.material_id,
                  )
                }
                error={
                  number.length !== name.length && supplierName && supplierName.length > 0
                    ? 'Supplier number and name length is not same'
                    : false
                }
                disabled={
                  !row.original.int_rfs_impl_supplier.add_supplier?.includes('New Supplier') ||
                  row.original.int_rfs_impl_supplier.add_supplier == 'No New Supplier' ||
                  row.original.status == 'Not Started' ||
                  (supplierRef.current[row.original.id] &&
                    !supplierRef.current[row.original.id].includes('New Supplier')) ||
                  disable
                }
              />
              {showSubmit && (
                <Button
                  disabled={
                    !formRef.current ||
                    formRef.current.length === 0 ||
                    hasInvalidCharacters?.length > 0 ||
                    (value || '').toString().length > 55
                  }
                  style={{
                    padding: '10px',
                    marginTop: '10px',
                    background: 'black',
                    color: 'white',
                  }}
                  position='Right'
                  onClick={() => postSupplierDetails(row.original.other_materials, row.original.id)}
                  size='sm'
                  color='yellow'
                  radius='xs'
                  fullWidth
                >
                  Submit
                </Button>
              )}
            </>
          );
        },
      },
      {
        Header: 'Status',
        Cell: ({ row }) => {
          return (
            <Badge
              color={
                row.original.status === 'In Progress' &&
                !row.original.finished_date &&
                moment(new Date()).format('YYYY-MM-DD') <=
                  moment(row.original.sla_date).format('YYYY-MM-DD')
                  ? 'yellow'
                  : (row.original.status === 'In Progress' &&
                      moment(row.original.finished_date).format('YYYY-MM-DD') >
                        moment(row.original.sla_date).format('YYYY-MM-DD')) ||
                    (row.original.status === 'In Progress' &&
                      moment(new Date()).format('YYYY-MM-DD') >
                        moment(row.original.sla_date).format('YYYY-MM-DD'))
                  ? 'red'
                  : row.original.status === 'Closed' &&
                    moment(row.original.finished_date).format('YYYY-MM-DD') <=
                      moment(row.original.sla_date).format('YYYY-MM-DD')
                  ? 'green'
                  : row.original.status === 'Closed' &&
                    moment(row.original.finished_date).format('YYYY-MM-DD') >
                      moment(row.original.sla_date).format('YYYY-MM-DD')
                  ? 'red'
                  : 'gray'
              }
              size='lg'
            >
              {row.original.status}
            </Badge>
          );
        },
      },

      {
        Header: 'Log',
        Cell: ({ row }) => {
          return (
            <Button
              onClick={() => getLogs(row.original.id)}
              style={{ background: 'black', color: 'white' }}
            >
              View Logs
            </Button>
          );
        },
      },
      {
        Header: 'Step Deadline',
        Cell: ({ row }) => {
          return <Text>{moment(row.original.sla_date).format('YYYY-MM-DD')}</Text>;
        },
      },
      {
        Header: 'Step Finished',
        Cell: ({ row }) => {
          return (
            <Text>
              {row.original.finished_date
                ? moment(row.original.finished_date).format('YYYY-MM-DD')
                : row.original.status}
            </Text>
          );
        },
      },
    ],
    [vendors.length],
  );

  const tableInstance = useTable({
    columns,
    data,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <Styles>
      <div style={{ overflowX: 'auto' }}>
        <table style={{ width: 'fitContent' }} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} id='header-row'>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
              );
            })}
            {filterLoading && (
              <tr>
                <td colSpan={13}>
                  <div className='loader-container'>
                    <AppLoader size={'md'} />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <b>
          <font color='red'>
            {
              '* In case of multiple new suppliers, please use "/" while writing in the text box. For Example: 123456/654321'
            }
          </font>
        </b>
      </div>
      {logModal && (
        <ProductionLog
          logModal={logModal}
          setLogModal={setLogModal}
          id={imp_id}
          type='Supplier Name'
        />
      )}
    </Styles>
  );
}
