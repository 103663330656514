import React, { createContext, useContext, useEffect, useState } from 'react';
import { axios } from '../utils/axios';
import { useMsal } from '@azure/msal-react';
import {
  loginRequest,
  graphConfig,
  ProductNPSConfig,
  snowBackendRequest,
} from '../utils/authConfig';
import { roles } from '../constants';
import { isExpired, decodeToken } from 'react-jwt';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [userName, setUserName] = useState('');
  const [users, setUsers] = useState([]);
  const [techLeads, setTechleads] = useState([]);
  const [gateKeepers, setGatekeepers] = useState([]);
  const [d2pUsers, setD2pUsers] = useState([]);

  // to be replaced with better logic in future
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem('token') && localStorage.getItem('token') !== 'loading',
  );

  const { instance, accounts } = useMsal();
  const [profilePhoto, setProfilePhoto] = useState(null);

  useEffect(() => {
    const name = accounts[0] ? accounts[0]['name'].split(', ').reverse().join(' ') : '';

    setUserName(name);
    // setUserEmail(accounts[0].username);
  }, [accounts[0]]);

  useEffect(() => {
    if (loggedIn) {
      //ssoLogin instance

      info();
      fetchUsers();
      fetchTechleads();
      fetchGatekeepers();
      fetchD2pUsers();
    }
  }, [loggedIn]);

  const setToken = (token) => {
    localStorage.setItem('token', token);
  };

  const clearStorage = () => {
    localStorage.clear();
  };

  const login = (payload, callback) => {
    setLoading(true);
    axios
      .post('/user/login', {
        token: payload.token,
        email: payload.email,
      })
      .then(
        (res) => {
          if (res.data.token) {
            setLoggedIn(true);
            setToken(res.data.token);
            axios.defaults.headers.common['Authorization'] = `${res.data.token}`;
            callback('/home');
          } else {
            callback('/register');
          }
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() => setLoading(false));
  };

  const info = () => {
    setLoading(true);
    axios
      .get('/user/info')
      .then(
        (res) => {
          setUserRole(res.data.role);
          setUserEmail(res.data.email);
        },
        (error) => {},
      )
      .finally(() => setLoading(false));
  };

  const fetchUsers = () => {
    axios.get('/user/all').then(
      (res) => {
        let allUsers = [];
        let token = res.data.users;
        const myDecodedToken = decodeToken(token);

        allUsers = myDecodedToken.users.map((user) => {
          return { name: user.label, email: user.value };
        });

        setUsers(allUsers);
      },
      (error) => {},
    );
  };

  const fetchGatekeepers = () => {
    axios.get('/user/get', { params: { role: 'pmo' } }).then(
      (res) => {
        let allgatekeepers = [];
        let token = res.data.users;
        const myDecodedToken = decodeToken(token);

        allgatekeepers = myDecodedToken.users.map((user) => {
          return { name: user.label, email: user.value };
        });

        setGatekeepers(allgatekeepers);
      },
      (error) => {},
    );
  };

  const fetchTechleads = () => {
    axios.get('/user/get', { params: { role: 'zitec' } }).then(
      (res) => {
        let alltechleads = [];
        let token = res.data.users;
        const myDecodedToken = decodeToken(token);

        alltechleads = myDecodedToken.users.map((user) => {
          return { name: user.label, email: user.value };
        });

        setTechleads(alltechleads);
      },
      (error) => {},
    );
  };

  const fetchD2pUsers = () => {
    axios.get('/user/get', { params: { role: 'd2p' } }).then(
      (res) => {
        let allUsers = [];
        let token = res.data.users;
        const myDecodedToken = decodeToken(token);

        allUsers = myDecodedToken.users.map((user) => {
          return { name: user.label, email: user.value };
        });

        setD2pUsers(allUsers);
      },
      (error) => {},
    );
  };

  useEffect(() => {
    if (accounts.length > 0) {
      const fetchProfilePhoto = () => {
        instance
          .acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
          })
          .then((response) => {
            try {
              const headers = new Headers();
              const bearer = `Bearer ${response.accessToken}`;

              headers.append('Authorization', bearer);

              const options = {
                method: 'GET',
                headers: headers,
              };

              return fetch(graphConfig.graphProfilePhotoEndpoint, options)
                .then((response) => {
                  if (response.status === 200) {
                    response = response.blob().then((img) => {
                      if (img.type === 'image/jpeg') setProfilePhoto(img);
                    });
                  }
                })
                .catch((error) => console.log(error));
            } catch (error) {
              throw error;
            }
          })
          .catch((err) => {
            console.log(`Error occured while acquiring graph api token: ${err}`);
          });
      };

      const setNpsToken = () => {
        instance
          .acquireTokenSilent({
            ...ProductNPSConfig,
            account: accounts[0],
          })
          .then((response) => {
            if (response) {
              // setToken(response.accessToken);
              localStorage.setItem('token-customscope', response.accessToken);
            }
          })
          .catch((error) => {
            console.log('error acquiring nps token', error);
          });
      };

      const setSnowToken = () => {
        instance
          .acquireTokenSilent({
            ...snowBackendRequest,
            account: accounts[0],
          })
          .then((response) => {
            localStorage.setItem('snow_api_access_token', response?.accessToken);
          })
          .catch((err) => {
            console.log(`Error occurred while acquiring snow token: ${err}`);

            // instance.logout({
            //   account: accounts.length > 0 ? accounts[0] : null,
            // });
          });
      };

      fetchProfilePhoto();
      setNpsToken();
      setSnowToken();
    }
  }, [accounts]);

  const fetchNpsToken = () => {
    return new Promise((resolve, reject) => {
      instance
        .acquireTokenSilent({
          ...ProductNPSConfig,
          account: accounts[0],
        })
        .then((response) => {
          if (response) {
            resolve(response.accessToken);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  return (
    <AuthContext.Provider
      value={{
        loading,
        setLoading,
        login,
        loggedIn,
        setLoggedIn,
        setToken,
        clearStorage,
        userRole,
        setUserRole,
        users,
        gateKeepers,
        techLeads,
        setUsers,
        setTechleads,
        setGatekeepers,
        fetchUsers,
        fetchTechleads,
        fetchGatekeepers,
        userEmail,
        userName,
        profilePhoto,
        fetchD2pUsers,
        d2pUsers,
        fetchNpsToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
