import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ContentPaper from '../../components/layouts/rfs/Content';
import { loginRequest } from '../../utils/authConfig';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { Image, Text, Button, Grid, Divider } from '@mantine/core';
import abilogo from '../../images/abilogo.svg';
import abilogoback from '../../images/abilogoback.jpg';
import { AuthContext } from '../../contexts/AuthContext';

import './Login.css';
import { AppLoader } from '../../components/common/loader';
import styled from 'styled-components';

import { Link } from 'react-router-dom';

const LoadingContainer = styled.div`
  height: 100vh;
  width: 100vw;
`;

const Login = () => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const { login, loading, setToken, clearStorage, loggedIn } = useContext(AuthContext);
  const isAuthenticated = useIsAuthenticated();
  const TokenValue = localStorage.getItem('token');

  useEffect(() => {
    if (isAuthenticated) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };

      instance
        .acquireTokenSilent(request)
        .then((response) => {
          login(
            {
              token: response.accessToken,
              email: response.idTokenClaims.email,
            },
            (path) => {
              navigate(path);
            },
          );
        })
        .catch((e) => {
          clearStorage();
          navigate('/');
        });
    }
  }, [isAuthenticated]);

  const handleLogin = () => {
    setToken('loading');
    instance.loginRedirect(loginRequest).catch(() => clearStorage());
  };

  if (loading || TokenValue === 'loading')
    return (
      <LoadingContainer>
        <AppLoader size='lg' center />
      </LoadingContainer>
    );
  // random
  return (
    //<ContentPaper page='home-login'>
    <div style={{ height: '100vh', overflow: 'hidden' }}>
      <Grid align='flex-start'>
        <Grid.Col span={12} className='photo'>
          <Image src={abilogoback} alt='AB InBev Logo Back' />
        </Grid.Col>

        <Grid.Col span={12} className='data content'>
          <Grid.Col span={6} className='loginForm'>
            <div className='innerContent'>
              <div>
                <Link to={'/'}>
                  <Image
                    style={{
                      maxWidth: '50%',
                      margin: '0 auto',
                    }}
                    className='logo'
                    src={abilogo}
                    alt='AB InBev logo'
                  />
                </Link>
                <Divider className='divider' my='1'></Divider>
                <Image
                  style={{
                    maxWidth: '50%',
                    margin: '0 auto',
                    clipPath: 'inset(0% 0% 18% 0%)',
                  }}
                  className='logo'
                  src={'/images/STM_Logo_White_Yellow.svg'}
                  alt='AB InBev logo'
                />
                <Text
                  style={{
                    marginTop: '-10px',
                    fontSize: '3.2vh',
                    fontWeight: '600',
                    display: 'inline-flex',
                    justifyContent: 'center',
                    width: '100%',
                    color: '#fff',
                  }}
                >
                  Speed to Market
                </Text>
                <Text
                  className='grd-txt-yellow'
                  style={{
                    fontSize: '22px',
                    fontWeight: '500',
                    display: 'inline-flex',
                    justifyContent: 'center',
                    width: '100%',
                    color: '#fff',
                    marginTop: '25px',
                  }}
                >
                  Welcome
                </Text>
                <Text
                  size='lg'
                  style={{
                    display: 'inline-flex',
                    fontSize: '0.7em',
                    justifyContent: 'center',
                    width: '100%',
                    color: '#fff',
                  }}
                >
                  Please use your official AB InBev ID to login
                </Text>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-evenly',
                  flexWrap: 'wrap',
                  aligncontent: 'center',
                  alignItems: 'center',
                  maxWidth: '55%',
                  margin: '0 auto',
                }}
              >
                <Button
                  className='login w-100'
                  variant='gradient'
                  gradient={{ from: '#D7A833', to: '#F4DF03', deg: 90 }}
                  styles={(theme) => ({
                    root: {
                      color: '#000',
                      margin: '20px 0 15px',
                    },
                  })}
                  onClick={() => handleLogin()}
                >
                  Login
                </Button>

                <Button
                  className='register w-100'
                  variant='gradient'
                  gradient={{ from: '#D7A833', to: '#F4DF03', deg: 90 }}
                  styles={(theme) => ({
                    root: {
                      color: '#000',
                    },
                  })}
                  onClick={() => handleLogin()}
                >
                  Register
                </Button>
              </div>
            </div>
          </Grid.Col>
        </Grid.Col>
      </Grid>
    </div>
  );
};

export default Login;
