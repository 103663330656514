import React, { useState, useContext, useEffect } from 'react';
import { Box, Accordion, Grid, Button } from '@mantine/core';
import ContentPaper from '../../../../components/layouts/rfs/Content';
import { Row } from 'react-bootstrap';
import { RfsContext } from '../../../../contexts/RfsContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AppLoader } from '../../../../components/common/loader';
import { MaterialChangeTable } from './MaterialChangeTable';
import { TableExport } from 'tabler-icons-react';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { roles } from '../../../../constants';
import { AuthContext } from '../../../../contexts/AuthContext';
import { sCodeStyles } from '../../../../utils/helpers';

export default function MaterialChangeCode() {
  const { userRole } = useContext(AuthContext);
  const { form } = useContext(RfsContext);
  const { rfs_number } = form.rfsInformation;
  const accessors = {
    materials: 'selection_number',
    liquids: 'selection_number',
    skus: 'selection_number',
  };
  const secondaryAccessor = {
    materials: 'selection_name',
    liquids: 'selection_name',
    skus: 'selection_name',
  };
  const tertiaryAccessor = {
    materials: null,
    liquids: null,
    skus: null,
  };
  const [scopeErrors, setScopeErrors] = useState({
    errors: {},
    liquidErrors: {},
    materialErrors: {},
    productionSiteErrors: {},
  });

  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(true);

  const [materialChange, setMaterialChange] = useState([]);

  const { fetchMaterialChange, StoreManualClosure } = useContext(RfsContext);

  useEffect(() => {
    getData(rfs_number);
  }, []);

  useEffect(() => {
    if ([roles.admin, roles.superAdmin, roles.pmo, roles.mrp].includes(userRole)) setDisable(false);
  }, [userRole]);

  const getData = (rfs_number) => {
    setLoading(true);
    fetchMaterialChange(rfs_number).then((res) => {
      setMaterialChange(res.data.data);
      setLoading(false);
    });
  };

  const save = (payload, id, callback) => {
    StoreManualClosure(payload, id).then(() => {
      refresh(rfs_number);
      callback();
    });
  };

  const refresh = (rfs_number) => {
    fetchMaterialChange(rfs_number).then((res) => {
      setMaterialChange(res.data.data);
    });
  };

  const sort_MatChange_acctoSKUs = [...materialChange].sort((a, b) => a.scope_id - b.scope_id);

  const selectedScopeFn = (data) => {
    let selectedScopeList = {};
    for (let i = 0; i < data.length; i++) {
      selectedScopeList[data[i].id] = {
        selection_number: data[i].selection_number,
        selection_name: data[i].selection_name,
      };
    }
    return selectedScopeList;
  };

  var selectedScopeList = selectedScopeFn(form.selectedScope.skus);

  const dataToExport = ({
    scope_id,
    manually_closed,
    sla_date,
    finished_date,
    reference_plant,
    status,
    int_rfs_ps_sku_reference,
    int_rfs_ps_bom,
    material_description,
    material_number,
  }) => {
    return {
      'SKU no.': selectedScopeList[scope_id]?.selection_number,
      'SKU DESC': selectedScopeList[scope_id]?.selection_name,
      Plant: int_rfs_ps_sku_reference.selected_plant,
      'New material code': material_number,
      'New material description ': material_description,
      Action:
        status === 'Closed' ? (manually_closed ? 'Manually Closed' : 'Automatic Closure') : 'NA',
      Status: status,
      'Step Deadline': sla_date,
      'Step Finish': status === 'Closed' ? finished_date : status,
    };
  };
  const csvArray = sort_MatChange_acctoSKUs.map(dataToExport);

  if (loading) {
    return (
      <ContentPaper page={'ProductDevelopment'}>
        <AppLoader size='lg' center />
      </ContentPaper>
    );
  }

  return (
    <ContentPaper page={'ProductDevelopment'}>
      <Box sx={sCodeStyles}>SKU CODES</Box>
      <div
        style={{
          display: 'flex',
          justifyContent: 'right',
          marginRight: '4rem',
        }}
      >
        <CSVLink
          data={csvArray}
          filename={'Material Status Change_' + moment().format('YYYY-MM-DD HH:mm:ss')}
        >
          <Button
            leftIcon={<TableExport />}
            color='yellow'
            style={{
              marginBottom: '1rem',
              marginTop: '2rem',
              background: 'black',
            }}
          >
            Export to CSV
          </Button>
        </CSVLink>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'right',
          marginRight: '4rem',
        }}
      ></div>
      <Row>
        <Accordion defaultValue={'key_0'}>
          {Object.keys(form?.selectedScope || {}).map((name) =>
            form?.selectedScope[name].map((item, index) => {
              const materialList = materialChange?.findIndex((m) => m.scope_id === item.id);
              const plants = Object.values(form.scopeSelectedSkus);
              const uniquePlants = {};
              plants.forEach((p) => {
                if (p.scope_id === item.id && p.active) {
                  if (uniquePlants[p.selected_plant]) {
                    uniquePlants[p.selected_plant].plantIds.push(p.id);
                  } else {
                    uniquePlants[p.selected_plant] = { plantIds: [p.id] };
                  }
                }
              });
              return (
                materialList > -1 && (
                  <Accordion.Item
                    className='scope-label'
                    key={`key_${name}_${index}`}
                    value={`key_${name}_${index}`}
                  >
                    <Accordion.Control className={scopeErrors.errors[item.id] ? 'error-label' : ''}>
                      {`${name.substring(0, 3).toUpperCase()} -  ${item[accessors[name]]}- ${
                        item[secondaryAccessor[name]]
                      }${tertiaryAccessor[name] ? `- ${item[tertiaryAccessor[name]]}` : ''}`}
                    </Accordion.Control>
                    <Accordion.Panel>
                      {Object.keys(uniquePlants).map((plant, index) => {
                        const data = materialChange.filter((d) =>
                          uniquePlants[plant].plantIds.includes(d.int_rfs_ps_sku_reference.id),
                        );

                        return (
                          data.length > 0 && (
                            <Accordion>
                              <Accordion.Item
                                className='scope-label'
                                value={`key_${name}_${index}`}
                                key={`key_${name}_${index}`}
                              >
                                <Accordion.Control
                                  className={scopeErrors.errors[item.id] ? 'error-label' : ''}
                                >{`Plant ${plant}`}</Accordion.Control>
                                <Accordion.Panel>
                                  {
                                    <Grid>
                                      <Grid.Col span={12}>
                                        <MaterialChangeTable
                                          materialData={data}
                                          save={save}
                                          disable={disable}
                                        />
                                      </Grid.Col>
                                    </Grid>
                                  }
                                </Accordion.Panel>
                              </Accordion.Item>
                            </Accordion>
                          )
                        );
                      })}
                    </Accordion.Panel>
                  </Accordion.Item>
                )
              );
            }),
          )}
        </Accordion>
      </Row>
    </ContentPaper>
  );
}
