import React, { useState, useContext, useEffect } from 'react';
import { Button, Checkbox, Select, Textarea, TextInput } from '@mantine/core';
import { MultiSelect } from '@mantine/core';
import { AppLoader } from '../../common/loader';
import { ProjectOverviewContext } from '../../../contexts/projectOverviewContext';
import { showAlert } from '../../../utils/alerts';
import {
  changeoverCancellation,
  existingLiquidsTrialOptions,
  newLiquidsTrialOptions,
  quantitiesChange,
  scopeRestatment,
  technicalSpecificationNumberChange,
} from '../../../constants/product-development';
import { liquidBomChanges } from '../../../constants';
import { AuthContext } from '../../../contexts/AuthContext';
import DOMPurify from 'dompurify';

export const ProductDevelopmentTableLiquid = ({
  trials,
  accordionIndex,
  selectionNumber,
  editable,
}) => {
  const [saveLoading, setSaveLoading] = useState(false);
  const {
    saveLiquids,
    vendors,
    liquids,
    setLiquids,
    liquidStepStatus,
    getLiquidsImplementation,
    stepEigibleRoles,
  } = useContext(ProjectOverviewContext);
  const [stepId, setStepId] = useState();
  const [activeLiquidsIndex, setActiveLiquidsIndex] = useState();
  const { userRole } = useContext(AuthContext);

  useEffect(() => {
    if (liquids.length > 0) {
      getLiquidsImplementation(
        liquids.map((liquid) => {
          return {
            liquid_number: liquid.liquid_number,
            plant: liquid.plant,
            sup_number: liquid.sup_number,
            selection_number: liquid.selection_number,
            packMatsMaterialType: liquid.packMatsMaterialType,
          };
        }),
      );

      //update number if liquid number when liquid type is same Liquid
      liquids.map((liquid) => {
        if (liquid.plant === 'DE02') {
          /* console.log(
            (liquid[`liquid_type`] === 'Same Liquid' ||
              liquid.bom_changes === liquidBomChanges.removal ||
              [liquidBomChanges.drawing].includes(liquid.bom_changes)) &&
              !liquid.liquid_number,
          );
          console.log(liquid); */
        }

        if (
          (liquid[`liquid_type`] === 'Same Liquid' ||
            liquid.bom_changes === liquidBomChanges.removal ||
            [liquidBomChanges.drawing].includes(liquid.bom_changes)) &&
          !liquid.liquid_number
        ) {
          handleClick(liquid);
        }
      });
    }
  }, [liquids.length]);

  useEffect(() => {
    setActiveStepInfo();
  }, [accordionIndex]);

  const setActiveStepInfo = (index = -1, stepId = null) => {
    setActiveLiquidsIndex(index);
    setStepId(stepId);
  };

  const handleChange = (event, index, stepId) => {
    setActiveStepInfo(index, stepId);
    const currentValues = [...liquids];
    if (event.target.type === 'checkbox') {
      currentValues[index][event.target.name] = event.target.checked;
    } else {
      currentValues[index][event.target.name] = event.target.value;
    }

    setLiquids(currentValues);
  };

  const handleSelect = (type, value, index, stepId) => {
    setActiveStepInfo(index, stepId);
    const currentValues = [...liquids];
    currentValues[index][type] = value;

    setLiquids(currentValues);
  };

  const sanitizePayload = (data) => {
    let payload = { ...data };
    if (payload.liquid_type === 'New Liquid' && !payload.liquid_number) {
      payload.new_liquid_code = payload.new_liquid_number;
      delete payload.new_liquid_number;
    } else if (payload.liquid_type === 'Existing Liquid') {
      payload.new_liquid_code = payload.liquid_number;
    } else if (
      payload.liquid_type === 'Same Liquid' ||
      payload.bom_changes === liquidBomChanges.removal ||
      [liquidBomChanges.drawing].includes(payload.bom_changes)
    ) {
      payload.new_liquid_code = payload.liquid_code;
    }

    if (payload.SelectionPlant) {
      payload.plant = payload.ComponentPlant;
      delete payload.ComponentPlant;
    }

    if (payload.liquid_code) {
      payload.old_liquid_code = payload.liquid_code;
      delete payload.liquid_code;
    }

    if (payload.supplier_name_number) {
      payload.supplier_name_number = payload.supplier_name_number.join(',');
    }

    return payload;
  };

  const handleClick = (data) => {
    setSaveLoading(true);
    const payload = sanitizePayload({ ...data });

    saveLiquids(payload, () => setSaveLoading(false), trials);
  };

  const getSuppliers = (item) => {
    const vendors = item.sup_number.split('/');
    const vendorsDescription = item.sup_name.split('/');
    const finalList = [];

    vendors.map((vendor, key) => {
      finalList.push(`${vendor}-${vendorsDescription[key]}`);
    });

    return finalList.join('<br />');
  };
  const rows = [
    {
      step_id: 'trial_liquid_code',
      status_key: 'Liquid Code',
      name: <b>{trials ? 'Trial Liquid Code' : 'Liquid Code'}</b>,
      render: (item, index, active, step_id) => {
        return (
          <>
            {item.liquid_number || item.new_liquid_code ? (
              item.liquid_number ? (
                item.liquid_number
              ) : (
                item.new_liquid_code
              )
            ) : (
              <>
                <TextInput
                  name='new_liquid_number'
                  value={item.new_liquid_number ? item.new_liquid_number : ''}
                  disabled={!active || !editable}
                  onChange={(event) => handleChange(event, index, step_id)}
                />
                {stepId === step_id && activeLiquidsIndex === index && (
                  <SaveButton clickHandler={() => handleClick(item)} active={active} />
                )}
              </>
            )}
          </>
        );
      },
    },
    {
      step_id: 'liquid_status',
      name: <b>Liquid Status</b>,
      render: (item, index, active, step_id, status) => {
        if (getStepStatus('trial_liquid_code', item)) return 'Open';

        return status === 'inprogress'
          ? 'In progress'
          : item.packMatsPlantStatus
          ? item.packMatsPlantStatus
          : item.plantDataPlantStatus
          ? item.plantDataPlantStatus
          : 'In progress';
      },
    },
    {
      step_id: 'liquid_description',
      name: <b>Liquid Description</b>,
      render: (item, index, active, step_id, status) => {
        if (getStepStatus('trial_liquid_code', item)) return 'Open';

        return status === 'inprogress'
          ? 'In progress'
          : item.PackMatDesc
          ? item.PackMatDesc
          : item.PlantDataDesc;
      },
    },
    {
      step_id: 'supplier_number_and_name',
      name: <b>Supplier Number & Name</b>,
      render: (item, index, active, step_id) => {
        return item.sup_number ? (
          <div style={{ padding: '10px 0px 10px 10px' }}>
            <div
              style={{
                height: '75px',
                overflowY: 'auto',
              }}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(getSuppliers(item)),
              }}
            />
          </div>
        ) : (
          <>
            <MultiSelect
              searchable
              name='supplier_name_number'
              onChange={(value) => {
                if (value.length > 5)
                  return showAlert({ message: 'Only 5 vendors can be selected' }, 'error');
                handleSelect('supplier_name_number', value, index, step_id);
              }}
              value={item.supplier_name_number || []}
              dropdownPosition='bottom'
              data={vendors}
              disabled={!active || !editable}
            />
            {stepId === step_id && activeLiquidsIndex === index && (
              <SaveButton clickHandler={() => handleClick(item)} active={active} />
            )}
          </>
        );
      },
    },

    {
      step_id: 'changeover',
      name: <b>Changeover</b>,
      render: (item, index, active, step_id, status) =>
        status === 'closed' ? (
          <Checkbox
            label='Automatically closed'
            style={{ padding: '10px' }}
            checked={true}
            disabled={true}
          />
        ) : status === 'inprogress' ? (
          'In progress'
        ) : (
          'Open'
        ),
    },

    {
      step_id: 'trial',
      name: <b>Trials (Success or Fail)</b>,
      render: (item, index, active, step_id) => (
        <>
          <Select
            searchable
            clearable={active}
            dropdownPosition='bottom'
            data={[
              { value: 'success', label: 'Success' },
              { value: 'fail', label: 'Fail' },
            ]}
            name='trial_status'
            value={item.trial_status}
            disabled={!active || !editable}
            onChange={(value) => handleSelect('trial_status', value, index, step_id)}
          />

          {stepId === step_id && activeLiquidsIndex === index && (
            <SaveButton clickHandler={() => handleClick(item)} active={active} />
          )}
        </>
      ),
    },
    {
      step_id: 'trials_failed',
      status_key: 'Trials Failed',
      name: <b>Trials Failed</b>,
      render: (item, index, active, step_id) => (
        <>
          <Select
            searchable
            clearable={active}
            dropdownPosition='bottom'
            name='trial_fail'
            data={
              item.material_type === 'Existing Liquid'
                ? existingLiquidsTrialOptions
                : newLiquidsTrialOptions
            }
            value={item.trial_fail}
            onChange={(value) => handleSelect('trial_fail', value, index, step_id)}
            disabled={!active || !editable}
          />

          {stepId === step_id && activeLiquidsIndex === index && (
            <SaveButton clickHandler={() => handleClick(item)} active={active} />
          )}
        </>
      ),
    },
    {
      step_id: 'reason_of_failure',
      name: <b>Reason of Failure</b>,
      render: (item, index, active, step_id) => (
        <>
          <Textarea
            style={{ paddingTop: '10px' }}
            placeholder='Please write reason here'
            name='fail_reason'
            onChange={(event) => handleChange(event, index, step_id)}
            value={item.fail_reason}
            disabled={!active || !editable}
            required
          />
          {stepId === step_id && activeLiquidsIndex === index && (
            <SaveButton clickHandler={() => handleClick(item)} active={active} />
          )}
        </>
      ),
    },
    {
      step_id: 'tech_spec_number_changed',
      name: <b>Tech Spec Number Changed</b>,
      render: (item, index, active, step_id) =>
        item.trial_fail === technicalSpecificationNumberChange ? (
          <>
            <Textarea
              disabled={!active || !editable}
              style={{ paddingTop: '10px' }}
              placeholder='Please type here'
              name='tech_spec_matrix'
              onChange={(event) => handleChange(event, index, step_id)}
              value={item.tech_spec_matrix}
              required
            />
            {stepId === step_id && activeLiquidsIndex === index && (
              <SaveButton clickHandler={() => handleClick(item)} active={active} />
            )}
          </>
        ) : (
          'Not Applicable'
        ),
    },
    {
      step_id: 'quantities_changed',
      name: <b>Quantities Changed</b>,
      render: (item, index, active, step_id) =>
        [scopeRestatment, quantitiesChange].includes(item.trial_fail) ? (
          <>
            <TextInput
              type='number'
              disabled={!active || !editable}
              style={{ paddingTop: '10px' }}
              placeholder='Please enter quantities'
              name='new_quantity'
              onChange={(event) => handleChange(event, index, step_id)}
              value={item.new_quantity}
              required
            />
            {stepId === step_id && activeLiquidsIndex === index && (
              <SaveButton clickHandler={() => handleClick(item)} active={active} />
            )}
          </>
        ) : (
          'Not Applicable'
        ),
    },

    {
      step_id: 'pack_mat_change',
      name: <b>Pack Mat Change</b>,
      status_key: 'Pack Mat Change',
      render: (item, index, active) => (
        <>
          <div className='artworkCheck'>
            <Checkbox
              style={{ padding: '10px' }}
              label='Supplier Production'
              checked={item.StockAtSupplierProduction > 0}
              disabled={true}
            />
            <Checkbox
              style={{ padding: '10px' }}
              checked={item.StockAtSupplier > 0}
              label='Material on stock at supplier'
              disabled={true}
            />
            <Checkbox
              style={{ padding: '10px' }}
              label='Material on stock at ABI'
              disabled={true}
              checked={item.ABICount > 0}
            />
          </div>
        </>
      ),
    },
    {
      name: <b>Filling/Brewing/Repacking</b>,
      render: (item) => (item.fillingStatus && item.fillingStatus > 0 ? 'Closed' : 'Open'),
    },
    {
      step_id: 'removal_of_changeover',
      name: <b>Removal of Changeover</b>,
      render: (item, index, active, step_id, status) =>
        [changeoverCancellation, scopeRestatment].includes(item.trial_fail) ? (
          <>
            <Checkbox
              checked={
                (item.changeoverAppliedStatusRemoval > 0 &&
                  item.material_type === 'Remove Liquid') ||
                item.changeoverAppliedStatus === 0
              }
              name='changeover_removal'
              disabled={true}
            />
          </>
        ) : (
          'Not Applicable'
        ),
    },
  ];

  const getStepStatus = (step_id, item) => {
    const key = `${item.selection_number}_${item.liquid_code}`;
    if (liquidStepStatus[key]) {
      return ['inprogress'].includes(liquidStepStatus[key][step_id]);
    }

    return false;
  };

  const getStepStatusName = (step_id, item) => {
    return liquidStepStatus[`${item.selection_number}_${item.liquid_code}`][step_id];
  };

  const SaveButton = ({ clickHandler, active }) =>
    active ? (
      <Button
        style={{ padding: '10px', marginTop: '10px' }}
        onClick={clickHandler}
        position='Right'
        size='sm'
        color='yellow'
        radius='xs'
        disabled={saveLoading}
        fullWidth
      >
        {saveLoading ? <AppLoader /> : 'Submit'}
      </Button> 
    ) : null;

  return (
    <>
      {rows.map(({ name, render, step_id }, index) =>
        (trials && !['filing_brewing_repacking', 'pack_mat_change'].includes(step_id)) ||
        (!trials &&
          ![
            'material_status_change',
            'tech_spec_number_changed',
            'trials_failed',
            'reason_of_failure',
            'trial',
            'quantities_changed',
          ].includes(step_id)) ? (
          <tr key={`liquid_${index}`}>
            <td className='prod-dev-table-header'>{name}</td>
            {(liquids || []).map((item, i) => {
              const status = getStepStatusName(step_id, item);

              return (
                selectionNumber === item.selection_number && (
                  <td className='prod-dev-table-data'>
                    {[
                      'trials_failed',
                      'reason_of_failure',
                      'tech_spec_number_changed',
                      'removal_of_changeover',
                      'quantities_changed',
                    ].includes(step_id) && item.trial_status !== 'Fail'
                      ? 'Not Applicable'
                      : render(
                          item,
                          i,
                          getStepStatus(step_id, item) &&
                            stepEigibleRoles[step_id] &&
                            stepEigibleRoles[step_id].includes(userRole),
                          step_id,
                          status,
                        )}
                  </td>
                )
              );
            })}{' '}
          </tr>
        ) : null,
      )}
    </>
  );
};
