import React, { useMemo, useEffect } from 'react';
import { useTable, usePagination, useFilters, useSortBy } from 'react-table';
import { ChevronDown, ChevronUp, ArrowsSort } from 'tabler-icons-react';
import { Styles } from '../../scopeComp/tableStyles';
import { Checkbox, TextInput } from '@mantine/core';
import { PaginationContainer } from '../SKUDetailsTable/Styles';
import moment from 'moment';
import { TablePagination } from '../SKUDetailsTable/Pagination';
import { AppLoader } from '../../common/loader';

export default function SkuSelectedMaterialTable({
  data = [],
  handleChange = () => {},
  materialPlant = '',
  readOnly = false,
  handleSelectAll,
  isSubmissionReview,
  loading = false,
}) {
  const columns = useMemo(
    () => [
      {
        Header: 'SKUs',
        accessor: 'SKUs',
        canFilter: true,
      },
      {
        Header: 'SKU Description',
        accessor: 'SKUDescription',
        canFilter: true,
      },
      {
        Header: 'Alt BOM',
        accessor: 'AlternativeBOM',
        canFilter: true,
      },
      {
        Header: 'Plant',
        accessor: 'Plant',
        canFilter: true,
      },
      {
        Header: 'Component Qty',
        accessor: 'ComponentQty',
        canFilter: true,
      },
      {
        Header: 'UoM',
        accessor: 'UoM',
        canFilter: true,
      },
      {
        Header: 'Markets',
        accessor: 'Markets',
        canFilter: true,
        Cell: ({ row }) => {
          let data = (row?.original?.Markets || '').split(', ');
          return (
            <div style={{ height: 'auto', overflowY: 'auto', maxHeight: '100px' }}>
              {data.map((market, index) => {
                if (index !== 0 && index % 10 === 0) {
                  return (
                    <>
                      <br />
                      <span>{`${market}, `}</span>
                    </>
                  );
                } else {
                  return <span>{`${market} ${index === data.length - 1 ? '' : ', '}`}</span>;
                }
              })}
            </div>
          );
        },
      },
      {
        Header: 'EAN Numbers',
        accessor: 'EANNumbers',
        canFilter: true,
        Cell: ({ row }) => {
          let data = (row?.original?.EANNumbers || '').split('; ');
          data = [...new Set(data)];

          return (
            <div>
              {data.map((d, k) => (
                <p key={k} style={{ marginBottom: '0px' }}>
                  {d}
                </p>
              ))}
            </div>
          );
        },
      },
      {
        Header: 'Valid Till',
        accessor: 'ValidTill',
        canFilter: true,
        Cell: ({ row }) => {
          return (
            <div>
              {moment().isValid(row.original.ValidTill)
                ? moment(row.original.ValidTill, 'YYYYMMDD').format('DD-MM-YYYY')
                : 'N/A'}
            </div>
          );
        },
      },
    ],
    [data],
  );

  if (!readOnly && !isSubmissionReview) {
    columns.unshift({
      Header: ({ filteredRows }) => {
        return (
          <Checkbox onChange={(event) => handleSelectAll(event, materialPlant, filteredRows)} />
        );
      },
      id: 'checkbox',
      Cell: ({ row }) => (
        <Checkbox
          checked={row.original.selected}
          onChange={(event) => handleChange(event, row, materialPlant)}
        />
      ),
    });
  }

  const DefaultColumnFilter = ({ column: { filterValue, setFilter } }) => {
    return isSubmissionReview ? null : (
      <TextInput
        disabled={isSubmissionReview}
        value={filterValue || ''}
        placeholder={'Search'}
        className='liquidComment'
        size='sm'
        name='comment'
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
        id='fname'
      />
    );
  };

  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    [],
  );

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    [],
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;

  useEffect(() => {
    setPageSize(10);
  }, [setPageSize]);

  return (
    <>
      <div style={{ overflowX: 'auto', marginBottom: '10px' }}>
        <Styles>
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    return (
                      <td>
                        {column?.columns?.length > 0 ? (
                          column.render('Header')
                        ) : (
                          <>
                            <span
                              {...column.getHeaderProps(column.getSortByToggleProps())}
                              style={{
                                boxShadow: 'none',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              {column.render('Header')}
                              {!column.disableSortBy && (
                                <>
                                  {column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <ChevronDown className='sort-icon' />
                                    ) : (
                                      <ChevronUp className='sort-icon' />
                                    )
                                  ) : column.id === 'checkbox' ? null : (
                                    <ArrowsSort className='sort-icon' />
                                  )}
                                </>
                              )}
                            </span>
                            <div>{column.canFilter ? column.render('Filter') : null}</div>
                          </>
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr style={{ textAlign: 'left' }} {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          style={{
                            textAlign: 'left',
                            paddingLeft: cell.column.id === 'checkbox' ? '0.8rem' : '2rem',
                          }}
                          {...cell.getCellProps()}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          {loading && (
            <div style={{ padding: '16px' }}>
              {' '}
              <AppLoader center size='lg' />
            </div>
          )}
        </Styles>
      </div>
      <PaginationContainer>
        <TablePagination
          total={data.length}
          page={page}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageOptions={pageOptions}
          pageCount={pageCount}
          gotoPage={gotoPage}
          nextPage={nextPage}
          previousPage={previousPage}
          setPageSize={setPageSize}
          pageIndex={pageIndex}
          pageSize={pageSize}
        />
      </PaginationContainer>
    </>
  );
}
