import React from 'react';
import { Modal, Button, Grid, Group, Text } from '@mantine/core';
import { InfoCircle } from 'tabler-icons-react';

export const NewMarketModal = ({ invalidModal, setInvalidModal }) => {
  const handleClose = () => {
    setInvalidModal(false);
  };

  return (
    <>
      <Modal
        className='Invalid-modal'
        opened={invalidModal}
        onClose={() => handleClose()}
        title='Warning'
      >
        <Grid>
          <Grid.Col span={12}>
            <Group noWrap className='invalid-modal-group'>
              <InfoCircle color='red' size='5rem' />
              <Text size='sm'>
                You have not selected any new markets. Please select a new market to proceed.
              </Text>
            </Group>
          </Grid.Col>

          <Grid.Col span={4} offset={8}>
            <Button
              onClick={() => handleClose()}
              variant='filled'
              color='yellow'
              fullWidth
              style={{background:'linear-gradient(90deg,#e3af32 0%,#f4e00f 100%)',color: 'black' }}
            >
              Ok
            </Button>
          </Grid.Col>
        </Grid>
      </Modal>
    </>
  );
};
