import React, { useEffect, useState, useContext } from 'react';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import {
  ActionIcon,
  Button,
  Flex,
  Tooltip,
  Switch,
  TextInput,
  Select,
  MantineProvider,
  useMantineTheme,
  Alert,
} from '@mantine/core';
import {
  IconEdit,
  IconSearch,
  IconSearchOff,
  IconEditCircle,
  IconEditCircleOff,
  IconRefresh,
  IconAlertCircle,
} from '@tabler/icons-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { RfsContext } from '../../contexts/RfsContext';
import { AuthContext } from '../../contexts/AuthContext';
import ContentPaper from '../layouts/rfs/Content';

//CSV Download
import csvDownload from 'json-to-csv-export';
import { TableExport } from 'tabler-icons-react';
import moment from 'moment';
import withConfirm from '../common/confirm-dialog';
import { dateFormat } from '../../constants';

const EDIT_LIMIT = 50;

function transformData(data, newUsername, toBeEditedValue) {
  // Check if data is an array and not empty
  if (Array.isArray(data) && data.length > 0) {
    // Iterate through the array and transform each object
    const transformedData = data.map((item) => {
      // Extract MaterialNumber and Status
      const { MaterialNumber, Status, ...rest } = item;
      // Add the new key-value pair for Username
      const updatedItem = {
        MaterialNumber,
        Status: toBeEditedValue.value,
        Username: newUsername,
        Changetype: 'report',
      };

      return updatedItem;
    });

    return transformedData;
  } else {
    console.error('Invalid data format or empty array');
    return [];
  }
}

const DecomplexityReport = ({
  columns,
  setValidationErrors,
  validationErrors,
  statusData,
  unique,
  enableEditing,
  readOnly,
  confirm,
}) => {
  const [switchChecked, setSwitchChecked] = useState(false);
  const [selected, setSelected] = useState([{}]);
  const [rowSelection, setRowSelection] = useState({});
  const { userName } = useContext(AuthContext);
  const [filteredRows, setFilteredRows] = useState([]);
  const globalTheme = useMantineTheme;

  //Custom search
  const [toBeSearched, setToBeSearched] = useState('');

  //Search Icon
  const [searchOn, setSearchOn] = useState(false);
  const [allEditOn, setAllEditOn] = useState(false);

  //AllEdit
  const [toBeEditedValue, setToBeEditedValue] = useState({});
  const [selectType, setSelectType] = useState('');

  //All Select
  const [allSelected, setAllSelected] = useState(false);

  const [data, setData] = useState([]);
  const [successData, setSuccessData] = useState([]);

  //const data = switchChecked && Object.keys(selected).length ? selected : reportData;
  const { fetchDecomplexity, updateDecomplexity } = useContext(RfsContext);

  //READ hook (get data in api)
  function useGet() {
    return useQuery({
      queryKey: ['fetchDecomplexity'],
      queryFn: fetchDecomplexity,
      refetchOnWindowFocus: false,
    });
  }

  //UPDATE hook (put data in api)
  function useUpdate() {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: updateDecomplexity,
      //client side optimistic update
      onMutate: async (newData) => {
        await queryClient.setQueryData(['updateDecomplexity'], (prevData) =>
          prevData?.map((oldData) => (oldData[unique] === newData[unique] ? newData : oldData)),
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['fetchDecomplexity'] });
      },
    });
  }

  //function to format data
  const formattingData = (data) => {
    data = data.map((item) => {
      item.LastModified = moment(item.LastModified).isValid()
        ? moment(item.LastModified).format('DD-MM-YYYY')
        : 'N/A';
      return item;
    });
    return data;
  };

  //call READ hook
  // let successData = [];
  const {
    data: fetchedData = [],
    isError,
    isFetching,
    isLoading,
    error,
    status,
    refetch,
  } = useGet();

  useEffect(() => {
    if (status === 'success' && !isFetching) {
      let data = fetchedData.data.data;
      data = formattingData(data);
      setData(data);
      setSuccessData(data);
      setFilteredRows(data);

      if (selected.length > 0) updateSelectedRecords(data);
    }
  }, [status, isFetching]);

  //call UPDATE hook
  const { mutateAsync: updateFn, isLoading: isUpdating } = useUpdate();

  const validateRequired = (value) => !!value?.length;

  function validate(items) {
    return {
      Status: !validateRequired(items.Status) ? 'Status is Required' : '',
    };
  }

  const updateSelectedRecords = (data) => {
    const materialObject = {};
    data.forEach((val) => {
      materialObject[val.MaterialNumber] = val;
    });

    let currentValues = [...selected];
    currentValues = currentValues.map((val) => {
      return materialObject[val.MaterialNumber];
    });

    setSelected(currentValues);
  };

  //UPDATE action
  const handleSave = async ({ values, exitEditingMode }) => {
    if (!readOnly) {
      const payload = {
        MaterialNumber: values.MaterialNumber,
        Username: userName,
        Status: values.Status,
        Changetype: 'report',
      };
      const newValidationErrors = validate(values);
      if (Object.values(newValidationErrors).some((error) => error)) {
        setValidationErrors(newValidationErrors);
        return;
      }
      setValidationErrors({});
      await updateFn([payload]);
      refetch();
      exitEditingMode();
    }
  };

  //Excel Download------------
  const downloadExcel = (type) => {
    const dataToExport = {
      data: [],
      filename: '',
      delimiter: ',',
      headers: [],
    };

    const headers = [
      'Material Number',
      'Material Description',
      'Decomplexity Status',
      'Decomplexity Last Modified',
      'Decomplexity User Name',
      'Decomplexity Rfs Id',
      'Decomplexity Change Type',
      'SKU Status',
      'Production Plant',
      'Forecast (Next 52 Weeks)',
      'Forecast Unit',
      'Production Volume',
      'Production Unit',
      'Stock ERP',
    ];

    const updatedHeaders = headers.map((header) =>
      header.includes('Decomplexity Last Modified') ? `${header} (${dateFormat})` : header,
    );

    dataToExport.data = getDataToExport(data);
    dataToExport.filename = 'Download_Report_' + moment().format('YYYY-MM-DD HH:mm:ss');
    dataToExport.headers = updatedHeaders;
    csvDownload(dataToExport);
  };

  const getDataToExport = (data) => {
    const csvArray = data.map(dataToExport);
    return csvArray;
  };

  const dataToExport = (data) => {
    return Object.keys(data).map((key) => {
      if (key === 'LastModified' && data[key]) {
        return moment(data[key], 'DD-MM-YYYY').isValid()
          ? moment(data[key], 'DD-MM-YYYY').format('MM/DD/YYYY')
          : 'N/A';
      }
      return data[key];
    });
  };

  //Custom Search
  const filterMultiSearch = (event) => {
    const input = event.target.value.split(' ');

    const myArrayFiltered = successData.filter((el) => {
      return input.some((f) => {
        return el[toBeSearched]?.toLowerCase().includes(f.toLowerCase());
      });
    });

    setData(
      input.length > 0
        ? myArrayFiltered
        : switchChecked && Object.keys(selected).length
        ? selected
        : successData,
    );
    setFilteredRows(input.length > 0 ? myArrayFiltered : successData);
  };

  const resetAll = () => {
    setSearchOn(false);
    setAllSelected(false);
    setRowSelection({});
    setAllEditOn(false);
    setSwitchChecked(false);
    setData(successData);
    setFilteredRows(successData);
  };

  const table = useMantineReactTable({
    columns,
    data: data,
    createDisplayMode: 'row', // ('modal', and 'custom' are also available)
    editDisplayMode: 'row', // ('modal', 'cell', 'table', and 'custom' are also available)
    enableEditing: enableEditing,
    getRowId: (row) => row[unique],
    enableRowSelection: true,
    selectDisplayMode: 'checkbox',
    enableGlobalFilter: false,
    enableSelectAll: true,
    selectAllMode: 'all',
    initialState: {
      showColumnFilters: true,
    },
    mantineToolbarAlertBannerProps: isError
      ? {
          color: 'red',
          children: 'Error loading data',
        }
      : undefined,
    mantineTableContainerProps: {
      sx: {
        minHeight: '500px',
      },
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSave,
    renderRowActions: ({ row, table }) => (
      <Flex>
        <Tooltip label='Edit'>
          <ActionIcon disabled={readOnly} onClick={() => table.setEditingRow(row)}>
            <IconEdit />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
    renderTopToolbarCustomActions: ({ table }) => {
      const saveAll = async () => {
        if (status === 'success' && !readOnly) {
          const payload = transformData(selected, userName, toBeEditedValue);
          await updateFn(payload);
        }
      };

      const setUpEditedValues = (e) => {
        setToBeEditedValue({ ...toBeEditedValue, ['selectedColumn']: e.split('|')[0] });
        setSelectType(e.split('|')[1]);
      };

      const editableColumns = [
        {
          accessorKey: 'Status',
          header: 'Decomplexity Status',
          editVariant: 'select',
          id: 'Status',
        },
      ];

      return (
        <>
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <Tooltip label='Show All Selected'>
              <Switch
                disabled={selected.length === 0 && !switchChecked}
                checked={switchChecked}
                onChange={(event) => setSwitchChecked(event.currentTarget.checked)}
              />
            </Tooltip>

            <Tooltip label='Reset All'>
              <IconRefresh onClick={resetAll} />
            </Tooltip>

            <div
              onClick={() => {
                if (searchOn) setFilteredRows(successData);
                setSearchOn(searchOn ? false : true);
              }}
            >
              <Tooltip label='Search All'>{searchOn ? <IconSearchOff /> : <IconSearch />}</Tooltip>
            </div>

            {Object.keys(rowSelection).length > 0 && enableEditing && (
              <Tooltip label='Edit All Selected'>
                <div onClick={() => setAllEditOn(allEditOn ? false : true)}>
                  {allEditOn ? <IconEditCircleOff /> : <IconEditCircle />}
                </div>
              </Tooltip>
            )}
          </div>
          {
            //Search Function------------------
          }
          {searchOn && (
            <div className='search-Group'>
              <div className='searchGroup' style={{ display: 'flex' }}>
                <>
                  <Select
                    label='Select The Column To Search'
                    placeholder='Select The Column'
                    onChange={(value) => {
                      setToBeSearched(value);
                    }}
                    data={columns.map((column) => {
                      return { value: column.accessorKey, label: column.header };
                    })}
                    style={{ position: 'relative', zIndex: '1' }}
                  />
                  <Tooltip
                    arrowOffset={10}
                    arrowSize={4}
                    label='To search for multiple values, please enter space-separated values e.g 10023 12309 11287'
                    withArrow
                    position='top-start'
                    multiline
                    width={220}
                  >
                    <TextInput
                      clearable
                      label='&nbsp;'
                      placeholder='Search'
                      onChange={(event) => filterMultiSearch(event)}
                      style={{ position: 'relative', zIndex: '0' }}
                      disabled={toBeSearched === ''}
                    />
                  </Tooltip>
                </>
              </div>
            </div>
          )}
          {
            //Edit Function-------------------
          }
          {allEditOn && (
            <div className='edit-Group'>
              {Object.keys(rowSelection).length > EDIT_LIMIT && (
                <Alert icon={<IconAlertCircle size='1rem' />} title='Error!' color='red'>
                  {`Maximum number of SKUs which can be edited at a time is limited to ${EDIT_LIMIT}.`}
                </Alert>
              )}
              <div className='searchGroup' style={{ display: 'flex', alignItems: 'flex-end' }}>
                <Select
                  disabled={readOnly || Object.keys(rowSelection).length > EDIT_LIMIT}
                  label='Select The Column To Edit'
                  placeholder='Select The Column'
                  data={editableColumns.map((column) => {
                    return {
                      value: column.accessorKey + '|' + column.editVariant,
                      label: column.header,
                      disabled: column.enableEditing != undefined ? !column.enableEditing : false,
                    };
                  })}
                  onChange={(e) => setUpEditedValues(e)}
                />
                {selectType && selectType === 'select' ? (
                  <Select
                    label='Select'
                    placeholder='Select'
                    disabled={readOnly || Object.keys(rowSelection).length > EDIT_LIMIT}
                    data={statusData.map((item) => item)}
                    onChange={(value) =>
                      setToBeEditedValue({
                        ...toBeEditedValue,
                        ['value']: value,
                      })
                    }
                  />
                ) : (
                  <TextInput
                    disabled={readOnly || Object.keys(rowSelection).length > EDIT_LIMIT}
                    clearable
                    label='&nbsp;'
                    placeholder='Edit'
                    onKeyUp={(event) =>
                      setToBeEditedValue({
                        ...toBeEditedValue,
                        ['value']: event.target.value,
                      })
                    }
                  />
                )}
                <Button
                  disabled={readOnly || Object.keys(rowSelection).length > EDIT_LIMIT}
                  onClick={() => {
                    return confirm(
                      () => {
                        saveAll();
                      },
                      {
                        title: 'Confirm!',
                        description: 'Are you sure you want to save these changes?',
                        confirmationText: 'Confirm',
                      },
                    );
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          )}
        </>
      );
    },
    state: {
      isLoading: isLoading,
      isSaving: isUpdating,
      showAlertBanner: isError,
      showProgressBars: isFetching,
      rowSelection,
    },
    onRowSelectionChange: setRowSelection,
  });

  const updateSelected = () => {
    const selectedSet = new Set(Object.keys(rowSelection));

    const filtered = filteredRows.filter((item) => selectedSet.has(item[unique]));

    setSelected(filtered);
  };
  useEffect(() => {
    if (switchChecked || allSelected) setData(selected);
    else if ((!switchChecked || !allSelected) && !searchOn) setData(successData);
    else if ((!switchChecked || !allSelected) && searchOn) setData(filteredRows);
    else setData(successData);
  }, [switchChecked, selected, data, searchOn]);

  useEffect(() => {
    updateSelected();
  }, [rowSelection]);

  if (isError) {
    return (
      <ContentPaper page={'Decomplexity'}>
        <span>{error.message}</span>
      </ContentPaper>
    );
  }

  return (
    <>
      <div
        className='downLoadBtn'
        style={{
          marginBottom: '1rem',
          marginTop: '2rem',
          display: 'flex',
          justifyContent: 'right',
        }}
      >
        <Button
          leftIcon={<TableExport />}
          onClick={() => {
            downloadExcel();
          }}
          color='dark'
          style={{
            margin: '0',
          }}
        >
          Export Report to CSV
        </Button>
      </div>
      <MantineProvider theme={{ ...globalTheme, primaryColor: 'yellow', primaryShade: 7 }}>
        <MantineReactTable table={table} />
      </MantineProvider>
    </>
  );
};

export default withConfirm(DecomplexityReport);
