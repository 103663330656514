import React, { useContext, useState, useMemo } from 'react';
import RfsLayout from '../../components/layouts/RfsLayout';
import { Button } from '@mantine/core';

import { Row, Col } from 'react-bootstrap';
import ContentPaper from '../../components/layouts/rfs/Content';
import { AuthContext } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import ReportCustomComp from '../../components/report/reportCustomComp';
import DecomplexityLogModal from './decomplexityLogModal';
import { reportPermissions } from '../../constants';

function DecomplexityReport() {
  const [validationErrors, setValidationErrors] = useState({}); //for Report
  const [logModal, setLogModal] = useState({ id: null, open: false }); // log modal
  const { userRole } = useContext(AuthContext);

  const navigate = useNavigate();

  //Report Data for New UI
  let statusData = [
    'KEEP',
    'LAST_PROD',
    'TEMP',
    'INNO_KEEP',
    'INNO_TEMP',
    'SEASONAL',
    'PROMO',
    'COMP',
    'SELL OUT',
    'KILL',
    'ADMIN',
    'BUDGET',
  ];

  const columns = useMemo(
    () => [
      {
        accessorKey: 'MaterialNumber',
        header: `Material Number`,
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: 'MaterialDescription',
        header: 'Material Description',
        enableEditing: false,
      },
      {
        accessorKey: 'Status',
        header: 'Decomplexity Status',
        editVariant: 'select',
        mantineEditSelectProps: {
          data: statusData,
          error: validationErrors?.Status,
          disabled: !reportPermissions['decomplexity'].includes(userRole),
        },
      },
      {
        accessorKey: 'LastModified',
        header: 'Decomplexity last modified',
        enableEditing: false,
      },
      {
        accessorKey: 'Username',
        header: 'Decomplexity user name',
        enableEditing: false,
        Cell: ({ renderedCellValue, row }) =>
          renderedCellValue === '' ? 'Not Available' : renderedCellValue,
      },
      {
        accessorKey: 'ProjectID',
        header: 'Decomplexity rfs id',
        enableEditing: false,
        Cell: ({ renderedCellValue, row }) =>
          renderedCellValue === '["N/A"]' ? 'N/A' : renderedCellValue,
      },
      {
        accessorKey: 'Changetype',
        header: 'Decomplexity change type',
        enableEditing: false,
      },
      {
        accessorKey: 'SKUStatus',
        header: 'SKU Status',
        enableEditing: false,
      },
      {
        accessorKey: 'plant',
        header: 'Production Plant',
        enableEditing: false,
      },
      {
        accessorKey: 'CumulativeForecast',
        header: 'Forecast (Next 52 weeks)',
        enableEditing: false,
        Cell: ({ renderedCellValue, row }) => {
          if (renderedCellValue === null) return 'No forecast found';
          if (Number(renderedCellValue) === renderedCellValue && renderedCellValue % 1 !== 0)
            return parseFloat(row.original.CumulativeForecast).toFixed(2);
          return renderedCellValue;
        },
      },
      {
        accessorKey: 'ForecastUnit',
        header: 'Forecast Unit',
        enableEditing: false,
        Cell: ({ renderedCellValue, row }) => {
          if (row.original.CumulativeForecast === null) return '';
          return renderedCellValue;
        },
      },
      {
        accessorKey: 'ProductVolume',
        header: 'Production Volume',
        enableEditing: false,
        Cell: ({ renderedCellValue, row }) => {
          if (renderedCellValue === null) return 'No Production Volume found';
          if (Number(renderedCellValue) === renderedCellValue && renderedCellValue % 1 !== 0)
            return parseFloat(renderedCellValue.toFixed(2));
          return renderedCellValue;
        },
      },
      {
        accessorKey: 'ProductionUnit',
        header: 'Production Unit',
        enableEditing: false,
        Cell: ({ renderedCellValue, row }) => {
          if (row.original.ProductVolume === null) return '';
          return renderedCellValue;
        },
      },
      {
        accessorKey: 'StockERP',
        header: 'Stock ERP',
        enableEditing: false,
        Cell: ({ renderedCellValue, row }) => {
          if (Number(renderedCellValue) === renderedCellValue && renderedCellValue % 1 !== 0) {
            return parseFloat(renderedCellValue.toFixed(2));
          } else {
            return renderedCellValue;
          }
        },
      },
      {
        accessorKey: 'logs',
        header: 'Decomplexity Logs',
        enableEditing: false,
        Cell: ({ renderedCellValue, row }) => (
          <Button
            variant='filled'
            color='yellow'
            style={{ background: 'black', color: 'white' }}
            onClick={() => {
              setLogModal({ id: row.original.MaterialNumber, open: true });
            }}
          >
            View Logs
          </Button>
        ),
      },
    ],
    [validationErrors],
  );

  return (
    <RfsLayout>
      <ContentPaper page='decomplexity-report'>
        <Row>
          <Col
            md={{ span: 6 }}
            style={{
              marginTop: '1rem',
              display: 'inline-flex',
              justifyContent: 'flex-start',
            }}
          >
            <Button
              size='sm'
              color='dark'
              width='2rem'
              style={{ paddingLeft: '20', paddingRight: '20' }}
              onClick={() => {
                navigate('/reports');
              }}
            >
              Back{' '}
            </Button>
            <h1
              className='sectionHeadings'
              style={{ paddingLeft: '10px', fontSize: '28px', color: 'black' }}
            >
              {' '}
              Decomplexity Report{' '}
            </h1>
          </Col>
        </Row>
        <div id='customReport'>
          {
            <ReportCustomComp
              columns={columns}
              setValidationErrors={setValidationErrors}
              validationErrors={validationErrors}
              statusData={statusData}
              unique={'MaterialNumber'}
              enableEditing={true}
              type={'decomplexity'}
              readOnly={!reportPermissions['decomplexity'].includes(userRole)}
            />
          }
        </div>
        {logModal.open && <DecomplexityLogModal logModal={logModal} setLogModal={setLogModal} />}
      </ContentPaper>
    </RfsLayout>
  );
}

export default DecomplexityReport;
