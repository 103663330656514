import { Button, Group, Modal, Tabs, UnstyledButton, Grid, Input } from '@mantine/core';
import { useContext, useEffect, useState } from 'react';
import { AddNew } from './addNewSupplier';
import { CircleCheck } from 'tabler-icons-react';
import { MagnifyingGlassIcon } from '@modulz/radix-icons';
import { SupplierContext } from '../../../contexts/SupplierContext';
import styled from 'styled-components';

const ButtonContainer = styled('div')`
  width: 100%;
  margin-top: 16px;
  display: flex;
  justify-content: end;
`;

export const Suppliers = ({ status, setStatus, data, onChange, isSubmissionReview }) => {
  const [suppliersList, setSuppliersList] = useState(data);
  const { supplierForm } = useContext(SupplierContext);
  const [tempSuppliers, setTempSuppliers] = useState([]);
  const [addModal, setAddModal] = useState({ open: false, id: null });
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    if (status.open && tempSuppliers.length === 0) {
      const index = supplierForm.findIndex((val) => parseInt(status.id) === parseInt(val.scope_id));

      if (index > -1 && supplierForm[index]['to_be_supplier'])
        setTempSuppliers(supplierForm[index]['to_be_supplier']);
    }
  }, [status]);

  useEffect(() => {
    if (tempSuppliers.length > 0 && firstLoad) {
      setFirstLoad(false);
      const currentSuppliers = [...suppliersList];
      tempSuppliers.forEach((item) => {
        const isNew = suppliersList.findIndex((i) => i.value === item);
        if (isNew === -1) currentSuppliers.push({ label: item, value: item });
      });

      setSuppliersList(currentSuppliers);
    }
  }, [tempSuppliers, firstLoad]);

  const handleConfirm = () => {
    onChange(tempSuppliers, status.id);
  };

  const handleSupplierSelect = (data, plant) => {
    setFirstLoad(false);
    const currentTempValues = [...tempSuppliers];
    let addNewValidation = null;

    if (data.isNew) {
      const currentValues = [...suppliersList];
      const isNew = currentValues.findIndex((i) => i.value === data.value);
      if (isNew === -1) {
        currentValues.push(data);
        setSuppliersList(currentValues);
      } else {
        addNewValidation = 'Supplier already exists.';
      }
    }

    if (!addNewValidation) {
      if (data.selected) {
        if (tempSuppliers.length <= 4) currentTempValues.push(data.value);
      } else {
        const index = currentTempValues.findIndex((val) => val === data.value);
        if (index > -1) currentTempValues.splice(index, 1);
      }
    }

    if (addModal.open) {
      setAddModal((o) => {
        return {
          open: addNewValidation ? o.open : false,
          id: addNewValidation ? o.id : null,
          error: addNewValidation,
        };
      });
    }

    setTempSuppliers(currentTempValues);
  };

  return (
    <>
      <Modal
        size={'lg'}
        opened={status.open}
        onClose={() => setStatus({ open: false, id: null })}
        title='Select Existing ABI Suppliers/Indicate New'
      >
        <ModalContent
          data={suppliersList}
          onChange={handleSupplierSelect}
          status={status}
          suppliers={tempSuppliers}
          addModal={addModal}
          setAddModal={setAddModal}
          handleSupplierSelect={handleSupplierSelect}
          isSubmissionReview={isSubmissionReview}
        />
        {!isSubmissionReview && (
          <ButtonContainer>
            <Button
              disabled={tempSuppliers.length > 5}
              onClick={handleConfirm}
              color='yellow'
              variant='gradient'
              gradient={{ from: '#e3af32', to: '#f4e00f' }}
              radius='sm'
            >
              Confirm
            </Button>
          </ButtonContainer>
        )}
      </Modal>
    </>
  );
};

const ModalContent = ({
  data,
  onChange,
  status,
  suppliers,
  addModal,
  setAddModal,
  handleSupplierSelect,
  isSubmissionReview,
}) => {
  const [searchStr, setSearchStr] = useState('');

  return (
    <>
      <Grid>
        <Grid.Col span={12}>
          <Input
            className='search'
            radius='md'
            onChange={(event) => setSearchStr(event.target.value)}
            value={searchStr}
            icon={<MagnifyingGlassIcon />}
            style={{ margin: '0 4rem' }}
          />
        </Grid.Col>
      </Grid>

      <Tabs defaultValue={isSubmissionReview ? 'selected' : 'all'}>
        <Tabs.List>
          {!isSubmissionReview && <Tabs.Tab value='all'>All</Tabs.Tab>}
          <Tabs.Tab value='selected'>Selected</Tabs.Tab>
        </Tabs.List>

        {!isSubmissionReview && (
          <Tabs.Panel value='all' pt='xs'>
            <AddNewSupplier setStatus={setAddModal} />
            <div style={{ marginTop: '12px', height: '50vh', overflowY: 'auto' }}>
              {data.map(
                (item, key) =>
                  item.label.toLowerCase().includes(searchStr.toLowerCase()) && (
                    <div key={key}>
                      <List
                        item={item}
                        onChange={onChange}
                        status={status}
                        suppliers={suppliers}
                        isSubmissionReview={isSubmissionReview}
                      />
                    </div>
                  ),
              )}
            </div>
          </Tabs.Panel>
        )}

        <Tabs.Panel value='selected' pt='xs'>
          {!isSubmissionReview && <AddNewSupplier setStatus={setAddModal} />}
          <div style={{ marginTop: '12px', height: '50vh', overflowY: 'auto' }}>
            {data
              .filter((item) => suppliers.includes(item.value))
              .map(
                (item, key) =>
                  item.label.toLowerCase().includes(searchStr.toLowerCase()) && (
                    <div key={key}>
                      <List
                        item={item}
                        onChange={onChange}
                        status={status}
                        suppliers={suppliers}
                        isSubmissionReview={isSubmissionReview}
                      />
                    </div>
                  ),
              )}
          </div>
        </Tabs.Panel>
      </Tabs>
      <AddNew status={addModal} setStatus={setAddModal} onChange={handleSupplierSelect} />
    </>
  );
};

const AddNewSupplier = ({ setStatus }) => (
  <div style={{ display: 'flex', justifyContent: 'end' }}>
    <Button
      onClick={() => setStatus({ open: true })}
      variant='filled'
      color='yellow'
      style={{ background: 'black', color: 'white' }}
      size='sm'
      radius='sm'
    >
      Add New Supplier
    </Button>
  </div>
);

const List = ({ item, status, onChange, suppliers, isSubmissionReview }) => {
  const isSelected = suppliers.includes(item.value);
  return (
    <UnstyledButton
      onClick={() => onChange({ ...item, selected: isSelected ? false : true }, status.id)}
      className={item.selected ? 'lists selected' : 'lists'}
      disabled={isSubmissionReview}
    >
      <Group style={{ flexWrap: 'nowrap' }}>
        {isSelected && <CircleCheck color='green' size='1.5rem' />}

        <span style={{ color: 'inherit', fontSize: 'inherit', lineHeight: 1.55 }}>
          {item.label}
        </span>
      </Group>
    </UnstyledButton>
  );
};
