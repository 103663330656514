import React, { useState, useContext, useEffect } from 'react';
import { Box, Accordion, Grid, Button } from '@mantine/core';
import ContentPaper from '../../../../components/layouts/rfs/Content';
import { Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { RfsContext } from '../../../../contexts/RfsContext';
import { TrialsTable } from './trialsTable';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AppLoader } from '../../../../components/common/loader';
import { AuthContext } from '../../../../contexts/AuthContext';
import { TableExport } from 'tabler-icons-react';
import { CSVLink } from 'react-csv';
import { routes } from '../../../../routes';
import moment from 'moment';
import { roles } from '../../../../constants';
import { sCodeStyles } from '../../../../utils/helpers';

export default function TrialsCode() {
  const navigate = useNavigate();
  const { userRole } = useContext(AuthContext);
  const { form } = useContext(RfsContext);
  const { rfs_number } = form.rfsInformation;
  const { userEmail } = useContext(AuthContext);
  const accessors = {
    materials: 'selection_number',
    liquids: 'selection_number',
    skus: 'selection_number',
  };
  const secondaryAccessor = {
    materials: 'selection_name',
    liquids: 'selection_name',
    skus: 'selection_name',
  };
  const tertiaryAccessor = {
    materials: null,
    liquids: null,
    skus: null,
  };
  const [scopeErrors, setScopeErrors] = useState({
    errors: {},
    liquidErrors: {},
    materialErrors: {},
    productionSiteErrors: {},
  });

  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(true);
  const [trials, setTrials] = useState([]);
  const [resultData, setResultData] = useState([]);
  const [trialInfo, setTrialInfo] = useState([]);
  const [clicked, setClicked] = useState(false);

  const { fetchTrials, submitAllTrials, fetchTrialInfo } = useContext(RfsContext);

  useEffect(() => {
    getData(rfs_number);
    getTrials(rfs_number);
  }, []);

  useEffect(() => {
    getTrials(rfs_number);
    setResultData(trials.filter((item) => item.int_rfs_impl_trial?.trials_result));
  }, [trials]);

  const getTrials = (rfs_number) => {
    fetchTrialInfo(rfs_number).then((res) => {
      setTrialInfo(res.data.data);
    });
  };
  useEffect(() => {
    if ([roles.admin, roles.superAdmin, roles.pmo, roles.zitec].includes(userRole))
      setDisable(false);
  }, [userRole]);

  const getData = (rfs_number) => {
    setLoading(true);
    fetchTrials(rfs_number).then((res) => {
      setTrials(res.data.data);
      setLoading(false);
    });
  };

  const isSubmitDisabled = () => {
    const index = trials.findIndex(
      (t) =>
        t.int_rfs_impl_trial.trials_result === 'Fail' &&
        (!t.int_rfs_impl_trial?.trials_comment ||
          t.int_rfs_impl_trial?.trials_comment.length === 0),
    );

    return index > -1;
  };
  const save = () => {
    fetchTrials(rfs_number).then((res) => {
      setTrials(res.data.data);
    });
  };
  const handleSubmit = () => {
    setClicked(true);
    let payload = {
      email: userEmail,
    };
    submitAllTrials(payload, rfs_number).then(() => window.location.reload());
  };

  const handleTrialChange = (id, field_name, value) => {
    let currentValues = [...trials];
    const index = currentValues.findIndex((i) => i.sku_id === id);
    currentValues[index]['int_rfs_impl_trial'][field_name] = value;
    setTrials(currentValues);
  };

  const sort_Trials_acctoSKUs = [...trials].sort((a, b) => a.scope_id - b.scope_id);

  const selectedScopeFn = (data) => {
    let selectedScopeList = {};
    for (let i = 0; i < data.length; i++) {
      selectedScopeList[data[i].id] = {
        selection_number: data[i].selection_number,
        selection_name: data[i].selection_name,
      };
    }
    return selectedScopeList;
  };

  var selectedScopeList = selectedScopeFn(form.selectedScope.skus);

  const dataToExport = ({
    int_rfs_ps_sku_reference,
    int_rfs_impl_trial,
    sla_date,
    status,
    scope_id,
    finished_date,
  }) => {
    return {
      'SKU no.': selectedScopeList[scope_id]?.selection_number,
      'SKU Desc': selectedScopeList[scope_id]?.selection_name,
      Trials: int_rfs_ps_sku_reference?.selected_plant,
      Result: int_rfs_impl_trial?.trials_result,
      'Reason of Failure': int_rfs_impl_trial?.trials_failure_reason,
      Comments: int_rfs_impl_trial?.trials_comment,
      'Step Deadline': sla_date,
      'Step Finished': status === 'Closed' ? finished_date : status,
    };
  };
  const csvArray = sort_Trials_acctoSKUs.map(dataToExport);

  if (loading) {
    return (
      <ContentPaper page={'ProductDevelopment'}>
        <AppLoader size='lg' center />
      </ContentPaper>
    );
  }

  return (
    <ContentPaper page={'ProductDevelopment'}>
      <Box sx={sCodeStyles}>SKU CODES</Box>
      <br />

      <div
        style={{
          display: 'flex',
          justifyContent: 'right',
        }}
      >
        <CSVLink data={csvArray} filename={'Trials_' + moment().format('YYYY-MM-DD HH:mm:ss')}>
          <Button
            style={{
              marginBottom: '0.5rem',
              background: 'black',
            }}
            leftIcon={<TableExport />}
            variant='filled'
            radius='md'
            size='md'
            className='submit_button_trials'
            color='yellow'
          >
            Export to CSV
          </Button>
        </CSVLink>
      </div>
      {trialInfo && (
        <Box
          sx={(theme) => ({
            background: "linear-gradient(90deg,#e3af32 0%,#f4e00f 100%)",
            textAlign: 'center',
            padding: theme.spacing.xs,
            borderRadius: theme.radius.md,
            cursor: 'pointer',
            color: 'black',
            fontSize: theme.fontSizes.lg,
            fontWeight: 1000,

            '&:hover': {
              background: "linear-gradient(90deg,#e3af32 0%,#f4e00f 100%)",
            },
          })}
        >
          {trialInfo.rfs_number?.length > 0 &&
          trialInfo.trials_reversal &&
          !trialInfo.rfs_reversal_closure_date ? (
            'Reversal in Progress'
          ) : trialInfo.rfs_number?.length > 0 &&
            trialInfo.trials_reversal &&
            trialInfo.rfs_reversal_closure_date &&
            trialInfo.child_rfs_number?.length === 0 ? (
            `Scope Restatement is Complete`
          ) : trialInfo.rfs_number?.length > 0 &&
            trialInfo.trials_reversal &&
            trialInfo.rfs_reversal_closure_date &&
            trialInfo.child_rfs_number?.length > 0 ? (
            <>
              {`Scope Restatement is complete`} <br />(
              <Link
                className='white rfs-link'
                to={`${routes.project(trialInfo.child_rfs_number).root}?tab=projectOverview`}
              >
                Click here to view child RFS{' '}
                <span className='blue'>{`${trialInfo.child_rfs_number}`}</span>
              </Link>
              )
            </>
          ) : trialInfo.rfs_number?.length > 0 &&
            trialInfo.trials_reversal &&
            !trialInfo.rfs_reversal_closure_date &&
            trialInfo.child_rfs_number?.length > 0 ? (
            <>
              {`Reversal in progress`} <br />(
              <Link
                className='white rfs-link'
                to={`${routes.project(trialInfo.child_rfs_number).root}?tab=projectOverview`}
              >
                Click here to view child RFS{' '}
                <span className='blue'>{`${trialInfo.child_rfs_number}`}</span>
              </Link>
              )
            </>
          ) : trialInfo.rfs_number?.length > 0 &&
            trialInfo.trials_reversal &&
            trialInfo.rfs_reversal_closure_date ? (
            `Reversal is complete`
          ) : (
            ''
          )}
        </Box>
      )}
      <Grid>
        <Grid.Col span={12}>
          <Accordion defaultValue={'key_0'}>
            {Object.keys(form?.selectedScope || {}).map((name) =>
              form?.selectedScope[name].map((item, index) => {
                const trialsList = trials.filter((m) => m.scope_id === item.id);

                return (
                  trialsList.length > 0 && (
                    <Accordion.Item
                      className='scope-label'
                      key={`key_${name}_${index}`}
                      value={`key_${name}_${index}`}
                    >
                      <Accordion.Control
                        className={scopeErrors.errors[item.id] ? 'error-label' : ''}
                      >
                        {`${name.substring(0, 3).toUpperCase()} -  ${item[accessors[name]]}- ${
                          item[secondaryAccessor[name]]
                        }${tertiaryAccessor[name] ? `- ${item[tertiaryAccessor[name]]}` : ''}`}
                      </Accordion.Control>
                      <Accordion.Panel>
                        {
                          <Grid>
                            <Grid.Col span={12}>
                              <TrialsTable
                                trialsData={trialsList}
                                onChange={handleTrialChange}
                                save={save}
                                disable={disable}
                              />
                            </Grid.Col>
                          </Grid>
                        }
                      </Accordion.Panel>
                    </Accordion.Item>
                  )
                );
              }),
            )}
          </Accordion>
        </Grid.Col>
        <Grid.Col span={2} offset={10}>
          <Button
            variant='filled'
            radius='md'
            size='md'
            className='submit_button_trials'
            color='dark'
            disabled={
              resultData.length != trials.length ||
              clicked ||
              form.rfsInformation.status != `Product Development` ||
              isSubmitDisabled()
            }
            onClick={() => handleSubmit()}
            fullWidth
          >
            Submit
          </Button>
        </Grid.Col>
      </Grid>
    </ContentPaper>
  );
}
