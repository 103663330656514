import React, { useContext } from 'react';
import { Dropzone, MIME_TYPES } from '@mantine/dropzone';
import { Group, Text } from '@mantine/core';
import styled from '@emotion/styled';
import { RfsContext } from '../../contexts/RfsContext';

const GroupStyles = styled(Group)`
  height: 100%;
  pointer-events: none;
  background-color: #eaeaea;
`;

const ImageStyles = styled.img`
  max-width: fit-content;
  max-height: 8vh;
`;

const DropzoneStyles = styled(Dropzone)`
  margin-bottom: 0rem;
  height: auto;
  & .mantine-Dropzone-inner {
    height: 100%;
    min-height: 100px;
    & .mantine-Group-root {
      min-height: 100px;
      padding: 16px;
    }
  }
`;

const ACCEPTED_MIME_TYPE = [
  MIME_TYPES.png,
  MIME_TYPES.jpeg,
  MIME_TYPES.svg,
  MIME_TYPES.gif,
  MIME_TYPES.pdf,
  MIME_TYPES.csv,
  MIME_TYPES.doc,
  MIME_TYPES.docx,
  MIME_TYPES.xls,
  MIME_TYPES.xlsx,
  MIME_TYPES.pptx,
  MIME_TYPES.ppt,
  '.msg',
  '.eml',
];

const DropzoneChildren = ({ maxFiles }) => (
  <GroupStyles position='center' spacing='xl'>
    <div>
      <ImageStyles src='/images/dropzoneImage.jpg' alt='dropzone' />
    </div>

    <div>
      <Text size='sm' inline>
        Click here to attach files
      </Text>
      <Text size='sm' color='dimmed' inline mt={7}>
        {`You can attach upto ${maxFiles} files. (Strictly allowed png, jpeg, svg, gif, pdf, csv, doc, docx, xls, xlsx, pptx, ppt, msg, eml)`}
      </Text>
    </div>
  </GroupStyles>
);

export const CustomDropzone = ({
  type,
  setError = () => {},
  maxFiles = 5,
  multiple = true,
  onChange = false,
  size = 5,
}) => {
  const { form, setForm } = useContext(RfsContext);

  const handleFileSelect = (files) => {
    const currentValues = { ...form };
    const filesLength = currentValues.attachments[type].length + files.length;
    if (filesLength <= maxFiles) {
      files.forEach((file) => currentValues.attachments[type].push(file));

      setForm(currentValues);
    } else {
      setError({
        state: true,
        message: `You have reached the upload limit of ${maxFiles} files.`,
        type,
      });
    }
  };

  return (
    <DropzoneStyles
      multiple={multiple}
      onDrop={(files) => {
        setError({ state: false, message: null, type: null });
        if (onChange) {
          onChange(files);
        } else {
          handleFileSelect(files);
        }
      }}
      onReject={(files) => setError({ state: true, message: files[0].errors[0].message, type })}
      maxSize={size * 1024 ** 2}
      accept={ACCEPTED_MIME_TYPE}
      className={'DropZone_root'}
      maxFiles={maxFiles}
    >
      <DropzoneChildren maxFiles={maxFiles} />
    </DropzoneStyles>
  );
};
