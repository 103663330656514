import React, { useContext, useState, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Textarea, Accordion, Button, Grid } from '@mantine/core';

import ContentPaper from '../layouts/rfs/Content';
import ProjectName from '../projectName/projectName';
import { RfsContext } from '../../contexts/RfsContext';
import { axios } from '../../utils/axios';
import LiquidTable from '../tables/liquidsTable/liquidsTable';
import MaterialTable from '../tables/materialTable/materialTable';
import { SkuTable } from '../tables/skutable/skuTable';
import { InvalidModal } from '../../components/modal/invalidModal';
import { NewMarketModal } from '../modal/newMarketModal';
import { AppLoader } from '../common/loader';
import { routes } from '../../routes';
import { SetFirstShipment } from '../../components/modal/SetFirstShipment';
import { rfsStatus } from '../../constants';
import { ProductionSite } from '../tables/skutable/production-site';

import './scopeComp.css';
import { CommonContext } from '../../contexts/common';

const accessors = {
  materials: 'selection_number',
  liquids: 'selection_number',
  skus: 'selection_number',
};
const secondaryAccessor = {
  materials: 'selection_name',
  liquids: 'selection_name',
  skus: 'selection_name',
};
const tertiaryAccessor = {
  materials: null,
  liquids: null,
  skus: null,
};

export default function ScopeComp(props) {
  const params = useParams();
  const navigate = useNavigate();
  const {
    form,
    scopeStepTwo,
    update,
    loading,
    saveAsDraft,
    infoLoading,
    validateScope,
    setForm,
    updateSkus,
    fetchMarkets,
    storeProductionSiteSkus,
  } = useContext(RfsContext);
  const [values, setValues] = useState([]);
  const [scopeErrors, setScopeErrors] = useState({
    errors: {},
    liquidErrors: {},
    materialErrors: {},
    productionSiteErrors: {},
  });
  const [invalidModal, setInvalidModal] = useState({
    open: false,
    callback: false,
  });
  const [firstShipment, setFirstShipment] = useState(false);
  const [materialDropDown, setMaterialDropDown] = useState([]);
  const [dropdownliquids, setDropdownLiquids] = useState([]);
  const [materialsLabel, setMaterialsLabel] = useState({});
  const [liquidsLabel, setLiquidsLabel] = useState({});
  const [errorModal, setErrorModal] = useState(false);
  const [accordionState, setAccordionState] = useState({});
  const { status = '', rfs_type } = form.rfsInformation;
  const [errorsList, setErrorsList] = useState({});

  useEffect(() => {
    if (rfs_type === 'Open SKU to market') fetchMarkets();
  }, [rfs_type]);

  useEffect(() => {
    if (Object.keys(errorsList).length > 0) setScopeErrors(errorsList);
  }, [errorsList]);

  const handleNext = (callback = null, type = null) => {
    const errors = validateScope();
    setErrorsList(errors);

    setTimeout(() => {
      if (
        Object.keys(errors.errors).length > 0 ||
        Object.keys(errors.liquidErrors).length > 0 ||
        Object.keys(errors.materialErrors).length > 0 ||
        Object.keys(errors.productionSiteErrors).length > 0
      ) {
        setInvalidModal({
          open: true,
          callback: callback ? callback : () => setInvalidModal(false),
        });
      } else {
        if (type.toLowerCase() === 'production site') {
          storeProductionSiteSkus(callback);
        } else if (type === 'Open SKU to market') {
          updateSkus(callback);
        } else {
          scopeStepTwo(callback);
        }
      }
    }, 500);
  };

  const saveDataAsDraft = (type) => {
    if (type.toLowerCase() === 'production site') {
      storeProductionSiteSkus(null);
    } else if (type === 'Open SKU to market') {
      updateSkus(null);
    } else {
      scopeStepTwo(null);
    }
  };

  const handleCommentChange = (event, id) => {
    const currentValues = { ...form };
    currentValues.scopeComments[id] = event.target.value;

    setForm(currentValues);
  };

  useEffect(() => {
    const fetchMaterialDropdown = () => {
      axios.get(`/materials/dropdown`).then(
        (res) => {
          let response = res.data.data.materials;
          const labels = {};
          response = response.map((material) => {
            labels[material.bom_component] = `${material.bom_component}-${material.material_text}`;
            return {
              label: `${material.bom_component}-${material.material_text}`,
              value: `${material.bom_component}`,
            };
          });

          (response || []).unshift({
            label: '+ New Material',
            value: 'New Material',
          });

          setMaterialsLabel(labels);
          setMaterialDropDown(response);
        },
        (error) => {
          console.log(error);
        },
      );
    };

    if (materialDropDown.length === 0) fetchMaterialDropdown();
  }, [params.rfsNumber]);

  useEffect(() => {
    const fetchLiquidDropdown = () => {
      axios.get(`/liquids/dropdown`).then(
        (res) => {
          let response = res.data.data.liquids;
          const labels = [];
          response = response.map((liquid) => {
            labels[liquid.Material] = {
              label: `${liquid.Material}-${liquid.MaterialDescription}`,
              unit: liquid.Unit,
            };

            return {
              label: `${liquid.Material}-${liquid.MaterialDescription}`,
              value: liquid.Material,
              unit: liquid.Unit,
            };
          });

          (response || []).unshift({
            label: '+ New Liquid Material',
            value: 'New Liquid',
          });

          setLiquidsLabel(labels);
          setDropdownLiquids(response);
        },
        (error) => {
          console.log(error);
        },
      );
    };
    if (dropdownliquids.length === 0) fetchLiquidDropdown();
  }, [params.rfsNumber]);

  const Footer = () =>
    props.disableButtons ? null : (
      <Grid>
        <Grid.Col span={2} offset={6}>
          {' '}
          <Link to={routes.submission(params.rfsNumber).scopeSelect}>
            <Button
              variant='filled'
              color='yellow'
              fullWidth
              style={{ background: 'white', color: 'black', border: ' 0.25px solid gray' }}
              size='md'
              radius='sm'
            >
              Back
            </Button>
          </Link>
        </Grid.Col>
        <Grid.Col span={2}>
          <Button
            disabled={saveAsDraft}
            onClick={() => saveDataAsDraft(rfs_type)}
            variant='filled'
            color='dark'
            fullWidth
            size='md'
            radius='sm'
          >
            {!saveAsDraft ? 'Save' : <AppLoader />}
          </Button>
        </Grid.Col>
        <Grid.Col span={2}>
          <Button
            onClick={() =>
              handleNext(
                () =>
                  status === rfsStatus.draft
                    ? navigate(routes.submission(params.rfsNumber).attachment)
                    : navigate(-2),
                rfs_type,
              )
            }
            disabled={loading}
            variant='gradient'
            gradient={{ from: '#e3af32', to: '#f4e00f' }}
            color='yellow'
            size='md'
            fullWidth
          >
            {!loading ? 'Next' : <AppLoader />}
          </Button>
        </Grid.Col>
      </Grid>
    );

  if (infoLoading)
    return (
      <ContentPaper page={'sku-2'}>
        <AppLoader size='md' center />
      </ContentPaper>
    );

  if (
    form.rfsInformation.status &&
    ![
      rfsStatus.draft,
      rfsStatus.tech_feasible,
      rfsStatus.tech_feasible2,
      rfsStatus.supplier_pmo2_active,
    ].includes(form.rfsInformation.status)
  ) {
    navigate(routes.submission(params.rfsNumber).overView);
  }

  return (
    <ContentPaper page={'sku-2'} footer={<Footer />}>
      {props.withProjectName ? (
        <Row style={{ padding: '1rem' }}>
          <ProjectName Pnumber='AM001' initialItem={props.initialItem} />
        </Row>
      ) : null}

      <Accordion
        style={props.withEdit ? { maxWidth: '100vw' } : { maxWidth: '100vw', minHeight: '100vh' }}
        onChange={(event) => {
          setAccordionState(event ? event : {});
        }}
      >
        {Object.keys(form?.selectedScope || {}).map((name) =>
          form?.selectedScope[name].map((item, index) => (
            <Accordion.Item value={`key_${index}_${name}`} key={`key_${index}_${name}`}>
              <Accordion.Control
                className={scopeErrors.errors[item.id] ? 'error-label' : ''}
              >{`${name.substring(0, 3).toUpperCase()} - ${item[accessors[name]]} - ${
                item[secondaryAccessor[name]]
              }${
                tertiaryAccessor[name] ? ` - ${item[tertiaryAccessor[name]]}` : ''
              }`}</Accordion.Control>
              <Accordion.Panel>
                <Grid>
                  {name === 'liquids' && (
                    <Grid.Col span={12}>
                      <LiquidTable
                        index={index}
                        scope={item}
                        errors={scopeErrors.liquidErrors}
                        firstShipment={firstShipment}
                        setFirstShipment={setFirstShipment}
                        liquids={dropdownliquids}
                        liquidsLabel={liquidsLabel}
                      />
                    </Grid.Col>
                  )}

                  {name === 'skus' && (
                    <Grid.Col span={12}>
                      {rfs_type.toLowerCase() === 'production site' && (
                        <ProductionSite
                          name={item[accessors[name]]}
                          scope={item}
                          errors={scopeErrors.productionSiteErrors}
                          setBomErrors={setErrorsList}
                        />
                      )}
                      {rfs_type === 'Open SKU to market' && (
                        <SkuTable
                          name={item[accessors[name]]}
                          scope={item}
                          index={`key_${index}_${name}`}
                          accordionState={accordionState ? accordionState : null}
                        />
                      )}
                    </Grid.Col>
                  )}

                  {name === 'materials' && (
                    <Grid.Col span={12}>
                      <MaterialTable
                        index={index}
                        material={item}
                        errors={scopeErrors.materialErrors}
                        firstShipment={firstShipment}
                        setFirstShipment={setFirstShipment}
                        materialDropDown={materialDropDown}
                        materialsLabel={materialsLabel}
                        accordionState={accordionState}
                        materialAccordionIndex={
                          form?.selectedScope.liquids.length > 0
                            ? form?.selectedScope.liquids.length + index
                            : index
                        }
                      />
                    </Grid.Col>
                  )}
                  {name === 'materials' && (
                    <Grid.Col span={12}>
                      {/* <Tabs active={activeTab} onTabChange={setActiveTab}>
                      {} */}
                      {/* <SkuSelectedMaterialTable data={getAffectedSKUs(item)} /> */}
                      {/* </Tabs> */}
                    </Grid.Col>
                  )}

                  {!['production site'].includes(rfs_type.toLowerCase()) && name !== 'skus' && (
                    <Grid.Col span={12}>
                      <Textarea
                        label='Additional Comments'
                        className='additional-comments'
                        value={form.scopeComments[item.id] || null}
                        onChange={(event) => handleCommentChange(event, item.id)}
                      />
                    </Grid.Col>
                  )}
                </Grid>
              </Accordion.Panel>
            </Accordion.Item>
          )),
        )}
      </Accordion>

      {invalidModal && (
        <InvalidModal
          callback={() =>
            status === rfsStatus.draft
              ? navigate(routes.submission(params.rfsNumber).attachment)
              : navigate(-2)
          }
          invalidModal={invalidModal}
          setInvalidModal={setInvalidModal}
          nextClick={
            form.rfsInformation.rfs_type &&
            form.rfsInformation.rfs_type.toLowerCase() === 'production site'
              ? storeProductionSiteSkus
              : scopeStepTwo
          }
        />
      )}

      {errorModal && (
        <NewMarketModal
          invalidModal={errorModal}
          setInvalidModal={setErrorModal}
          text='You have not selected any new markets. Please select a new market to proceed.'
          nextClick={rfs_type === 'Open SKU to market' ? updateSkus : scopeStepTwo}
        />
      )}

      {setFirstShipment && <SetFirstShipment open={firstShipment} setOpen={setFirstShipment} />}
    </ContentPaper>
  );
}
