import React, { useState, useContext, useEffect } from 'react';
import { Checkbox, Textarea } from '@mantine/core';
import { Button } from '@mantine/core';
import { RfsContext } from '../../../contexts/RfsContext';
import { AuthContext } from '../../../contexts/AuthContext';
import { useParams } from 'react-router-dom';
import { axios } from '../../../utils/axios';
import { routes } from '../../../routes';
import { useNavigate } from 'react-router-dom';
import { SubmissionTrueModal } from '../../modal/SubmissionTrueModal';
import { FeedbackModal } from '../../modal/FeedbackModal';
import { AppLoader } from '../../common/loader';
import { NpsComponent } from '../../../common/nps';
import { NpsIds, roles } from '../../../constants';

export default function FeedbackFormTable({ readOnly }) {
  const [loading, setLoading] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const { userName, userEmail } = useContext(AuthContext);
  const rfs = params.rfsNumber;
  const [SubmissionSucess, setSubmissionSucess] = useState(false);
  const [data, setData] = useState([]);
  const [err_found, setErr_found] = useState(false);
  const { form } = useContext(RfsContext);
  const [npsOpened, setNpsOpened] = useState(false);

  const checkboxes = (team_to_feedback, response) => {
    return {
      all_okay: response.length > 0 ? getItem(team_to_feedback, response)?.all_okay : true,
      team_to_feedback: team_to_feedback,
      instructions_not_clear:
        response.length > 0 ? getItem(team_to_feedback, response)?.instructions_not_clear : false,
      scope_modified:
        response.length > 0 ? getItem(team_to_feedback, response)?.scope_modified : false,
      not_correct_input:
        response.length > 0 ? getItem(team_to_feedback, response)?.not_correct_input : false,
      others: response.length > 0 ? getItem(team_to_feedback, response)?.others : false,
      comment: response.length > 0 ? getItem(team_to_feedback, response)?.comment : '',
    };
  };

  useEffect(() => {
    const controller = new AbortController();

    setLoading(true);
    axios
      .get('feedback/f/get', {
        params: { rfsNumber: `${rfs}`, useremail: `${userEmail}` },
        signal: controller.signal,
      })
      .then(
        (res) => {
          const response = res.data.data;

          let data_arr = {};
          columnMap[rfs_type].forEach((key) => {
            data_arr[key.value] = checkboxes(key.value, response);
          });

          setData([
            {
              rfs_number: rfs,
              user: userName,
              data_arr,
            },
          ]);
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() => setLoading(false));

    return () => {
      controller.abort();
    };
  }, []);

  function getItem(team_to_feedback, response) {
    return response.find((item) => item.team_to_feedback === team_to_feedback);
  }

  const { rfs_type } = form.rfsInformation;

  const handleChange = (event, index, team_to_feedback) => {
    const values = [...data];
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    if (event.target.type === 'checkbox') {
      values[index]['data_arr'][team_to_feedback] = getCheckboxValues(
        { ...values[index]['data_arr'][team_to_feedback] },
        event.target.name,
        value,
      );
    } else {
      values[index]['data_arr'][team_to_feedback][event.target.name] = value;
    }

    setData(values);
  };

  const getCheckboxValues = (currentValues, name, value) => {
    if (name === 'all_okay') {
      Object.keys(currentValues).forEach((key) => {
        if (key !== 'comment') {
          currentValues[key] = key !== 'all_okay' ? false : value;
        }
      });
    } else {
      Object.keys(currentValues).forEach((key) => {
        currentValues[key] = key === 'all_okay' ? false : name === key ? value : currentValues[key];
      });
    }

    const keysToCheck = Object.keys(currentValues).filter(
      (key) => key !== 'comment' && key !== 'team_to_feedback',
    );
    const noCheckboxSelected = keysToCheck.every((key) => currentValues[key] === false);

    if (noCheckboxSelected) {
      currentValues['all_okay'] = true;
    }

    return currentValues;
  };

  const handleNext = (payload, callback) => {
    payload[0]['email'] = userEmail;
    let isValid = true;
    for (let item in payload[0].data_arr) {
      const dataItem = payload[0].data_arr[item];
      if (dataItem?.others && (dataItem?.comment || '').trim().length === 0) {
        isValid = false;
        break;
      }
    }

    if (isValid) {
      setSubmitLoader(true);
      axios
        .post(`/feedback/f`, payload[0])
        .then(
          (res) => {
            if (callback) callback();
            setSubmissionSucess(true);

            setTimeout(() => {
              setSubmissionSucess(false);
              setNpsOpened(true);
            }, 2000);
          },
          (err) => {
            console.log(err);
          },
        )
        .finally(() => setSubmitLoader(false));
    } else {
      setErr_found(true);
    }
  };

  const veamColumns = [
    { label: 'pmo', value: roles.pmo },
    { label: 'mrp', value: roles.mrp },
    { label: 'd2p', value: roles.d2p },
    { label: 'procurement', value: roles.procurement },
    { label: 'masterdata', value: 'masterdata' },
  ];

  const psColumns = [
    { label: 'pmo', value: roles.pmo },
    { label: 'mrp', value: roles.mrp },
    { label: 'd2p', value: roles.d2p },
    { label: 'procurement', value: roles.procurement },
    { label: 'masterdata', value: 'masterdata' },
    { label: 'transfer_pricing', value: roles.standard_transfer_pricing },
    { label: 'scheduling', value: roles.scheduling },
    { label: 'zitec', value: roles.zitec },
    { label: 'snp', value: roles.snp },
  ];

  const oskuColumns = [
    { label: 'pmo', value: roles.pmo },
    { label: 'masterdata', value: 'masterdata' },
    { label: 'demand_planning', value: roles.dp },
    { label: 'transfer_pricing', value: roles.standard_transfer_pricing },
  ];

  const dlstColumns = [
    { label: 'pmo', value: roles.pmo },
    { label: 'masterdata', value: 'masterdata' },
    { label: 'snp', value: roles.snp },
    { label: 'mrp', value: roles.mrp },
    { label: 'demand_planning', value: roles.dp },
  ];

  const supplierChangeColumns = [
    { label: 'pmo', value: roles.pmo },
    { label: 'masterdata', value: 'masterdata' },
    { label: 'd2p', value: roles.d2p },
    { label: 'procurement', value: roles.procurement },
    { label: 'zitec', value: roles.zitec },
  ];

  const columnMap = {
    'Article Modification/Value Engineering': veamColumns,
    'Open SKU to market': oskuColumns,
    'Production Site': psColumns,
    Delisting: dlstColumns,
    'Supplier Change': supplierChangeColumns,
  };

  const rows = columnMap[rfs_type].map((column) => ({
    step_id: column.value,
    name: <b>{column.label.toUpperCase().replace('_', ' ')}</b>,
    render: (item, index, active) => (
      <>
        <div className={column.label}>
          {Object.entries(checkboxes(column.value, item)).map(([checkbox, val]) => {
            if (['team_to_feedback', 'comment'].includes(checkbox)) {
              return <></>;
            }

            return (
              <Checkbox
                disabled={readOnly}
                style={{ padding: '0.2rem' }}
                label={checkbox.toUpperCase().replace('_', ' ')}
                name={checkbox}
                checked={data[index]['data_arr'][column.value][checkbox]}
                onChange={(event) => handleChange(event, index, column.value)}
              />
            );
          })}
        </div>
      </>
    ),
    comment: (item, index, active) => {
      const dataItem = data[0]['data_arr'][column.value];

      return (
        <>
          <div className={column.label}>
            <Textarea
              style={{ padding: '1rem' }}
              placeholder='Your comment'
              name={'comment'}
              radius='md'
              value={dataItem?.comment || ''}
              error={
                (!dataItem.comment || (dataItem?.comment || '').length === 0) && dataItem['others']
              }
              onChange={(event) => handleChange(event, index, column.value)}
            />
          </div>
        </>
      );
    },
  }));

  return loading ? (
    <AppLoader center size='lg' />
  ) : (
    <>
      {rows.map(({ name, render, comment }, index) => (
        <tr key={index}>
          <td
            colSpan={2}
            style={{
              position: 'sticky',
              backgroundColor: '#cdcdcd',
              width: '25%',
              padding: '15px',
              maxWidth: '25%',
              minWidth: '25%',
            }}
          >
            {name}
          </td>

          {(data || []).map((item, i) => (
            <>
              <td style={{ borderRight: '1px solid #dee2e6' }}>{render(item, i)}</td>
              <td style={{ borderRight: '1px solid #dee2e6' }}>{comment(item, i)}</td>
            </>
          ))}
        </tr>
      ))}
      <tr>
        <td
          colSpan={2}
          style={{
            position: 'sticky',
            width: '25%',
            padding: '15px',
            maxWidth: '25%',
            minWidth: '25%',
          }}
        ></td>
        <td></td>
        <td
          style={{
            width: '100%',
            display: 'inline-flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            onClick={() => handleNext(data)}
            loading={submitLoader}
            variant='gradient'
            gradient={{ from: '#e3af32', to: '#f4e00f' }}
            size='lg'
            style={{
              paddingLeft: 30,
              paddingRight: 30,
            }}
          >
            Submit
          </Button>
        </td>
      </tr>
      {err_found && <FeedbackModal open={err_found} setOpen={setErr_found} />}
      {SubmissionSucess && (
        <SubmissionTrueModal open={SubmissionSucess} setOpen={setSubmissionSucess} />
      )}

      {npsOpened && (
        <NpsComponent
          rfs_number={params.rfsNumber}
          opened={npsOpened}
          setOpened={setNpsOpened}
          callback={() => {
            window.location.reload();
          }}
          id={NpsIds().ftr}
        />
      )}
    </>
  );
}
