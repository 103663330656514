import React, { useState, useContext, useEffect } from 'react';
import { Box, Accordion, Grid, Button } from '@mantine/core';
import ContentPaper from '../../../../components/layouts/rfs/Content';
import { Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { RfsContext } from '../../../../contexts/RfsContext';
import TrialsTableVEAM from './trialsTableVEAM';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AppLoader } from '../../../../components/common/loader';
import { AuthContext } from '../../../../contexts/AuthContext';
import { TableExport } from 'tabler-icons-react';
import { CSVLink } from 'react-csv';
import { routes } from '../../../../routes';
import csvDownload from 'json-to-csv-export';
import moment from 'moment';
import checkClosedOrInProgress from '../../veamHelper';
import { sCodeStyles } from '../../../../utils/helpers';

export default function TrialsVEAM({ checkClosuresTrials, readOnly }) {
  const { form } = useContext(RfsContext);
  const { rfs_number } = form.rfsInformation;
  const { userEmail } = useContext(AuthContext);
  const accessors = {
    materials: 'selection_number',
    liquids: 'selection_number',
    skus: 'selection_number',
  };
  const secondaryAccessor = {
    materials: 'selection_name',
    liquids: 'selection_name',
    skus: 'selection_name',
  };
  const tertiaryAccessor = {
    materials: null,
    liquids: null,
    skus: null,
  };
  const [scopeErrors, setScopeErrors] = useState({
    errors: {},
    liquidErrors: {},
    materialErrors: {},
    productionSiteErrors: {},
  });

  const [loading, setLoading] = useState(false);

  const [trials, setTrials] = useState([]);
  const [resultData, setResultData] = useState([]);
  const [trialInfo, setTrialInfo] = useState([]);
  const [clicked, setClicked] = useState(false);

  const { fetchTrialsVEAM, submitAllTrialsVEAM, fetchTrialInfoVEAM } = useContext(RfsContext);

  const [value, setValue] = useState(0);

  const checkAndFilterScopeIds = (dataArr) => {
    let arr = [];

    // for liquids
    dataArr.map((item) =>
      arr.includes(item.int_rfs_scope_selection.id) === false &&
      item.int_rfs_scope_selection.selection_type === 'liquids'
        ? arr.push(item.int_rfs_scope_selection.id)
        : '',
    );

    let a = arr.map((arr_item) => {
      return dataArr.filter((item) => {
        return (
          item.int_rfs_scope_selection.id == arr_item &&
          item.int_rfs_scope_selection.selection_type === 'liquids'
        );
      });
    });

    // for materials
    const b = dataArr.filter((item) => {
      return item.int_rfs_scope_selection.selection_type === 'materials';
    });

    a = a.map((item) => item[0]).concat(b);

    return a;
  };

  useEffect(() => {
    getData(rfs_number);
    getTrials(rfs_number);
  }, [rfs_number]);

  useEffect(() => {
    getTrials(rfs_number);
    const filt = trials.filter((item) => item.int_rfs_impl_trial?.trials_result);
    const newRows = checkAndFilterScopeIds(filt);
    setResultData(newRows);
    checkClosuresTrials(checkClosedOrInProgress(trials));
    //setResultData(filt);
  }, [trials]);

  const getTrials = (rfs_number) => {
    fetchTrialInfoVEAM(rfs_number).then((res) => {
      setTrialInfo(res.data.data);
    });
  };

  const getData = (rfs_number, bool) => {
    setLoading(true);
    fetchTrialsVEAM(rfs_number).then((res) => {
      setTrials(checkAndFilterScopeIds(res.data.data));
      //setTrials(res.data.data);
      setLoading(false);
      if (bool) window.location.reload();
    });
  };

  const save = () => {
    fetchTrialsVEAM(rfs_number).then((res) => {
      setTrials(checkAndFilterScopeIds(res.data.data));
      //setTrials(res.data.data);
    });
  };
  const handleSubmit = () => {
    setClicked(true);
    let payload = {
      email: userEmail,
    };
    submitAllTrialsVEAM(payload, rfs_number).then(() => getData(rfs_number, true));
  };

  const handleTrialChange = (id, field_name, value) => {
    let currentValues = [...trials];

    const index = currentValues.findIndex((i) => i.int_rfs_impl_trial.status_id === id);

    currentValues[index]['int_rfs_impl_trial'][field_name] = value;
    setTrials(currentValues);
  };

  if (loading) {
    return (
      <ContentPaper page={'ProductDevelopment'}>
        <AppLoader size='lg' center />
      </ContentPaper>
    );
  }

  const downloadExcel = (type) => {
    const dataToExport = {
      data: [],
      filename: '',
      delimiter: ',',
      headers: [],
    };
    const materialsToExport = {
      data: [],
      filename: '',
      delimiter: ',',
      headers: [],
    };
    const liquidHeaders = [
      'Liquid Code',
      'Result',
      'Reason of failure',
      'Comments',
      'Step Deadline',
      'Step finished',
    ];
    const materialHeaders = [
      'Material Code',
      'Plant',
      'Result',
      'Reason of failure',
      'Comment',
      'Step Deadline',
      'Step finished',
    ];

    const liquids = trials.filter((m) => m.int_rfs_scope_selection.selection_type === 'liquids');
    const materials = trials.filter(
      (m) => m.int_rfs_scope_selection.selection_type === 'materials',
    );

    if ((liquids || []).length > 0) {
      dataToExport.data = getDataToExport(liquids, 'liquids');
      dataToExport.filename = ' Trials_liquids' + moment().format('YYYY-MM-DD HH:mm:ss');
      dataToExport.headers = liquidHeaders;
      csvDownload(dataToExport);
    }

    if ((materials || []).length > 0) {
      materialsToExport.data = getDataToExport(materials, 'materials');
      materialsToExport.filename = 'Trials_materials' + moment().format('YYYY-MM-DD HH:mm:ss');
      materialsToExport.headers = materialHeaders;
      csvDownload(materialsToExport);
    }
  };

  const getDataToExport = (data, type) => {
    const csvArray = type === 'liquids' ? data.map(dataToExportL) : data.map(dataToExport);

    return csvArray;
  };

  const dataToExport = ({
    Trials_Liquids,
    int_rfs_material,
    int_rfs_impl_trial,
    sla_date,
    finished_date,
    status,
  }) => {
    return {
      'New Material Code': Trials_Liquids,
      Plant: int_rfs_material?.plant,
      Result: int_rfs_impl_trial?.trials_result ? int_rfs_impl_trial?.trials_result : 'N/A',
      'Reason of failure': int_rfs_impl_trial?.trials_failure_reason
        ? int_rfs_impl_trial?.trials_failure_reason
        : 'N/A',
      Comments: int_rfs_impl_trial?.trials_comment ? int_rfs_impl_trial?.trials_comment : 'N/A',
      'Step Deadline': sla_date,
      'Step finished': finished_date === null ? status : finished_date,
    };
  };

  const dataToExportL = ({
    Trials_Liquids,
    int_rfs_impl_trial,
    sla_date,
    finished_date,
    status,
  }) => {
    return {
      'New Material Code': Trials_Liquids,
      Result: int_rfs_impl_trial?.trials_result ? int_rfs_impl_trial?.trials_result : 'N/A',
      'Reason of failure': int_rfs_impl_trial?.trials_failure_reason
        ? int_rfs_impl_trial?.trials_failure_reason
        : 'N/A',
      Comments: int_rfs_impl_trial?.trials_comment ? int_rfs_impl_trial?.trials_comment : 'N/A',
      'Step Deadline': sla_date,
      'Step finished': finished_date === null ? status : finished_date,
    };
  };

  return (
    <>
      <Box sx={sCodeStyles}>SKU CODES</Box>
      <div
        style={{
          display: 'flex',
          justifyContent: 'right',
          marginRight: '4rem',
        }}
      >
        <Button
          leftIcon={<TableExport />}
          onClick={() => {
            downloadExcel();
          }}
          color='yellow'
          style={{
            marginTop: '1rem',
            background: 'black',
          }}
        >
          Export to CSV
        </Button>
      </div>
      <br />

      {trialInfo && (
        <Box
          sx={(theme) => ({
            background: 'linear-gradient(90deg,#e3af32 0%,#f4e00f 100%)',
            textAlign: 'center',
            padding: theme.spacing.xs,
            borderRadius: theme.radius.md,
            cursor: 'pointer',

            color: 'black',
            fontSize: theme.fontSizes.lg,
            fontWeight: 1000,

            '&:hover': {
              background: 'linear-gradient(90deg,#e3af32 0%,#f4e00f 100%)',
            },
          })}
        >
          {trialInfo.rfs_number?.length > 0 &&
          trialInfo.trials_reversal &&
          !trialInfo.rfs_reversal_closure_date ? (
            'Reversal in Progress'
          ) : trialInfo.rfs_number?.length > 0 &&
            trialInfo.trials_reversal &&
            trialInfo.rfs_reversal_closure_date &&
            trialInfo.child_rfs_number?.length === 0 ? (
            `Scope Restatement is Complete`
          ) : trialInfo.rfs_number?.length > 0 &&
            trialInfo.trials_reversal &&
            trialInfo.rfs_reversal_closure_date &&
            trialInfo.child_rfs_number?.length > 0 ? (
            <>
              {`Scope Restatement is complete`} <br />(
              <Link
                className='white rfs-link'
                to={`${routes.project(trialInfo.child_rfs_number).root}?tab=projectOverview`}
              >
                Click here to view child RFS{' '}
                <span className='blue'>{`${trialInfo.child_rfs_number}`}</span>
              </Link>
              )
            </>
          ) : trialInfo.rfs_number?.length > 0 &&
            trialInfo.trials_reversal &&
            !trialInfo.rfs_reversal_closure_date &&
            trialInfo.child_rfs_number?.length > 0 ? (
            <>
              {`Reversal in progress`} <br />(
              <Link
                className='white rfs-link'
                to={`${routes.project(trialInfo.child_rfs_number).root}?tab=projectOverview`}
              >
                Click here to view child RFS{' '}
                <span className='blue'>{`${trialInfo.child_rfs_number}`}</span>
              </Link>
              )
            </>
          ) : trialInfo.rfs_number?.length > 0 &&
            trialInfo.trials_reversal &&
            trialInfo.rfs_reversal_closure_date ? (
            `Reversal is complete`
          ) : (
            ''
          )}
        </Box>
      )}

      <Grid>
        <Grid.Col span={12}>
          <Accordion value={value} onChange={setValue}>
            {Object.keys(form?.selectedScope || {})
              .reverse((a, b) => b - a)
              .map((name, index) => {
                const trialsList = trials.filter(
                  (m) => m.int_rfs_scope_selection.selection_type === name,
                );
                const item = form?.selectedScope[name][index];

                return (
                  trialsList.length > 0 && (
                    <Accordion.Item
                      className='scope-label'
                      key={`key_${name}_${index}`}
                      value={`key_${name}_${index}`}
                    >
                      <Accordion.Control>{`Trials for ${name.toUpperCase()} code`}</Accordion.Control>
                      <Accordion.Panel>
                        {
                          <Grid>
                            <Grid.Col span={12}>
                              <TrialsTableVEAM
                                trialsData={trialsList}
                                onChange={handleTrialChange}
                                save={save}
                                name={name}
                                readOnly={readOnly}
                              />
                            </Grid.Col>
                          </Grid>
                        }
                      </Accordion.Panel>
                    </Accordion.Item>
                  )
                );
                //})
              })}
          </Accordion>
        </Grid.Col>
        <Grid.Col span={2} offset={10}>
          <Button
            variant='gradient'
            gradient={{ from: '#e3af32', to: '#f4e00f' }}
            radius='md'
            size='md'
            className='submit_button_trials'
            color='yellow'
            disabled={
              resultData.length != trials.length ||
              clicked ||
              form.rfsInformation.status != `Product Development`
            }
            onClick={() => handleSubmit()}
            fullWidth
          >
            Submit
          </Button>
        </Grid.Col>
      </Grid>
    </>
  );
}
