import { Routes, Route, Navigate } from 'react-router-dom';
import Login from '../pages/Login/Login';
import Register from '../pages/register/userRegister/register';
import { routes } from '../routes';

const LoggedOutRoutes = () => (
  <Routes>
    <Route path='/' element={<Login />} />
    <Route path={routes.home.register} element={<Register />} />
    <Route path='*' element={<Navigate to='/' replace />} />
  </Routes>
);

export default LoggedOutRoutes;
