import { BrowserRouter as Router } from 'react-router-dom';

import LoggedInRoutes from './routes/loggedIn-routes';
import LoggedOutRoutes from './routes/loggedOut-routes';

import './App.css';
import { useContext } from 'react';
import { AuthContext } from './contexts/AuthContext';

import { Helmet } from 'react-helmet';

const Clarity = ({ userRole }) => {
  return (
    <>
      <Helmet>
        <meta name='referrer' content='no-referrer' />
        <script type='text/javascript'>
          {`(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "${process.env.REACT_APP_CLARITY_KEY}");
    window.clarity('set', 'userRole', '${userRole}')`}
        </script>
      </Helmet>
    </>
  );
};

function App() {
  const { loggedIn, userRole } = useContext(AuthContext);

  return (
    <div className={`App `} style={{ overflow: 'hidden' }}>
      <Clarity userRole={userRole} />
      <Router>{loggedIn ? <LoggedInRoutes /> : <LoggedOutRoutes />}</Router>
    </div>
  );
}

export default App;
