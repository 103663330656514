import React, { useState, useContext, useEffect } from 'react';
import { Text, useMantineTheme, Button } from '@mantine/core';
import { Photo } from 'tabler-icons-react';
import ProjectName from '../../components/projectName/projectName';
import { Tabs } from '@mantine/core';
import { Row, Col } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { RfsContext } from '../../contexts/RfsContext';
import { AttachmentModal } from '../modal/AttachmentModal';

import './attachmentComp.css';
import ContentPaper from '../layouts/rfs/Content';
import { AppLoader } from '../common/loader';
import { routes } from '../../routes';
import { liquidTypes, rfsStatus } from '../../constants';
import { CustomDropzone } from '../../common/dropzone';
import { FileList } from '../../common/dropzone/fileList';

function AttachmentComp(props) {
  const params = useParams();
  const navigate = useNavigate();
  const [attachmentModal, setAttachmentModal] = useState(false);
  const [artworkRequired, setArtworkRequired] = useState(false);
  const [liquidRequired, setLiquidRequired] = useState(false);
  const [vis1, setVis1] = useState(true);
  const [error, setError] = useState({ state: false, message: null, type: null });
  const { form, addAttachments, loading, saveAsDraft, downloadFile } = useContext(RfsContext);
  const { status = '', fast_track = '', capex_needed = '', rfs_type } = form.rfsInformation;
  const { scopeSelectedMaterials = [], scopeSelectedLiquids = [] } = form;

  const {
    artworkBriefing,
    fastTrackApproval,
    capexEmailApproval,
    emailAttachment,
    liquidAttachment,
  } = form.attachments;

  useEffect(() => {
    if (scopeSelectedMaterials.length > 0) {
      const index = scopeSelectedMaterials.findIndex((material) => material.artwork_change);
      setArtworkRequired(index > -1);
    }
  }, [scopeSelectedMaterials]);

  useEffect(() => {
    if (scopeSelectedLiquids.length > 0) {
      const index = scopeSelectedLiquids.findIndex(
        (liquid) =>
          [liquidTypes.new, liquidTypes.add].includes(liquid.liquid_type) && !liquid.liquid_number,
      );
      setLiquidRequired(index > -1);
    }
  }, [scopeSelectedLiquids]);

  useEffect(() => {
    if (
      form.rfsInformation &&
      form.rfsInformation.rfs_type &&
      form.rfsInformation.rfs_type === 'Open SKU to market'
    )
      setVis1(false);
  }, [form.rfsInformation.rfs_type]);

  const isDisabled = () => {
    return (
      (Boolean(artworkRequired) && artworkBriefing.length === 0) ||
      (Boolean(fast_track) && fastTrackApproval.length === 0) ||
      (Boolean(capex_needed) && capexEmailApproval.length === 0) ||
      (Boolean(liquidRequired) && liquidAttachment.length === 0)
    );
  };

  const handleNext = (callback) => {
    if (!callback) {
      addAttachments(callback);
      return;
    }
    if (isDisabled()) {
      setAttachmentModal(true);
    } else {
      addAttachments(callback);
    }
  };

  const Footer = () =>
    props.disableButtons ? null : (
      <Row className='justify-content-md-end' md={4}>
        <Col md='auto'>
          <div classname='buttonRow'>
            <Link to={routes.submission(params.rfsNumber).scopeDefine}>
              <Button
                className='back'
                color='yellow'
                radius='md'
                size='md'
                fullWidth
                style={{
                  marginRight: '1rem',
                  background: 'white',
                  color: 'black',
                  border: ' 0.25px solid gray',
                  width: '200px',
                }}
              >
                Back
              </Button>
            </Link>
          </div>
        </Col>

        <Col md='auto'>
          <Button
            disabled={saveAsDraft}
            className='save'
            color='dark'
            radius='md'
            size='md'
            fullWidth
            style={{
              marginRight: '1rem',
              border: 'black',
              width: '200px',
            }}
            onClick={() => handleNext()}
          >
            {!saveAsDraft ? 'Save' : <AppLoader />}
          </Button>
        </Col>
        <Col md='auto'>
          <Button
            disabled={loading}
            className='next'
            color='yellow'
            variant='gradient'
            gradient={{ from: '#e3af32', to: '#f4e00f' }}
            radius='md'
            size='md'
            fullWidth
            style={{
              marginRight: '1rem',
              width: '200px',
            }}
            onClick={() =>
              handleNext(() =>
                status === rfsStatus.draft
                  ? navigate(routes.submission(params.rfsNumber).review)
                  : navigate(-1),
              )
            }
          >
            {!loading ? 'Next' : <AppLoader />}
          </Button>
        </Col>
      </Row>
    );

  return (
    <ContentPaper page={'attachments'} footer={<Footer />}>
      {props.withProjectName ? (
        <Row>
          <div className='projectNameComp'>
            <ProjectName initialItem={props.initialItem} />
          </div>
        </Row>
      ) : null}

      <Row>
        {vis1 && (
          <Row>
            {props.withEdit ? (
              <Text size='sm' weight={600}>
                Artwork Briefing
              </Text>
            ) : (
              <Text size='sm' weight={600}>
                Please upload Artwork Briefing{' '}
                {artworkRequired ? (
                  <span style={{ color: 'red', fontWeight: '400' }}>* (Required Field)</span>
                ) : (
                  <div></div>
                )}
              </Text>
            )}

            <Tabs color='dark' tabPadding='md' defaultValue={'Files'}>
              <Tabs.List>
                <Tabs.Tab className='topTabs' value='Files' icon={<Photo size={14} />}>
                  Files
                </Tabs.Tab>
                {!props.withEdit && (
                  <Tabs.Tab value='Template' icon={<Photo size={14} />}>
                    Template
                  </Tabs.Tab>
                )}
              </Tabs.List>

              <Tabs.Panel className='topTabs' value='Files' icon={<Photo size={14} />}>
                <CustomDropzone setError={setError} type={'artworkBriefing'} />

                <FileList
                  files={artworkBriefing}
                  type={'artworkBriefing'}
                  error={Boolean(artworkRequired) && artworkBriefing.length === 0}
                />
                {error.state && error.type === 'artworkBriefing' && (
                  <div className='red-custom-mantine-Textarea-error'>{error.message}</div>
                )}
              </Tabs.Panel>

              {!props.withEdit && (
                <Tabs.Panel value='Template' icon={<Photo size={14} />}>
                  <Col md={{ span: 2 }}>
                    <Button
                      style={{ marginBottom: '1rem', background: 'black', color: 'white' }}
                      color='yellow'
                      fullWidth
                      onClick={() => downloadFile('Artwork Briefing Template.xlsx')}
                    >
                      Download PDF
                    </Button>
                  </Col>
                </Tabs.Panel>
              )}
            </Tabs>
          </Row>
        )}

        <Row>
          {props.withEdit ? (
            <Text size='sm' weight={600}>
              Fast Track Approval
            </Text>
          ) : (
            <Text size='sm' weight={600}>
              Please upload Fast Track Approval{' '}
              {fast_track ? (
                <span style={{ color: 'red', fontWeight: '400' }}>* (Required Field)</span>
              ) : (
                <div></div>
              )}
            </Text>
          )}
          <Tabs color='dark' tabPadding='md' defaultValue={'Files'}>
            <Tabs.List>
              <Tabs.Tab value='Files' icon={<Photo size={14} />}>
                Files
              </Tabs.Tab>
              {!props.withEdit && (
                <Tabs.Tab value='Template' icon={<Photo size={14} />}>
                  Template
                </Tabs.Tab>
              )}
            </Tabs.List>

            <Tabs.Panel value='Files' icon={<Photo size={14} />}>
              <CustomDropzone setError={setError} type={'fastTrackApproval'} />

              <FileList
                files={fastTrackApproval}
                type={'fastTrackApproval'}
                error={Boolean(fast_track) && fastTrackApproval.length === 0}
              />
              {error.state && error.type === 'fastTrackApproval' && (
                <div className='red-custom-mantine-Textarea-error'>{error.message}</div>
              )}
            </Tabs.Panel>

            {!props.withEdit && (
              <Tabs.Panel value='Template' icon={<Photo size={14} />}>
                <Col md={{ span: 2 }}>
                  <Button
                    style={{ marginBottom: '1rem', background: 'black', color: 'white' }}
                    color='yellow'
                    fullWidth
                    onClick={() => downloadFile('Fast Track Document.pdf')}
                  >
                    Download PDF
                  </Button>
                </Col>
              </Tabs.Panel>
            )}
          </Tabs>
        </Row>

        {vis1 && (
          <Row>
            {props.withEdit ? (
              <Text size='sm' weight={600}>
                Capex Email Approval
              </Text>
            ) : (
              <Text size='sm' weight={600}>
                Please upload Capex Email Approval{' '}
                {capex_needed ? (
                  <span style={{ color: 'red', fontWeight: '400' }}>* (Required Field)</span>
                ) : (
                  <div></div>
                )}
              </Text>
            )}
            <Tabs color='dark' tabPadding='md' defaultValue={'Files'}>
              <Tabs.List>
                <Tabs.Tab value='Files' icon={<Photo size={14} />}>
                  Files
                </Tabs.Tab>
              </Tabs.List>

              <Tabs.Panel value='Files' icon={<Photo size={14} />}>
                <CustomDropzone setError={setError} type={'capexEmailApproval'} />

                <FileList
                  files={capexEmailApproval}
                  type={'capexEmailApproval'}
                  error={Boolean(capex_needed) && capexEmailApproval.length === 0}
                />
                {error.state && error.type === 'capexEmailApproval' && (
                  <div className='red-custom-mantine-Textarea-error'>{error.message}</div>
                )}
              </Tabs.Panel>
            </Tabs>
          </Row>
        )}
        <Row>
          {props.withEdit ? (
            <Text size='sm' weight={600}>
              Additional Email Attachment
            </Text>
          ) : (
            <Text size='sm' weight={600}>
              Please upload Additional Email Attachment
            </Text>
          )}

          <Tabs color='dark' tabPadding='md' defaultValue={'Files'}>
            <Tabs.List>
              <Tabs.Tab value='Files' icon={<Photo size={14} />}>
                Files
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value='Files' icon={<Photo size={14} />}>
              <CustomDropzone setError={setError} type={'emailAttachment'} maxFiles={10} size={8} />

              <FileList files={emailAttachment} type={'emailAttachment'} />
              {error.state && error.type === 'emailAttachment' && (
                <div className='red-custom-mantine-Textarea-error'>{error.message}</div>
              )}
            </Tabs.Panel>
          </Tabs>
        </Row>

        {rfs_type === 'Article Modification/Value Engineering' && (
          <Row>
            {props.withEdit ? (
              <Text size='sm' weight={600}>
                Liquid Attachment
              </Text>
            ) : (
              <Text size='sm' weight={600}>
                Please upload Liquid Attachment
                {liquidRequired && (
                  <span style={{ color: 'red', fontWeight: '400' }}>* (Required Field)</span>
                )}
              </Text>
            )}

            <Tabs color='dark' tabPadding='md' defaultValue={'Files'}>
              <Tabs.List>
                <Tabs.Tab value='Files' icon={<Photo size={14} />}>
                  Files
                </Tabs.Tab>
              </Tabs.List>
              <Tabs.Panel value='Files' icon={<Photo size={14} />}>
                <CustomDropzone setError={setError} type={'liquidAttachment'} />
                <FileList
                  files={liquidAttachment}
                  type={'liquidAttachment'}
                  error={liquidRequired}
                />
                {error.state && error.type === 'liquidAttachment' && (
                  <div className='red-custom-mantine-Textarea-error'>{error.message}</div>
                )}
              </Tabs.Panel>
            </Tabs>
          </Row>
        )}
      </Row>

      {attachmentModal && (
        <AttachmentModal invalidModal={attachmentModal} setInvalidModal={setAttachmentModal} />
      )}
    </ContentPaper>
  );
}

export default AttachmentComp;
