import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { Checkbox, TextInput, Textarea, Button, Select, Text } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { FileIcon } from '@radix-ui/react-icons';
import { CalendarIcon, Pencil1Icon } from '@modulz/radix-icons';

import { RfsContext } from '../../../contexts/RfsContext';
import { AuthContext } from '../../../contexts/AuthContext';
import ProjectName from '../../../components/projectName/projectName';
import ContentPaper from '../../../components/layouts/rfs/Content';
import { AppLoader } from '../../../components/common/loader';
import { CostCenter } from '../../../common/inputs/CostCenter';
import ModalUsers from '../../../components/modal/modal';
import FastTrackModal from '../../../components/modal/FastTrackModal';
import CCModal from '../../../components/modal/CCModal';
import { InvalidModal } from '../../../components/modal/invalidModal';
import ModalAccf from '../../../pages/accf/ModalAccf';
import { routes } from '../../../routes';
import { rfsStatus, roles } from '../../../constants';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../components/forms/rfsInitiationForm/rfsInitiationForm.css';

export const minWordsValidation = (text = '', wordsLength = 9) => {
  let processedText = text && text.length > 0 ? text.replace(/\n/g, ' ') : '';
  const textArray = processedText.split(' ');

  return textArray.length <= wordsLength;
};

export default function SupplierChangeInformation(props) {
  const navigate = useNavigate();
  const params = useParams();
  const {
    form,
    setForm,
    costCenters = [],
    update,
    loading,
    saveAsDraft,
    downloadFile,
    changeLog,
    infoLoading,
  } = useContext(RfsContext);
  const { userRole, users, userEmail } = useContext(AuthContext);
  const [invalidModal, setInvalidModal] = useState({
    open: false,
    callback: false,
  });
  const [ccModal, setCcModal] = useState({
    open: false,
    callback: null,
    query: null,
  });
  const [validate, setValidate] = useState(false);
  const { state } = useLocation();

  const {
    project_name,
    initiator_position,
    initiator_team,
    backupUsers = [],
    confidential,
    allowedUsers = [],
    first_shipment,
    fast_track,
    gatekeeper = [],
    technicalLead,
    cost_center,
    project_purpose,
    project_details,
    comments,
    trials_needed,
    impactedMarkets = [],
    d2p_lead = [],
    color_settings_target_needed = null,
    agree_to_pay = false,
  } = form.rfsInformation;

  const handleChange = (event, type = null) => {
    const currentValues = { ...form };
    if (type) {
      currentValues.rfsInformation[type][event.target.name] =
        event.target.type === 'checkbox'
          ? event.target.checked
          : (event.target.value || '').trim().length === 0
          ? event.target.value.trim()
          : event.target.value;
    } else {
      currentValues.rfsInformation[event.target.name] =
        event.target.type === 'checkbox'
          ? event.target.checked
          : (event.target.value || '').trim().length === 0
          ? event.target.value.trim()
          : event.target.value;

      //word limit
      if (event.target.name === 'initiator_position' && event.target.value.length > 100)
        currentValues.rfsInformation[event.target.name] = event.target.value.substring(0, 100);

      if (event.target.name === 'capex_amount') {
        if (/^0*$/.test(event.target.value)) {
          event.target.value = event.target.value
            .replace(/[^1-9.]/g, '')
            .replace(/(\..*)\./g, '$1');
          currentValues.rfsInformation[event.target.name] = event.target.value;
        }

        event.target.value =
          (event.target.value.match(/\./g) || []).length > 1
            ? (currentValues.rfsInformation[event.target.name] = event.target.value.slice(0, -1))
            : '';
      }

      if (
        (event.target.name === 'project_purpose' && event.target.value.length > 1000) ||
        (event.target.name === 'project_details' && event.target.value.length > 1000) ||
        (event.target.name === 'comments' && event.target.value.length > 1000)
      )
        currentValues.rfsInformation[event.target.name] = event.target.value.substring(0, 1000);
    }

    if (event.target.name === 'fast_track' && !event.target.checked) {
      currentValues.rfsInformation['first_shipment'] = null;
    }

    setForm(currentValues);
  };

  const validateTextArea = (name) => {
    let response = '';

    if (validate && !form.rfsInformation[name]) {
      response = 'Required Field';
    } else if (validate && minWordsValidation(form.rfsInformation[name])) {
      //response = 'Minimum 10 words required';
      response = 'Please provide more details';
    }

    return response;
  };

  const handleSelect = (type, value, subType = null) => {
    const currentValues = { ...form };

    if (subType) {
      currentValues.rfsInformation[subType][type] = value;
    } else {
      currentValues.rfsInformation[type] =
        type === 'first_shipment'
          ? moment(value).isValid()
            ? moment(value).format('YYYY-MM-DD')
            : null
          : value;
    }

    setForm(currentValues);
  };

  const handleCostCenterCreate = (query) => {
    handleSelect('cost_center', query);
  };

  const handleUserSelect = (type, users) => {
    const currentValues = { ...form };
    currentValues.rfsInformation[type] = users;
    setForm(currentValues);
  };

  const handleNext = (callback = null) => {
    if (!callback) {
      update(callback);
      return;
    }

    setValidate(true);
    if (isDisabled()) {
      setInvalidModal({
        open: true,
        callback: callback ? callback : () => setInvalidModal(false),
      });
    } else {
      update(callback);
      if (userRole === roles.pmo) changeLog(callback);
    }
  };

  const isDisabled = () => {
    return (
      !initiator_position ||
      !initiator_team ||
      backupUsers.length === 0 ||
      !first_shipment ||
      !trials_needed ||
      gatekeeper?.length === 0 ||
      impactedMarkets.length === 0 ||
      minWordsValidation(project_purpose) ||
      minWordsValidation(project_details) ||
      !cost_center ||
      (trials_needed === 'Yes' && (!technicalLead || technicalLead.length === 0)) ||
      !color_settings_target_needed ||
      (color_settings_target_needed === 'Yes' && !d2p_lead) ||
      (color_settings_target_needed === 'Yes' && d2p_lead.length === 0) ||
      (confidential && (!allowedUsers || allowedUsers.length === 0)) ||
      !agree_to_pay
    );
  };

  const handleAccfSelect = (type, data) => {
    const currentValues = { ...form };
    const fields = {
      markets: 'impactedMarkets',
      plants: 'impactedPlants',
    };
    if (currentValues.rfsInformation[fields[type]]) currentValues.rfsInformation[fields[type]] = [];

    currentValues.rfsInformation[fields[type]] = data;
    setForm(currentValues);
  };

  const Footer = () =>
    state?.routeFromRfsOverview ? (
      <Row>
        <Col md={{ span: 2, offset: 10 }}>
          <Button
            disabled={loading && !invalidModal.open}
            leftIcon={<FileIcon />}
            color='yellow'
            variant='gradient'
            gradient={{ from: '#e3af32', to: '#f4e00f' }}
            fullWidth
            radius='sm'
            onClick={() =>
              handleNext(() => {
                navigate(-1);
              })
            }
          >
            {loading && !invalidModal.open ? <AppLoader /> : 'Save Edits'}
          </Button>
        </Col>
      </Row>
    ) : (
      <Row>
        {props.disableButtons ? null : (
          <Row className='justify-content-md-end' md={4}>
            <Col md='auto'>
              <Button
                disabled={saveAsDraft}
                className='save'
                color='yellow'
                radius='md'
                size='md'
                fullWidth
                style={{ marginRight: '1rem', background: 'black', color: 'white', width: '200px' }}
                onClick={() => handleNext()}
              >
                {saveAsDraft && !invalidModal.open ? <AppLoader /> : 'Save as Draft'}
              </Button>
            </Col>
            <Col md='auto'>
              <Button
                disabled={loading && !invalidModal.open}
                className='back'
                color='yellow'
                radius='md'
                variant='gradient'
                gradient={{ from: '#e3af32', to: '#f4e00f' }}
                size='md'
                fullWidth
                style={{
                  marginRight: '1rem',
                  width: '200px',
                }}
                onClick={() =>
                  handleNext(() => {
                    navigate(routes.submission(params.rfsNumber).scopeSelect);
                  })
                }
              >
                {loading && !invalidModal.open ? <AppLoader /> : 'Next'}
              </Button>
            </Col>
          </Row>
        )}
      </Row>
    );

  if (infoLoading)
    return (
      <ContentPaper page={'rfs-inintiation'}>
        <AppLoader size='lg' center />
      </ContentPaper>
    );

  if (
    form.rfsInformation.status &&
    ![
      rfsStatus.draft,
      rfsStatus.tech_feasible,
      rfsStatus.tech_feasible2,
      rfsStatus.supplier_pmo2_active,
    ].includes(form.rfsInformation.status)
  ) {
    navigate(routes.submission(params.rfsNumber).overView);
  }

  return (
    <ContentPaper page={'rfs-inintiation'} footer={<Footer />}>
      {props.withProjectName ? (
        <Row className='ProjectName'>
          <Col md={{ span: 12 }}>
            <ProjectName Pnumber={project_name} initialItem={props.initialItems} />
          </Col>
        </Row>
      ) : null}

      <Row className='projectDetailsForm'>
        <Container className='ProjectDetails'>
          <form>
            <Row>
              <Col
                md={1}
                style={{
                  width: '2.0833%',
                  flex: '0 0 2.0833%',
                  maxWidth: ' 2.0833%',
                }}
              ></Col>
              <Col style={{ marginTop: '1rem' }}>
                <h5 className='sectionHeadings'>GENERAL DETAILS</h5>
              </Col>
            </Row>
            <Row>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666%',
                }}
              ></Col>
              <Col md={{ span: 5, offset: 0 }}>
                <TextInput
                  error={validate && !initiator_position ? 'Required Field' : ''}
                  required
                  className='InputField'
                  radius='md'
                  label='Initiator Position'
                  name='initiator_position'
                  value={initiator_position}
                  disabled={props.disabled}
                  onChange={handleChange}
                />
                {initiator_position?.length > 99 && (
                  <div className='custom-mantine-Textarea-error'>
                    100 Character limit reached, exceeding character would be truncated on saving.
                  </div>
                )}
              </Col>
              <Col md={{ span: 5, offset: 1 }}>
                <Select
                  error={validate && !initiator_team ? 'Required Field' : ''}
                  required
                  className='InputField'
                  searchable
                  clearable
                  creatable
                  nothingFound='Nothing found'
                  radius='md'
                  label='Initiator Team'
                  name='initiator_team'
                  value={initiator_team}
                  onChange={(value) => handleSelect('initiator_team', value)}
                  data={[
                    'ZITEC',
                    'Brewery',
                    'Quality',
                    'Marketing/Brand Team',
                    'Sales/Revenue Management',
                    'Demand Planning',
                    'Commercial Planning',
                    'SNP/DP',
                    'Logistics Transp/WHS',
                    'Innovation',
                    'Procurement/MRP',
                    'Acceleration Managers',
                    'Trade Marketing',
                  ]}
                  disabled={props.disabled}
                />
              </Col>
            </Row>
            <Row>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666%',
                }}
              ></Col>
              <Col md={{ span: 2, offset: 0 }}>
                <div>
                  <p className='fixedLeft' style={{ marginBottom: '0px' }}>
                    Gatekeeper <span style={{ color: 'red' }}>*</span>
                  </p>
                  {validate && gatekeeper?.length === 0 && (
                    <span
                      style={{
                        color: '#f03e3e',
                        fontSize: '14px',
                        marginBottom: '1rem',
                      }}
                    >
                      Required Field
                    </span>
                  )}
                </div>
              </Col>

              <Col className='FixedRight' md={{ span: 2, offset: 1 }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    flexDirection: 'row',
                  }}
                >
                  {gatekeeper.length > 0 && (
                    <Text
                      style={{
                        fontSize: '0.875rem',
                        fontWeight: '450',
                        color: '#212529',
                        paddingRight: 'unset',
                      }}
                    >
                      {gatekeeper[0].name}
                    </Text>
                  )}
                  <ModalUsers
                    style={{ paddingLeft: 'unset' }}
                    title={gatekeeper.length === 0 ? 'Indicate Gatekeeper' : <Pencil1Icon />}
                    type={'gatekeeper'}
                    selected={gatekeeper}
                    handleUserSelect={handleUserSelect}
                    variant={'singleSelect'}
                  />
                </div>
              </Col>

              <Col md={{ span: 2, offset: 1 }}>
                <div>
                  <p className='fixedLeft' style={{ marginBottom: '0px' }}>
                    Initiator Backup <span style={{ color: 'red' }}>*</span>
                  </p>
                  {validate && backupUsers.length === 0 && (
                    <span
                      style={{
                        color: '#f03e3e',
                        fontSize: '14px',
                        marginBottom: '1rem',
                      }}
                    >
                      Required Field
                    </span>
                  )}
                </div>
              </Col>

              <Col className='FixedRight' md={{ span: 2, offset: 1 }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    flexDirection: 'row',
                  }}
                >
                  {backupUsers.length > 0 && (
                    <Text
                      style={{
                        fontSize: '0.875rem',
                        fontWeight: '450',
                        color: '#212529',
                        paddingRight: 'unset',
                      }}
                    >
                      Check and Change Backup Users
                    </Text>
                  )}
                  <ModalUsers
                    required
                    title={backupUsers.length === 0 ? 'Indicate Backup User' : <Pencil1Icon />}
                    type={'backupUsers'}
                    selected={backupUsers}
                    handleUserSelect={handleUserSelect}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666%',
                }}
              ></Col>
              <Col md={{ span: 3, offset: 0 }}>
                {' '}
                <FastTrackModal
                  handleChange={handleChange}
                  value={fast_track}
                  disabled={props.disabled}
                  download={downloadFile}
                />
              </Col>
              <Col md={2}></Col>

              <Col md={{ span: 2, offset: 1 }}>
                <Checkbox
                  size='sm'
                  label='Confidential?'
                  className='checkbox '
                  color='orange'
                  disabled={props.disabled}
                  name='confidential'
                  checked={confidential}
                  onChange={handleChange}
                />
              </Col>

              <Col className='FixedRight' md={{ span: 2, offset: 1 }}>
                {confidential ? (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'center',
                      flexDirection: 'row',
                    }}
                  >
                    {allowedUsers.length > 0 && (
                      <Text
                        style={{
                          fontSize: '0.875rem',
                          fontWeight: '450',
                          color: '#212529',
                          paddingRight: 'unset',
                        }}
                      >
                        Check and Change Allowed Users
                      </Text>
                    )}
                    <ModalUsers
                      required
                      title={allowedUsers.length === 0 ? 'Indicate Allowed User' : <Pencil1Icon />}
                      error={
                        confidential && validate && (!allowedUsers || allowedUsers.length === 0)
                      }
                      type={'allowedUsers'}
                      selected={allowedUsers}
                      handleUserSelect={handleUserSelect}
                    />
                  </div>
                ) : (
                  <div></div>
                )}
              </Col>
            </Row>
            <Row>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666s%',
                }}
              ></Col>
              <Col md={{ span: 5, offset: 0 }}>
                <FastTrackDatePickerInput
                  validate={validate}
                  first_shipment={first_shipment}
                  disabled={props.disabled}
                  fast_track={fast_track}
                  handleSelect={handleSelect}
                />
              </Col>

              <Col md={{ span: 5, offset: 1 }}>
                <Select
                  error={validate && !trials_needed ? 'Required Field' : ''}
                  required
                  disabled={props.disabled}
                  style={{ paddingTop: '0.5rem' }}
                  label='Are technical trials needed?'
                  placeholder='---'
                  data={[
                    { value: 'Yes', label: 'Yes' },
                    { value: 'No', label: 'No' },
                    { value: 'Do not know', label: 'Do not know' },
                  ]}
                  name='trials_needed'
                  value={trials_needed}
                  onChange={(value) => handleSelect('trials_needed', value)}
                />
              </Col>
            </Row>

            <Row>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666%',
                }}
              ></Col>
              <Col md={{ span: 5 }} />
              <Col md={{ span: 2, offset: 1 }}>
                <div>
                  <p className='fixedLeft' style={{ marginBottom: '0px' }}>
                    Technical Lead{' '}
                    {trials_needed === 'Yes' && <span style={{ color: 'red' }}>*</span>}
                  </p>
                  {validate && technicalLead.length === 0 && trials_needed === 'Yes' && (
                    <span
                      style={{
                        color: '#f03e3e',
                        fontSize: '14px',
                        marginBottom: '1rem',
                      }}
                    >
                      Required Field
                    </span>
                  )}
                </div>
              </Col>

              <Col className='FixedRight' md={{ span: 2, offset: 1 }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    flexDirection: 'row',
                  }}
                >
                  {technicalLead.length > 0 && (
                    <Text
                      style={{
                        fontSize: '0.875rem',
                        fontWeight: '450',
                        color: '#212529',
                        paddingRight: 'unset',
                      }}
                    >
                      {technicalLead[0].name}
                    </Text>
                  )}
                  <ModalUsers
                    required={trials_needed === 'Yes'}
                    title={
                      !technicalLead || technicalLead.length === 0 ? (
                        'Indicate Technical Lead'
                      ) : (
                        <Pencil1Icon />
                      )
                    }
                    type={'technicalLead'}
                    selected={technicalLead}
                    handleUserSelect={handleUserSelect}
                    variant={'singleSelect'}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666s%',
                }}
              ></Col>
              <Col md={{ span: 5 }}>
                <CostCenter
                  data={costCenters}
                  onCreate={(query) => {
                    setCcModal({
                      open: true,
                      callback: handleCostCenterCreate,
                      query,
                    });
                  }}
                  value={cost_center}
                  onChange={(value) => handleSelect('cost_center', value)}
                  error={validate && !cost_center ? 'Required Field' : ''}
                />
              </Col>
              <Col md={{ span: 5, offset: 1 }}>
                <Select
                  error={validate && !color_settings_target_needed ? 'Required Field' : ''}
                  required
                  disabled={props.disabled}
                  style={{ paddingTop: '0.5rem' }}
                  label='Is Colour Target Setting needed?'
                  placeholder='---'
                  data={[
                    { value: 'Yes', label: 'Yes' },
                    { value: 'No', label: 'No' },
                    { value: 'Do not know', label: 'Do not know' },
                  ]}
                  name='color_settings_target_needed'
                  value={color_settings_target_needed}
                  onChange={(value) => handleSelect('color_settings_target_needed', value)}
                />
              </Col>
            </Row>

            <Row>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666%',
                }}
              ></Col>
              <Col md={{ span: 5 }}>
                <Row>
                  <Col md={{ span: 5, offset: 0 }}>
                    <div>
                      {' '}
                      <p
                        className='fixedLeft'
                        style={{
                          paddingBottom: '0px',
                          marginBottom: '0px',
                        }}
                      >
                        Impacted Markets <span style={{ color: 'red' }}>*</span>
                      </p>{' '}
                      {validate && impactedMarkets?.length === 0 && (
                        <span
                          style={{
                            color: '#f03e3e',
                            fontSize: '14px',
                            marginBottom: '1rem',
                          }}
                        >
                          Required Field
                        </span>
                      )}
                    </div>
                  </Col>
                  <Col className='FixedRight' md={{ span: 5, offset: 2 }}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                        flexDirection: 'row',
                      }}
                    >
                      {impactedMarkets.length > 0 && (
                        <Text
                          style={{
                            fontSize: '0.875rem',
                            fontWeight: '450',
                            color: '#212529',
                            paddingRight: 'unset',
                          }}
                        >
                          Check and Change Impacted Markets
                        </Text>
                      )}
                      <ModalAccf
                        type={'markets'}
                        title={
                          impactedMarkets?.length > 0 ? (
                            <Pencil1Icon />
                          ) : (
                            'Indicate Impacted Markets'
                          )
                        }
                        selected={
                          form.rfsInformation['impactedMarkets']
                            ? form.rfsInformation['impactedMarkets']
                            : []
                        }
                        handleSelect={handleAccfSelect}
                        submitted={false}
                        disabled={false}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col md={{ span: 5, offset: 1 }}>
                <Row>
                  <Col md={{ span: 5, offset: 0 }}>
                    <div>
                      <p
                        className='fixedLeft'
                        style={{
                          marginBottom: '0px',
                          color:
                            validate &&
                            d2p_lead.length === 0 &&
                            color_settings_target_needed === 'Yes'
                              ? 'red'
                              : '',
                        }}
                      >
                        Design To Print Lead{' '}
                        {color_settings_target_needed === 'Yes' && (
                          <span style={{ color: 'red' }}>*</span>
                        )}
                      </p>
                      {validate &&
                        d2p_lead.length === 0 &&
                        color_settings_target_needed === 'Yes' && (
                          <span
                            style={{
                              color: '#f03e3e',
                              fontSize: '14px',
                              marginBottom: '1rem',
                            }}
                          >
                            Required Field
                          </span>
                        )}
                    </div>
                  </Col>

                  <Col className='FixedRight' md={{ span: 5, offset: 2 }}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                        flexDirection: 'row',
                      }}
                    >
                      {d2p_lead.length > 0 && (
                        <Text
                          style={{
                            fontSize: '0.875rem',
                            fontWeight: '450',
                            color: '#212529',
                            paddingRight: 'unset',
                          }}
                        >
                          {d2p_lead[0].name}
                        </Text>
                      )}
                      <ModalUsers
                        required
                        title={
                          !d2p_lead || d2p_lead.length === 0 ? 'Indicate D2P Lead' : <Pencil1Icon />
                        }
                        type={'d2p_lead'}
                        selected={d2p_lead}
                        handleUserSelect={handleUserSelect}
                        variant={'singleSelect'}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666%',
                }}
              ></Col>
              <Col md={{ span: 5, offset: 0 }}>
                <Checkbox
                  size='sm'
                  label={
                    <div style={{ color: validate && !agree_to_pay ? 'red' : '' }}>
                      Agree to pay for artwork and print plates costs where relevant{' '}
                      <span style={{ color: 'red' }}>*</span>
                    </div>
                  }
                  className='checkbox'
                  color='orange'
                  disabled={props.disabled}
                  name='agree_to_pay'
                  checked={agree_to_pay}
                  onChange={handleChange}
                />
              </Col>
            </Row>
          </form>{' '}
          {/*//end of project details form */}
        </Container>
      </Row>
      <Row>
        <hr
          style={{
            color: '#E5B611',
            width: '93%',
            marginLeft: 'auto',
            marginTop: '30px',
            marginRight: 'auto',
            height: '3px',
          }}
        />
      </Row>

      <Row>
        {' '}
        {/*Start of Rfs details row */}
        <Container className='RFSDetailsForm'>
          <form>
            <Row>
              <Col
                md={1}
                style={{
                  width: '2.0833%',
                  flex: '0 0 2.0833%',
                  maxWidth: ' 2.0833%',
                }}
              ></Col>
              <Col>
                <h5 className='sectionHeadings'> PROJECT DETAILS</h5>
              </Col>
            </Row>
            <Row>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666s%',
                }}
              ></Col>
              <Col md={5}>
                <Textarea
                  error={validateTextArea('project_purpose')}
                  required
                  style={{ paddingBottom: '1em' }}
                  className='rfs-form-textarea'
                  radius='md'
                  placeholder='Why is this project needed?'
                  label='Project Purpose (Character limit 1000)'
                  disabled={props.disabled}
                  name='project_purpose'
                  value={
                    project_purpose?.length > 1000
                      ? project_purpose.substring(0, 1000)
                      : project_purpose
                  }
                  onChange={(event) => handleChange(event)}
                />
                {project_purpose?.length > 999 && (
                  <div className='custom-mantine-Textarea-error'>
                    1000 Character limit reached, exceeding character would be truncated on saving.
                  </div>
                )}
              </Col>

              <Col md={{ span: 5, offset: 1 }}>
                <Textarea
                  error={validateTextArea('project_details')}
                  required
                  style={{ paddingBottom: '1rem' }}
                  className='rfs-form-textarea'
                  radius='md'
                  placeholder='How and Where the changes are needed?'
                  label='Project Explanation (Character limit 1000)'
                  disabled={props.disabled}
                  name='project_details'
                  value={
                    project_details?.length > 999
                      ? project_details.substring(0, 999)
                      : project_details
                  }
                  onChange={(event) => handleChange(event)}
                />
                {project_details?.length > 999 && (
                  <div className='custom-mantine-Textarea-error'>
                    1000 Character limit reached, exceeding character would be truncated on saving.
                  </div>
                )}
              </Col>
            </Row>
          </form>
        </Container>
      </Row>
      <Row>
        <hr
          style={{
            color: '#E5B611',
            width: '93%',
            marginLeft: 'auto',
            marginTop: '30px',
            marginRight: 'auto',
            height: '3px',
          }}
        />
      </Row>

      <Row className='Artworksection'>
        <Container className='artworkDetails'>
          <form>
            <Row>
              <Col
                md={1}
                style={{
                  width: '2.0833%',
                  flex: '0 0 2.0833%',
                  maxWidth: ' 2.0833%',
                }}
              ></Col>
            </Row>

            <Row>
              <Col
                md={1}
                style={{
                  width: '4.1666%',
                  flex: '0 0 4.1666%',
                  maxWidth: ' 4.1666s%',
                }}
              ></Col>
              <Col md={{ span: 11 }}>
                <Textarea
                  style={{ paddingTop: '1rem' }}
                  className='rfs-form-textarea'
                  radius='md'
                  placeholder='Placeholder text'
                  label='Any Additional Comments (Character limit 1000)'
                  disabled={props.disabled}
                  name='comments'
                  value={comments?.length > 999 ? comments.substring(0, 999) : comments}
                  onChange={(event) => handleChange(event)}
                />
                {comments?.length > 999 && (
                  <div className='custom-mantine-Textarea-error'>
                    1000 Character limit reached, exceeding character would be truncated on saving.
                  </div>
                )}
              </Col>
            </Row>
          </form>
        </Container>
      </Row>

      {invalidModal && (
        <InvalidModal
          invalidModal={invalidModal}
          setInvalidModal={setInvalidModal}
          nextClick={update}
        />
      )}

      {ccModal.open && (
        <CCModal
          data={ccModal}
          setCcModal={setCcModal}
          value={cost_center}
          disabled={props.disabled}
        />
      )}
    </ContentPaper>
  );
}

const FastTrackDatePickerInput = ({
  validate,
  first_shipment,
  disabled,
  fast_track,
  handleSelect,
}) => {
  const [initialMonth, setIntialMonth] = useState(moment().add('days', 1).toDate());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      if (fast_track) {
        setIntialMonth(moment().add('days', 1).toDate());
      } else {
        setIntialMonth(moment().add('days', 77).toDate());
      }

      setLoading(false);
    }, [50]);
  }, [fast_track]);

  if (loading)
    return (
      <TextInput
        icon={<CalendarIcon color='#e5b611' />}
        required
        className='InputField'
        radius='md'
        label='First Shipment'
        placeholder={fast_track ? '' : 'Select dates after 11 weeks from today.'}
      />
    );

  return (
    <DatePickerInput
      error={validate && !first_shipment ? 'Required Field' : ''}
      required
      placeholder={fast_track ? '' : 'Select dates after 11 weeks from today.'}
      allowFreeInput
      numberOfColumns={2}
      minDate={initialMonth}
      initialMonth={initialMonth}
      maxDate={moment().add('year', 2).toDate()}
      className='InputField'
      radius='md'
      icon={<CalendarIcon color='#e5b611' />}
      label='First Shipment'
      disabled={disabled}
      value={first_shipment ? moment(first_shipment, 'YYYY-MM-DD').toDate() : null}
      onChange={(value) => handleSelect('first_shipment', value)}
    />
  );
};
