import React, { useState, useContext, useEffect } from 'react';
import { Box, Accordion, Grid, Button } from '@mantine/core';
import ContentPaper from '../../../../components/layouts/rfs/Content';
import { Row } from 'react-bootstrap';
import { RfsContext } from '../../../../contexts/RfsContext';
import { MaterialCodeTable } from './MaterialCodeTable';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AppLoader } from '../../../../components/common/loader';
import { TableExport } from 'tabler-icons-react';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { roles } from '../../../../constants';
import { AuthContext } from '../../../../contexts/AuthContext';
import { sCodeStyles } from '../../../../utils/helpers';

const accessors = {
  materials: 'selection_number',
  liquids: 'selection_number',
  skus: 'selection_number',
};
const secondaryAccessor = {
  materials: 'selection_name',
  liquids: 'selection_name',
  skus: 'selection_name',
};
const tertiaryAccessor = {
  materials: null,
  liquids: null,
  skus: null,
};

export default function MaterialCreationCode({ trials }) {
  const { userRole } = useContext(AuthContext);
  const { form } = useContext(RfsContext);
  const { rfs_number } = form.rfsInformation;
  const [materialData, setMaterialData] = useState([]);
  const [disable, setDisable] = useState(true);
  const [scopeErrors, setScopeErrors] = useState({
    errors: {},
    liquidErrors: {},
    materialErrors: {},
    productionSiteErrors: {},
  });
  const [loading, setLoading] = useState(false);
  const { fetchMaterialCreation, StoreManualClosure } = useContext(RfsContext);

  useEffect(() => {
    getMaterialDetails(rfs_number);
  }, []);

  useEffect(() => {
    if ([roles.admin, roles.superAdmin, roles.pmo, roles.masterDataTeam].includes(userRole))
      setDisable(false);
  }, [userRole]);

  const getMaterialDetails = (rfs_number) => {
    setLoading(true);
    fetchMaterialCreation(rfs_number, trials).then((res) => {
      setMaterialData(res.data.data);
      setLoading(false);
    });
  };

  const save = (payload, id, callback) => {
    StoreManualClosure(payload, id).then(() => {
      refresh(rfs_number);
      callback();
    });
  };

  const refresh = (rfs_number) => {
    fetchMaterialCreation(rfs_number).then((res) => {
      setMaterialData(res.data.data);
    });
  };

  const sort_MD_acctoSKUs = [...materialData].sort((a, b) => a.scope_id - b.scope_id);
  const selectedScopeFn = (data) => {
    let selectedScopeList = {};
    for (let i = 0; i < data.length; i++) {
      selectedScopeList[data[i].id] = {
        selection_number: data[i].selection_number,
        selection_name: data[i].selection_name,
      };
    }
    return selectedScopeList;
  };

  var selectedScopeList = selectedScopeFn(form.selectedScope.skus);

  const dataToExport = ({
    int_rfs_ps_bom,
    int_rfs_ps_sku_reference,
    int_rfs_impl_material_creation,
    manually_closed,
    sla_date,
    status,
    finished_date,
    scope_id,
  }) => {
    return {
      'SKU no.': selectedScopeList[int_rfs_ps_sku_reference.scope_id]?.selection_number,
      'SKU Desc': selectedScopeList[int_rfs_ps_sku_reference.scope_id]?.selection_name,
      'Old material Code': int_rfs_ps_bom?.bom_component,
      'Old material name': int_rfs_ps_bom?.component_description,
      'New Plant': int_rfs_ps_sku_reference?.selected_plant,
      'Technical Pack Change': int_rfs_ps_bom?.technical_pack_change,
      'Technical Quantity Change': int_rfs_ps_bom?.technical_quality_change,
      'Artwork Management':
        int_rfs_ps_bom?.artwork_change === null ? 'N/A' : int_rfs_ps_bom.artwork_change,
      'New. Tech. Spec':
        int_rfs_ps_bom.tech_spec === null ? 'Spec not in Data Base' : int_rfs_ps_bom.tech_spec,
      Comments: int_rfs_ps_bom?.comment,
      'New material Code': int_rfs_impl_material_creation?.new_material_code,
      'New material Description': int_rfs_impl_material_creation?.new_material_desc,
      Status: status,
      Action:
        status === 'Closed' ? (manually_closed ? 'Manually Closed' : 'Automatic Closure') : 'NA',
      'Step deadline': sla_date,
      'Step finished': status === 'Closed' ? finished_date : status,
    };
  };
  const csvArray = sort_MD_acctoSKUs.map(dataToExport);

  if (loading) {
    return (
      <ContentPaper page={'ProductDevelopment'}>
        <AppLoader size='lg' center />
      </ContentPaper>
    );
  }

  return (
    <ContentPaper page={'ProductDevelopment'}>
      <Box sx={sCodeStyles}>SKU CODES</Box>
      <div
        style={{
          display: 'flex',
          justifyContent: 'right',
          marginRight: '4rem',
        }}
      >
        <CSVLink
          data={csvArray}
          filename={'Material Code Creation_' + moment().format('YYYY-MM-DD HH:mm:ss')}
        >
          <Button
            leftIcon={<TableExport />}
            color='yellow'
            style={{
              marginBottom: '1rem',
              marginTop: '2rem',
              background: 'black',
            }}
          >
            Export to CSV
          </Button>
        </CSVLink>
      </div>

      <Row>
        <Accordion defaultValue={'key_0'}>
          {Object.keys(form?.selectedScope || {}).map((name) =>
            form?.selectedScope[name].map((item, index) => {
              const materialsList = materialData.filter(
                (m) => m.int_rfs_ps_sku_reference.scope_id === item.id,
              );

              return (
                materialsList.length > 0 && (
                  <Accordion.Item
                    className='scope-label'
                    key={`key_${name}_${index}`}
                    value={`key_${name}_${index}`}
                  >
                    <Accordion.Control>
                      {`${name.substring(0, 3).toUpperCase()} -  ${item[accessors[name]]}- ${
                        item[secondaryAccessor[name]]
                      }${tertiaryAccessor[name] ? `- ${item[tertiaryAccessor[name]]}` : ''}`}
                    </Accordion.Control>
                    <Accordion.Panel>
                      {
                        <Grid>
                          <Grid.Col span={12}>
                            <MaterialCodeTable
                              materialData={materialsList}
                              save={save}
                              refresh={refresh}
                              rfs={rfs_number}
                              disable={disable}
                            />
                          </Grid.Col>
                        </Grid>
                      }
                    </Accordion.Panel>
                  </Accordion.Item>
                )
              );
            }),
          )}
        </Accordion>
      </Row>
    </ContentPaper>
  );
}
