import React, { useState, useContext, useEffect } from 'react';
import { Box, Grid, Button } from '@mantine/core';
import ContentPaper from '../../../../components/layouts/rfs/Content';
import { Row } from 'react-bootstrap';
import { RfsContext } from '../../../../contexts/RfsContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import { EanApprovalTable } from './Table';
import { AppLoader } from '../../../../components/common/loader';
import { TableExport } from 'tabler-icons-react';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { axios } from '../../../../utils/axios';
import checkClosedOrInProgress from '../../veamHelper';

export default function EanApprovalCode({checkClosuresEAN, readOnly}) {
  const { form, StoreManualClosureVEAM } = useContext(RfsContext);
  const { rfs_number } = form.rfsInformation;
  const [loading, setLoading] = useState(false);
  const [approval, setApproval] = useState([]);
  const [actionModal, setActionModal] = useState({ open: false, id: '' });
  const [manualClosureLoading, setManualClosureLoading] = useState(false);

  useEffect(() => {
    checkClosuresEAN(checkClosedOrInProgress(approval));
  }, [approval]);

  useEffect(() => {
    getData(rfs_number);
  }, []);

  const getData = (rfs_number, loading = true) => {
    setLoading(loading);
    axios
      .get(`/rfs/ean_approval/fetch/${rfs_number}`)
      .then(
        (res) => {
          setApproval(res.data.data);
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() => setLoading(false));
  };
  const save = (payload, id, callback) => {
    setManualClosureLoading(true);
    StoreManualClosureVEAM(payload, id).then(() => {
      getData(rfs_number, false);
      setActionModal({ open: false, id: '' });
      setManualClosureLoading(false);
      if (callback) callback();
    });
  };

  const sort_approval_acctoSKUs = [...approval].sort((a, b) => a.scope_id - b.scope_id);

  const selectedScopeFn = (data) => {
    let selectedScopeList = {};
    for (let i = 0; i < data.length; i++) {
      selectedScopeList[data[i].id] = {
        selection_number: data[i].selection_number,
        selection_name: data[i].selection_name,
      };
    }
    return selectedScopeList;
  };

  var selectedScopeList = selectedScopeFn(form.selectedScope.skus);

  const dataToExport = ({
    int_rfs_impl_material_creation,
    int_rfs_ps_sku_reference,
    int_rfs_material,
    sla_date,
    status,
    scope_id,
    manually_closed,
    finished_date,
  }) => {
    return {
      'New Material Code': int_rfs_impl_material_creation?.new_material_code,
      'New Material Description': int_rfs_impl_material_creation?.new_material_desc,
      Plant: int_rfs_material?.plant,
      'Materials on BOM': status === 'Not Started' ? 'Not Started' : 'Closed',
      'Status Change': status,
      Action:
        status === 'Closed' ? (manually_closed ? 'Manually Closed' : 'Automatic Closure') : 'NA',
      'Step Deadline': sla_date,
      'Step Finished': finished_date === null ? status : finished_date,
    };
  };
  const csvArray = sort_approval_acctoSKUs.map(dataToExport);

  if (loading || manualClosureLoading) {
    return (
      <ContentPaper page={'ProductDevelopment'}>
        <AppLoader size='lg' center />
      </ContentPaper>
    );
  }
  return (
    <ContentPaper page={'ProductDevelopment'}>
      <Box
        sx={(theme) => ({
          backgroundColor:'black',
          padding: theme.spacing.xs,
          borderRadius: theme.radius.md,
          cursor: 'pointer',

          color: theme.colors.yellow[5],
          fontSize: theme.fontSizes.lg,
          fontWeight: 1000,

          '&:hover': {
            backgroundColor:'black',
          },
        })}
      >
        MATERIAL CODES
      </Box>
      <div
        style={{
          display: 'flex',
          justifyContent: 'right',
          marginRight: '4rem',
        }}
      >
        <CSVLink data={csvArray} filename={'EAN Approval' + moment().format('YYYY-MM-DD HH:mm:ss')}>
          <Button
            leftIcon={<TableExport />}
            color='yellow'
            style={{
              marginBottom: '0rem',
              marginTop: '2rem',
              background:'black',
            }}
          >
            Export to CSV
          </Button>
        </CSVLink>
      </div>
      <Row>
        <Grid>
          <Grid.Col span={12}>
            <EanApprovalTable
              data={approval}
              save={save}
              actionModal={actionModal}
              setActionModal={setActionModal}
              loading={manualClosureLoading}
              readOnly={readOnly}
            />
          </Grid.Col>
        </Grid>
      </Row>
    </ContentPaper>
  );
}
