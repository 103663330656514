import React, { useState, useContext, useEffect } from 'react';
import { Box, Accordion, Grid, Button, TextInput } from '@mantine/core';
import ContentPaper from '../../../../components/layouts/rfs/Content';
import { Row } from 'react-bootstrap';
import { RfsContext } from '../../../../contexts/RfsContext';
//import { MaterialCodeTableVeam } from './MaterialCodeTableVeam';
import ChangeOverTableVEAM from './ChangeOverTableVEAM';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AppLoader } from '../../../../components/common/loader';
import csvDownload from 'json-to-csv-export';
import { TableExport } from 'tabler-icons-react';
import moment from 'moment';
import { AuthContext } from '../../../../contexts/AuthContext';
import checkClosedOrInProgress from '../../veamHelper';
import {
  materialTypes,
  liquidTypes,
  liquidBomChanges,
  additionLiquid,
} from '../../../../constants/index';
import { prodDevPermissions } from '../../../../constants';
import { AccfComment } from '../../../../common/accf';

const ChangeOverVEAM = ({ checkClosuresChangeOver, readOnly }) => {
  const { userRole } = useContext(AuthContext);
  const trgt = document.querySelector('.page-productdevelopment > div');
  const { form, info, fetchChangeOversVeam, StoreManualClosureVEAM } = useContext(RfsContext);
  const { rfs_number, rfs_type } = form.rfsInformation;
  const [statusData, setstatusData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [value, setValue] = useState(0);
  const [slaId, setSlaId] = useState(0);
  const [actionModal, setActionModal] = useState({ open: false, id: '' });
  const [disable, setDisable] = useState(false);
  const [accState, setAccState] = useState('');
  const [statusDataN, setstatusDataN] = useState([]);
  const [status, setStatus] = useState(true);

  const permissions = prodDevPermissions[rfs_type];
  useEffect(() => {
    if (trgt)
      trgt.addEventListener('scroll', (event) => {
        localStorage.setItem('scrollValues2', event.currentTarget.scrollTop);
      });
  }, [trgt]);

  useEffect(() => {
    checkClosuresChangeOver(checkClosedOrInProgress(statusDataN));
  }, [statusDataN]);

  useEffect(() => {
    setDisable(!permissions['changeover'].includes(userRole));
  }, []);

  const save = (payload, id, callback) => {
    let scrollValues2 = localStorage.getItem('scrollValues2')
      ? localStorage.getItem('scrollValues2')
      : '';

    setLoading(true);
    StoreManualClosureVEAM(payload, id).then(() => {
      info(rfs_number);
      getStatusDetails(rfs_number, true, true, scrollValues2);
      setAccState(callback);
      trgt?.scrollTo({ top: scrollValues2 });
    });
  };

  useEffect(() => {
    getStatusDetails(rfs_number, null, false);
  }, [rfs_number]);

  const getStatusDetails = (rfs_number, ls, act, scroll) => {
    ls === false ? setLoading(false) : setLoading(true);
    fetchChangeOversVeam(rfs_number).then((res) => {
      setData(res.data.data);
      const changeOverFilter = res.data.data.filter(
        (item) => item.sla_weeks_id === 214 || item.sla_weeks_id === 230,
      );

      const result = [];
      setstatusDataN(changeOverFilter);

      result['Materials'] = changeOverFilter
        .filter((item) => item.selection_type === 'materials')
        .sort((a, b) => a.id - b.id);
      result['Liquids'] = changeOverFilter
        .filter((item) => item.selection_type === 'liquids')
        .sort((a, b) => a.id - b.id);

      setstatusData(result);
      setSlaId(changeOverFilter[0].sla_weeks_id);

      setLoading(false);
      if (act) setActionModal({ open: false, id: null });
      if (scroll) trgt?.scrollTo({ top: scroll });
    });
  };

  useEffect(() => {
    const FilteredData = data.filter(
      (item) => item.sla_weeks_id === 214 || item.sla_weeks_id === 230,
    );

    let arr = [];
    for (let i = 0; i < FilteredData.length; i++) {
      arr.push(FilteredData[i].status);
    }
    if (arr) {
      const setArray = [...new Set(arr)];
      if (
        (setArray.length === 1 && setArray.includes('Closed')) ||
        (setArray.length === 1 && setArray.includes('Not Started'))
      ) {
        setStatus(true);
      } else {
        setStatus(false);
      }
    }
  }, [data]);

  if (loading) {
    return (
      <ContentPaper page={'ProductDevelopment'}>
        <AppLoader size='lg' center />
      </ContentPaper>
    );
  }

  //----CSV download-----------------------------
  const downloadExcel = (type) => {
    const dataToExport = {
      data: [],
      filename: '',
      delimiter: ',',
      headers: [],
    };
    const materialsToExport = {
      data: [],
      filename: '',
      delimiter: ',',
      headers: [],
    };
    const liquidHeaders = [
      'Liquid Code',
      'Liquid Description',
      'Plant',
      'Liquid BOM Material Change',
      'Changeover Type',
      'New Liquid Number',
      'New Liquid Name ',
      'Current Quantity',
      'New Quantity',
      'Unit',
      'Status',
      'Action',
      'Step Deadline',
      'Step Finished',
    ];
    const materialHeaders = [
      'Material Code',
      'Material Description',
      'Plant',
      'Type of Change',
      'New Material Number',
      'New Material Name',
      'Changeover Type',
      'Status',
      'Action',
      'Step Deadline',
      'Step Finished',
    ];

    const liquids = statusData.Liquids;
    const materials = statusData.Materials;

    if ((liquids || []).length > 0) {
      dataToExport.data = getDataToExport(liquids, 'liquids');

      dataToExport.filename = 'ChangeOverRemoval_liquids' + moment().format('YYYY-MM-DD HH:mm:ss');
      dataToExport.headers = liquidHeaders;
      csvDownload(dataToExport);
    }

    if ((materials || []).length > 0) {
      materialsToExport.data = getDataToExport(materials, 'materials');
      materialsToExport.filename =
        'ChangeOverRemoval_materials' + moment().format('YYYY-MM-DD HH:mm:ss');
      materialsToExport.headers = materialHeaders;
      csvDownload(materialsToExport);
    }
  };

  const getDataToExport = (data, type) => {
    const csvArray = type === 'materials' ? data.map(dataToExportM) : data.map(dataToExportL);

    return csvArray;
  };

  const dataToExportM = ({
    manually_closed,
    sla_date,
    status,
    finished_date,
    material_type,
    int_rfs_material_number,
    material_code_or,
    pack_mat_material_text,
    plant,
    material_description_or,
    new_material_code,
    new_material_description_intrfsml,
    material_changeover_type,
    material_changeover_date,
    material_comment,
  }) => {
    let Code = 'N/A',
      new_num = 'N/A',
      new_name = 'N/A',
      type_of_change = 'N/A',
      changeovertype = 'N/A',
      changeoverdate = 'N/A',
      comment = 'N/A',
      Desc;
    if (material_type === materialTypes.existing || material_type === materialTypes.remove) {
      Code = material_code_or ? material_code_or : 'N/A';
      new_num = int_rfs_material_number ? int_rfs_material_number : 'N/A';

      Desc = material_description_or;
      new_name = pack_mat_material_text ? pack_mat_material_text : 'N/A';
    } else if (material_type === materialTypes.new) {
      Code = material_code_or ? material_code_or : 'N/A';
      new_num = new_material_code ? new_material_code : 'N/A';

      Desc = material_description_or;
      new_name = new_material_description_intrfsml ? new_material_description_intrfsml : 'N/A';
    }

    type_of_change = material_type ? material_type : 'N/A';
    changeovertype = material_changeover_type ? material_changeover_type : 'N/a';
    changeoverdate = material_changeover_date
      ? moment(material_changeover_date).format('YYYY-MM-DD')
      : 'N/A';
    comment = material_comment ? material_comment : 'N/A';

    return {
      'Material Code': Code ? Code : 'N/A',
      'Material Description': Desc ? Desc : 'N/A',
      Plant: plant ? plant : 'N/A',
      'Type of Change ': type_of_change ? type_of_change : 'N/A',
      'New Material Number': new_num ? new_num : 'N/A',
      'New Material Name': new_name ? new_name : 'N/A',
      'Changeover type': changeovertype,
      Status: status,
      Action:
        status === 'Closed' ? (manually_closed ? 'Manually Closed' : 'Automatic Closure') : 'NA',
      'step Deadline': sla_date,
      'step Finished': status === 'Closed' ? finished_date : status,
    };
  };

  const dataToExportL = ({
    manually_closed,
    sla_date,
    status,
    finished_date,
    liquid_code,
    liquid_number,
    liquid_description,
    material_text,
    liquid_changeover_type,
    bom_changes,
    liquid_changeover_date,
    liquid_comment,
    plant,
    quantity,
    new_quantity,
    unit,
    new_material_code,
    new_material_description_intrfsml,
    liquid_type,
  }) => {
    let Code = 'N/A',
      new_num = 'N/A',
      new_name = 'N/A',
      type_of_change = 'N/A',
      changeovertype = 'N/A',
      changeoverdate = 'N/A',
      comment = 'N/A',
      Desc;

    Code = liquid_code ? liquid_code : 'N/A';
    new_num = liquid_number ? liquid_number : 'N/A';

    Desc = liquid_description ? liquid_description : 'N/A';
    new_name = material_text ? material_text : 'N/A';

    if (
      [liquidTypes.new, liquidTypes.add].includes(liquid_type) &&
      (bom_changes.includes(liquidBomChanges.thickness) ||
        (bom_changes.includes(additionLiquid.addition) && !liquid_number))
    ) {
      Code = new_material_code ? new_material_code : 'N/A';
      Desc = new_material_description_intrfsml ? new_material_description_intrfsml : 'N/A';
      new_num = new_material_code ? new_material_code : 'N/A';
      new_name = new_material_description_intrfsml ? new_material_description_intrfsml : 'N/A';
    }

    changeovertype = liquid_changeover_type ? liquid_changeover_type : 'N/A';
    type_of_change = bom_changes ? bom_changes : 'N/a';
    changeoverdate = liquid_changeover_date
      ? moment(liquid_changeover_date).format('YYYY-MM-DD')
      : 'N/A';
    comment = liquid_comment ? liquid_comment : 'N/A';
    return {
      'Material Code': Code ? Code : 'N/A',
      'Material Description': Desc ? Desc : 'N/A',
      Plant: plant ? plant : 'N/A',
      'Type of Change ': type_of_change ? type_of_change : 'N/A',
      'Changeover type': changeovertype,
      'New Material Number': new_num ? new_num : 'N/A',
      'New Material Name': new_name ? new_name : 'N/A',
      'Current Quantity': quantity ? quantity : 'N/A',
      'New Quantity': new_quantity ? new_quantity : 'N/A',
      Unit: unit ? unit : 'N/A',
      Status: status,
      Action:
        status === 'Closed' ? (manually_closed ? 'Manually Closed' : 'Automatic Closure') : 'NA',
      'step Deadline': sla_date,
      'step Finished': status === 'Closed' ? finished_date : status,
    };
  };
  //----CSV download-----------------------------//

  return (
    <>
      <Row>
        <div
          style={{
            display: 'flex',
            justifyContent: 'right',
            marginRight: '4rem',
          }}
        >
          <Button
            leftIcon={<TableExport />}
            onClick={() => {
              downloadExcel();
            }}
            color='yellow'
            style={{
              marginBottom: '1rem',
              marginTop: '2rem',
              background: 'black',
            }}
          >
            Export to CSV
          </Button>
        </div>
        <Accordion value={value} onChange={setValue}>
          {Object.keys(statusData) &&
            Object.keys(statusData).map((name, index) => {
              return (
                Object.values(statusData)[index].length > 0 && (
                  <Accordion.Item
                    className='scope-label'
                    key={`key_${index}_${name}`}
                    value={`key_${index}_${name}`}
                  >
                    <Accordion.Control>{`${name}`}</Accordion.Control>
                    <Accordion.Panel>
                      <Grid>
                        <Grid.Col span={12}>
                          <ChangeOverTableVEAM
                            statusDataList={Object.values(statusData)[index]}
                            type={name}
                            save={save}
                            accState={accState}
                            actionModal={actionModal}
                            setActionModal={setActionModal}
                            readOnly={readOnly}
                          />
                        </Grid.Col>
                      </Grid>
                    </Accordion.Panel>
                  </Accordion.Item>
                )
              );
            })}
        </Accordion>
      </Row>
      {!disable && <AccfComment slaId={slaId} status={status} rfs_number={rfs_number} />}
    </>
  );
};

export default ChangeOverVEAM;
