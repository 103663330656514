import React, { useEffect, useState, useContext } from 'react';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import {
  Button,
  Tooltip,
  Switch,
  TextInput,
  Select,
  MantineProvider,
  useMantineTheme,
} from '@mantine/core';
import {
  IconSearch,
  IconSearchOff,
  IconEditCircle,
  IconEditCircleOff,
  IconRefresh,
} from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import { RfsContext } from '../../contexts/RfsContext';
import { AuthContext } from '../../contexts/AuthContext';
import ContentPaper from '../layouts/rfs/Content';

//CSV Download
import csvDownload from 'json-to-csv-export';
import { TableExport } from 'tabler-icons-react';
import moment from 'moment';
import { dateFormat } from '../../constants';

const RawdataReport = ({
  columns,
  setValidationErrors,
  statusData,
  unique,
  enableEditing,
  readOnly,
}) => {
  const [switchChecked, setSwitchChecked] = useState(false);
  const [selected, setSelected] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const { userName } = useContext(AuthContext);
  const [filteredRows, setFilteredRows] = useState([]);
  const globalTheme = useMantineTheme;

  //Custom search
  const [toBeSearched, setToBeSearched] = useState('');

  //Search Icon
  const [searchOn, setSearchOn] = useState(false);
  const [allEditOn, setAllEditOn] = useState(false);

  //AllEdit
  const [toBeEditedValue, setToBeEditedValue] = useState({});
  const [selectType, setSelectType] = useState('');

  //All Select
  const [allSelected, setAllSelected] = useState(false);

  const [data, setData] = useState([]);
  const [successData, setSuccessData] = useState([]);

  //const data = switchChecked && Object.keys(selected).length ? selected : reportData;
  const { fetchRawdata } = useContext(RfsContext);

  //READ hook (get data in api)
  function useGet() {
    return useQuery({
      queryKey: ['fetchRawdata'],
      queryFn: fetchRawdata,
      refetchOnWindowFocus: false,
    });
  }

  const { data: fetchedData = [], isError, isFetching, isLoading, error, status } = useGet();

  useEffect(() => {
    if (status === 'success' && !isFetching) {
      let data = fetchedData.data.data;
      setData(data);
      setSuccessData(data);
      setFilteredRows(data);
    }
  }, [status, isFetching]);

  const validateRequired = (value) => !!value?.length;

  function validate(items) {
    return {
      Status: !validateRequired(items.Status) ? 'Status is Required' : '',
    };
  }

  //Excel Download------------
  const downloadExcel = (type) => {
    const dataToExport = {
      data: [],
      filename: '',
      delimiter: ',',
      headers: [],
    };

    const headers = [
      'Submission Date',
      'Rfs Id',
      'Rfs Name',
      'Approval Date',
      'Implementation Date',
      'Rfs Type',
      'Gatekeeper',
      'Initiator Name',
      'Rfs Status',
      'Confidential',
      'Fast Track',
      'Sku Number',
      'Sku Description',
      'Brand',
      'Subbrand',
      'Container',
      'Markets',
      'Maco',
      'First Forcast',
      'First Forcast Week',
      'Additional Markets',
      'Plant',
      'Additional Plants',
      'First Filling Plan Vol',
      'First Filling Plan Week',
      'Fsd',
      'Material Number',
      'Material Description',
      'Suppliername',
      'Gfp Confirmation',
      'Obsoletes',
      'Reference Sku Number',
      'Reference Sku Description',
      'Production Buisness Unit',
      'Sales Business Unit',
      'Cost Center',
    ];

    const updatedHeaders = headers.map((header) =>
      header.includes('Date') || header.includes('FSD') ? `${header} (${dateFormat})` : header,
    );

    const exportdata = removeFirstElement(data);
    dataToExport.data = getDataToExport(exportdata);
    dataToExport.filename = 'Download_Report_' + moment().format('YYYY-MM-DD HH:mm:ss');
    dataToExport.headers = updatedHeaders;
    csvDownload(dataToExport);
  };

  const removeFirstElement = (data) => {
    data = data.map((item, index) => {
      if (item['data_id']) delete item['data_id'];
      return item;
    });
    return data;
  };

  const getDataToExport = (data) => {
    const csvArray = data.map(dataToExport);
    return csvArray;
  };

  const dataToExport = (data) => {
    return Object.keys(data).map((key) => {
      if (
        ['submission_date', 'approval_date', 'Implementation_date', 'FSD'].includes(key) &&
        data[key]
      ) {
        return moment(data[key], 'DD-MM-YYYY').isValid()
          ? moment(data[key], 'DD-MM-YYYY').format('MM/DD/YYYY')
          : 'N/A';
      }
      return data[key];
    });
  };

  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };

  //Custom Search
  const filterMultiSearch = (event) => {
    const input = event.target.value.split(' ');

    const myArrayFiltered = successData.filter((el) => {
      return input.some((f) => {
        return el[toBeSearched]?.toLowerCase().includes(f.toLowerCase());
      });
    });

    setData(
      input.length > 0
        ? myArrayFiltered
        : switchChecked && Object.keys(selected).length
        ? selected
        : successData,
    );
    setFilteredRows(input.length > 0 ? myArrayFiltered : successData);
  };

  const resetAll = () => {
    setSearchOn(false);
    setAllSelected(false);
    setRowSelection({});
    setAllEditOn(false);
    setSwitchChecked(false);
    setData(successData);
    setFilteredRows(successData);
  };

  const table = useMantineReactTable({
    columns,
    data: data,
    createDisplayMode: 'row',
    editDisplayMode: 'row',
    enableEditing: enableEditing,
    getRowId: (row) => row[unique],
    enableRowSelection: true,
    selectDisplayMode: 'checkbox',
    enableGlobalFilter: false,
    enableSelectAll: true,
    selectAllMode: 'all',
    initialState: {
      showColumnFilters: true,
      columnVisibility: { data_id: false },
    },
    mantineToolbarAlertBannerProps: isError
      ? {
          color: 'red',
          children: 'Error loading data',
        }
      : undefined,
    mantineTableContainerProps: {
      sx: {
        minHeight: '500px',
      },
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    onEditingRowCancel: () => setValidationErrors({}),

    renderTopToolbarCustomActions: ({ table }) => {
      const setUpEditedValues = (e) => {
        setToBeEditedValue({ ...toBeEditedValue, ['selectedColumn']: e.split('|')[0] });
        setSelectType(e.split('|')[1]);
      };

      const editableColumns = [
        {
          accessorKey: 'Status',
          header: 'Rawdata Status',
          editVariant: 'select',
          id: 'Status',
        },
      ];

      return (
        <>
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <Tooltip label='Show All Selected'>
              <Switch
                disabled={selected.length === 0 && !switchChecked}
                checked={switchChecked}
                onChange={(event) => setSwitchChecked(event.currentTarget.checked)}
              />
            </Tooltip>

            <Tooltip label='Reset All'>
              <IconRefresh onClick={resetAll} />
            </Tooltip>

            <div
              onClick={() => {
                if (searchOn) setFilteredRows(successData);
                setSearchOn(searchOn ? false : true);
              }}
            >
              <Tooltip label='Search All'>{searchOn ? <IconSearchOff /> : <IconSearch />}</Tooltip>
            </div>

            {Object.keys(rowSelection).length > 0 && enableEditing && (
              <Tooltip label='Edit All Selected'>
                <div onClick={() => setAllEditOn(allEditOn ? false : true)}>
                  {allEditOn ? <IconEditCircleOff /> : <IconEditCircle />}
                </div>
              </Tooltip>
            )}
          </div>

          {searchOn && (
            <div className='search-Group'>
              <div className='searchGroup' style={{ display: 'flex' }}>
                <>
                  <Select
                    label='Select The Column To Search'
                    placeholder='Select The Column'
                    onChange={(value) => {
                      setToBeSearched(value);
                    }}
                    data={columns
                      .filter((c) => c.accessorKey !== unique)
                      .map((column) => {
                        return { value: column.accessorKey, label: column.header };
                      })}
                    style={{ position: 'relative', zIndex: '1' }}
                  />
                  <TextInput
                    clearable
                    label='&nbsp;'
                    placeholder='Search'
                    onChange={(event) => filterMultiSearch(event)}
                    style={{ position: 'relative', zIndex: '0' }}
                    disabled={toBeSearched === ''}
                  />
                </>
              </div>
            </div>
          )}

          {allEditOn && (
            <div className='edit-Group'>
              <div className='searchGroup' style={{ display: 'flex', alignItems: 'flex-end' }}>
                <Select
                  disabled={readOnly}
                  label='Select The Column To Edit'
                  placeholder='Select The Column'
                  data={editableColumns.map((column) => {
                    return {
                      value: column.accessorKey + '|' + column.editVariant,
                      label: column.header,
                      disabled: column.enableEditing != undefined ? !column.enableEditing : false,
                    };
                  })}
                  onChange={(e) => setUpEditedValues(e)}
                />
                {selectType && selectType === 'select' ? (
                  <Select
                    label='Select'
                    placeholder='Select'
                    disabled={readOnly}
                    data={statusData.map((item) => item)}
                    onChange={(value) =>
                      setToBeEditedValue({
                        ...toBeEditedValue,
                        ['value']: value,
                      })
                    }
                  />
                ) : (
                  <TextInput
                    disabled={readOnly}
                    clearable
                    label='&nbsp;'
                    placeholder='Edit'
                    onKeyUp={(event) =>
                      setToBeEditedValue({
                        ...toBeEditedValue,
                        ['value']: event.target.value,
                      })
                    }
                  />
                )}
              </div>
            </div>
          )}
        </>
      );
    },
    state: {
      isLoading: isLoading,
      showAlertBanner: isError,
      showProgressBars: isFetching,
      rowSelection,
    },
    onRowSelectionChange: setRowSelection,
  });

  //Selection Toggle
  const updateSelected = () => {
    var filtered = filteredRows.filter((item, index) => {
      return Object.keys(rowSelection).indexOf(item[unique]) !== -1;
    });

    const currentValues = selected;
    currentValues.forEach((val) => {
      const index = filtered.findIndex((item) => item[unique] === val[unique]);
      if (index === -1) filtered.push(val);
    });

    setSelected(filtered);
  };

  useEffect(() => {
    if (switchChecked || allSelected) setData(selected);
    else if ((!switchChecked || !allSelected) && !searchOn) setData(successData);
    else if ((!switchChecked || !allSelected) && searchOn) setData(filteredRows);
    else setData(successData);
  }, [switchChecked, selected, data, searchOn]);

  useEffect(() => {
    updateSelected();
  }, [rowSelection]);

  if (isError) {
    return (
      <ContentPaper page={'Rawdata'}>
        <span>{error.message}</span>
      </ContentPaper>
    );
  }

  return (
    <>
      <div
        className='downLoadBtn'
        style={{
          marginBottom: '1rem',
          marginTop: '2rem',
          display: 'flex',
          justifyContent: 'right',
        }}
      >
        <Button
          leftIcon={<TableExport />}
          onClick={() => {
            downloadExcel();
          }}
          color='dark'
          style={{
            margin: '0',
          }}
        >
          Export Report to CSV
        </Button>
      </div>
      <MantineProvider theme={{ ...globalTheme, primaryColor: 'yellow', primaryShade: 7 }}>
        <MantineReactTable table={table} />
      </MantineProvider>
    </>
  );
};

export default RawdataReport;
