import styled from 'styled-components';

export const RightItems = styled.div`
  margin-bottom: 1rem;
  margin-top: 2rem;
  display: flex;
  justify-content: right;
  button {
    margin: 0;
  }
`;

export const HeaderLeftPanel = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const TableStyles = styled.div`
  :first-child :nth-child(2) {
    min-height: unset;
  }
`;
