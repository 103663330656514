import React, { useContext } from 'react';
import { useState, useEffect } from 'react';
import { Modal, Button, Container, Grid, Checkbox, Group, Text } from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';
import { axios } from '../../utils/axios';

import './ToggleModal.css';
import { CircleCheck } from 'tabler-icons-react';
import { routes } from '../../routes';
import { RfsContext } from '../../contexts/RfsContext';

function ConfirmModal({ opened, setOpened, pname, setNpsOpened, setSubmitted }) {
  const navigate = useNavigate();

  const handleClick = () => {
    //navigate('/');
    setOpened(false);
    if (setNpsOpened || setSubmitted) {
      if (setNpsOpened) setNpsOpened(true);
      // if (setSubmitted) setSubmitted(true);
    }
  };

  return (
    <Modal
      closeOnEscape={false}
      closeOnClickOutside={false}
      withCloseButton={false}
      opened={opened}
      onClose={() => setOpened(false)}
      title='Project Submitted Successfully!'
      className='confirm-modal'
    >
      <Container>
        <Grid>
          <Grid.Col span={12}>
            <Group noWrap className='confirm-modal-group'>
              <CircleCheck color='green' size='5rem' />
              <Text size='sm'>
                Congratulations! Your project <strong>{pname}</strong> has been successfully
                submitted to the PMO and will now be under review. Click on the 'OK' button below to
                be redirected to the Home Page.
              </Text>
            </Group>
          </Grid.Col>

          <Grid.Col span={3} offset={9}>
            <Button
              color='yellow'
              variant='gradient'
              gradient={{ from: '#e3af32', to: '#f4e00f' }}
              onClick={handleClick}
              fullWidth
            >
              OK
            </Button>
          </Grid.Col>
        </Grid>
      </Container>
    </Modal>
  );
}

export default ConfirmModal;
