import React, { useContext, useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import TicketPage from '../../pages/RaiseTicket/TicketPage';
import {
  UnstyledButton,
  Group,
  Avatar,
  Text,
  Tooltip,
  Burger,
  Header,
  Stack,
  MediaQuery,
  Button,
} from '@mantine/core';
import { Navbar, Container, Form, Nav, FormControl, Badge } from 'react-bootstrap';
import { routes } from '../../routes';
import { Link, useNavigate } from 'react-router-dom';
import './NavbarComp.css';
import { BellIcon } from '@modulz/radix-icons';
import { AuthContext } from '../../contexts/AuthContext';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { InfoSquare } from 'tabler-icons-react';
import { roles } from '../../constants';

export default function NavbarComp({ opened, setOpened }) {
  const { userEmail, userName, userRole, profilePhoto } = useContext(AuthContext);

  //Raise Ticket
  const navigate = useNavigate();

  const [ticketPageURL, setTicketPageURL] = useState('');

  const handleRaiseTicketClick = () => {
    //
    window.location.href = '/RaiseTicket';
  };

  const history = useNavigate();
  const getName = () => {
    if (userName?.includes(' ')) {
      if (userName !== null) {
        const fullName = userName.replace('(GLOBAL-V)', '');
        return fullName;
      }
    } else {
      if (userName !== null) {
        const fullName = userName
          .replace('(GLOBAL-V)', '')
          .split(/(?=[A-Z])/)
          .join(' ');
        return fullName;
      }
    }
  };
  const getEmail = () => {
    const Email = userEmail;
    return Email;
  };
  const getInitials = () => {
    if (userName?.includes(' ')) {
      const fullName = userName?.split(' ');
      const initials = fullName?.shift()?.charAt(0) + fullName?.pop()?.charAt(0);
      return initials;
    } else {
      const initials = userName?.charAt(0);
      return initials;
    }
  };

  return (
    <Navbar style={{ height: '73px', backgroundColor: 'rgba(0,0,0,.9)' }}>
      <Container style={{ padding: '0.5rem' }} fluid>
        <MediaQuery largerThan='md' styles={{ display: 'none' }}>
          <Burger
            opened={opened}
            onClick={() => setOpened((o) => !o)}
            size='md'
            mr='xl'
            color='white'
          />
        </MediaQuery>
        <Navbar.Brand as={Link} to={'/'}>
          <img
            style={{ paddingLeft: '10px' }}
            src='/images/FullColor-White.png'
            height='25px'
            className='d-inline-block align-top'
            alt='STM logo'
          />
        </Navbar.Brand>
        <Navbar.Brand as={Link} to={'/'} style={{ borderLeft: '1px solid gray' }}>
          <img
            style={{ paddingLeft: '10px' }}
            src='/images/STM_Logo_White_Yellow.svg'
            width='100px'
            className='d-inline-block align-top'
            alt='STM logo'
          />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className='justify-content-end'>
          <Nav className='me-auto '>
            {[roles.superAdmin].includes(userRole) ? (
              <Nav.Link as={Link} className='navlink' to={routes.admin.root}>
                Admin
              </Nav.Link>
            ) : (
              <div></div>
            )}
          </Nav>
          {/* <Form>
            <FormControl
              className='searchbar'
              type='search'
              style={{
                marginRight: '1vw',
                borderRadius: '20px',
                borderColor: '#E5B611',
                borderWidth: '2px',
                width: '25vw',
                backgroundColor: '#262626',
              }}
              placeholder='Search'
              aria-label='Search'
            />
          </Form> */}
          <Button
            //background ='#E5B611'
            //backgroundColor='#E5B611'
            //color='white'
            style={{
              marginRight: '1rem',
              background: 'linear-gradient(90deg,#e3af32 0%,#f4e00f 100%)',
              color: 'black',
            }}
            // onClick={handleRaiseTicketClick}
            onClick={() => navigate(routes.RaiseTicket().root)}
          >
            Technical Support
          </Button>
          <Nav>
            <Group>
              <Avatar
                size={50}
                className='avatar'
                color='yellow'
                style={{
                  objectFit: 'fill',
                  backgroundColor: 'rgba(255, 249, 219, 1)',
                  //color:'black'
                }}
                src={profilePhoto && URL.createObjectURL(profilePhoto)}
                alt=''
              >
                {!profilePhoto && getInitials()}
              </Avatar>
              <Stack className='data' spacing={0}>
                <Text
                  color='yellow'
                  style={{
                    color: '#e5b611',
                  }}
                  className='data'
                >
                  <b>{getName()}</b>
                </Text>
                <Text size='xs' color='dimmed' className='data'>
                  {getEmail()}
                </Text>
                <Text size='xs' color='dimmed' className='data'>
                  Current Role:{' '}
                  {userRole &&
                    userRole
                      .split(/(?=[A-Z])/)
                      .join(' ')
                      .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())}
                  <Tooltip label='Role Definition' placement='start' withArrow className='data'>
                    <InfoSquare size={'1rem'} color={'rgb(229, 182, 17)'} />
                  </Tooltip>
                </Text>
              </Stack>
            </Group>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
