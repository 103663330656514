import React, { useState, useContext, useEffect } from 'react';
import { Box, Accordion, Grid, Button } from '@mantine/core';
import ContentPaper from '../../../../components/layouts/rfs/Content';
import { Row } from 'react-bootstrap';
import { RfsContext } from '../../../../contexts/RfsContext';
//import { MaterialCodeTableVeam } from './MaterialCodeTableVeam';
import FillingAndShipmentTableVEAM from './FillingAndShipmentTableVEAM';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AppLoader } from '../../../../components/common/loader';
import csvDownload from 'json-to-csv-export';
import { TableExport } from 'tabler-icons-react';
import moment from 'moment';
import { AuthContext } from '../../../../contexts/AuthContext';
import { prodDevPermissions, liquidBomChanges } from '../../../../constants';

const FillingAndShipment = () => {
  const { form, info, fetchStatusVeam, StoreManualClosureVEAM } = useContext(RfsContext);
  const { userRole } = useContext(AuthContext);
  const { rfs_number, rfs_type = '' } = form.rfsInformation;
  const [statusData, setstatusData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [state, handlers] = useState({ initialItem: 0 });
  const [active, setActive] = useState(0);

  const [accState, setAccState] = useState([]);

  useEffect(() => {
    handlers({ initialItem: 0 });
  }, [active]);

  const save = (payload, id, callback) => {
    setLoading(true);
    StoreManualClosureVEAM(payload, id).then(() => {
      getStatusDetails(rfs_number, true);
      setLoading(false);
      setAccState(callback);
    });
  };

  useEffect(() => {
    getStatusDetails(rfs_number);
  }, [rfs_number]);

  const getStatusDetails = (rfs_number, saving = false) => {
    setLoading(true);
    fetchStatusVeam(rfs_number).then((res) => {
      const statusCheckFilter = res.data.data.filter(
        (item) => item.sla_weeks_id === 223 || item.sla_weeks_id === 242,
      );

      const result = [];

      var count = 0;
      const a = statusCheckFilter?.forEach((element) => {
        count = element.status === 'Closed' ? count + 1 : count;
      });

      if (count === statusCheckFilter.length && saving) info(rfs_number, false);

      result['Materials'] = statusCheckFilter
        .filter((item) => item.selection_type === 'materials')
        .sort((a, b) => a.id - b.id);
      result['Liquids'] = statusCheckFilter
        .filter((item) => item.selection_type === 'liquids')
        .sort((a, b) => a.id - b.id);

      setstatusData(result);
      setLoading(false);
    });
  };

  if (loading || !rfs_type || rfs_type.length === 0) {
    return (
      <ContentPaper page={'ProductDevelopment'}>
        <AppLoader size='lg' center />
      </ContentPaper>
    );
  }

  const permissions = prodDevPermissions[rfs_type];

  const downloadExcel = (type) => {
    const dataToExport = {
      data: [],
      filename: '',
      delimiter: ',',
      headers: [],
    };
    const materialsToExport = {
      data: [],
      filename: '',
      delimiter: ',',
      headers: [],
    };
    const liquidHeaders = [
      'Liquid Code',
      'Liquid Description',
      'Plant',
      'Status',
      'Action',
      'Step Deadline',
      'Step finished',
    ];
    const materialHeaders = [
      'Material Code',
      'Material Description',
      'Plant',
      'Status',
      'Action',
      'Step Deadline',
      'Step finished',
    ];

    const liquids = statusData.Liquids;
    const materials = statusData.Materials;

    if ((liquids || []).length > 0) {
      dataToExport.data = getDataToExport(liquids, 'liquids');
      dataToExport.filename = 'Filing_n_Shipment_liquids' + moment().format('YYYY-MM-DD HH:mm:ss');
      dataToExport.headers = liquidHeaders;
      csvDownload(dataToExport);
    }

    if ((materials || []).length > 0) {
      materialsToExport.data = getDataToExport(materials, 'materials');
      materialsToExport.filename =
        'Filing_n_Shipment_materials' + moment().format('YYYY-MM-DD HH:mm:ss');
      materialsToExport.headers = materialHeaders;
      csvDownload(materialsToExport);
    }
  };

  const getDataToExport = (data, type) => {
    const csvArray = type === 'liquids' ? data.map(dataToExportL) : data.map(dataToExportM);
    return csvArray;
  };

  const dataToExportL = ({
    bom_changes,
    liquid_code,
    liquid_description,
    plant,
    sla_date,
    status,
    manually_closed,
    finished_date,
    liquid_number,
    material_text,
  }) => {
    let Code = 'N/A',
      Description = 'N/A';

    if (bom_changes.includes(liquidBomChanges.thickness)) {
      Code = liquid_number;
      Description = material_text;
    }
    if (
      bom_changes.includes(liquidBomChanges.drawing) ||
      bom_changes.includes(liquidBomChanges.removal)
    ) {
      Code = liquid_code;
      Description = liquid_description;
    }

    return {
      'Material Code': Code,
      'Material Description': Description,
      Plant: plant,
      Status: status,
      Action:
        status === 'Closed' ? (manually_closed ? 'Manually Closed' : 'Automatic Closure') : 'NA',
      'Step Deadline': sla_date,
      'Step Finished': status === 'Closed' ? finished_date : status,
    };
  };

  const dataToExportM = ({
    material_type,
    int_rfs_material_number,
    pack_mat_material_text,
    new_material_code,
    new_material_description_intrfsml,
    plant,
    sla_date,
    status,
    manually_closed,
    finished_date,
  }) => {
    let Code = 'N/A',
      Description = 'N/A';

    if (material_type === 'Existing Material' || material_type === 'Remove Material') {
      Code = int_rfs_material_number ? int_rfs_material_number : 'N/A';

      Description = pack_mat_material_text ? pack_mat_material_text : 'N/A';
    } else if (material_type === 'New Material') {
      Code = new_material_code ? new_material_code : 'N/A';

      Description = new_material_description_intrfsml ? new_material_description_intrfsml : 'N/A';
    }

    return {
      'Material Code': Code,
      'Material Description': Description,
      Plant: plant,
      Status: status,
      Action:
        status === 'Closed' ? (manually_closed ? 'Manually Closed' : 'Automatic Closure') : 'NA',
      'Step Deadline': sla_date,
      'Step Finished': status === 'Closed' ? finished_date : status,
    };
  };

  return (
    <Row>
      <div
        style={{
          display: 'flex',
          justifyContent: 'right',
          marginRight: '4rem',
        }}
      >
        {/* <CSVLink
          data={}
          filename={
            'status check_' + moment().format('YYYY-MM-DD HH:mm:ss')
          }> */}
        <Button
          leftIcon={<TableExport />}
          onClick={() => {
            downloadExcel();
          }}
          color='yellow'
          style={{
            marginBottom: '1rem',
            marginTop: '2rem',
            background: 'black',
          }}
        >
          Export to CSV
        </Button>
        {/* </CSVLink> */}
      </div>
      <Accordion value={state} onChange={handlers} defaultValue={'key_0'}>
        {Object.keys(statusData) &&
          Object.keys(statusData).map((name, index) => {
            return (
              Object.values(statusData)[index].length > 0 && (
                <Accordion.Item className='scope-label' key={`key_${index}`} value={`key_${index}`}>
                  <Accordion.Control>{`${name}`}</Accordion.Control>
                  <Accordion.Panel>
                    <Grid>
                      <Grid.Col span={12}>
                        <FillingAndShipmentTableVEAM
                          statusDataList={Object.values(statusData)[index]}
                          type={name}
                          save={save}
                          accState={accState}
                          readOnly={!permissions['filings_shipment'].includes(userRole)}
                        />
                      </Grid.Col>
                    </Grid>
                  </Accordion.Panel>
                </Accordion.Item>
              )
            );
          })}
      </Accordion>
    </Row>
  );
};

export default FillingAndShipment;
