import React, { useContext } from 'react';
import { Modal, Button, Text, Container, Grid, Group } from '@mantine/core';
import { RfsContext } from '../../contexts/RfsContext';
import { InfoCircle } from 'tabler-icons-react';

function CCModal({ data, setCcModal, value, onClose = null }) {
  const { form, setForm } = useContext(RfsContext);
  const { rfs_type } = form.rfsInformation;

  const closeModal = () => {
    setCcModal({
      open: false,
      callback: null,
      query: null,
    });
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      closeModal();
      setForm((o) => {
        return {
          ...o,
          rfsInformation: {
            ...o.rfsInformation,
            cost_center: null,
          },
        };
      });
    }
  };

  const handleConfirm = () => {
    rfs_type === 'Delisting' ? data.callback(data.query, data.id) : data.callback(data.query);
    closeModal();
  };

  return (
    <div>
      <Modal
        opened={data.open}
        onClose={() => handleClose()}
        title='Disclaimer'
        className='confirm-modal'
      >
        <Container>
          <Grid>
            <Grid.Col span={12}>
              <Group noWrap className='invalid-modal-group'>
                <InfoCircle color='red' size='5rem' />
                <Text size='sm'>
                  This cost centre does not exist in the ERP. Are you sure you want to use an
                  unknown CC?
                </Text>
              </Group>
            </Grid.Col>

            <Grid.Col span={3} offset={9}>
              <Button 
              color='yellow' 
              variant='filled' 
              style={{ background:'linear-gradient(90deg,#e3af32 0%,#f4e00f 100%)', color: 'black' }}
              onClick={() => handleConfirm()} fullWidth>
                Confirm
              </Button>
            </Grid.Col>
          </Grid>
        </Container>
      </Modal>

      {/* <Modal
        opened={open}
        onClose={() => handleClose()}
        title='Disclaimer'
        className='cost-centre-modal'>
        <Container>
          <Grid>
            <Grid.Col span={12}>
              <Text size='md'>
                This cost centre does not exist in the ERP. Are you sure you
                want to use an unknown CC?
              </Text>
            </Grid.Col>
            <Grid.Col span={5} offset={7}>
              <Button
                color='yellow'
                fullWidth
                onClick={(e) => setOpen(false)}
                style={{ marginTop: '1rem' }}>
                Confirm
              </Button>
            </Grid.Col>
          </Grid>
        </Container>
      </Modal> */}
    </div>
  );
}

export default CCModal;
