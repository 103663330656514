import { React, useContext, useEffect, useState } from 'react';
import RfsLayout from '../../components/layouts/RfsLayout';
import ContentPaper from '../../components/layouts/rfs/Content';
import { Container, Row, Col } from 'react-bootstrap';
import './accf.css';
import ModalAccf from './ModalAccf';
import { Button } from '@mantine/core';
import { axios } from '../../utils/axios';
import { AuthContext } from '../../contexts/AuthContext';
import { SubmissionTrueModal } from '../../components/modal/SubmissionTrueModal';

const AccfPage = () => {
  const [data, setData] = useState({});
  const { userEmail } = useContext(AuthContext);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const handleSelect = (type, val) => {
    const currentValues = { ...data };
    currentValues[type] = val;
    setData(currentValues);
  };

  const fetchData = () => {
    axios.get(`/accf/get_accf_settings/${userEmail}`).then(
      (res) => {
        if (res.data.data) {
          const currentValues = { ...data };
          currentValues['plants'] = res.data.data.plants ? JSON.parse(res.data.data.plants) : [];
          currentValues['markets'] = res.data.data.markets ? JSON.parse(res.data.data.markets) : [];
          setData(currentValues);
        }
      },
      (error) => {
        console.log(error);
      },
    );
  };

  useEffect(() => {
    fetchData();
  }, [userEmail]);

  const handleSubmit = () => {
    data['user_email'] = userEmail;
    axios.post(`/accf/submit_accf_settings/`, data).then(
      (res) => {
        setSubmitSuccess(true);
      },
      (error) => {
        console.log(error);
      },
    );
  };

  return (
    <RfsLayout>
      <ContentPaper page='accf-page'>
        <Container className='pos-r h-100'>
          <h5 className='sectionHeadings'>ACCF Settings</h5>
          <div className='nthdpg'>
            <h6 className='sectionHeadings' style={{ fontSize: '18px' }}>
              SELECT SCOPE
            </h6>
            <Row>
              <Col md={6}>
                <Container>
                  <Row>
                    <Col md={6}>
                      <b>Plants</b>
                    </Col>
                    <Col md={6}>
                      <ModalAccf
                        type={'plants'}
                        title={'Select Plants'}
                        selected={data['plants']}
                        handleSelect={handleSelect}
                        submitted={submitSuccess}
                        disabled={false}
                      />
                    </Col>
                  </Row>
                </Container>
              </Col>
              <Col md={6}>
                <Container>
                  <Row>
                    <Col md={6}>
                      <b>Markets</b>
                    </Col>
                    <Col md={6}>
                      <ModalAccf
                        type={'markets'}
                        title={'Select Markets'}
                        selected={data['markets']}
                        handleSelect={handleSelect}
                        submitted={submitSuccess}
                        disabled={false}
                      />
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </div>

          <div className='d-flex justify-content-right pos-a submitBtnWrap'>
            <Button color='yellow' variant='filled' radius='sm' onClick={() => handleSubmit()}>
              Save Selections
            </Button>
          </div>
        </Container>
      </ContentPaper>
      {submitSuccess && (
        <SubmissionTrueModal
          open={submitSuccess}
          setOpen={setSubmitSuccess}
          text={'Your settings has been saved succesfully!'}
        />
      )}
    </RfsLayout>
  );
};

export default AccfPage;
