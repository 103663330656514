import React, { useState, useContext, useEffect, useRef } from 'react';
import {
  Text,
  Select,
  Button,
  SimpleGrid,
  Grid,
  Image,
  TextInput,
  Center,
  Divider,
} from '@mantine/core';
import { useNavigate, Link } from 'react-router-dom';
import { AuthContext } from '../../../contexts/AuthContext';
import { axios } from '../../../utils/axios';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { loginRequest } from '../../../utils/authConfig';
import { showAlert } from '../../../utils/alerts';
import { roles } from '../../../constants';

import '../../Login/Login.css';
import abilogo from '../../../images/abilogo.svg';
import abilogoback from '../../../images/abilogoback.jpg';

function Register() {
  const { login, clearStorage, userName = '' } = useContext(AuthContext);
  const [fetched, setFetched] = useState({});
  const [userEmail, setUserEmail] = useState();
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [posted, setPosted] = useState(true);
  const [stay, setStay] = useState(true);
  const stayRef = useRef(null);
  stayRef.current = stay;

  useEffect(() => {
    !!fetched.role_requested ? setPosted(false) : setPosted(true);
  }, [fetched.role_requested]);

  useEffect(() => {
    if (accounts[0]) setUserEmail(accounts[0].idTokenClaims.email);
  }, [accounts[0]]);

  const handleSelect = (name, value) => {
    const currentValues = { ...fetched };
    currentValues[name] = value;
    setFetched(currentValues);
  };

  const createRegister = (payload, callback) => {
    axios.post(`/register/add`, payload).then(
      (res) => {
        res.status === 200 ? setStay(false) : setStay(true);
        res.status === 200 ? setPosted(true) : setPosted(false);
        callback();
      },
      (err) => {
        setPosted(false);
        console.log(err);
        return showAlert({ message: 'Registration failed. Please try again.' }, 'error');
      },
    );
  };

  const handleNext = () => {
    setPosted(true);
    createRegister(
      {
        name: userName,
        email: userEmail,
        role_requested: fetched.role_requested,
        role_approved: 'initiator',
        role_reviewed: false,
      },
      () => {
        if (isAuthenticated) {
          const request = {
            ...loginRequest,
            account: accounts[0],
          };

          instance
            .acquireTokenSilent(request)
            .then((response) => {
              login(
                {
                  token: response.accessToken,
                  email: response.idTokenClaims.email,
                },
                (path) => {
                  if (!stayRef.current) navigate(path);
                },
              );
            })
            .catch((e) => {
              return showAlert({ message: 'Registration failed. Please try again.' }, 'error');
              // clearStorage();
              // navigate('/');
            });
        }
      },
    );
  };

  return (
    <>
      <div style={{ height: '100vh', overflow: 'hidden' }}>
        <Grid align='flex-start'>
          <Grid.Col span={12} className='photo'>
            <Image src={abilogoback} alt='AB InBev Background' />
          </Grid.Col>

          <Grid.Col span={12} className='data content'>
            <Grid.Col span={6} className='loginForm registerForm'>
              <div className='innerContent'>
                <div>
                  <Link to={'/'}>
                    <Image
                      style={{
                        maxWidth: '40%',
                        margin: '0 auto',
                      }}
                      className='logo'
                      src={abilogo}
                      alt='AB InBev logo'
                    />
                  </Link>
                  <Divider className='divider' my='1'></Divider>
                  <Text
                    style={{
                      fontSize: '3.8vh',
                      fontWeight: '600',
                      display: 'inline-flex',
                      justifyContent: 'center',
                      width: '100%',
                      color: '#fff',
                    }}
                  >
                    Speed to Market
                  </Text>

                  <div style={{ padding: '0  25px' }}>
                    <TextInput
                      style={{
                        marginTop: '25px',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignContent: 'flex-start',
                      }}
                      styles={(theme) => ({
                        input: {
                          borderBottom: '1px solid #ffc107',
                        },
                        label: {
                          fontWeight: '500',
                          fontSize: '1.9vh',
                          color: '#fff',
                        },
                      })}
                      label='Full Name'
                      value={userName}
                      disabled='true'
                    />

                    <TextInput
                      style={{
                        paddingTop: '4vh',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                      }}
                      styles={(theme) => ({
                        input: {
                          border: '1px solid #ffc107',
                        },
                        label: {
                          // display:'flex',  justifyContent:'center',
                          fontWeight: '500',
                          fontSize: '1.9vh',
                          color: '#fff',
                        },
                      })}
                      value={userEmail}
                      label='Email'
                      disabled='true'
                    />

                    <Select
                      style={{
                        paddingTop: '4vh',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                      }}
                      required
                      clearable
                      radius='md'
                      data={[
                        { value: roles.pmo, label: 'PMO' },
                        { value: roles.initiator, label: 'Initiator' },
                        { value: roles.superAdmin, label: 'Super Admin' },
                        { value: roles.masterDataTeam, label: 'Master Data' },
                        { value: roles.procurement, label: 'Procurement' },
                        { value: roles.d2p, label: 'Design To Print' },
                        { value: roles.mrp, label: 'MRP Team' },
                        { value: roles.zitec, label: 'ZITEC' },
                        { value: roles.snp, label: 'SNP' },
                        {
                          value: roles.standard_transfer_pricing,
                          label: 'Standard/Transfer Pricing',
                        },
                        { value: roles.scheduling, label: 'Scheduling' },
                      ]}
                      name='role_requested'
                      label='Select your desired role'
                      value={fetched.role_requested}
                      onChange={(value) => handleSelect('role_requested', value)}
                      styles={(theme) => ({
                        input: {
                          display: 'inline-flex',
                          justifyContent: 'center',
                          border: '2px solid #ffc107',
                        },
                        label: { fontSize: '1.9vh', color: '#fff' },
                      })}
                    />
                    <Text
                      style={{
                        fontSize: '1.8vh',
                        padding: '5vh 0 2vh',
                        display: 'inline-flex',
                        justifyContent: 'center',
                        width: '100%',
                        color: '#fff',
                      }}
                    >
                      Submit for Approval and Proceed as Initiator
                    </Text>
                    <Center>
                      <Button
                        variant='gradient'
                        gradient={{ from: '#D7A833', to: '#F4DF03', deg: 90 }}
                        styles={(theme) => ({
                          root: {
                            color: '#000',
                          },
                        })}
                        className='w-50'
                        onClick={() => handleNext()}
                      >
                        Submit
                      </Button>
                    </Center>
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                    flexWrap: 'wrap',
                    aligncontent: 'center',
                    alignItems: 'center',
                    maxWidth: '55%',
                    margin: '0 auto',
                  }}
                ></div>
              </div>
            </Grid.Col>
          </Grid.Col>
        </Grid>
      </div>

      {/* <div style={{ height: '100vh', overflow: 'hidden' }}>
        <Grid align='flex-start'>
          <Grid.Col
            span={6}
            style={{ minHeight: '110vh', backgroundColor: 'black' }}>
            <Image
              style={{
                display: 'flex',
                marginTop: '40vh',
                marginBottom: '40vh',
                justifyContent: 'center',
                paddingLeft: '3vw',
                paddingRight: '3vw',
                height: '100%',
                width: '100%',
                objectFit: 'fill',
                overflow: 'hidden',
              }}
              src={abilogo}
              alt='AB InBev Logo'
            />
          </Grid.Col>
          <Grid.Col
            span={6}
            style={{
              minHeight: '110vh',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'flex-start',
            }}>
            <div
              style={{
                marginTop: '10vh',
                width: '65%',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignContent: 'flex-start',
                placeContent: 'flex-start',
              }}>
              <Text
                style={{
                  fontSize: '7vh',
                  fontWeight: '700',
                  paddingTop: '3vh',
                  display: 'inline-flex',
                  justifyContent: 'center',
                  width: '100%',
                }}>
                Speed to Market
              </Text>
              
              <TextInput
                style={{
                  marginTop: '10vh',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignContent: 'flex-start',
                }}
                styles={(theme) => ({
                  input: {
                    width: '32vw',
                    border: '1px solid #ffc107',
                  },
                  label: {
                    fontWeight: '500',
                    fontSize: '1.9vh',
                  },
                })}
                label='Full Name'
                value={userName}
                disabled='true'
              />

              <TextInput
                style={{
                  paddingTop: '4vh',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
                styles={(theme) => ({
                  input: {
                    width: '32vw',
                    border: '1px solid #ffc107',
                  },
                  label: {
                    // display:'flex',  justifyContent:'center',
                    fontWeight: '500',
                    fontSize: '1.9vh',
                  },
                })}
                value={userEmail}
                label='Email'
                disabled='true'
              />

              <Select
                style={{
                  paddingTop: '4vh',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
                required
                clearable
                radius='md'
                data={[
                  { value: roles.pmo, label: 'PMO' },
                  { value: roles.initiator, label: 'Initiator' },
                  { value: roles.superAdmin, label: 'Super Admin' },
                  { value: roles.masterDataTeam, label: 'Master Data' },
                  { value: roles.procurement, label: 'Procurement' },
                  { value: roles.d2p, label: 'Design To Print' },
                  { value: roles.mrp, label: 'MRP Team' },
                  { value: roles.zitec, label: 'ZITEC' },
                ]}
                name='role_requested'
                label='Select your desired role'
                value={fetched.role_requested}
                onChange={(value) => handleSelect('role_requested', value)}
                styles={(theme) => ({
                  input: {
                    width: '32vw',
                    display: 'inline-flex',
                    justifyContent: 'center',
                    border: '2px solid #ffc107',
                  },
                  label: { fontSize: '1.9vh' },
                })}
              />
              <Text
                style={{
                  fontSize: '2vh',
                  fontWeight: 'bold',
                  paddingTop: '16vh',
                  display: 'inline-flex',
                  justifyContent: 'center',
                  width: '100%',
                }}>
                Submit for Approval and Proceed as Initiator
              </Text>
              <Center>
                <Button
                  variant='outline'
                  color='yellow'
                  radius='xl'
                  size='xl'
                  styles={(theme) => ({
                    root: {
                      paddding: '2rem',
                      marginTop: '3vh',
                      marginLeft: 'vh',
                      width: '300px',
                      fontSize: '3.00vh',
                      boxShadow: '5px 5px 5px 1px #ffc107',
                      '&:hover': {
                        backgroundColor: '#ffc107',
                        color: 'black',
                        boxShadow: '5px 5px 5px 1px black',
                      },
                    },
                  })}
                  onClick={() => handleNext()}>
                  Submit
                </Button>
              </Center>


            </div>
          </Grid.Col>
        </Grid>
      </div> */}
    </>
  );
}

export default Register;
