import React, { useEffect, useState, useMemo } from 'react';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import {
  ActionIcon,
  Button,
  Flex,
  Tooltip,
  Switch,
  TextInput,
  Select,
  MantineProvider,
  useMantineTheme,
  Grid,
} from '@mantine/core';
import {
  IconEdit,
  IconSearch,
  IconSearchOff,
  IconRefresh,
  IconDeviceFloppy,
  IconCircleX,
  IconEditCircle,
  IconEditCircleOff,
} from '@tabler/icons-react';
import { CalendarIcon } from '@modulz/radix-icons';
import { DatePickerInput } from '@mantine/dates';
import moment from 'moment';

//CSV Download
import csvDownload from 'json-to-csv-export';
import { TableExport } from 'tabler-icons-react';
import { HeaderLeftPanel, RightItems } from './styles';
import { CustomTextInput } from '../../inputs/CustomTextInput';

const MantineTable = ({
  columns,
  setValidationErrors = () => {},
  unique,
  enableEditing = false,
  readOnly,
  enableExport = false,
  csvHeaders,
  csvFilename,
  initialData = [],
  loading = true,
  handleRowSelection = () => {},
  activeRows = {},
  enableRowSelection = true,
  resetTableData = () => {},
  enableExpanding = false,
  enablePinning = false,
  enableStickyFooter = false,
  initialState = null,
  hideSelectColumn = true,
  sendFilteredData = () => {},
  showSelectedToggle = true,
  pageSize = 5,
  enablePagination = true,
  enableBottomToolbar = true,
  showResetAll = true,
  showEditActions = () => {
    return true;
  },
  customSave = () => {},
  positionToolbarAlertBanner = 'top',
  editAll = false,
  applyToAll = () => {},
  alwaysShowEditAll = false,
}) => {
  const [switchChecked, setSwitchChecked] = useState(false);
  const [selected, setSelected] = useState([]);
  const [rowSelection, setRowSelection] = useState(activeRows);
  const [filteredRows, setFilteredRows] = useState([]);
  const globalTheme = useMantineTheme;

  //Custom search
  const [toBeSearched, setToBeSearched] = useState('');

  //Search Icon
  const [searchOn, setSearchOn] = useState(false);

  //All Select
  const [allSelected, setAllSelected] = useState(false);

  const [data, setData] = useState([]);
  const [successData, setSuccessData] = useState([]);
  const [allEditOn, setAllEditOn] = useState(false);
  const [applyAllColumn, setApplyAllColumn] = useState(null);
  const [tempApplyAllValue, setTempApplyAll] = useState(null);

  useEffect(() => {
    if (Object.keys(rowSelection).length !== Object.keys(activeRows).length) {
      setRowSelection(activeRows);
    }
  }, [activeRows]);

  useEffect(() => {
    if (!allEditOn) {
      setApplyAllColumn(null);
      setTempApplyAll(null);
    }
  }, [allEditOn]);

  useEffect(() => {
    setData(initialData);
    setSuccessData(initialData);
    setFilteredRows(initialData);
    sendFilteredData(initialData);
  }, [initialData]);

  const downloadExcel = () => {
    const dataToExport = {
      data: getDataToExport(data),
      filename: csvFilename,
      delimiter: ',',
      headers: csvHeaders,
    };
    csvDownload(dataToExport);
  };

  const getDataToExport = (data) => {
    const csvArray = data.map(Object.values(data).map((i, index) => i));
    return csvArray;
  };

  const filterMultiSearch = (event) => {
    const input = event.target.value.split(' ');

    const myArrayFiltered = successData.filter((el) => {
      return input.some((f) => {
        if (!el[toBeSearched] || !f) return false;
        return el[toBeSearched].toString().toLowerCase().includes(f.toString().toLowerCase());
      });
    });

    setData(
      event.target.value.length > 0
        ? myArrayFiltered
        : switchChecked && Object.keys(selected).length
        ? selected
        : successData,
    );
    setFilteredRows(event.target.value.length > 0 ? myArrayFiltered : successData);
    sendFilteredData(event.target.value.length > 0 ? myArrayFiltered : successData);
  };

  const resetAll = () => {
    setSearchOn(false);
    setAllSelected(false);
    setRowSelection({});
    setSwitchChecked(false);
    setData(successData);
    setFilteredRows(successData);
    sendFilteredData(successData);
    handleRowSelection({});
    resetTableData();
  };

  const getApplyAllData = (type = 'type') => {
    const dataArray = applyAllColumn.split('|');
    return type === 'type' ? dataArray[1] : dataArray[0];
  };

  const table = useMantineReactTable({
    columns,
    data: data,
    createDisplayMode: 'row',
    editDisplayMode: 'row',
    enableEditing: enableEditing,
    getRowId: (row) => row[unique],
    enableRowSelection: hideSelectColumn
      ? false
      : (row) => {
          if (row.original.hasOwnProperty('disabled')) {
            return !row.original.disabled;
          } else {
            return enableRowSelection;
          }
        },
    selectDisplayMode: 'checkbox',
    enableGlobalFilter: false,
    selectAllMode: 'all',
    enableExpanding: enableExpanding,
    enablePinning: enablePinning,
    enablePagination: enablePagination,
    enableBottomToolbar: enableBottomToolbar,
    enableStickyFooter: enableStickyFooter,
    positionToolbarAlertBanner: positionToolbarAlertBanner,
    initialState: initialState
      ? initialState
      : {
          showColumnFilters: true,
          density: 'xs',
          pagination: { pageSize: pageSize, pageIndex: 0 },
        },
    displayColumnDefOptions: {
      'mrt-row-select': {
        enableHiding: alwaysShowEditAll,
      },
    },
    mantineTableProps: {
      highlightOnHover: false,
      withColumnBorders: true,
      withBorder: 'light',
    },
    mantineTableHeadProps: {
      zIndex: 0,
    },
    mantineTableFooterProps: {
      style: { zIndex: 0 },
    },
    mantineTableContainerProps: {
      sx: {
        zIndex: 0,
      },
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    onEditingRowCancel: () => setValidationErrors({}),
    renderRowActions: ({ row, table }) => (
      <Flex style={{ zIndex: 99999 }}>
        <ActionIcon
          disabled={readOnly || !showEditActions(row.original)}
          onClick={() => {
            setRowSelection({ [row.original.id]: !rowSelection[row.original.id] });
          }}
        >
          {!rowSelection[row.original.id] ? <IconEdit /> : <IconCircleX />}
        </ActionIcon>

        {rowSelection[row.original.id] && (
          <ActionIcon
            disabled={readOnly || !showEditActions(row.original)}
            onClick={() => {
              customSave(row.original, () => setRowSelection({}));
            }}
          >
            <IconDeviceFloppy />
          </ActionIcon>
        )}
      </Flex>
    ),
    renderTopToolbarCustomActions: ({ table }) => {
      return (
        <>
          <HeaderLeftPanel>
            {showSelectedToggle && (
              <Tooltip label='Show All Selected'>
                <Switch
                  disabled={selected.length === 0 && !switchChecked}
                  checked={switchChecked}
                  onChange={(event) => setSwitchChecked(event.currentTarget.checked)}
                />
              </Tooltip>
            )}

            {showResetAll && (
              <Tooltip label='Reset All'>
                <IconRefresh onClick={resetAll} />
              </Tooltip>
            )}

            <div
              onClick={() => {
                if (searchOn) {
                  setFilteredRows(successData);
                  sendFilteredData(successData);
                }
                setSearchOn(searchOn ? false : true);
                setAllEditOn(false);
              }}
            >
              <Tooltip label='Search All'>{searchOn ? <IconSearchOff /> : <IconSearch />}</Tooltip>
            </div>

            {(alwaysShowEditAll || (Object.keys(rowSelection).length > 0 && editAll)) && (
              <Tooltip label='Edit All Selected'>
                <div
                  onClick={() => {
                    const value = allEditOn ? false : true;
                    if (alwaysShowEditAll) {
                      if (!value) table.resetRowSelection();
                      const column = table.getColumn('mrt-row-select');
                      if (column) column.toggleVisibility(value);
                    }

                    setAllEditOn(value);
                    setSearchOn(false);
                  }}
                >
                  {allEditOn ? <IconEditCircleOff /> : <IconEditCircle />}
                </div>
              </Tooltip>
            )}
          </HeaderLeftPanel>

          {searchOn && (
            <Grid gutter='md'>
              <Grid.Col span={6}>
                <Select
                  placeholder='Select The Column To Search'
                  onChange={(value) => {
                    setToBeSearched(value);
                  }}
                  data={columns.map((column) => {
                    return { value: column.accessorKey, label: column.header };
                  })}
                  style={{ zIndex: '1' }}
                  withinPortal
                />
              </Grid.Col>

              <Grid.Col span={6}>
                <Tooltip
                  arrowOffset={10}
                  arrowSize={4}
                  label='To search for multiple values, please enter space-separated values e.g 10023 12309 11287'
                  withArrow
                  position='top-start'
                  multiline
                  width={220}
                >
                  <TextInput
                    clearable
                    placeholder='Search'
                    onChange={(event) => filterMultiSearch(event)}
                    style={{ zIndex: '0' }}
                    disabled={toBeSearched === ''}
                  />
                </Tooltip>
              </Grid.Col>
            </Grid>
          )}

          {allEditOn && (
            <Grid gutter='md' grow justify='center' align='center' style={{ minWidth: '40%' }}>
              <Grid.Col span={6}>
                <Select
                  value={applyAllColumn}
                  disabled={readOnly}
                  label='Select the column to edit'
                  placeholder='Select The Column'
                  data={columns
                    .filter((c) => c.enableEditing)
                    .map((column) => {
                      return {
                        value: column.accessorKey + '|' + column.editVariant,
                        label: column.header,
                      };
                    })}
                  onChange={(e) => {
                    setApplyAllColumn(e);
                    setTempApplyAll(null);
                  }}
                  withinPortal
                />
              </Grid.Col>

              <Grid.Col span={4}>
                {applyAllColumn && getApplyAllData() === 'select' && (
                  <Select
                    value={tempApplyAllValue}
                    label='Select'
                    placeholder='Select'
                    data={
                      columns.find((c) => getApplyAllData('key') === c.accessorKey)
                        ?.mantineEditSelectProps?.data || []
                    }
                    onChange={(value) => setTempApplyAll(value)}
                    withinPortal
                  />
                )}

                {applyAllColumn && getApplyAllData() === 'text' && (
                  <div style={{ marginTop: '12px' }}>
                    <CustomTextInput
                      value={tempApplyAllValue}
                      label='Please type here'
                      onChange={(value) => setTempApplyAll(value)}
                    />
                  </div>
                )}

                {applyAllColumn && getApplyAllData() === 'datepicker' && (
                  <DatePickerInput
                    styles={{
                      arrow: {
                        display: 'block',
                      },
                      dropdown: {
                        display: 'block',
                      },
                    }}
                    style={{ marginTop: '22px' }}
                    onChange={(event) =>
                      setTempApplyAll(
                        moment(event).isValid() ? moment(event).format('YYYY-MM-DD') : null,
                      )
                    }
                    minDate={
                      columns.find((c) => getApplyAllData('key') === c.accessorKey)
                        ?.mantineEditSelectProps?.data?.minDate
                    }
                    initialMonth={
                      columns.find((c) => getApplyAllData('key') === c.accessorKey)
                        ?.mantineEditSelectProps?.data?.initialMonth
                    }
                    allowFreeInput
                    numberOfColumns={2}
                    radius='md'
                    icon={<CalendarIcon color='#e5b611' />}
                    popoverProps={{ withinPortal: true }}
                  />
                )}
              </Grid.Col>

              <Grid.Col span={2}>
                <Button
                  style={{ marginTop: '22px' }}
                  onClick={() =>
                    applyToAll(
                      tempApplyAllValue,
                      applyAllColumn.split('|')[0],
                      table.getFilteredSelectedRowModel(),
                    )
                  }
                  className='custom-mantine-button'
                  disabled={!tempApplyAllValue}
                >
                  Save
                </Button>
              </Grid.Col>
            </Grid>
          )}
        </>
      );
    },
    state: {
      isLoading: loading,
      showProgressBars: loading,
      rowSelection,
      allEditOn,
    },
    onRowSelectionChange: setRowSelection,
  });

  const columnFilterResults = table.getFilteredRowModel();

  useEffect(() => {
    if (table && alwaysShowEditAll) {
      const column = table.getColumn('mrt-row-select');
      if (column) column.toggleVisibility(false);
    }
  }, [alwaysShowEditAll]);

  useEffect(() => {
    sendFilteredData(columnFilterResults.rows.map((row) => row.original));
  }, [columnFilterResults]);

  const updateSelected = () => {
    var filtered = filteredRows.filter((item, index) => {
      return Object.keys(rowSelection).indexOf((item[unique] || '').toString()) !== -1;
    });

    setSelected(filtered);
  };

  useEffect(() => {
    updateSelected();
    handleRowSelection(rowSelection);
  }, [rowSelection, filteredRows]);

  useEffect(() => {
    if (switchChecked || allSelected) setData(selected);
    else if ((!switchChecked || !allSelected) && !searchOn) setData(successData);
    else if ((!switchChecked || !allSelected) && searchOn) setData(filteredRows);
    else setData(successData);
  }, [switchChecked, selected, data, searchOn]);

  return (
    <>
      {enableExport && (
        <RightItems>
          <Button
            leftIcon={<TableExport />}
            onClick={() => {
              downloadExcel();
            }}
            color='dark'
          >
            Export Report to CSV
          </Button>
        </RightItems>
      )}

      <MantineProvider theme={{ ...globalTheme, primaryColor: 'yellow', primaryShade: 7 }}>
        <MantineReactTable
          table={table}
          mantineTableBodyProps={{
            striped: true,
            sx: {
              minHeight: 'unset',
            },
          }}
        />
      </MantineProvider>
    </>
  );
};

export default MantineTable;
