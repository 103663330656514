import { Button, Tabs } from '@mantine/core';
import React, { useContext, useEffect, useState } from 'react';
import { RfsContext } from '../../../contexts/RfsContext';
import { axios } from '../../../utils/axios';
import { AppLoader } from '../../common/loader';

import SkuSelectedMaterialTable from '../SkuSelectedMaterialTable/SkuSelectedMaterialTable';

const AffectedSkus = ({
  plants,
  isSubmissionReview,
  errors,
  scope_id,
  affectedSkus,
  setAffectedSkus,
}) => {
  const { form, setForm } = useContext(RfsContext);
  const [loading, setLoading] = useState(false);

  const fetchAffectedSkus = (material) => {
    setLoading(true);
    return new Promise((resolve) => {
      axios
        .get('/materials/affected-skus', {
          params: {
            material: material.material_code,
            plant: material.plant,
          },
        })
        .then(
          (res) => {
            let response = res.data.data.affectedSkus;

            response = response.map((sku) => {
              const index = form.selectedAffectedSkus.findIndex(
                (val) =>
                  val.SKUs === sku.SKUs &&
                  val.Plant === sku.Plant &&
                  parseInt(val.scope_id) === parseInt(scope_id),
              );
              if (index > -1) sku['selected'] = true;
              return sku;
            });

            resolve(response);
          },
          (error) => {
            console.log(error);
          },
        )
        .finally(() => setLoading(false));
    });
  };

  const getAffectedSKUs = async (info) => {
    const skus = await fetchAffectedSkus(info);
    const values = { ...affectedSkus };
    values[`${info.material_code}_${info.plant}`] = [...skus];
    setAffectedSkus(values);
  };

  const getFilteredIds = (filteredRows) => {
    const filteredIds = {};
    filteredRows.forEach((row) => {
      filteredIds[`${row.original.SKUs}_${row.original.Plant}`] = true;
    });

    return filteredIds;
  };

  const handleSelectAll = (event, materialPlant, filteredRows) => {
    const filteredIds = getFilteredIds([...filteredRows]);

    let currentValues = { ...form };
    const index = currentValues.scopeSelectedMaterials.findIndex(
      (val) => `${val.material_code}_${val.plant}` === materialPlant,
    );

    if (index > -1) {
      let skus = currentValues?.scopeSelectedMaterials[index]?.selectedAffectedSkus || [];

      if (event.target.checked) {
        skus = affectedSkus[materialPlant];
      } else {
        skus = [];
      }

      skus = skus.filter((val) => filteredIds[`${val.SKUs}_${val.Plant}`]);

      currentValues.scopeSelectedMaterials[index]['selectedAffectedSkus'] = skus;

      setForm(currentValues);
    }

    const currentSkus = { ...affectedSkus };

    currentSkus[materialPlant].forEach((val, index) => {
      currentSkus[materialPlant][index]['selected'] = filteredIds[`${val.SKUs}_${val.Plant}`]
        ? event.target.checked
        : false;
    });
    setAffectedSkus(currentSkus);
  };

  const handleSkuSelect = (event, row, materialPlant) => {
    const selectedRow = row.original;

    const currentSkus = { ...affectedSkus };
    const skuIndex = (currentSkus[materialPlant] || []).findIndex(
      (sku) =>
        sku.SKUs === selectedRow.SKUs &&
        sku.AlternativeBOM === selectedRow.AlternativeBOM &&
        sku.Plant === selectedRow.Plant,
    );

    currentSkus[materialPlant][skuIndex]['selected'] = event.target.checked;
    setAffectedSkus(currentSkus);

    let currentValues = { ...form };
    const index = currentValues.scopeSelectedMaterials.findIndex(
      (val) => `${val.material_code}_${val.plant}` === materialPlant,
    );

    if (index > -1) {
      const skus = JSON.parse(
        JSON.stringify(currentValues?.scopeSelectedMaterials[index]?.selectedAffectedSkus || []),
      );
      const skuIndex = skus.findIndex(
        (sku) =>
          sku.SKUs === selectedRow.SKUs &&
          sku.AlternativeBOM === selectedRow.AlternativeBOM &&
          sku.Plant === selectedRow.Plant,
      );

      if (event.target.checked) {
        skus.push(row.original);
      } else {
        skus.splice(skuIndex, 1);
      }

      currentValues.scopeSelectedMaterials[index]['selectedAffectedSkus'] = skus;
    }

    setForm(currentValues);
  };

  const getSkus = (info) => {
    const data = [...form.selectedAffectedSkus].filter(
      (sku) => sku.Plant === info.plant && parseInt(sku.scope_id) === parseInt(scope_id),
    );

    return data;
  };

  return (
    <>
      {plants.length > 0 ? (
        <div style={{ marginTop: '16px' }}>
          <Tabs defaultValue={`affected_skus_key_0`}>
            <Tabs.List>
              {plants.map((info, key) => (
                <Tabs.Tab
                  className={`${errors[info.id]?.affected_skus ? 'affected-sku-error' : ''}`}
                  color={(errors[info.id]?.affected_skus && 'red') || 'yellow'}
                  label={info.plant}
                  key={`affected_skus_key_${key}`}
                  value={`affected_skus_key_${key}`}
                >
                  {info.plant}
                </Tabs.Tab>
              ))}
            </Tabs.List>

            {plants.map((info, key) => (
              <Tabs.Panel
                className={`${errors[info.id]?.affected_skus ? 'affected-sku-error' : ''}`}
                color={(errors[info.id]?.affected_skus && 'red') || 'yellow'}
                label={info.plant}
                key={`affected_skus_key_${key}`}
                value={`affected_skus_key_${key}`}
              >
                {isSubmissionReview ? (
                  <SkuSelectedMaterialTable
                    data={getSkus(info)}
                    materialPlant={`${info.material_code}_${info.plant}`}
                    handleChange={handleSkuSelect}
                    readOnly={true}
                    isSubmissionReview={isSubmissionReview}
                  />
                ) : typeof affectedSkus[`${info.material_code}_${info.plant}`] === 'undefined' ? (
                  loading ? (
                    <AppLoader center size='lg' />
                  ) : (
                    <>
                      {errors[info.id]?.affected_skus && <ErrorComp />}
                      <div className='fetch-skus-button'>
                        <Button
                          disabled={loading}
                          variant='gradient'
                          gradient={{ from: '#e3af32', to: '#f4e00f' }}
                          color='yellow'
                          onClick={() => getAffectedSKUs(info)}
                        >
                          Add/Remove Affected SKUs
                        </Button>
                        <>
                          <SkuSelectedMaterialTable
                            data={getSkus(info)}
                            materialPlant={`${info.material_code}_${info.plant}`}
                            handleChange={handleSkuSelect}
                            readOnly={true}
                            isSubmissionReview={isSubmissionReview}
                          />
                        </>
                      </div>
                    </>
                  )
                ) : (
                  <>
                    {errors[info.id]?.affected_skus && <ErrorComp />}
                    <SkuSelectedMaterialTable
                      data={affectedSkus[`${info.material_code}_${info.plant}`] || []}
                      materialPlant={`${info.material_code}_${info.plant}`}
                      handleChange={handleSkuSelect}
                      handleSelectAll={handleSelectAll}
                      isSubmissionReview={isSubmissionReview}
                    />
                  </>
                )}
              </Tabs.Panel>
            ))}
          </Tabs>
        </div>
      ) : null}
    </>
  );
};

const ErrorComp = () => <p className='padded-error'>Please select Affected SKUs</p>;

export default AffectedSkus;
