import React, { useMemo, useState } from 'react';
import { useTable } from 'react-table';
import styled from 'styled-components';
import { Checkbox, Button } from '@mantine/core';
import { SkuDetailModal } from './sku-detail-view';

const Styles = styled.div`
  padding: 1rem;
  table {
    border-spacing: 0;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 10rem;
      padding: 0.8rem;
      text-align: center;
      width: 22rem;

      :last-child {
        border-right: 0;
      }
    }

    #header-row {
      background-color: #d8d8d8;
    }
    tr:nth-child(even) {
      background-color: #cdcdcd;
    }
    tr:nth-child(odd) {
      background-color: #fff;
    }
  }
  input {
    background-color: #fff;
  }
`;

export function SkuTable({ form, values, setValues, disabled, skus = [] }) {
  const [viewModal, setViewModal] = useState({ open: false, scope_id: null });

  const handleSelect = (event) => {
    const currentSkus = [...values.scope_ids];
    if (event.target.checked) {
      currentSkus.push(event.target.name);
    } else {
      const index = currentSkus.findIndex((i) => i === event.target.name);
      if (index > -1) currentSkus.splice(index, 1);
    }

    setValues({ scope_ids: currentSkus }, values);
  };

  const columns = useMemo(
    () => [
      {
        Header: '',
        id: 'checkbox',
        width: '50',
        Cell: ({ row }) => {
          return (
            <div>
              <Checkbox
                name={row.original.id}
                onChange={handleSelect}
                checked={
                  values.id
                    ? row.original.selected
                    : (values?.scope_ids || []).includes(row.original.id.toString())
                }
                disabled={disabled || row.original.invalid}
              />
            </div>
          );
        },
      },
      {
        Header: 'SKU Code',
        accessor: 'selection_number',
      },
      {
        Header: 'SKU Description',
        accessor: 'selection_name',
      },
      {
        Header: 'Scope Selection',
        accessor: 'type',
        Cell: ({ row }) => (
          <Button
            variant='outline'
            color='dark'
            radius='xl'
            onClick={() => setViewModal({ open: true, scope_id: row.original.id })}
          >
            View
          </Button>
        ),
      },
    ],
    [values],
  );

  const tableInstance = useTable({
    columns,
    data: skus || [],
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <Styles>
      <div style={{ overflowX: 'auto' }}>
        <table style={{ width: 'fitContent' }} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} id='header-row'>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {viewModal.open && (
        <SkuDetailModal form={form} viewModal={viewModal} setViewModal={setViewModal} />
      )}
    </Styles>
  );
}
