import React, { useState, useContext, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RfsContext } from '../../contexts/RfsContext';
import { Row, Col } from 'react-bootstrap';
import { Select, SimpleGrid, Text, Button, Accordion, TextInput } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import moment from 'moment';
import { axios } from '../../utils/axios';
import ContentPaper from '../../components/layouts/rfs/Content';
import ImpFeasTable from '../../components/tables/impFeasibiltyTable/impFeasibiltyTable';
import { AppLoader } from '../../components/common/loader';
import ImpFeasiblityModal from './modal';
import SubmitProjectModal from '../../components/modal/SubmitProjectModal';
import './impFeasibility.css';
import { rfsStatus } from '../../constants';
import ProjectName from '../../components/projectName/projectName';
import { CalendarIcon } from '@radix-ui/react-icons';
import { routes } from '../../routes';
import { AttachmentsModal } from '../../common/attachments';
import { attachmentChunks } from '../../utils/helpers';

const MAX_ATTACHMENTS = 5;

export default function ImpFeasiblity(props) {
  const params = useParams();
  const navigate = useNavigate();
  const { form, updateRfsStatus, info, uploadAttachment } = useContext(RfsContext);
  const {
    project_name,
    first_shipment,
    trials_needed,
    submission_date,
    fast_track,
    status,
    rfs_type,
  } = form.rfsInformation;
  const [firstShipmentPmo, setFirstShipmentPmo] = useState(
    moment(new Date()).add(11, 'week').toDate(),
  );
  const [fastTrackPmo, setFastTrackPmo] = useState(fast_track ? 'Yes' : 'No');
  const [impFeasibility, setImpFeasibility] = useState({});
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fetched, setFetched] = useState({});
  const [validate, setValidate] = useState(false);
  const [validateFeilds, setValidateFeilds] = useState(false);
  const [submitProjectModal, setSubmitProjectModal] = useState(false);
  const [allData, setAllData] = useState([]);
  const [attachmentModal, setAttachmentModal] = useState({
    open: false,
    status_id: null,
    readOnly: null,
    attachments: [],
  });
  const [impLoading, setImpLoading] = useState(false);
  const [error, setError] = useState({ state: false, message: null });
  const [submitLoading, setSubmitLoading] = useState({ status: false, action: null });

  const handleFileSelect = (files) => {
    const currentValues = { ...attachmentModal };
    const attachments = currentValues.attachments || [];
    const filesLength = attachments.length + files.length;

    if (filesLength <= MAX_ATTACHMENTS) {
      files.forEach((file) => attachments.push(file));
      currentValues.attachments = attachments;
      setAttachmentModal(currentValues);
    }
  };

  const handleFileRemove = (index) => {
    const currentValues = { ...attachmentModal };
    currentValues.attachments.splice(index, 1);

    setAttachmentModal(currentValues);
  };

  const handleSubmit = async () => {
    await storeAttachment(
      {
        rfs: params.rfsNumber,
        attachments: attachmentModal.attachments,
        type: 'Implementation',
      },
      () => refresh(),
    );
  };

  const editable = () => {
    return status === rfsStatus.imp_feasible;
  };

  const fetchImpFeasibility = (rfsNumber) => {
    setImpLoading(true);
    axios
      .get(`/implementation/${rfsNumber}`)
      .then(
        (res) => {
          const response = res.data.fetchedData[0];
          let saveResponse = response === undefined ? {} : response;
          setFetched(saveResponse);
          if (Object.keys(saveResponse).length > 0 && rfs_type === 'Open SKU to market') {
            setFirstShipmentPmo(
              moment(first_shipment).isValid() ? moment(first_shipment).toDate() : firstShipmentPmo,
            );
          }
        },
        (error) => {},
      )
      .finally(() => setImpLoading(false));
  };

  const storeAttachment = async (payload, callback) => {
    await createFormData(payload);
    if (callback) callback();
  };

  const createFormData = async (payload) => {
    const formData = new FormData();
    formData.append('rfs_number', payload.rfs);
    formData.append('type', payload.type);

    for (const attachment of payload.attachments) {
      if (attachment.id) {
        formData.append('attachmentId[]', attachment.id);
      }
    }

    const chunks = attachmentChunks(payload.attachments.filter((file) => file.size));
    if (Object.keys(chunks).length > 0) {
      for (const chunk of Object.values(chunks)) {
        for (const file of chunk) {
          formData.append('attachments', file);
        }
        await uploadAttachment(formData);
      }
    } else {
      await uploadAttachment(formData);
    }

    return {};
  };

  const refresh = async (callback) => {
    await attachments(params.rfsNumber);
    if (callback) callback();
  };

  const createImpFeasibility = (payload, callback) => {
    let formData = new FormData();
    if (payload.attachment.files !== undefined) {
      if (payload.attachment.files.length > 0) {
        for (let k in payload.attachment.files) {
          if (false) {
          } else {
            formData.append('attachments', payload.attachment.files[k]);
            formData.append('bus_unit', payload.prod_bus_unit);
            formData.append('rfs', payload.rfs_number);
            formData.append('status', payload.tf_status);
          }
        }
      } else {
        formData.append('attachmentId[]', []);
        formData.append('bus_unit', payload.prod_bus_unit);
        formData.append('rfs', payload.rfs_number);
        formData.append('status', payload.tf_status);
        formData.append('first_shipment', payload.first_shipment);
        formData.append('fast_track', payload.fast_track);
      }

      axios
        .put(`/implementation`, formData, {
          prod_bus: payload.prod_bus_unit,
          rfs: payload.rfs_number,
          tf: payload.tf_status,
          first_shipment: payload.first_shipment,
          fast_track: payload.fast_track,
        })
        .then(
          (res) => {
            callback();
          },
          (err) => {},
        );
    } else {
      formData.append('attachments', []);
      formData.append('bus_unit', payload.prod_bus_unit);
      formData.append('rfs', payload.rfs_number);
      formData.append('status', payload.tf_status);
      formData.append('first_shipment', payload.first_shipment);
      formData.append('fast_track', payload.fast_track);
      axios
        .put(`/implementation`, formData, {
          prod_bus: payload.prod_bus_unit,
          rfs: payload.rfs_number,
          tf: payload.tf_status,
          first_shipment: payload.first_shipment,
          fast_track: payload.fast_track,
        })
        .then(
          (res) => {
            callback();
          },
          (err) => {},
        );
    }
  };

  const updateImpFeasibility = (payload, callback) => {
    axios.put(`/implementation/${params.rfsNumber}`, payload).then(
      (res) => {
        callback();
      },
      (err) => {},
    );
  };

  const fetchImpFeasibilityTable = (rfsNumber) => {
    setLoading(true);
    axios
      .get(`/implementation/fetch/${rfsNumber}`)
      .then(
        (res) => {
          let response = res.data.data;
          let materials = [];

          response = response.map((sku) => {
            const index = materials.findIndex(
              (material) => material.mat === sku.material_code && material.plant === sku.plant,
            );
            const mappedMaterial = {
              skus: sku.skus,
              desc: sku.sku_description,
              mat: sku.material_code,
              plant: sku.plant,
              matdesc: sku.material_description,
              markets_concat: sku.MarketsVolumeUnit,
            };
            if (index === -1) materials.push(mappedMaterial);

            return mappedMaterial;
          });

          setImpFeasibility(materials);
          setAllData(response);
        },
        (error) => {},
      )
      .finally(() => setLoading(false));
  };

  const isDisabled = () => {
    const VEAM = form.rfsInformation.rfs_type === 'Article Modification/Value Engineering';

    return (VEAM && !fetched.prod_bus_unit) || (fast_track && !fastTrackPmo);
  };

  const handleNext = (type) => {
    setValidate(true);
    if (isDisabled()) {
      setSubmitProjectModal(true);
      setValidateFeilds(true);
    } else {
      setSubmitLoading({ status: true, action: type });
      if (fetched.id) {
        updateRfsStatus(params.rfsNumber, type);
        updateImpFeasibility(
          {
            prod_bus_unit: fetched.prod_bus_unit,
            tf_status: type,
            rfs_number: params.rfsNumber,
          },
          () =>
            info(params.rfsNumber, false, () => {
              setSubmitLoading({ status: true, action: null });
              navigate(`${routes.project(params.rfsNumber).root}?tab=imp`);
            }),
        );
      } else {
        updateRfsStatus(params.rfsNumber, type);
        createImpFeasibility(
          {
            prod_bus_unit: fetched.prod_bus_unit,
            attachment: attachmentModal.attachments,
            tf_status: type,
            rfs_number: params.rfsNumber,
            first_shipment: moment(firstShipmentPmo).format('YYYY-MM-DD'),
            fast_track: fastTrackPmo,
          },
          () =>
            info(params.rfsNumber, false, () => {
              setSubmitLoading({ status: true, action: null });
              navigate(`${routes.project(params.rfsNumber).root}?tab=imp`);
            }),
        );
      }
    }
  };

  useEffect(() => {
    info(params.rfsNumber);
  }, []);

  useEffect(() => {
    if (rfs_type.length > 0) {
      setFastTrackPmo(fast_track ? 'Yes' : 'No');

      fetchImpFeasibility(params.rfsNumber);
      fetchImpFeasibilityTable(params.rfsNumber);
      attachments(params.rfsNumber);
    }
  }, [rfs_type.length]);

  const attachments = (values) => {
    axios.post(`/implementation/attachment`, { rfs_number: values }).then(
      (res) => {
        const response = res.data;

        setAttachmentModal({
          ...attachmentModal,
          open: false,
          attachments: response.files ? response.files.recordset : [],
        });
      },
      (error) => {},
    );
  };
  const handleSelect = (value) => {
    const currentValues = { ...fetched };
    currentValues['prod_bus_unit'] = value;
    setFetched(currentValues);
  };

  const getMaterials = () => {
    let data = [];
    impFeasibility.map((item) => {
      const index = data.findIndex((val) => val.mat === item.mat);
      if (index === -1) data.push(item);
    });

    return data;
  };

  const Footer = () => (
    <Row>
      <Col md={{ span: 2, offset: 8 }}>
        <Button
          color='red'
          size='sm'
          radius='xs'
          fullWidth
          disabled={submitLoading.status}
          onClick={() => handleNext(props.trials === true ? 'Impl Feas Rejected' : 'rejected')}
        >
          {submitLoading.status && submitLoading.action !== 'Implementation' ? (
            <AppLoader size='sm' />
          ) : (
            'Reject Project'
          )}
        </Button>
      </Col>
      <Col md={{ span: 2 }}>
        <Button
          color='green'
          size='sm'
          radius='xs'
          fullWidth
          disabled={submitLoading.status}
          onClick={() => handleNext('Implementation')}
        >
          {submitLoading.status && submitLoading.action === 'Implementation' ? (
            <AppLoader size='sm' />
          ) : (
            'Approve Project'
          )}
        </Button>
      </Col>
    </Row>
  );

  if (loading || rfs_type.length === 0 || impLoading)
    return (
      <ContentPaper>
        <AppLoader center size='lg' />
      </ContentPaper>
    );

  return (
    <ContentPaper page='imp-feasibility' footer={editable() ? <Footer /> : null}>
      <ProjectName initialItem={0} />
      {error.state && <div className='red-custom-mantine-Textarea-error'>{error.message}</div>}
      <Row>
        <Col
          md={1}
          style={{
            width: '2.0833%',
            flex: '0 0 2.0833%',
            maxWidth: ' 2.0833%',
          }}
        ></Col>
        <Col
          md={{ span: 6 }}
          style={{
            marginTop: '1rem',
          }}
        >
          <h5 className='sectionHeadings'>Implementation Feasibility</h5>
        </Col>
      </Row>
      <Row style={{ marginTop: '2rem' }}>
        <Col
          md={1}
          style={{
            width: '2.0833%',
            flex: '0 0 2.0833%',
            maxWidth: ' 2.0833%',
          }}
        ></Col>
        <Col md={{ span: 5, offset: 0 }}>
          <SimpleGrid cols={2}>
            <Text size='sm' weight={600}>
              Project Name:
            </Text>
            <Text size='sm' weight={400}>
              {project_name}
            </Text>
          </SimpleGrid>
        </Col>

        <Col
          md={1}
          style={{
            width: '2.0833%',
            flex: '0 0 2.0833%',
            maxWidth: ' 2.0833%',
          }}
        ></Col>
        <Col md={{ span: 5, offset: 0 }}>
          <SimpleGrid cols={2}>
            <Text size='sm' weight={600}>
              Project Submission Date:
            </Text>
            <Text size='sm' weight={400}>
              {submission_date}
            </Text>
          </SimpleGrid>
        </Col>
      </Row>
      <Row style={{ marginTop: '1rem' }}>
        <Col
          md={1}
          style={{
            width: '2.0833%',
            flex: '0 0 2.0833%',
            maxWidth: ' 2.0833%',
          }}
        ></Col>
        <Col md={{ span: 5, offset: 0 }}>
          <SimpleGrid cols={2}>
            <Text size='sm' weight={600}>
              First Shipment Date:
            </Text>
            <Text size='sm' weight={400}>
              {first_shipment}
            </Text>
          </SimpleGrid>
        </Col>

        <Col
          md={1}
          style={{
            width: '2.0833%',
            flex: '0 0 2.0833%',
            maxWidth: ' 2.0833%',
          }}
        ></Col>
        <Col md={{ span: 5, offset: 0 }}>
          <SimpleGrid cols={2}>
            {isDisabled() && rfs_type === 'Article Modification/Value Engineering' ? (
              <Text size='sm' weight={600} color='red'>
                Production Business Unit*:
              </Text>
            ) : (
              <Text size='sm' weight={600}>
                Production Business Unit:
              </Text>
            )}
            <Select
              disabled={!editable() || rfs_type === 'Open SKU to market'}
              required
              // error={validate && !fetched.prod_bus_unit}
              className='impfeasibility-input'
              searchable
              clearable={editable()}
              nothingFound='No such Production Business Unit'
              radius='md'
              onChange={(value) => handleSelect(value)}
              data={[
                'BE',
                'NL',
                'FR',
                'LU',
                'DE',
                'UK',
                'IT',
                'ES',
                'RUK',
                'Expansion South',
                'Expansion North',
                'EXTREME',
              ]}
              name='prodBusinessUnit'
              value={fetched?.prod_bus_unit}
            />
          </SimpleGrid>
        </Col>
      </Row>

      {(Object.keys(impFeasibility).length > 0 ||
        rfs_type === 'Open SKU to market' ||
        rfs_type === 'Production Site') && (
        <>
          <Row>
            <Col
              md={1}
              style={{
                width: '2.0833%',
                flex: '0 0 2.0833%',
                maxWidth: ' 2.0833%',
              }}
            ></Col>

            <Col md={{ span: 5, offset: 0 }}>
              <SimpleGrid cols={2} style={{ marginTop: '0.5rem' }}>
                <Text size='sm' weight={600}>
                  Obsoletes Calculation:
                </Text>
                <Button
                  color='dark'
                  onClick={() => setModal(true)}
                  disabled={rfs_type === 'Open SKU to market' || rfs_type === 'Production Site'}
                >
                  Obsoletes Calculation
                </Button>
              </SimpleGrid>
            </Col>

            <Col
              md={1}
              style={{
                width: '2.0833%',
                flex: '0 0 2.0833%',
                maxWidth: ' 2.0833%',
              }}
            ></Col>

            <Col md={{ span: 5, offset: 0 }}>
              <SimpleGrid cols={2} style={{ marginTop: '0.5rem' }}>
                <Text size='sm' weight={600}>
                  Obsoletes Attachment:
                </Text>
                <Button
                  color='dark'
                  onClick={() =>
                    setAttachmentModal({
                      ...attachmentModal,
                      open: true,
                    })
                  }
                  disabled={rfs_type === 'Open SKU to market'}
                >
                  Obsoletes Attachment
                </Button>
              </SimpleGrid>
            </Col>
          </Row>

          {(fast_track && trials_needed == 'No') ||
          (rfs_type === 'Open SKU to market' && fast_track) ? (
            <Row style={{ marginTop: '1rem' }}>
              <Col
                md={1}
                style={{
                  width: '2.0833%',
                  flex: '0 0 2.0833%',
                  maxWidth: ' 2.0833%',
                }}
              ></Col>
              <Col md={{ span: 5, offset: 0 }}>
                <SimpleGrid cols={2}>
                  {isDisabled() ? (
                    <Text size='sm' weight={600} color='red'>
                      Fast Track:
                    </Text>
                  ) : (
                    <Text size='sm' weight={600}>
                      Fast Track:
                    </Text>
                  )}
                  <Select
                    disabled={!editable() || (rfs_type === 'Open SKU to market' && !fast_track)}
                    error={isDisabled()}
                    required
                    radius='md'
                    data={[
                      { value: 'Yes', label: 'Yes' },
                      { value: 'No', label: 'No' },
                    ]}
                    name='fastTrackPmo'
                    value={fastTrackPmo}
                    onChange={(value) => setFastTrackPmo(value)}
                  />
                </SimpleGrid>
              </Col>
              <Col
                md={1}
                style={{
                  width: '2.0833%',
                  flex: '0 0 2.0833%',
                  maxWidth: ' 2.0833%',
                }}
              ></Col>
              {fastTrackPmo === 'Yes' ? null : (
                <Col md={{ span: 5, offset: 0 }}>
                  <SimpleGrid cols={2}>
                    <Text size='sm' weight={600}>
                      First Shipment Date:
                    </Text>
                    <FastTrackDatePicker
                      disabled={!editable() || (rfs_type === 'Open SKU to market' && !fast_track)}
                      value={firstShipmentPmo || new Date()}
                      onChange={(e) => {
                        setFirstShipmentPmo(e);
                      }}
                      placeholder='Pick date'
                      name='firstShipmentPmo'
                      fast_track={fast_track}
                      fastTrackPmo={fastTrackPmo === 'Yes'}
                    />
                  </SimpleGrid>
                </Col>
              )}
            </Row>
          ) : null}
        </>
      )}

      <Row style={{ marginTop: '0.5rem' }}>
        {Object.keys(impFeasibility)?.map((k) => (
          <Accordion defaultValue='implfeas'>
            <Accordion.Item className='imp-feas-accordion' value='implfeas'>
              <Accordion.Control>{`${impFeasibility[k].mat} - ${impFeasibility[k].matdesc} - ${impFeasibility[k].plant}`}</Accordion.Control>
              <Accordion.Panel>
                <ImpFeasTable
                  data={allData.filter(
                    (item) =>
                      item.mat === impFeasibility[k].mat && item.plant === impFeasibility[k].plant,
                  )}
                />
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        ))}
      </Row>
      {modal && (
        <ImpFeasiblityModal
          opened={modal}
          setOpened={setModal}
          fsd={first_shipment}
          material={impFeasibility[0]?.mat}
          materials={getMaterials()}
        />
      )}

      {attachmentModal.open && (
        <AttachmentsModal
          modal={attachmentModal}
          setModal={setAttachmentModal}
          onChange={handleFileSelect}
          onSubmit={handleSubmit}
          onRemove={handleFileRemove}
          readOnly={!editable()}
        />
      )}
      {submitProjectModal && (
        <SubmitProjectModal opened={submitProjectModal} setOpened={setSubmitProjectModal} />
      )}
    </ContentPaper>
  );
}

const FastTrackDatePicker = ({
  value,
  disabled,
  fast_track,
  onChange,
  placeholder,
  name,
  fastTrackPmo,
}) => {
  const [initialMonth, setIntialMonth] = useState(moment().add('days', 1).toDate());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      if (fastTrackPmo) {
        setIntialMonth(moment().add('days', 1).toDate());
      } else {
        setIntialMonth(moment().add('days', 77).toDate());
      }

      setLoading(false);
    }, [50]);
  }, [fastTrackPmo]);

  if (loading)
    return (
      <TextInput
        icon={<CalendarIcon color='#e5b611' />}
        required
        radius='md'
        placeholder={fast_track ? '' : 'Select dates after 11 weeks from today.'}
      />
    );

  return (
    <DatePickerInput
      required
      placeholder={fast_track ? '' : 'Select dates after 11 weeks from today.'}
      allowFreeInput
      amountOfMonths={2}
      minDate={initialMonth}
      initialMonth={initialMonth}
      radius='md'
      icon={<CalendarIcon color='#e5b611' />}
      disabled={disabled}
      value={value}
      onChange={(value) => onChange(value)}
    />
  );
};
