import React, { useState, useContext, useEffect } from 'react';
import { Box, Accordion, Grid, Button } from '@mantine/core';
import ContentPaper from '../../../../components/layouts/rfs/Content';
import { Row } from 'react-bootstrap';
import { RfsContext } from '../../../../contexts/RfsContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AppLoader } from '../../../../components/common/loader';
import { MaterialPricingTable } from './materialPricingTable';
import { TableExport } from 'tabler-icons-react';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { roles } from '../../../../constants';
import { AuthContext } from '../../../../contexts/AuthContext';
import { sCodeStyles } from '../../../../utils/helpers';

export default function MaterialPricing() {
  const { userRole } = useContext(AuthContext);
  const { form } = useContext(RfsContext);
  const { rfs_number } = form.rfsInformation;
  const accessors = {
    materials: 'selection_number',
    liquids: 'selection_number',
    skus: 'selection_number',
  };
  const secondaryAccessor = {
    materials: 'selection_name',
    liquids: 'selection_name',
    skus: 'selection_name',
  };
  const tertiaryAccessor = {
    materials: null,
    liquids: null,
    skus: null,
  };
  const [scopeErrors, setScopeErrors] = useState({
    errors: {},
    liquidErrors: {},
    materialErrors: {},
    productionSiteErrors: {},
  });

  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(true);
  const [materialPricing, setMaterialPricing] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [price, setPrice] = useState([]);

  const { fetchMaterialPricing, submitMaterialPricing, fetchCurrencyAndPrice, StoreManualClosure } =
    useContext(RfsContext);

  useEffect(() => {
    getData(rfs_number);
  }, []);

  const getData = (rfs_number) => {
    setLoading(true);
    fetchCurrencyAndPrice().then((res) => {
      setCurrency(res.data['currency']);
      setPrice(res.data['price_unit']);
    });

    fetchMaterialPricing(rfs_number).then((res) => {
      setMaterialPricing(res.data.data);
      setLoading(false);
    });
  };
  useEffect(() => {
    if ([roles.admin, roles.superAdmin, roles.pmo, roles.procurement].includes(userRole))
      setDisable(false);
  }, [userRole]);

  const save = (payload, id, callback) => {
    StoreManualClosure(payload, id).then(() => {
      refresh(rfs_number);
      callback();
    });
  };

  const handleAltNumberChange = (id, name, value) => {
    let currentValues = [...materialPricing];
    const index = currentValues.findIndex((i) => i.id === id);
    currentValues[index]['int_rfs_impl_supplier'][name] = value;
    setMaterialPricing(currentValues);
  };

  const refresh = (rfs_number) => {
    fetchMaterialPricing(rfs_number).then((res) => {
      setMaterialPricing(res.data.data);
    });
  };

  const sort_SN_acctoSKUs = [...materialPricing].sort((a, b) => a.scope_id - b.scope_id);

  const selectedScopeFn = (data) => {
    let selectedScopeList = {};
    for (let i = 0; i < data.length; i++) {
      selectedScopeList[data[i].id] = {
        selection_number: data[i].selection_number,
        selection_name: data[i].selection_name,
      };
    }
    return selectedScopeList;
  };

  var selectedScopeList = selectedScopeFn(form.selectedScope.skus);

  const dataToExport = ({
    mat_code,
    mat_desc,
    int_rfs_ps_bom,
    int_rfs_impl_supplier,
    status,
    sla_date,
    scope_id,
    finished_date,
    int_rfs_ps_sku_reference,
    int_rfs_impl_material_price,
    manually_closed,
  }) => {
    const recalculatedPrice = int_rfs_impl_material_price[0]?.recalculated_price;

    const recalculatedUnit = int_rfs_impl_material_price[0]?.recalculated_price_units;

    const recalculatedCurrency = int_rfs_impl_material_price[0]?.recalculated_price_currency;

    return {
      'SKU no.': selectedScopeList[scope_id]?.selection_number,
      'SKU DESC': selectedScopeList[scope_id]?.selection_name,
      Plant: int_rfs_ps_sku_reference?.selected_plant,
      'Pack Mat Code': int_rfs_ps_bom?.bom_component,
      'Pack Mat Description': int_rfs_ps_bom?.component_description,
      'Material Group': int_rfs_ps_bom.id,
      Price: recalculatedPrice,
      Unit: recalculatedUnit,
      currency: recalculatedCurrency,
      Action:
        status === 'Closed' ? (manually_closed ? 'Manually Closed' : 'Automatic Closure') : 'NA',
      Status: status,
      'Step deadline': sla_date,
      'Step finish': status === 'Closed' ? finished_date : status,
    };
  };

  const csvArray = sort_SN_acctoSKUs.map(dataToExport);

  if (loading) {
    return (
      <ContentPaper page={'ProductDevelopment'}>
        <AppLoader size='lg' center />
      </ContentPaper>
    );
  }

  return (
    <ContentPaper page={'ProductDevelopment'}>
      <Box sx={sCodeStyles}>SKU CODES</Box>
      <div
        style={{
          display: 'flex',
          justifyContent: 'right',
          marginRight: '4rem',
        }}
      >
        <CSVLink
          data={csvArray}
          filename={'Material Pricing_' + moment().format('YYYY-MM-DD HH:mm:ss')}
        >
          <Button
            leftIcon={<TableExport />}
            color='yellow'
            style={{
              marginBottom: '0rem',
              marginTop: '2rem',
              background: 'black',
            }}
          >
            Export to CSV
          </Button>
        </CSVLink>
      </div>
      <Row>
        <Accordion>
          {Object.keys(form?.selectedScope || {}).map((name) =>
            form?.selectedScope[name].map((item, index) => {
              const supplierList = materialPricing?.findIndex((m) => m.scope_id === item.id);
              const plants = Object.values(form.scopeSelectedSkus);
              const uniquePlants = {};
              plants.forEach((p) => {
                if (p.scope_id === item.id && p.active) {
                  if (uniquePlants[p.selected_plant]) {
                    uniquePlants[p.selected_plant].plantIds.push(p.id);
                  } else {
                    uniquePlants[p.selected_plant] = { plantIds: [p.id] };
                  }
                }
              });

              return (
                supplierList > -1 && (
                  <Accordion.Item
                    className='scope-label'
                    key={`key_${name}_${index}`}
                    value={`key_${name}_${index}`}
                  >
                    <Accordion.Control className={scopeErrors.errors[item.id] ? 'error-label' : ''}>
                      {`${name.substring(0, 3).toUpperCase()} -  ${item[accessors[name]]}- ${
                        item[secondaryAccessor[name]]
                      }${tertiaryAccessor[name] ? `- ${item[tertiaryAccessor[name]]}` : ''}`}
                    </Accordion.Control>
                    <Accordion.Panel>
                      {Object.keys(uniquePlants).map((plant, index) => {
                        const data = materialPricing.filter((d) =>
                          uniquePlants[plant].plantIds.includes(d.int_rfs_ps_sku_reference.id),
                        );

                        return (
                          data.length > 0 && (
                            <Accordion initialItem={0}>
                              <Accordion.Item
                                className='scope-label'
                                key={`key_${index}`}
                                value={`key_${index}`}
                              >
                                <Accordion.Control
                                  className={scopeErrors.errors[item.id] ? 'error-label' : ''}
                                >{`Plant ${plant}`}</Accordion.Control>
                                <Accordion.Panel>
                                  {
                                    <Grid>
                                      <Grid.Col span={12}>
                                        <MaterialPricingTable
                                          materialData={data}
                                          onChange={handleAltNumberChange}
                                          submit={submitMaterialPricing}
                                          refresh={refresh}
                                          currency={currency}
                                          disable={disable}
                                        />
                                      </Grid.Col>
                                    </Grid>
                                  }
                                </Accordion.Panel>
                              </Accordion.Item>
                            </Accordion>
                          )
                        );
                      })}
                    </Accordion.Panel>
                  </Accordion.Item>
                )
              );
            }),
          )}
        </Accordion>
      </Row>
    </ContentPaper>
  );
}
