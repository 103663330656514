import React, { useEffect, useState } from 'react';
import { Text, Group } from '@mantine/core';
import { Clock, CircleCheck, CircleX } from 'tabler-icons-react';
import { axios } from '../../utils/axios';
import { Link, useParams } from 'react-router-dom';
import { AppLoader } from '../common/loader';

const prodDevArray = [
  'Material Code Creation',
  'Production Plant Extension',
  'Status Check',
  'Supplier Name',
  'Changeover',
  'Artwork Development',
  'EAN Approval',
  'Material Contracts',
  'Trials Conclusion',
];

const implArray = [
  'SKU Planning',
  'Material Pricing',
  'Pack Mats Production',
  'Standard pricing',
  'Status 99',
  'Filings/Shipment',
  'Material Status Change',
];

// Mapping of pName to team responsible
const teamResponsibleMapping = {
  'Artwork Development': 'Design To Print',
  'EAN Approval': 'Material Release Planning',
  'Filings/Shipment': 'Scheduling',
  Implementation: 'NA',
  'Material Code Creation': 'Supply Master Data',
  'Material Contracts': 'Procurement',
  'Material Pricing': 'Procurement',
  'Material Status Change': 'Material Release Planning',
  'Pack Mats Production': 'Procurement',
  'Production Plant Extension': 'Supply Master Data',
  'SKU Planning': 'Supply Network Planning',
  'Standard pricing': 'GCC FP&A',
  'Status 99': 'Supply Master Data',
  'Supplier Name': 'Procurement',
  'Implementation Feasibility ': 'PMO',
  'Material on Stock at ABI': 'Procurement',
  'Material on Stock at Supplier': 'Procurement',
  'Supplier Production': 'Procurement',
  'Product Development': 'NA',
  'Trials Conclusion': 'Zitec',
  'Project Submission': 'Initiator',
  'BOM Availability': 'GCC FP&A',
  'Cost Collector': 'GCC FP&A',
  'Material At Status 99': 'GCC FP&A',
  'Procurement Price': 'GCC FP&A',
  'Production Version': 'GCC FP&A',
  'Repacking Fee': 'GCC FP&A',
  'Technical Feasibility': 'PMO',
};

const stepMapping = {
  'Material Code Creation': 'mcc',
  'Production Plant Extension': 'ppe',
  'Supplier Name': 'sn',
  'Artwork Development': 'ad',
  'EAN Approval': 'ean',
  'Material Contracts': 'mc',
  'Trials Conclusion': 'trls',
  'SKU Planning': 'sku',
  'Material Pricing': 'mp',
  'Pack Mats Production': 'pmp',
  'Standard pricing': 'sp',
  'Status 99': 's99',
  'Filings/Shipment': 'fs',
  'Material Status Change': 'msc',
  'Technical Feasibility': 'tf',
  'Project Submission': 'projectOverview',
  'Implementation Feasibility': 'if',
};
const PSWorkflow = () => {
  const params = useParams();
  const [steps, setSteps] = useState([]);
  const [trials, setTrials] = useState('');
  const [stepsPnameProdDevArr, setStepsPnameProdDevArr] = useState([]);
  const [stepsPnameImplArr, setStepsPnameImplArr] = useState([]);
  const urlParams = new URLSearchParams(window.location.search);
  const [firstStep, setFirstStep] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    function fetchSteps() {
      axios
        .get(`/production-site/gantt/${params.rfsNumber}`)
        .then(
          (res) => {
            const info = res.data.steps.filter(
              (step) => !['Implementation', 'Product Development'].includes(step.pName),
            );
            const implementation = res.data.steps.findIndex(
              (data) => data.pName === 'Implementation',
            );
            const prodDev = res.data.steps.findIndex(
              (data) => data.pName === 'Product Development',
            );

            setFirstStep({
              prodDev: res.data.steps[prodDev + 1]?.pName,
              implementation: res.data.steps[implementation + 1]?.pName,
            });
            setSteps(info);
          },
          (error) => {},
        )
        .finally(() => setLoading(false));

      axios.get(`/tech-feasibility/fetch/${params.rfsNumber}`).then(
        (res) => {
          setTrials(res.data?.fetchedData?.are_trials_actually_needed);
        },
        (error) => {},
      );
    }

    fetchSteps();
  }, [params.rfsNumber]);

  useEffect(() => {
    let prodDevStepArray = steps
      .filter((item) => prodDevArray.includes(item.pName))
      .map((item) => item.pName);

    setStepsPnameProdDevArr(prodDevStepArray);

    let implStepArray = steps
      .filter((item) => implArray.includes(item.pName))
      .map((item) => item.pName);

    setStepsPnameImplArr(implStepArray);
  }, [steps, trials]);

  const tabURL = (name) => {
    let Index = trials === 'Yes' ? (implArray.includes(name) ? 'imp' : 'pd') : 'imp',
      subIndex = stepMapping[name];

    //if (trials === 'Yes') {
    // Index = implArray.includes(name) ? 4 : 2; }

    //subIndex = prodDevArray.includes(name)
    // ? stepsPnameProdDevArr.findIndex((elem) => elem === name)
    // : stepsPnameImplArr.findIndex((elem) => elem === name);

    return '?tab=' + Index + '&sub=' + subIndex;
  };

  const goToLink = (name, pID) => {
    let tab = 1;
    switch (name) {
      case 'Project Submission':
        tab = '?tab=projectOverview';
        break;
      case 'Technical Feasibility':
        tab = '?tab=tf';
        break;
      case 'Implementation Feasibility ':
        tab = '?tab=if';
        break;
      default:
        tab = tabURL(name);
    }
    return `/project/overview/${params.rfsNumber}${tab}`;
  };

  return (
    <div className='workflow-tab'>
      {loading && <AppLoader center size='md' />}
      {!loading && steps.length === 0 && (
        <Text ta='center' size='md' color='dimmed' weight={400} fontWeight={'bold'}>
          No steps to display
        </Text>
      )}
      {steps.map((step, index) => {
        const teamResponsible = teamResponsibleMapping[step.pName] || '';
        const isActive = urlParams.get('sub')
          ? stepMapping[step.pName.trim()] === urlParams.get('sub')
          : ['pd', 'imp'].includes(urlParams.get('tab'))
          ? urlParams.get('tab') === 'pd'
            ? firstStep.prodDev === step.pName
            : firstStep.implementation === step.pName
          : stepMapping[step.pName.trim()] === urlParams.get('tab');

        return (
          <Group
            key={step.pID}
            noWrap
            style={{ padding: '0.5rem', borderLeft: isActive ? '8px solid #f5e003' : 'none' }}
            className={isActive ? 'TabActive' : ''}
          >
            {step.status === 'Closed' ? (
              <CircleCheck color='#90EE90' size={18} />
            ) : step.status === 'Not Started' ? (
              <CircleX color='grey' size={18} />
            ) : step.status === 'In Progress' ? (
              <Clock color='orange' size={18} />
            ) : null}
            <div>
              <Link
                to={goToLink(step.pName, step.pID)}
                onClick={() => localStorage.setItem('pId', step.pID)}
              >
                <Text
                  color={isActive ? '#f5e003' : '#FFFFFF'}
                  fontWeight={isActive ? 'bold' : 'normal'}
                  size={isActive ? 'md' : 'sm'}
                >
                  {step.pName}
                </Text>
              </Link>

              <Text size='sm' color='dimmed' weight={400}>
                Team: {teamResponsible}
              </Text>
              <Text size='sm' color='dimmed' weight={400}>
                Status: {step.status}
              </Text>
            </div>
          </Group>
        );
      })}
    </div>
  );
};

export default PSWorkflow;
