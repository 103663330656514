import React, { useMemo, useState } from 'react';
import { useTable } from 'react-table';
import styled from 'styled-components';
import { Checkbox, Button } from '@mantine/core';
import { PsBomModal } from './Modal';

const Styles = styled.div`
  padding: 1rem;
  table {
    border-spacing: 0;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 10rem;
      padding: 0.8rem;
      text-align: center;
      width: 22rem;

      :last-child {
        border-right: 0;
      }
    }

    #header-row {
      background-color: #d8d8d8;
    }
    tr:nth-child(even) {
      background-color: #cdcdcd;
    }
    tr:nth-child(odd) {
      background-color: #fff;
    }
  }
  input {
    background-color: #fff;
  }
`;

export function PsSkuTable({ form, values, setValues, disabled, unlockData, viewData }) {
  const [bomModal, setBomModal] = useState({
    state: false,
    type: 'skuBom',
    sku_id: 0,
  });

  const handleSelect = (event) => {
    let currentSkus = [...values.sku_ids];
    const currentScopes = [...values.scope_ids];
    if (event.target.checked) {
      currentScopes.push(event.target.name);
      unlockData.forEach((element) => {
        if (parseInt(element.id) === parseInt(event.target.name)) {
          if (typeof element.sku_id === 'object') {
            currentSkus = currentSkus.concat(element.sku_id);
          } else {
            currentSkus.push(element.sku_id);
          }
        }
      });
    } else {
      const index = currentScopes.findIndex((i) => i === event.target.name);
      if (index > -1) currentScopes.splice(index, 1);

      const skuIndex = unlockData.findIndex(
        (element) => parseInt(element.id) === parseInt(event.target.name),
      );

      if (skuIndex > -1) {
        const skuIds = unlockData[skuIndex].sku_id;
        for (let item of skuIds) {
          const i = currentSkus.findIndex((s) => s === item);
          if (i > -1) currentSkus.splice(i, 1);
        }
      }
    }

    setValues({ scope_ids: currentScopes, sku_ids: currentSkus }, values);
  };

  const columns = useMemo(
    () => [
      {
        Header: '',
        id: 'checkbox',
        width: '50',
        Cell: ({ row }) => {
          return (
            <div>
              <Checkbox
                name={row.original.id}
                onChange={handleSelect}
                checked={(values?.scope_ids?.length > 0
                  ? values.scope_ids
                  : values?.skus?.length > 0
                  ? values.skus
                  : []
                ).includes(row.original.id.toString())}
                disabled={disabled || row.original.status === 'invalid'}
              />
            </div>
          );
        },
      },
      {
        Header: 'SKU Code',
        accessor: 'selection_number',
      },
      {
        Header: 'SKU Description',
        accessor: 'selection_name',
      },
      {
        Header: 'Plant',
        accessor: 'selected_plant',
      },
      {
        Header: 'Scope Selection',
        accessor: 'type',
        Cell: ({ row }) => (
          <Button
            variant='outline'
            color='dark'
            radius='xl'
            onClick={() =>
              setBomModal({
                state: true,
                type: 'skuBom',
                sku_id: row.original.sku_id,
              })
            }
          >
            View
          </Button>
        ),
      },
    ],
    [values],
  );

  const tableInstance = useTable({
    columns,
    data: unlockData || [],
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <Styles>
      <div style={{ overflowX: 'auto' }}>
        <table style={{ width: 'fitContent' }} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} id='header-row'>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {bomModal.state && (
        <PsBomModal
          bomModal={bomModal}
          setBomModal={setBomModal}
          data={viewData}
          unlockData={unlockData}
        />
      )}
    </Styles>
  );
}
