import { showNotification } from '@mantine/notifications';

const SOMETHING_WENT_WRONG = 'Something went wrong. Please try again';

export const showAlert = (data, type = 'success') => {
  showNotification({
    title: type === 'error' ? 'Error' : type === 'success' ? 'Success' : '',
    message: data?.message ? data?.message : type === 'error' ? SOMETHING_WENT_WRONG : '',
    color: type === 'error' ? 'red' : 'teal',
    styles: () => ({
      title: { color: type === 'error' ? 'red' : 'teal' },
      description: { color: type === 'error' ? 'red' : 'teal' },
      closeButton: {
        color: type === 'error' ? 'red' : 'teal',
      },
    }),
  });
};
