import * as yup from 'yup';
import { materialchangeOverOptions } from '../../../constants';

const supplierFormSchema = yup.object().shape({
  artwork_required: yup.string().nullable().required('artwork_required'),
  artwork_adaption: yup
    .string()
    .nullable()
    .when('artwork_required', ([artwork_required]) => {
      if (artwork_required === 'Yes') return yup.string().nullable().required('artwork_adaption');
    }),
  artwork_change: yup
    .string()
    .nullable()
    .when(['artwork_adaption'], ([artwork_adaption]) => {
      if (artwork_adaption === 'Yes') return yup.string().nullable().required('artwork_change');
    }),
  changeover_date: yup
    .string()
    .nullable()
    .when(['changeover_type'], ([changeover_type]) => {
      if (
        [
          materialchangeOverOptions.hardChange,
          materialchangeOverOptions.notLaterThan,
          materialchangeOverOptions.notSoonerThan,
        ].includes(changeover_type)
      )
        return yup.string().nullable().required('changeover_date');
    }),
  comment: yup
    .string()
    .nullable()
    .when(['artwork_adaption', 'artwork_change'], ([artwork_adaption, artwork_change]) => {
      if (artwork_adaption === 'Yes' || artwork_change === 'Others')
        return yup.string().nullable().required('comment');
    }),
  to_be_supplier: yup.array().nullable().min(1, 'to_be_supplier'),
});

export default supplierFormSchema;
