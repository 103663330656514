import React, { useState, useMemo, useEffect, useContext, useRef } from 'react';
import { Checkbox, Select, TextInput, MultiSelect, Text } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { CalendarIcon } from '@modulz/radix-icons';
import { RfsContext } from '../../../contexts/RfsContext';
import moment from 'moment';
import { AddLiquid } from './Add';
import {
  additionLiquid,
  liquidBomChanges,
  liquidTypesMapping,
  materialchangeOverOptions,
} from '../../../constants';
import withConfirm from '../../common/confirm-dialog';
import { useParams } from 'react-router-dom';
import { CommonContext } from '../../../contexts/common';
import { Table } from '../../../common/table/VeamScopeTable';
import { removeSpecialCharacters } from '../../../utils/helpers';

const changeOverTypeOptions = [
  materialchangeOverOptions.hardChange,
  materialchangeOverOptions.softChange,
  materialchangeOverOptions.mixChange,
  materialchangeOverOptions.notSoonerThan,
  materialchangeOverOptions.notLaterThan,
];

const changeOverCellOptions = [
  materialchangeOverOptions.hardChange,
  materialchangeOverOptions.softChange,
  materialchangeOverOptions.notSoonerThan,
  materialchangeOverOptions.notLaterThan,
];

const additionOfLiquidOptions = [
  { value: additionLiquid.addition, label: additionLiquid.addition },
];

const LiquidTable = ({
  index,
  scope,
  scopeLiquids,
  isSubmissionReview,
  errors = {},
  setFirstShipment,
  liquids = [],
  confirm,
  liquidsLabel,
}) => {
  const params = useParams();
  const { units } = useContext(CommonContext);
  const { setForm, form } = useContext(RfsContext);
  const [materials, setMaterials] = useState([]);
  const [liquidsSearch, setLiquidsSearch] = useState({ str: '', liquid: '' });
  const [selections, setSelections] = useState({});
  const [showOnlySelected, setOnlyShowSelected] = useState(false);

  //modal
  const [addLiquidModal, setAddLiquidModal] = useState(false);

  const ref = useRef(null);
  const search = useRef(null);
  const selectionRef = useRef(null);
  const liquidsRef = useRef(null);
  const liquidsLabelRef = useRef(null);
  const formRef = useRef(null);

  ref.current = materials;
  search.current = liquidsSearch;
  selectionRef.current = selections;
  liquidsRef.current = liquids;
  liquidsLabelRef.current = liquidsLabel;
  formRef.current = form;

  useEffect(() => {
    if (form.scopeSelectedLiquids.length > 0 && materials.length === 0) {
      const data = JSON.parse(JSON.stringify(form.scopeSelectedLiquids));
      let liquids = data.filter((val) => parseInt(val.scope_id) === scope.id);
      setMaterials(liquids);
    }
  }, [form.scopeSelectedLiquids]);

  const handleAddLiquidClose = () => {
    setAddLiquidModal(false);
    setLiquidsSearch('');
    search.current = { str: '', liquid: '' };
  };

  const handleAdd = (formData) => {
    const mixChangeover = form.scopeSelectedLiquids.find(
      (l) => l.active && l.changeover_type === 'Mix Change',
    );
    const newLiquid = {
      ...formData,
      scope_id: scope.id,
      id: `temp_${form.scopeSelectedLiquids.length - 1}`,
      changeover_type: mixChangeover ? 'Mix Change' : null,
    };

    let currentValues = [...ref.current];
    currentValues.push(newLiquid);
    setMaterials(currentValues);

    let values = { ...form };
    values.scopeSelectedLiquids.push(newLiquid);
    setForm(values);

    handleAddLiquidClose();
  };

  const isDisabled = (data) => {
    return data && data.includes(liquidBomChanges.removal);
  };

  const isLiquidListDisabled = (data) => {
    return (
      !data ||
      isSubmissionReview ||
      (data.includes(liquidBomChanges.drawing) && !data.includes(liquidBomChanges.thickness)) ||
      data.includes(liquidBomChanges.removal)
    );
  };

  const isQuantityDisabled = (data = [], row) => {
    const type = row.liquid_number ? row.liquid_number : row.liquid_type;
    const checkOne = type === 'New Liquid' && data && data.includes(liquidBomChanges.thickness);
    const checkTwo = data && data.includes(liquidBomChanges.drawing);
    const checkThree = data && data.includes(additionLiquid.addition);

    if (isSubmissionReview) return true;

    if (checkOne || checkTwo || checkThree) return false;

    return true;
  };

  const isUnitDisabled = (data) => {
    return !data || data.includes(liquidBomChanges.removal) || isSubmissionReview;
  };

  const handleChange = (event, row, flag = false) => {
    if (flag) {
      event.target.value = event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
      if (/^(?:0*)$/.test(event.target.value)) event.target.value = '';
    }

    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : (event?.target?.value || '').trim().length === 0
        ? event.target.value.trim()
        : event.target.value;

    let currentValues = [...ref.current];
    const rowIndex = currentValues.findIndex((val) => val.id === row.original.id);
    if (event.target.type === 'checkbox') {
      let mixChangeover = null;
      if (event.target.checked)
        mixChangeover = currentValues.find((l) => l.active && l.changeover_type === 'Mix Change');

      currentValues[rowIndex]['active'] = event.target.checked;
      currentValues[rowIndex]['bom_changes'] = null;
      currentValues[rowIndex]['liquid_number'] = null;
      currentValues[rowIndex]['liquid_type'] = null;
      currentValues[rowIndex]['unit'] = null;
      currentValues[rowIndex]['new_quantity'] = null;
      currentValues[rowIndex]['changeover_type'] = mixChangeover ? 'Mix Change' : null;
      currentValues[rowIndex]['changeover_date'] = null;
      currentValues[rowIndex]['comment'] = null;
      currentValues[rowIndex]['tech_specification'] = null;
    } else {
      if (event.target.name === 'tech_specification') {
        currentValues[rowIndex][event.target.name] = removeSpecialCharacters(value);
      } else {
        currentValues[rowIndex][event.target.name] = value;
      }
    }
    // setMaterials(currentValues);

    let values = { ...formRef.current };
    const index = values.scopeSelectedLiquids.findIndex((val) => val.id === row.original.id);
    if (index > -1) {
      if (event.target.type === 'checkbox') {
        values.scopeSelectedLiquids[index]['active'] = event.target.checked;
      } else {
        if (
          event.target.name === 'changeover_type' &&
          event.target.value === changeOverTypeOptions.softChange
        )
          values.scopeSelectedLiquids[index]['changeover_date'] = null;

        if (event.target.name === 'tech_specification') {
          values.scopeSelectedLiquids[index][event.target.name] = removeSpecialCharacters(value);
        } else {
          values.scopeSelectedLiquids[index][event.target.name] = value;
        }
      }
    }

    setForm(values);
  };

  const handleSelectChange = (value, row, name) => {
    let currentValues = [...ref.current];
    const rowIndex = currentValues.findIndex((val) => val.id === row.original.id);
    const val = currentValues[rowIndex];
    const type = name;
    currentValues[rowIndex][name] =
      name === 'changeover_date'
        ? moment(value).isValid()
          ? moment(value).format('YYYY-MM-DD')
          : null
        : value;

    if (type === 'liquid_number' && val.active) {
      const isLiquidDisabled = isLiquidListDisabled(val.bom_changes);
      currentValues[rowIndex]['liquid_number'] = isLiquidDisabled ? null : value;
      if (value !== 'New Liquid') {
        currentValues[rowIndex]['unit'] = isLiquidDisabled
          ? null
          : liquidsLabelRef.current[value]?.unit || null;
        currentValues[rowIndex]['tech_specification'] = null;
      } else {
        currentValues[rowIndex]['unit'] = null;
      }
    }

    if (type === 'new_quantity' && val.active)
      currentValues[rowIndex]['new_quantity'] = isQuantityDisabled(val.bom_changes, val)
        ? null
        : value;

    const liquidType = val.liquid_number ? val.liquid_number : val.liquid_type;
    if (type === 'unit' && val.active) {
      currentValues[rowIndex]['unit'] = isUnitDisabled(val.bom_changes)
        ? null
        : liquidType === 'New Liquid'
        ? value
        : val.unit;
    }

    if (type === 'bom_changes' && isLiquidListDisabled(val.bom_changes)) {
      currentValues[rowIndex]['liquid_number'] = null;
      currentValues[rowIndex]['unit'] = null;
      currentValues[rowIndex]['tech_specification'] = null;
    }

    if (
      ((val.bom_changes || []).includes(liquidBomChanges.thickness) &&
        val.liquid_type &&
        val.liquid_type !== 'New Liquid' &&
        !currentValues[rowIndex]['new_quantity']) ||
      (val.bom_changes || []).includes(liquidBomChanges.removal) ||
      val.bom_changes === liquidBomChanges.drawing ||
      val.bom_changes === liquidBomChanges.thickness
    ) {
      currentValues[rowIndex]['new_quantity'] = null;
    }

    if (
      val.active &&
      type === 'changeover_type' &&
      [materialchangeOverOptions.softChange, materialchangeOverOptions.mixChange].includes(value)
    )
      currentValues[rowIndex]['changeover_date'] = null;

    if (type === 'changeover_date' && val.active) {
      const isDateDisabled = [
        materialchangeOverOptions.softChange,
        materialchangeOverOptions.mixChange,
      ].includes(val.changeover_type);
      currentValues[rowIndex]['changeover_date'] = isDateDisabled ? null : value;
    }

    let values = { ...formRef.current };
    const index = values.scopeSelectedLiquids.findIndex((val) => val.id === row.original.id);

    if (index > -1) {
      values.scopeSelectedLiquids[index][name] =
        name === 'changeover_date'
          ? moment(value).isValid()
            ? moment(value).format('YYYY-MM-DD')
            : null
          : value;

      if (type === 'liquid_number' && val.active) {
        const isLiquidDisabled = isLiquidListDisabled(val.bom_changes);
        values.scopeSelectedLiquids[index]['liquid_number'] = isLiquidDisabled ? null : value;
        if (value !== 'New Liquid') {
          values.scopeSelectedLiquids[index]['unit'] = isLiquidDisabled
            ? null
            : liquidsLabelRef.current[value]?.unit || null;
          values.scopeSelectedLiquids[index]['tech_specification'] = null;
        } else {
          values.scopeSelectedLiquids[index]['unit'] = null;
        }
      }

      if (type === 'new_quantity' && val.active)
        values.scopeSelectedLiquids[index]['new_quantity'] = isQuantityDisabled(
          val.bom_changes,
          val,
        )
          ? null
          : value;

      const liquidType = val.liquid_number ? val.liquid_number : val.liquid_type;
      if (type === 'unit' && val.active) {
        values.scopeSelectedLiquids[index]['unit'] = isUnitDisabled(val.bom_changes)
          ? null
          : liquidType === 'New Liquid'
          ? value
          : val.unit;
      }

      if (type === 'bom_changes' && isLiquidListDisabled(val.bom_changes)) {
        values.scopeSelectedLiquids[index]['liquid_number'] = null;
        values.scopeSelectedLiquids[index]['unit'] = null;
        values.scopeSelectedLiquids[index]['tech_specification'] = null;
      }

      if (
        ((val.bom_changes || []).includes(liquidBomChanges.thickness) &&
          liquidType !== 'New Liquid' &&
          !values.scopeSelectedLiquids[index]['new_quantity']) ||
        (val.bom_changes || []).includes(liquidBomChanges.removal) ||
        val.bom_changes === liquidBomChanges.thickness
      )
        values.scopeSelectedLiquids[index]['new_quantity'] = null;

      if (
        val.active &&
        type === 'changeover_type' &&
        [materialchangeOverOptions.softChange, materialchangeOverOptions.mixChange].includes(value)
      )
        values.scopeSelectedLiquids[index]['changeover_date'] = null;

      if (type === 'changeover_date' && val.active) {
        const isDateDisabled = [
          materialchangeOverOptions.softChange,
          materialchangeOverOptions.mixChange,
        ].includes(val.changeover_type);
        values.scopeSelectedLiquids[index]['changeover_date'] = isDateDisabled ? null : value;
      }
    }

    setForm(values);
  };

  const getFilteredIds = (filteredRows) => {
    const filteredIds = {};
    filteredRows.forEach((row) => {
      filteredIds[row.original.id] = true;
    });

    return filteredIds;
  };

  const handleDefaultSet = (event, type, filteredRows = [], flag = false) => {
    if (flag) {
      event.target.value = event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
      if (/^(?:0*)$/.test(event.target.value)) event.target.value = '';
    }
    const filteredIds = getFilteredIds([...filteredRows]);

    const value =
      event?.target?.type === 'checkbox'
        ? event.target.checked
        : ['comment', 'new_quantity', 'tech_specification'].includes(type)
        ? (event.target.value || '').trim().length === 0
          ? event.target.value.trim()
          : event.target.value
        : type === 'changeover_date'
        ? moment(event).isValid()
          ? moment(event).format('YYYY-MM-DD')
          : null
        : event;
    // default value set for headers only
    const defaultSelections = { ...selectionRef.current };
    defaultSelections[type] =
      type === 'tech_specification' ? removeSpecialCharacters(value) : value;
    setSelections(defaultSelections);

    let currentValues = [...ref.current];
    currentValues.forEach((val, key) => {
      if (!filteredIds[val.id]) return currentValues[key];

      if (type === 'active') {
        const mixChangeover = currentValues.find(
          (l) => l.active && l.changeover_type === 'Mix Change',
        );
        currentValues[key][type] = value && val.active ? val.active : value;
        currentValues[key]['bom_changes'] = value && val.active ? val.bom_changes : null;
        currentValues[key]['liquid_number'] = value && val.active ? val.liquid_number : null;
        currentValues[key]['liquid_type'] = value && val.active ? val.liquid_type : null;
        currentValues[key]['new_quantity'] = value && val.active ? val.new_quantity : null;
        currentValues[key]['changeover_type'] =
          value && val.active
            ? val.changeover_type
            : !value
            ? null
            : mixChangeover
            ? 'Mix Change'
            : null;
        currentValues[key]['changeover_date'] = value && val.active ? val.changeover_date : null;
        currentValues[key]['comment'] = value && val.active ? val.comment : null;
        currentValues[key]['unit'] = value && val.active ? val.unit : null;
        currentValues[key]['tech_specification'] =
          value && val.active ? val.tech_specification : null;
      }

      if (['bom_changes', 'comment', 'changeover_type', 'tech_specification'].includes(type)) {
        if (val.active && !(type === 'bom_changes' && val.custom_added)) {
          if (type === 'tech_specification') {
            currentValues[key][type] = isQuantityDisabled(val.bom_changes, val)
              ? null
              : removeSpecialCharacters(value);
          } else {
            currentValues[key][type] = value;
          }
        }
      }

      if (type === 'liquid_number' && val.active) {
        const isLiquidDisabled = isLiquidListDisabled(val.bom_changes);
        currentValues[key]['liquid_number'] = isLiquidDisabled ? null : value;
        if (value !== 'New Liquid') {
          currentValues[key]['unit'] = isLiquidDisabled
            ? null
            : liquidsLabelRef.current[value]?.unit || null;
        } else {
          currentValues[key]['unit'] = null;
        }
      }

      if (type === 'new_quantity' && val.active)
        currentValues[key]['new_quantity'] = isQuantityDisabled(val.bom_changes, val)
          ? null
          : value;

      const liquidType = val.liquid_number ? val.liquid_number : val.liquid_type;
      if (type === 'unit' && val.active) {
        currentValues[key]['unit'] = isUnitDisabled(val.bom_changes)
          ? null
          : liquidType === 'New Liquid'
          ? value
          : val.unit;
      }

      if (type === 'bom_changes' && isLiquidListDisabled(val.bom_changes)) {
        currentValues[key]['liquid_number'] = null;
        currentValues[key]['unit'] = null;
      }

      if (
        ((val.bom_changes || []).includes(liquidBomChanges.thickness) &&
          !(val.bom_changes || []).includes(liquidBomChanges.drawing) &&
          liquidType !== 'New Liquid' &&
          !currentValues[key]['new_quantity']) ||
        (val.bom_changes || []).includes(liquidBomChanges.removal) ||
        val.bom_changes === liquidBomChanges.thickness
      ) {
        currentValues[key]['new_quantity'] = null;
        currentValues[key]['tech_specification'] = null;
      }

      if (!['new_quantity', 'tech_specification'].includes(type) && liquidType !== 'New Liquid') {
        currentValues[key]['new_quantity'] = null;
        currentValues[key]['tech_specification'] = null;
      }

      if (
        val.active &&
        type === 'changeover_type' &&
        [materialchangeOverOptions.softChange, materialchangeOverOptions.mixChange].includes(value)
      )
        currentValues[key]['changeover_date'] = null;

      if (type === 'changeover_date' && val.active) {
        const isDateDisabled = [
          materialchangeOverOptions.softChange,
          materialchangeOverOptions.mixChange,
        ].includes(val.changeover_type);
        currentValues[key]['changeover_date'] = isDateDisabled ? null : value;
      }

      return currentValues[key];
    });

    let values = { ...formRef.current };
    values.scopeSelectedLiquids = values.scopeSelectedLiquids.map((val, key) => {
      if (!filteredIds[val.id]) return values.scopeSelectedLiquids[key];

      if (type === 'active') values.scopeSelectedLiquids[key][type] = value;

      if (['bom_changes', 'comment', 'changeover_type', 'tech_specification'].includes(type)) {
        if (val.active && !(type === 'bom_changes' && val.custom_added)) {
          if (type === 'tech_specification') {
            values.scopeSelectedLiquids[key][type] = isQuantityDisabled(val.bom_changes, val)
              ? null
              : removeSpecialCharacters(value);
          } else {
            values.scopeSelectedLiquids[key][type] = value;
          }
        }
      }

      if (type === 'liquid_number' && val.active) {
        const isLiquidDisabled = isLiquidListDisabled(val.bom_changes);
        values.scopeSelectedLiquids[key]['liquid_number'] = isLiquidDisabled ? null : value;
        if (value !== 'New Liquid') {
          values.scopeSelectedLiquids[key]['unit'] = isLiquidDisabled
            ? null
            : liquidsLabelRef.current[value]?.unit || null;
        } else {
          values.scopeSelectedLiquids[key]['unit'] = null;
        }
      }

      if (type === 'new_quantity' && val.active)
        values.scopeSelectedLiquids[key]['new_quantity'] = isQuantityDisabled(val.bom_changes, val)
          ? null
          : value;

      const liquidType = val.liquid_number ? val.liquid_number : val.liquid_type;
      if (type === 'unit' && val.active) {
        values.scopeSelectedLiquids[key]['unit'] = isUnitDisabled(val.bom_changes)
          ? null
          : liquidType === 'New Liquid'
          ? value
          : val.unit;
      }

      if (type === 'bom_changes' && isLiquidListDisabled(val.bom_changes)) {
        values.scopeSelectedLiquids[key]['liquid_number'] = null;
        values.scopeSelectedLiquids[key]['unit'] = null;
      }

      if (
        ((val.bom_changes || []).includes(liquidBomChanges.thickness) &&
          !(val.bom_changes || []).includes(liquidBomChanges.drawing) &&
          liquidType !== 'New Liquid' &&
          !values.scopeSelectedLiquids[key]['new_quantity']) ||
        (val.bom_changes || []).includes(liquidBomChanges.removal) ||
        val.bom_changes === liquidBomChanges.thickness
      ) {
        values.scopeSelectedLiquids[key]['new_quantity'] = null;
        values.scopeSelectedLiquids[key]['tech_specification'] = null;
      }

      if (!['new_quantity', 'tech_specification'].includes(type) && liquidType !== 'New Liquid') {
        values.scopeSelectedLiquids[key]['new_quantity'] = null;
        values.scopeSelectedLiquids[key]['tech_specification'] = null;
      }

      if (
        val.active &&
        type === 'changeover_type' &&
        [materialchangeOverOptions.softChange, materialchangeOverOptions.mixChange].includes(value)
      )
        values.scopeSelectedLiquids[key]['changeover_date'] = null;

      if (type === 'changeover_date' && val.active) {
        const isDateDisabled = [
          materialchangeOverOptions.softChange,
          materialchangeOverOptions.mixChange,
        ].includes(val.changeover_type);
        values.scopeSelectedLiquids[key]['changeover_date'] = isDateDisabled ? null : value;
      }

      return values.scopeSelectedLiquids[key];
    });

    setForm(values);
  };

  const isChangeOverDateDisabled = (data) => {
    return (
      (data && data === materialchangeOverOptions.softChange) ||
      data === materialchangeOverOptions.mixChange
    );
  };

  const getMinChangeOverDate = () => {
    const { first_shipment } = { ...form.rfsInformation };

    return moment(first_shipment, 'YYYY-MM-DD').isValid()
      ? moment(first_shipment, 'YYYY-MM-DD').toDate()
      : moment().add('days', 1).toDate();
  };

  const isDateValid = (data) => {
    return moment(data, 'YYYY-MM-DD').isValid();
  };

  const columns = useMemo(
    () => [
      {
        Header: ({ filteredRows }) => {
          return !isSubmissionReview ? (
            <Checkbox
              checked={selectionRef?.current?.active || false}
              onChange={(event) => {
                if (!event.target.checked) {
                  confirm(
                    () =>
                      handleDefaultSet(
                        { target: { checked: false, type: 'checkbox' } },
                        'active',
                        filteredRows,
                      ),
                    {
                      title: 'Confirm!',
                      description: 'This will remove all values',
                      confirmationText: 'Ok',
                    },
                  );
                } else {
                  handleDefaultSet(event, 'active', filteredRows);
                }
              }}
            />
          ) : (
            ''
          );
        },
        disableSortBy: true,
        id: 'checkbox',
        Cell: ({ row }) => (
          <Checkbox
            disabled={isSubmissionReview}
            checked={row.original.active}
            onChange={(event) => {
              if (!event.target.checked) {
                confirm(() => handleChange({ target: { checked: false, type: 'checkbox' } }, row), {
                  title: 'Confirm!',
                  description: 'This will remove all values',
                  confirmationText: 'Ok',
                });
              } else {
                handleChange(event, row);
              }
            }}
          />
        ),
      },
      {
        Header: 'Liquid Codes',
        accessor: 'liquid_code',
      },
      {
        Header: 'Liquid Description',
        accessor: 'liquid_description',
      },
      {
        Header: 'Plant',
        minWidth: '100px',
        maxWidth: '100px',
        accessor: 'plant',
      },
      {
        Header: 'BOM',
        minWidth: '100px',
        maxWidth: '100px',
        accessor: 'bom',
      },
      {
        Header: 'Curr Qty',
        minWidth: '100px',
        maxWidth: '100px',
        accessor: 'quantity',
      },

      {
        Header: ({ filteredRows }) => (
          <div className='custom-multi-select' style={{ marginBottom: '2px', minWidth: '350px' }}>
            <span>BOM Changes </span>
            {isSubmissionReview ? null : (
              <MultiSelect
                searchable
                onChange={(value) => {
                  let selectedValue = [];
                  if (value.includes(liquidBomChanges.removal)) {
                    selectedValue = [liquidBomChanges.removal];
                  } else {
                    selectedValue = value;
                  }
                  handleDefaultSet(selectedValue, 'bom_changes', filteredRows);
                }}
                data={[
                  {
                    value: liquidBomChanges.thickness,
                    label: liquidBomChanges.thickness,
                    disabled: isDisabled(selectionRef.current.bom_changes),
                  },
                  {
                    value: liquidBomChanges.drawing,
                    label: liquidBomChanges.drawing,
                    disabled: isDisabled(selectionRef.current.bom_changes),
                  },
                  {
                    value: liquidBomChanges.removal,
                    label: liquidBomChanges.removal,
                  },
                ]}
                clearable
                nothingFound='Nothing found'
              />
            )}
          </div>
        ),
        disableSortBy: true,
        minWidth: '350px',
        maxWidth: '350px',
        id: 'bom_changes',
        canFilter: false,
        Cell: ({ row }) => (
          <div className='custom-multi-select'>
            <MultiSelect
              disabled={!row.original.active || isSubmissionReview}
              value={
                typeof row.original.bom_changes === 'object'
                  ? row.original.bom_changes
                  : (row.original.bom_changes || '').split(',')
              }
              onChange={(value) => {
                let selectedValue = [];
                if (value.includes(liquidBomChanges.removal)) {
                  selectedValue = [liquidBomChanges.removal];
                } else {
                  selectedValue = value;
                }
                handleSelectChange(selectedValue, row, 'bom_changes');
              }}
              name={'bom_changes'}
              error={errors[row.original.id]?.bom_changes}
              data={
                row.original.custom_added
                  ? additionOfLiquidOptions
                  : [
                      {
                        value: liquidBomChanges.thickness,
                        label: liquidBomChanges.thickness,
                        disabled: isDisabled(row.original.bom_changes),
                      },
                      {
                        value: liquidBomChanges.drawing,
                        label: liquidBomChanges.drawing,
                        disabled: isDisabled(row.original.bom_changes),
                      },
                      {
                        value: liquidBomChanges.removal,
                        label: liquidBomChanges.removal,
                      },
                    ]
              }
              searchable
              clearable
              nothingFound='Nothing found'
            />
          </div>
        ),
      },
      {
        Header: ({ filteredRows }) => (
          <div style={{ marginBottom: '2px' }}>
            <span> Liquids List </span>
            {isSubmissionReview ? null : (
              <Select
                label=''
                data={liquidsRef.current}
                onSearchChange={(query) => setLiquidsSearch(query)}
                nothingFound='Nothing found'
                searchable
                clearable
                limit={100}
                onChange={(event) => handleDefaultSet(event, 'liquid_number', filteredRows)}
              />
            )}
          </div>
        ),
        disableSortBy: true,
        canFilter: false,
        minWidth: '200px',
        maxWidth: '200px',
        id: 'liquid_number',
        Cell: ({ row }) =>
          isSubmissionReview ? (
            <TextInput
              size='sm'
              disabled={true}
              error={row.original.active && errors[row.original.id]?.liquid_number}
              value={
                !row.original.active
                  ? null
                  : row.original.liquid_number
                  ? row.original.liquid_number
                  : liquidTypesMapping[row.original.liquid_type]
                  ? liquidTypesMapping[row.original.liquid_type]
                  : row.original.liquid_type
              }
            />
          ) : (
            <Select
              label=''
              data={liquidsRef.current}
              onSearchChange={(query) => setLiquidsSearch({ str: query, liquid: row.original.id })}
              nothingFound='Nothing found'
              disabled={isLiquidListDisabled(row.original.bom_changes) || !row.original.active}
              limit={100}
              searchable
              clearable
              error={row.original.active && errors[row.original.id]?.liquid_number}
              value={
                !row.original.active
                  ? null
                  : row.original.liquid_number
                  ? row.original.liquid_number
                  : row.original.liquid_type
              }
              onChange={(event) => handleSelectChange(event, row, 'liquid_number')}
            />
          ),
      },
      {
        Header: ({ filteredRows }) => (
          <div style={{ marginBottom: '2px' }}>
            <span> New Qty </span>
            {isSubmissionReview ? null : (
              <TextInput
                placeholder='Max (100000)'
                onChange={(event) => handleDefaultSet(event, 'new_quantity', filteredRows, true)}
              />
            )}
          </div>
        ),
        disableSortBy: true,
        canFilter: false,
        minWidth: '150px',
        maxWidth: '150px',
        id: 'new_quantity',
        Cell: ({ row }) => (
          <>
            <TextInput
              name='new_quantity'
              placeholder='Max (100000)'
              error={
                errors[row.original.id]?.new_quantity || errors[row.original.id]?.max_value_exceeded
                // (parseInt(row.original.new_quantity) || 0) > 100000
              }
              value={row.original.new_quantity || ''}
              onChange={(event) => handleChange(event, row, true)}
              disabled={
                !row.original.active ||
                isQuantityDisabled(row.original.bom_changes, row.original) ||
                isSubmissionReview
              }
            />
            {errors[row.original.id]?.max_value_exceeded && (
              <span style={{ color: 'red', fontSize: '12px' }}>Max value exceeded</span>
            )}
          </>
        ),
      },
      {
        Header: ({ filteredRows }) => (
          <div style={{ marginBottom: '2px' }}>
            <span>Unit</span>
            {isSubmissionReview ? null : (
              <Select
                placeholder='Select'
                searchable
                clearable
                data={units}
                onChange={(event) => handleDefaultSet(event, 'unit', filteredRows)}
              />
            )}
          </div>
        ),
        disableSortBy: true,
        minWidth: '100px',
        maxWidth: '100px',
        id: 'unit',
        Cell: ({ row }) => {
          const type = row.original.liquid_number
            ? row.original.liquid_number
            : row.original.liquid_type;

          return type !== 'New Liquid' ? (
            <TextInput
              error={errors[row.original.id]?.unit}
              value={row.original.unit || ''}
              disabled={true}
            />
          ) : (
            <Select
              disabled={
                !row.original.active ||
                isSubmissionReview ||
                isUnitDisabled(row.original.bom_changes)
              }
              error={errors[row.original.id]?.unit}
              placeholder='Select'
              searchable
              clearable
              data={units}
              value={row.original.unit}
              onChange={(event) => handleSelectChange(event, row, 'unit')}
            />
          );
        },
      },
      // {
      //   Header: 'Tech Spec matrix',
      //   paddingBottom: isSubmissionReview ? '0px' : '40px',
      //   id: 'techSpec',
      //   canFilter: false,
      //   disableSortBy: true,
      //   Cell: ({ row }) => <Text> Not Applicable </Text>,
      // },
      {
        Header: ({ filteredRows }) => (
          <div style={{ marginBottom: '2px' }}>
            <span>Technical Specification</span>
            {isSubmissionReview ? null : (
              <TextInput
                style={{ width: '400px' }}
                placeholder='Specify tech. spec. code or reference material (ERP code), if exact copy.'
                maxLength={20}
                value={selectionRef.current['tech_specification'] || ''}
                onChange={(event) => handleDefaultSet(event, 'tech_specification', filteredRows)}
              />
            )}
          </div>
        ),
        id: 'tech_specification',
        paddingBottom: isSubmissionReview ? '0px' : '40px',
        canFilter: false,
        disableSortBy: true,
        Cell: ({ row }) => {
          const type = row.original.liquid_number
            ? row.original.liquid_number
            : row.original.liquid_type;
          return (
            <TextInput
              name='tech_specification'
              placeholder='Specify tech. spec. code or reference material (ERP code), if exact copy.'
              maxLength={20}
              error={
                errors[row.original.id]?.tech_specification ||
                errors[row.original.id]?.max_tech_spec_value_exceeded
              }
              value={row.original.tech_specification || ''}
              onChange={(event) => handleChange(event, row)}
              disabled={
                type !== 'New Liquid' ||
                isUnitDisabled(row.original.bom_changes) ||
                !row.original.active ||
                isSubmissionReview
              }
            />
          );
        },
      },
      {
        Header: ({ filteredRows }) => (
          <div style={{ marginBottom: '2px' }}>
            <span> Changeover Type </span>
            {isSubmissionReview ? null : (
              <Select
                placeholder='Select'
                searchable
                clearable
                data={
                  (ref.current || []).filter((l) => l.active).length > 1
                    ? changeOverTypeOptions
                    : changeOverCellOptions
                }
                onChange={(event) => handleDefaultSet(event, 'changeover_type', filteredRows)}
              />
            )}
          </div>
        ),
        disableSortBy: true,
        minWidth: '100px',
        canFilter: false,
        id: 'changeover_type',
        Cell: ({ row }) => (
          <Select
            disabled={
              !row.original.active ||
              isSubmissionReview ||
              row.original.changeover_type === 'Mix Change'
            }
            placeholder='Select'
            searchable
            error={errors[row.original.id]?.changeover_type}
            name='changeover_type'
            data={
              row.original.changeover_type === 'Mix Change'
                ? changeOverTypeOptions
                : changeOverCellOptions
            }
            value={row.original.changeover_type || null}
            onChange={(event) => handleSelectChange(event, row, 'changeover_type')}
          />
        ),
      },
      {
        Header: ({ filteredRows }) => (
          <div style={{ marginBottom: '2px' }}>
            <span> Changeover Date </span>
            {isSubmissionReview ? null : (
              <DatePickerInput
                minDate={getMinChangeOverDate()}
                initialMonth={getMinChangeOverDate()}
                onClick={(event) => {
                  if (!isDateValid(form.rfsInformation.first_shipment)) setFirstShipment(true);
                }}
                allowFreeInput
                numberOfColumns={2}
                icon={<CalendarIcon color='#e5b611' />}
                onChange={(event) => handleDefaultSet(event, 'changeover_date', filteredRows)}
                maw={400}
                mx='auto'
                dropdownType='modal'
              />
            )}
          </div>
        ),
        disableSortBy: true,
        minWidth: '100px',
        canFilter: false,
        id: 'changeover_date',
        Cell: ({ row }) => (
          <div>
            {' '}
            <DatePickerInput
              disabled={
                isChangeOverDateDisabled(row.original.changeover_type) ||
                !row.original.active ||
                isSubmissionReview
              }
              styles={{
                arrow: {
                  display: !isDateValid(form.rfsInformation.first_shipment) ? 'none' : 'block',
                },
                dropdown: {
                  display: !isDateValid(form.rfsInformation.first_shipment) ? 'none' : 'block',
                },
              }}
              minDate={getMinChangeOverDate()}
              initialMonth={getMinChangeOverDate()}
              error={errors[row.original.id]?.changeover_date}
              allowFreeInput
              numberOfColumns={2}
              icon={<CalendarIcon color='#e5b611' />}
              onChange={(event) => handleSelectChange(event, row, 'changeover_date')}
              onClick={(event) => {
                if (!isDateValid(form.rfsInformation.first_shipment)) setFirstShipment(true);
              }}
              value={
                row.original.changeover_date
                  ? moment(row.original.changeover_date, 'YYYY-MM-DD').toDate()
                  : null
              }
            />
          </div>
        ),
      },
      {
        Header: ({ filteredRows }) => (
          <div style={{ marginBottom: '2px' }}>
            <span> Comment Box </span>
            {isSubmissionReview ? null : (
              <TextInput
                size='sm'
                style={{ width: '20rem', alignContent: 'center' }}
                placeholder='Detailed Description of the changes'
                onChange={(event) => handleDefaultSet(event, 'comment', filteredRows)}
              />
            )}
          </div>
        ),
        disableSortBy: true,
        minWidth: '100px',
        canFilter: false,
        id: 'comment',
        Cell: ({ row }) => (
          <TextInput
            disabled={!row.original.active || isSubmissionReview}
            className='liquidComment'
            error={errors[row.original.id]?.comment}
            size='sm'
            name='comment'
            onChange={(event) => handleChange(event, row)}
            value={row.original.comment}
          />
        ),
      },
    ],
    [errors, units],
  );

  const filterSelectedRows = useMemo(() => {
    let data = [...ref.current];
    data = data.filter((res) => res.active);
    return data;
  }, [showOnlySelected]);

  return (
    <>
      <Table
        columns={columns}
        data={showOnlySelected ? filterSelectedRows : ref.current}
        showOnlySelected={showOnlySelected}
        setOnlyShowSelected={setOnlyShowSelected}
        rightButton={!isSubmissionReview}
        buttonLabel={'Add liquid component'}
        onButtonClick={() => setAddLiquidModal(true)}
        displaySizeSelect={true}
      />
      {addLiquidModal && (
        <AddLiquid
          opened={addLiquidModal}
          handleClose={handleAddLiquidClose}
          handleAdd={handleAdd}
          materials={materials}
          rfs_number={params.rfsNumber}
        />
      )}
    </>
  );
};

export default withConfirm(LiquidTable);
