import React, { useEffect, useContext, useState } from 'react';
import { Table, Tabs, Title, Accordion, Text, Center } from '@mantine/core';

import ContentPaper from '../../components/layouts/rfs/Content';
import ProjectName from '../../components/projectName/projectName';
import { getBody } from './TableHelper';
import { ProductDevelopmentTableMaterial } from '../../components/tables/ProductDevelopmentTable/productDevelopmentTableMaterial';
import { ProductDevelopmentTableLiquid } from '../../components/tables/ProductDevelopmentTable/productDevelopmentTableLiquids';
import { ProjectOverviewContext } from '../../contexts/projectOverviewContext';
import { AppLoader } from '../../components/common/loader';
import { RfsContext } from '../../contexts/RfsContext';
import ViewSkus from '../../components/modal/productdevelopmentmodals/view-skus';
import { tabStyles } from '../../utils/helpers';

export default function ProductDevlopmentComp({ trials = false, scopeUnlock = false, editable }) {
  const {
    //fetchProductdevelopmentMaterials,
    getVendors,
    materials,
    liquids,
    scopeLiquids,
    //getLiquids,
    getScopeLiquids,
    reset,
  } = useContext(ProjectOverviewContext);
  const { form, downloadFile } = useContext(RfsContext);

  const [skuModel, setSkuModel] = useState({ open: false, plant: null });

  useEffect(() => {
    getVendors();
    //getLiquids(trials);
    getScopeLiquids();
    //fetchProductdevelopmentMaterials(trials);

    return () => reset();
  }, []);

  const handleViewSkus = (data) => {
    setSkuModel({ open: true, plant: data.plant });
  };

  if (scopeLiquids.length === 0 && materials.length === 0)
    return (
      <ContentPaper page={'ProductDevelopment'}>
        <AppLoader center size='lg' />
      </ContentPaper>
    );

  return (
    <>
      <ContentPaper page={'ProductDevelopment'}>
        <ProjectName initialItem={0} />
        <br />
        {scopeUnlock && (
          <Text className='padded-error'>
            <Center>Changeover removal in Progress</Center>
          </Text>
        )}
        <br />
        <Tabs
          variant='unstyled'
          styles={tabStyles}
          className='prod-dev-tabs'
          color='yellow'
          defaultValue={'mc'}
          keepMounted={false}
        >
          <Tabs.List>
            {(materials || []).length > 0 && (
              <Tabs.Tab className='topTabs' value='mc'>
                <b>Material Code</b>
              </Tabs.Tab>
            )}
            {(scopeLiquids || []).length > 0 && (
              <Tabs.Tab className='topTabs' value='lc'>
                <b>Liquid Code</b>
              </Tabs.Tab>
            )}
          </Tabs.List>

          {(materials || []).length > 0 && (
            <Tabs.Panel className='topTabs' value='mc'>
              <div
                className='table-container'
                style={{
                  overflowY: 'auto',
                  padding: '0px',
                  margin: '0px',
                }}
              >
                {' '}
                <Table
                  captionSide='top'
                  style={{
                    marginTop: 2,
                    border: '2px solid white',
                    borderTopRightRadius: '25px',
                  }}
                >
                  <caption>
                    <Title
                      order={3}
                      align='center'
                      style={{
                        backgroundColor: '#e5b611',
                        color: 'white',
                      }}
                    >
                      Material Codes
                    </Title>
                  </caption>

                  <tbody
                    style={{
                      border: '2px solid white',
                      color: 'black',
                      backgroundColor: 'white',
                      padding: '85px',
                    }}
                  >
                    {getBody(
                      materials,
                      'material',
                      form?.attachments?.artworkBriefing,
                      downloadFile,
                      handleViewSkus,
                    )}
                  </tbody>
                </Table>
              </div>
              {!scopeUnlock && (
                <div
                  className='table-container'
                  style={{
                    overflowY: 'auto',
                    padding: '0px',
                    margin: '0px',
                  }}
                >
                  <Table
                    captionSide='top'
                    style={{
                      marginTop: 2,
                      border: '2px solid white',
                      borderTopRightRadius: '25px',
                    }}
                  >
                    <caption>
                      <Title
                        order={3}
                        align='center'
                        style={{
                          marginTop: 10,
                          backgroundColor: '#e5b611',
                          color: 'white',
                        }}
                      >
                        Product Development Material Codes
                      </Title>
                    </caption>
                    <tbody
                      style={{
                        border: '2px solid white',
                        color: 'black',
                        backgroundColor: 'white',
                        padding: '85px',
                      }}
                    >
                      {<ProductDevelopmentTableMaterial trials={trials} editable={editable} />}
                    </tbody>
                  </Table>
                </div>
              )}
            </Tabs.Panel>
          )}

          {(scopeLiquids || []).length > 0 && (
            <Tabs.Panel value='lc'>
              <Table
                captionSide='top'
                style={{
                  marginTop: 2,
                  border: '2px solid white',
                  borderTopRightRadius: '25px',
                }}
              >
                <caption>
                  <Title
                    order={3}
                    align='center'
                    style={{
                      backgroundColor: '#e5b611',
                      color: 'white',
                    }}
                  >
                    Liquid Codes
                  </Title>
                </caption>
                <Accordion defaultValue='0'>
                  {scopeLiquids?.map((item, index) => (
                    <Accordion.Item style={{ backgroundColor: '#FFFFFF' }} value='0'>
                      <Accordion.Control>{`${item.selection_number} - ${item.selection_name}`}</Accordion.Control>
                      <Accordion.Panel>
                        <div
                          className='table-container'
                          style={{
                            overflowY: 'auto',
                            padding: '0px',
                            margin: '0px',
                          }}
                        >
                          <Table
                            captionSide='top'
                            style={{
                              marginTop: 2,
                              border: '2px solid white',
                              borderTopRightRadius: '25px',
                            }}
                          >
                            <tbody
                              style={{
                                border: '2px solid white',
                                color: 'black',
                                backgroundColor: 'white',
                              }}
                            >
                              {getBody(
                                liquids.filter(
                                  (liquid) => liquid.selection_number == item.selection_number,
                                ),
                                'liquid',
                              )}
                            </tbody>
                          </Table>
                        </div>

                        {!scopeUnlock && (
                          <div
                            className='table-container'
                            style={{
                              overflowY: 'auto',
                              padding: '0px',
                              margin: '0px',
                            }}
                          >
                            <Table
                              captionSide='top'
                              style={{
                                marginTop: 2,
                                border: '2px solid white',
                                borderTopRightRadius: '25px',
                              }}
                            >
                              <caption>
                                <Title
                                  order={3}
                                  align='center'
                                  style={{
                                    marginTop: 10,
                                    backgroundColor: '#e5b611',
                                    color: 'white',
                                  }}
                                >
                                  Product Development Liquid Codes
                                </Title>
                              </caption>
                              <tbody
                                style={{
                                  border: '2px solid white',
                                  color: 'black',
                                  backgroundColor: 'white',
                                  padding: '85px',
                                }}
                              >
                                <ProductDevelopmentTableLiquid
                                  trials={trials}
                                  accordionIndex={index}
                                  selectionNumber={item.selection_number}
                                  editable={editable}
                                />
                              </tbody>
                            </Table>
                          </div>
                        )}
                      </Accordion.Panel>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </Table>
            </Tabs.Panel>
          )}
        </Tabs>

        {skuModel.open && (
          <ViewSkus
            data={form.selectedAffectedSkus.filter((sku) => sku.Plant === skuModel.plant)}
            skuModel={skuModel}
            setSkuModel={setSkuModel}
          />
        )}
      </ContentPaper>
    </>
  );
}
