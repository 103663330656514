import React, { useState, useMemo } from 'react';
import ContentPaper from '../../../components/layouts/rfs/Content';
import { Row, Col } from 'react-bootstrap';
import { Select, Tabs, SimpleGrid, Button, Text } from '@mantine/core';
import { TrashIcon, CheckIcon } from '@radix-ui/react-icons';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ModalsProvider } from '@mantine/modals';
import { tabStyles } from '../../../utils/helpers';
import AdminUsers from './adminUsers';
import { AdminProvider } from '../../../contexts/AdminContext';
import { roles } from '../../../constants';

function AdminConsole() {
  const [activeTab, setActiveTab] = useState(0);
  const [validationErrors, setValidationErrors] = useState({});

  const handleTabChange = (activeTab, tabKey) => {
    setActiveTab(activeTab);
  };

  const [queryClient] = React.useState(() => new QueryClient());

  const rolesData = [
    { value: roles.pmo, label: 'PMO' },
    { value: roles.initiator, label: 'Initiator' },
    { value: roles.superAdmin, label: 'Super Admin' },
    { value: roles.procurement, label: 'Procurement' },
    {
      value: roles.masterDataTeam,
      label: 'Master Data Team',
    },
    { value: roles.d2p, label: 'D2P' },
    { value: roles.dp, label: 'Demand Planning' },
    { value: roles.zitec, label: 'Zitec' },
    { value: roles.mrp, label: 'MRP' },
    { value: roles.scheduling, label: 'Scheduling' },
    { value: roles.snp, label: 'SNP' },
    { value: roles.standard_transfer_pricing, label: 'Standard/Transfer Pricing' },
  ];

  const newUsersColumns = useMemo(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
        enableEditing: false,
      },
      {
        header: 'Email',
        accessorKey: 'email',
        enableEditing: false,
      },
      {
        header: 'Role Requested',
        accessorKey: 'role_requested',
        enableEditing: false,
      },

      {
        header: 'Role Approved',
        accessorKey: 'role_approved',
        editVariant: 'select',
        enableColumnFilter: false,
        Cell: ({ row }) => (
          <Select
            data={rolesData}
            error={validationErrors.role}
            onChange={(value) => (row.original['role_approved'] = value)}
            defaultValue={
              row.original.role_requested ? row.original.role_requested : roles.initiator
            }
          />
        ),
      },
    ],
    [validationErrors],
  );

  const existingUsersColumns = useMemo(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
        enableEditing: false,
      },
      {
        header: 'Email',
        accessorKey: 'email',
        enableEditing: false,
      },
      {
        header: 'Current Role',
        accessorKey: 'role_approved',
        enableEditing: false,
      },

      {
        header: 'New Role',
        accessorKey: 'newRole',
        editVariant: 'select',
        enableColumnFilter: false,
        mantineEditSelectProps: ({ row }) => ({
          data: rolesData.filter((role) => role.value !== row.original.role_approved),
          error: validationErrors.role,
        }),
        Cell: ({ row }) => {
          return <Text fs='italic'>Click edit to change</Text>;
        },
      },
    ],
    [validationErrors],
  );

  return (
    <ContentPaper page='admin-console'>
      <Row>
        <Col
          md={1}
          style={{
            width: '2.0833%',
            flex: '0 0 2.0833%',
            maxWidth: ' 2.0833%',
          }}
        ></Col>
        <Col
          md={{ span: 6 }}
          style={{
            marginTop: '1rem',
          }}
        >
          <h5 className='sectionHeadings'>Admin Console</h5>
        </Col>
      </Row>

      <Row>
        <Col
          md={1}
          style={{
            width: '2.0833%',
            flex: '0 0 2.0833%',
            maxWidth: ' 2.0833%',
          }}
        ></Col>
        <Col
          md={{ span: 11 }}
          style={{
            marginTop: '1rem',
          }}
        >
          <Tabs
            variant='unstyled'
            styles={tabStyles}
            className='all-rfs-tabs'
            active={activeTab}
            onTabChange={handleTabChange}
            color='yellow'
            keepMounted={false}
            defaultValue={'new'}
          >
            <Tabs.List>
              <Tabs.Tab value='new'>New Users</Tabs.Tab>
              <Tabs.Tab value='all'>All Users</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value='new'>
              <QueryClientProvider client={queryClient}>
                <AdminProvider>
                  <ModalsProvider>
                    <AdminUsers
                      type='new'
                      columns={newUsersColumns}
                      validationErrors={validationErrors}
                      setValidationErrors={setValidationErrors}
                    />
                  </ModalsProvider>
                </AdminProvider>
              </QueryClientProvider>
            </Tabs.Panel>
            <Tabs.Panel value='all'>
              <QueryClientProvider client={queryClient}>
                <AdminProvider>
                  <ModalsProvider>
                    <AdminUsers
                      type='existing'
                      columns={existingUsersColumns}
                      validationErrors={validationErrors}
                      setValidationErrors={setValidationErrors}
                    />
                  </ModalsProvider>
                </AdminProvider>
              </QueryClientProvider>
            </Tabs.Panel>
          </Tabs>
        </Col>
      </Row>
    </ContentPaper>
  );
}

export default AdminConsole;
