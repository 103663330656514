import React, { useState, useEffect, useContext, useMemo } from 'react';
import ContentPaper from '../../components/layouts/rfs/Content';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { axios } from '../../utils/axios';
import { Row, Col } from 'react-bootstrap';
import { AppLoader } from '../../components/common/loader';
import { Button } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import FtrReportComp from './ftrReportComp';
import FTRLogModal from './ftrLogModal';

function FtrInitiatorReport() {
  const [validationErrors, setValidationErrors] = useState({}); //for Report
  const [queryClient] = React.useState(() => new QueryClient());

  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();
  const [logModal, setLogModal] = useState({ logs: null, open: false });

  //Report Data for New UI
  const columns = useMemo(
    () => [
      {
        header: 'Initiator Name',
        accessorKey: 'name',
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: 'rfsId',
        header: 'RFS ID',
        enableEditing: false,
        Cell: ({ renderedCellValue, row }) => (
          <Button
            variant='filled'
            color='yellow'
            style={{ background: 'black', color: 'white' }}
            onClick={() => {
              setLogModal({ open: true, logs: row.original.rfsId });
            }}
          >
            View RFSes
          </Button>
        ),
      },
      {
        accessorKey: 'avgChangedFields',
        header: 'Number of Fields PMO modified',
      },
      {
        accessorKey: 'avgTotalFields',
        header: 'Total Fields',
      },
      {
        accessorKey: 'avgFtrPercent',
        header: 'Percentage of FTR',
      },
    ],
    [validationErrors],
  );

  return (
    <ContentPaper page='ftr-report'>
      <Row>
        <Col
          md={{ span: 6 }}
          style={{
            marginTop: '1rem',
            display: 'inline-flex',
            justifyContent: 'flex-start',
          }}
        ></Col>
      </Row>
      <div id='customReport'>
        {
          <QueryClientProvider client={queryClient}>
            <FtrReportComp
              columns={columns}
              setValidationErrors={setValidationErrors}
              validationErrors={validationErrors}
              unique={'name'}
              enableEditing={false}
              type={'ftrInitiator'}
            />
          </QueryClientProvider>
        }
      </div>
      {logModal.open && <FTRLogModal logModal={logModal} setLogModal={setLogModal} />}
    </ContentPaper>
  );
}

export default FtrInitiatorReport;
