import React, { useMemo, useState, useEffect, useRef } from 'react';
import { useTable } from 'react-table';
import { Styles } from './styles';
import { Text, Select, Textarea, Badge, Button, Tooltip } from '@mantine/core';
import { InfoCircle } from 'tabler-icons-react';
import { axios } from '../../../../../utils/axios';
import { AppLoader } from '../../../../../components/common/loader';
import { DownloadIcon, UploadIcon } from '@radix-ui/react-icons';
import moment from 'moment';
import { ObsoletesMrpSchema } from './validation';
import LogModal from './logModal';
import ObsoletesModal from './obsoletesModal';
import { calculateColor, addNewLines, limitWord } from '../../helpers';
import { RfsContext } from '../../../../../contexts/RfsContext';
import { useContext } from 'react';
import DOMPurify from 'dompurify';
import { AttachmentsModal } from '../../../../../common/attachments';
import { attachmentChunks } from '../../../../../utils/helpers';

const MAX_ATTACHMENTS = 5;

const ObsoletesMrp = ({ data, loading, refresh, rfs_number, readOnly }) => {
  const [attachmentModal, setAttachmentModal] = useState({
    open: false,
    attachments: [],
    status_id: null,
    readOnly: null,
  });
  const [errors, setErrors] = useState(null);
  const { downloadFile } = useContext(RfsContext);
  const [logModal, setLogModal] = useState({ open: false, id: null });
  const [details, setDetails] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [tableLoader, setTableLoader] = useState({});
  const [obsoletesModal, setObsoletesModal] = useState({
    open: false,
    id: null,
    scope_id: null,
  });
  const [attachmentLoading, setAttachmentLoading] = useState(false);

  const detailsRef = useRef(null);
  detailsRef.current = details;

  useEffect(() => {
    function setInitialInfo() {
      const info = {};
      data.forEach((item) => {
        const { mrp_comment, obsolete_attachment, aligned_with_obsoletes } =
          item.int_rfs_impl_mrp_approval ? item.int_rfs_impl_mrp_approval : '';

        info[item.id] = {
          aligned_with_obsoletes: aligned_with_obsoletes,
          mrp_comments: mrp_comment,
          attachment: obsolete_attachment,
        };
      });

      setDetails(info);
    }

    if (data.length > 0) setInitialInfo();
  }, [data]);

  const columns = useMemo(
    () => [
      {
        Header: 'SKU Code',
        accessor: 'selection_number',
        style: { minWidth: '10rem' },
        Cell: ({ row }) => {
          return <Text>{row.original.int_rfs_scope_selection.selection_number}</Text>;
        },
      },
      {
        Header: 'SKU Description',
        accessor: 'selection_name',
        style: { minWidth: '15rem' },
        Cell: ({ row }) => {
          return <Text>{row.original.int_rfs_scope_selection.selection_name}</Text>;
        },
      },
      {
        Header: 'Obsoletes',
        accessor: 'show_obsoletes',
        Cell: ({ row }) => {
          return (
            <Button
              variant='filled'
              color='yellow'
              style={{ background: 'black', color: 'white' }}
              onClick={() => {
                setObsoletesModal({
                  open: true,
                  id: row.original.id,
                  scope_id: row.original.int_rfs_scope_selection.id,
                });
              }}
            >
              Show Obsoletes
            </Button>
          );
        },
        style: { minWidth: '10rem' },
      },
      {
        Header: 'Aligned with obsoletes',
        accessor: 'aligned_with_obsoletes',
        style: { minWidth: '10rem' },
        Cell: ({ row }) => {
          const isDisabled =
            !detailsRef.current[row.original.id]?.aligned_with_obsoletes ||
            row.original.status === 'Not Started';

          if (tableLoader[row.original.id]?.aligned_with_obsoletes)
            return <AppLoader centre size='sm' />;

          return row.original.int_rfs_impl_mrp_approval &&
            row.original.int_rfs_impl_mrp_approval.aligned_with_obsoletes !== null ? (
            <Text>
              {row.original.int_rfs_impl_mrp_approval.aligned_with_obsoletes === '1' ? 'Yes' : 'No'}
            </Text>
          ) : (
            <div>
              <Select
                value={detailsRef.current[row.original.id]?.aligned_with_obsoletes}
                disabled={readOnly}
                clearable
                data={[
                  { value: '1', label: 'Yes' },
                  { value: '0', label: 'No' },
                ]}
                onChange={(value) => {
                  handleChange(row.original.id, value, 'aligned_with_obsoletes');
                }}
              />
              {!isDisabled && (
                <Tooltip
                  label='If not aligned, Attachments and Comments are necessary'
                  placement='start'
                  withArrow
                  className='data'
                >
                  <Button
                    leftIcon={<InfoCircle size='0.8rem' />}
                    size='sm'
                    style={{ marginTop: '0.5rem', background: 'black', color: 'white' }}
                    variant='filled'
                    color='yellow'
                    disabled={isDisabled || readOnly}
                    onClick={(e) =>
                      handleSubmit(
                        e,
                        row.original.id,
                        parseInt(detailsRef.current[row.original.id].aligned_with_obsoletes),
                        'aligned_with_obsoletes',
                      )
                    }
                  >
                    Submit
                  </Button>
                </Tooltip>
              )}
            </div>
          );
        },
      },
      {
        Header: 'Obsoletes Attachment',
        id: 'attachment',
        style: { minWidth: '10rem' },
        Cell: ({ row }) => {
          const { id, status } = row.original;
          const { obsolete_attachment: attachments = '' } =
            row.original.int_rfs_impl_mrp_approval || {};

          return (
            <div>
              <div>
                <Button
                  variant='filled'
                  color={errors?.errors[row.original.id]?.attachment ? 'red' : 'yellow'}
                  rightIcon={<UploadIcon />}
                  style={{ background: 'black', color: 'white' }}
                  onClick={() =>
                    setAttachmentModal({
                      open: true,
                      attachments:
                        attachments.length > 0
                          ? attachments.split(',').map((name, index) => {
                              return {
                                id: index,
                                name: name,
                              };
                            })
                          : [],
                      status_id: id,
                      info: row.original,
                      readOnly: readOnly || status === 'Closed',
                    })
                  }
                >
                  Attachment
                </Button>
              </div>
            </div>
          );
        },
      },
      {
        Header: 'MRP Comments',
        accessor: 'mrp_comments',
        style: { minWidth: '20rem' },
        Cell: ({ row }) => {
          const isDisabled =
            !detailsRef.current[row.original.id]?.mrp_comments ||
            row.original.status === 'Not Started';

          if (tableLoader[row.original.id]?.mrp_comments) return <AppLoader centre size='sm' />;

          return row.original.int_rfs_impl_mrp_approval?.mrp_comment &&
            row.original.int_rfs_impl_mrp_approval.mrp_comment !== null ? (
            <div>
              <div
                style={{
                  maxHeight: '75px',
                  overflowY: 'auto',
                }}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    addNewLines(row.original.int_rfs_impl_mrp_approval.mrp_comment),
                  ),
                }}
              />
            </div>
          ) : (
            <div>
              <Textarea
                value={detailsRef?.current[row.original.id]?.mrp_comments}
                disabled={readOnly}
                error={errors?.errors[row.original.id]?.mrp_comments}
                onChange={(event) => {
                  handleChange(row.original.id, event.target.value, 'mrp_comments');
                }}
                autosize
                placeholder='Enter MRP comments (Max. 100 chars)'
                radius='xs'
              />
              {detailsRef?.current[row.original.id]?.mrp_comments?.length > 100 && (
                <div className='red-custom-mantine-Textarea-error'>
                  100 Character limit reached, exceeding character would be truncated on saving.
                </div>
              )}
              {!isDisabled && (
                <Button
                  size='sm'
                  style={{ marginTop: '0.5rem', background: 'black', color: 'white' }}
                  variant='filled'
                  color='yellow'
                  disabled={isDisabled || readOnly}
                  onClick={(e) =>
                    handleSubmit(
                      e,
                      row.original.id,
                      detailsRef.current[row.original.id].mrp_comments,
                      'mrp_comments',
                    )
                  }
                >
                  Submit
                </Button>
              )}
            </div>
          );
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        style: { minWidth: '10rem' },
        Cell: ({ row }) => {
          const { status, sla_date } = row.original;
          const color = calculateColor(status, sla_date);
          return (
            <Badge style={{ padding: '0.8rem' }} size='md' color={color}>
              {status}
            </Badge>
          );
        },
      },
      {
        Header: 'Logs',
        accessor: 'view_logs',
        style: { minWidth: '10rem' },
        Cell: ({ row }) => {
          return (
            <Button
              variant='filled'
              color='yellow'
              style={{ background: 'black', color: 'white' }}
              onClick={() => setLogModal({ open: true, id: row.original.id })}
            >
              View Logs
            </Button>
          );
        },
      },
      {
        Header: 'Step Deadline',
        accessor: 'sla_date',
        style: { minWidth: '10rem' },
        Cell: ({ row }) => {
          return <Text>{moment(row.original.sla_date).format('DD-MM-YYYY')}</Text>;
        },
      },
      {
        Header: 'Step Finished',
        accessor: 'finished_date',
        style: { minWidth: '10rem' },
        Cell: ({ row }) => {
          return (
            <Text>
              {row.original.finished_date
                ? moment(row.original.finished_date).format('DD-MM-YYYY')
                : 'Not Finished'}
            </Text>
          );
        },
      },
    ],
    [submitted, tableLoader, errors?.errors],
  );

  const handleChange = (id, value, name) => {
    let currentDetails = { ...detailsRef.current };

    if (detailsRef.current.hasOwnProperty(id)) {
      currentDetails = {
        ...currentDetails,
        [id]: { ...currentDetails[id], [name]: value },
      };
      detailsRef.current = currentDetails;
      setDetails(currentDetails);
    } else {
      currentDetails[id] = { [name]: value };
      detailsRef.current = currentDetails;
      setDetails(currentDetails);
    }
  };

  const handleFileSelect = (files) => {
    const currentValues = { ...attachmentModal };
    const attachments = currentValues.attachments || [];
    const filesLength = attachments.length + files.length;

    if (filesLength <= MAX_ATTACHMENTS) {
      files.forEach((file) => attachments.push(file));
      currentValues.attachments = attachments;
      setAttachmentModal(currentValues);
    }
  };

  const handleFileRemove = (index) => {
    const currentValues = { ...attachmentModal };
    currentValues.attachments = currentValues.attachments.filter((i, k) => k !== index);

    setAttachmentModal(currentValues);
  };

  const handleAttachmentSubmit = async () => {
    try {
      const { info, attachments } = attachmentModal;
      const oldAttachments =
        (info?.int_rfs_impl_mrp_approval?.obsolete_attachment || '').length > 0
          ? info.int_rfs_impl_mrp_approval.obsolete_attachment.split(',')
          : [];

      const attachmentNames = new Set(attachments.map((file) => file.name));
      const deletedAttachments = oldAttachments.filter((name) => !attachmentNames.has(name));

      setAttachmentLoading(true);

      const formData = new FormData();
      formData.append('rfs_number', rfs_number);
      formData.append('status_id', attachmentModal.status_id);

      for (const item of deletedAttachments) {
        formData.append('deletedAttachments[]', item);
      }

      const chunks = attachmentChunks(attachments.filter((file) => file.size));
      if (Object.keys(chunks).length > 0) {
        for (const chunk of Object.values(chunks)) {
          for (const file of chunk) {
            formData.append('attachments', file);
          }
          await uploadAttachment(formData);
        }
      } else {
        await uploadAttachment(formData);
      }

      setAttachmentModal({ ...attachmentModal, open: false });
      refresh();
    } catch (e) {
      console.log(e);
    } finally {
      setAttachmentLoading(false);
    }
  };

  const uploadAttachment = async (formData) => {
    try {
      const res = await axios.post(`/delisting/obsoletes_attachment`, formData);
      return res;
    } catch (error) {
      console.error('Error while handling attachment submission:', error);
    }
  };

  const storeAlignedWithObsoletes = (payload) => {
    return axios.post(`delisting/obsoletes_approvals/`, payload);
  };

  const storeMrpComments = (payload) => {
    return axios.post(`delisting/obsoletes_comment/`, payload);
  };

  const validateObsoletesMrp = (data = {}) => {
    const errors = {};
    const convertedObj = Object.entries({ ...detailsRef.current }).reduce((acc, [key, value]) => {
      acc[key] = {
        scope_id: key,
        ...value,
      };
      return acc;
    }, {});

    for (let item of Object.values(convertedObj)) {
      ObsoletesMrpSchema.validate({ ...item }, { abortEarly: false })
        .then(() => {})
        .catch((err) => {
          const fieldErrors = err.errors;
          fieldErrors.forEach((error) => {
            if (!errors[item.scope_id]) errors[item.scope_id] = {};
            errors[item.scope_id][error.split(' ')[0]] = true;
          });
        });
    }

    return { errors };
  };

  const handleSubmit = (e, id, value, name) => {
    e.preventDefault();
    let validationErrors = {};
    switch (name) {
      case 'aligned_with_obsoletes':
        const payload = {
          status_id: id,
          aligned_with_obsoletes: value,
        };

        validationErrors = validateObsoletesMrp(detailsRef.current[id]);

        setTimeout(() => {
          setErrors(validationErrors);
          if (Object.keys(validationErrors.errors[id] ?? {}).length === 0) {
            setTableLoader({ ...tableLoader, [id]: { [name]: true } });
            storeAlignedWithObsoletes(payload).then(() => {
              refresh(() => setTableLoader({ ...tableLoader, [id]: { [name]: false } }));
            });
          }
        }, 500);

        break;
      case 'mrp_comments':
        if (value.length > 100) value = value.substring(0, 100);
        const payloadComments = {
          status_id: id,
          mrp_comment: value,
        };

        setTableLoader({ ...tableLoader, [id]: { [name]: true } });
        storeMrpComments(payloadComments).then(() => {
          refresh(() => setTableLoader({ ...tableLoader, [id]: { [name]: false } }));
        });
        break;

      default:
        break;
    }
  };

  const tableInstance = useTable({
    columns,
    data: data,
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  if (loading) return <AppLoader center size='md' />;

  return (
    <Styles>
      <div style={{ overflowX: 'auto', marginBottom: '2rem' }}>
        <table style={{ width: 'fitContent' }} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} id='header-row'>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()} style={{ backgroundColor: '#FAF9F6' }}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()} style={cell.column.style}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
        {attachmentModal.open && (
          <AttachmentsModal
            modal={attachmentModal}
            setModal={setAttachmentModal}
            onChange={handleFileSelect}
            onSubmit={handleAttachmentSubmit}
            onRemove={handleFileRemove}
            attachmentLoading={attachmentLoading}
            readOnly={attachmentModal.readOnly}
          />
        )}
        {logModal.open && (
          <LogModal type='Obsoletes Approval MRP' logModal={logModal} setLogModal={setLogModal} />
        )}
        {obsoletesModal.open && (
          <ObsoletesModal
            data={null}
            obsoletesModal={obsoletesModal}
            setObsoletesModal={setObsoletesModal}
          />
        )}
      </div>
    </Styles>
  );
};

export default ObsoletesMrp;
