import React, { useContext, useState } from 'react';
import { AppShell, Alert } from '@mantine/core';

import NavbarComp from '../../components/navbarcomp/NavbarComp';
import { RfsContext } from '../../contexts/RfsContext';
import SideMenu from '../sideMenu/sideMenu';
import Workflow from './workflow';
import styled from '@emotion/styled';
import { IconAlertCircle } from '@tabler/icons-react';

const StyledAlert = styled(Alert)`
  margin-bottom: 0px;
  border-radius: 0px;
  text-align: center;
  background: linear-gradient(90deg, rgb(227, 175, 50) 0%, rgb(244, 224, 15) 100%);
  color: black;
  padding: 3px;
  font-size: 14px;
`;

const updateInProgress = false;

const RfsLayout = ({ children, aside = false, trials = false, osku = false }) => {
  const { form, fullscreen } = useContext(RfsContext);
  const [leftNav, setLeftNav] = useState(true);
  const [opened, setOpened] = useState(false);

  return (
    <>
      {aside && (
        <div className='sticky top-0 z-99'>
          {!leftNav && (
            <div className={`nav-left-button`} style={{ top: '73px' }}>
              <span
                onClick={() => setLeftNav(!leftNav)}
                className='vertical-text'
                style={{
                  width: '24px',
                }}
              >
                Menu
              </span>
            </div>
          )}
          {leftNav && (
            <div className={`nav-right-button`} style={{ top: '73px' }}>
              <span
                onClick={() => setLeftNav(!leftNav)}
                className='vertical-text'
                style={{
                  width: '24px',
                }}
              >
                Workflow
              </span>
            </div>
          )}
        </div>
      )}
      <AppShell
        styles={{
          root: { height: '100vh !important' },
          body: {
            height: fullscreen
              ? '100vh'
              : updateInProgress
              ? 'calc(100vh - 101px)'
              : 'calc(100vh - 72px)',
          },
        }}
        header={
          !fullscreen ? (
            <>
              {updateInProgress && (
                <StyledAlert>
                  <IconAlertCircle size='1rem' /> New feature release is in progress. You can still
                  view the RFSes during this time however we will recommend not creating any new
                  RFSes/making any changes in the Tool during this window to avoid any data
                  corruption.
                </StyledAlert>
              )}
              <NavbarComp
                initials={form?.rfsInformation?.initiator_name}
                opened={opened}
                setOpened={setOpened}
              />
            </>
          ) : (
            ''
          )
        }
        aside={
          !leftNav && (
            <Workflow trials={trials} leftNav={leftNav} setLeftNav={setLeftNav} osku={osku} />
          )
        }
        navbar={
          !fullscreen && leftNav ? (
            <SideMenu leftNav={leftNav} setLeftNav={setLeftNav} rightNav={aside} open={opened} />
          ) : (
            ''
          )
        }
      >
        {children}
      </AppShell>
    </>
  );
};

export default RfsLayout;
