import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Button } from '@mantine/core';

import { SkuDetailsTable } from '../../components/tables/SKUDetailsTable/SkuDetailsTable';
import { filterStartsWith } from '../../components/tables/SKUDetailsTable/Helpers';
import { RfsContext } from '../../contexts/RfsContext';
import SkuRelationsModal from '../../components/modal/SkuRelationsModal';

export const Liquids = ({ handleSelect }) => {
  const {
    form,
    liquids,
    setBomModal,
    fetchLiquidBom,
    liquidsLoading,
    toggle,
    fullscreen,
    liquidsCount,
  } = useContext(RfsContext);
  const [showOnlySelected, setOnlyShowSelected] = useState(false);
  const [skuRelationsModal, setSkuRelationshipModal] = useState(false);
  const [liquidCode, setLiquidCode] = useState([]);

  const columns = [
    {
      Header: 'Liquid Code',
      accessor: 'LiquidCode',
      minWidth: 150,
      width: 150,
      maxWidth: 150,
      sticky: 'left',
    },
    {
      Header: 'Description',
      accessor: 'LiquidDescription',
      sticky: 'left',
      minWidth: 150,
      width: 150,
      maxWidth: 150,
    },
    {
      Header: 'Status',
      accessor: 'liquidStatus',
      filter: filterStartsWith,
      minWidth: 150,
      width: 150,
      maxWidth: 150,
    },
    {
      Header: 'Plants',
      accessor: 'plants',
      minWidth: 150,
      width: 150,
      maxWidth: 150,
    },
    {
      Header: 'Beer Stage',
      accessor: 'BeerStage',
      minWidth: 150,
      width: 150,
      maxWidth: 150,
    },
    {
      Header: 'Brand',
      accessor: 'Brand',
      minWidth: 150,
      width: 150,
      maxWidth: 150,
    },
    {
      Header: 'Sub Brand',
      accessor: 'SubBrand',
      minWidth: 150,
      width: 150,
      maxWidth: 150,
    },

    {
      Header: () => (
        <div style={{ textAlign: 'right' }} id='view-bom-column'>
          View BOM
        </div>
      ),
      id: 'bom',
      disableSortBy: true,
      Cell: ({ row }) =>
        row.original.liquidStatus.toLowerCase() === 'active' ? (
          <Button
            color='yellow'
            style={{background:'black', color: 'white' }}
            onClick={() => {
              fetchLiquidBom(row.original.LiquidCode);
              setBomModal({ state: true, type: 'liquid' });
            }}
          >
            View BOM
          </Button>
        ) : (
          <div />
        ),
    },

    {
      Header: () => <div id='view-bom-column'>View SKU Relationships</div>,
      id: 'relationships',
      disableSortBy: true,
      Cell: ({ row }) =>
        row.original.liquidStatus.toLowerCase() === 'active' ? (
          <Button
            color='yellow'
            style={{background:'black', color: 'white' }}
            onClick={() => {
              setLiquidCode(row.original.LiquidCode);
              setSkuRelationshipModal(true);
            }}
          >
            View SKU Relationships
          </Button>
        ) : (
          <div />
        ),
    },
  ];

  const getExportData = (items) => {
    let data = [];
    data = items.map((item) => item.original);
    return data;
  };

  const handleExport = (rows) => {
    if (form.scope.liquids.length > 0) {
      return getExportData(form.scope.liquids);
    } else {
      return liquids;
    }
  };

  const filterSelectedRows = useMemo(() => {
    let data = [...liquids];
    data = data.filter((res) => {
      if (form.selectedLiquids[res.LiquidCode]) return res;
    });
    return data;
  }, [Object.keys(form.selectedLiquids).length, showOnlySelected]);

  const handleToggle = (data) => {
    setOnlyShowSelected(data);
  };

  return (
    <div className='table-container'>
      <SkuDetailsTable
        columns={columns}
        data={showOnlySelected ? filterSelectedRows : liquids}
        handleSelect={handleSelect}
        type={'liquids'}
        handleExport={handleExport}
        loading={liquidsLoading}
        fullScreenToggle={toggle}
        fullscreen={fullscreen}
        selectedIds={form.selectedLiquids}
        noSelectAll
        handleToggle={handleToggle}
        showOnlySelected={showOnlySelected}
        toggleSelected={true}
        count={liquidsCount}
      />{' '}
      {skuRelationsModal && (
        <SkuRelationsModal
          liquidCode={liquidCode}
          opened={skuRelationsModal}
          setOpened={setSkuRelationshipModal}
        />
      )}
    </div>
  );
};
