import React, { useState, useEffect, useContext, useRef } from 'react';
import { Modal, Button, Grid, Text, Paper } from '@mantine/core';
import { axios } from '../../utils/axios';

import './SkuRelationsModal.css';
import { Tree } from '../common/treeview';
import { RfsContext } from '../../contexts/RfsContext';
import { AppLoader } from '../../components/common/loader/index';

function SkuRelationsModal({ opened, setOpened, liquidCode, selectionType = 'selectedLiquids' }) {
  const { form, setForm, setInfoLoading } = useContext(RfsContext);
  const [mother, setMother] = useState([]);
  const [daughter, setDaughter] = useState([]);
  const [selected, setSelected] = useState({});
  const [loading, setLoading] = useState(true);
  const rfs_type = form.rfsInformation.rfs_type;

  useEffect(() => {
    fetchRelationships('mother');
    fetchRelationships('daughter');
  }, []);

  const fetchRelationships = (type, code = liquidCode) => {
    let selectedCode = code === liquidCode ? liquidCode : code.value;
    let payload = selectedCode.split('_');

    axios
      .get(
        ['Production Site', 'Delisting', 'Open SKU to market'].includes(rfs_type)
          ? `/production-site/relationships/${type}/${selectedCode}`
          : `/liquids/relationships/${type}/${payload[0]}`,
      )
      .then(
        (res) => {
          let response = res.data.data.relationships;
          let relationships = makeData(
            response,
            code,
            selectedCode !== liquidCode ? 'child' : 'parent',
          );

          if (selectedCode !== liquidCode) {
            if (type === 'mother') {
              let data = [...mother];
              let values = updateTree(selectedCode, data, relationships);
              setMother([...values]);
            } else {
              let values = updateTree(selectedCode, [...daughter], relationships);
              setDaughter([...values]);
            }
          } else {
            if (type === 'mother') {
              setMother(relationships);
            } else {
              setDaughter(relationships);
            }
          }
        },
        (error) => {
          console.log(error);
        },
      )
      .finally(() => setLoading(false));
  };

  function updateTree(code, data, relationships) {
    const index = data.findIndex((val) => val.value === code);
    if (index > -1) {
      data[index]['children'] = relationships;
    }
    if (index < 0 && data.length > 0) {
      let iterator = 1000;
      if (data.length < 1000) iterator = data.length;
      for (var i = 0; i < iterator; i++) {
        if (data[i].children) data[i].children = updateTree(code, data[i].children, relationships);
      }
    }

    return data;
  }

  const makeData = (data, parent, type) => {
    if (data) {
      (data || []).forEach((d, k) => {
        data[k]['name'] = `${d.Component} ${d.componentDescription}`;
        data[k]['value'] = `${d.Component}_${Date.now()}`;
        data[k]['child'] = type === 'parent' ? 0 : parent.child + 1;
      });
    }

    return data;
  };

  const handleExpanded = (data, type) => {
    fetchRelationships(type, data);
  };

  const handleSkuSelect = (state, val) => {
    setSelected({ ...selected, [`${val.Component}`]: state });
  };

  const confirm = () => {
    setForm((o) => {
      return {
        ...o,
        [selectionType]: {
          ...o[selectionType],
          ...selected,
        },
      };
    });

    setOpened(false);
    //setInfoLoading(true);
    //setTimeout(() => {
    //window.scrollTo(0, scrollY);
    // setInfoLoading(false);
    //}, 200);
  };

  return (
    <Modal
      className='sku-relations-modal'
      overflow='outside'
      opened={opened}
      title='SKU Relationships'
      onClose={() => setOpened(false)}
    >
      <Paper
        padding='md'
        shadow='sm'
        radius='md'
        style={{
          margin: '20px 20px 20px 20px',
          backgroundColor: '#EFEFEF',
          minHeight: '21rem',
          maxHeight: '21rem',
         
        }}
      >
        <Grid style={{ marginTop: '0.5rem' }}>
          <Grid.Col span={12} style={{ margin: '0 1rem' }}>
            <Text weight='500' style={{ marginBottom: '1rem' }}>
              Mother SKUs
            </Text>

            {loading ? (
              <AppLoader />
            ) : mother.length === 0 ? (
              <Text>No Mother SKUs available</Text>
            ) : (
              <Tree
                entries={mother}
                onExpand={(data) => handleExpanded(data, 'mother')}
                handleSkuSelect={handleSkuSelect}
              />
            )}
          </Grid.Col>

          <Grid.Col span={12} style={{ margin: '0 1rem' }}>
            <Text weight='500' style={{ marginBottom: '1rem' }}>
              Daughter SKUs
            </Text>

            {loading ? (
              <AppLoader />
            ) : daughter.length === 0 ? (
              <Text>No Daughter SKUs available</Text>
            ) : (
              <Tree
                entries={daughter}
                onExpand={(data) => handleExpanded(data, 'daughter')}
                handleSkuSelect={handleSkuSelect}
              />
            )}
          </Grid.Col>
        </Grid>
      </Paper>

      <Grid justify='flex-end'>
        <Grid.Col span={3}>
          <Button onClick={() => confirm()} fullWidth variant='filled' color='yellow'
          style={{ marginTop: '1rem' ,background:'linear-gradient(90deg,#e3af32 0%,#f4e00f 100%)', color: 'black' }}>
            Confirm
          </Button>
        </Grid.Col>
      </Grid>
    </Modal>
  );
}

export default SkuRelationsModal;
